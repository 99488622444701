import axios from "axios";
const api = `${process.env.REACT_APP_API}`;
const options = [];
let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
    'Content-Type': 'multipart/form-data'
  },
};

// for (let i = 0; i < 50; ++i) {
//   options.push({
//     value: i + 1,
//     label: `Teman ${i + 1}`
//   });
// }

const getFriend = (data) => {
  // let data = []
  let jwtToken = localStorage.getItem("jwtToken");
  let config = {
    headers: {
      Authorization: "Bearer " + jwtToken,
      'Content-Type': 'multipart/form-data'
    },
  };
  let formData = new FormData();
  formData.append('username', data);
  axios.post(api + 'friend/get-friend', formData, config)
    .then(res => {
      let resp = res.data.data.items
      for (let i = 0; i < resp.length; ++i) {
        options.push({
          value: resp[i].username,
          label: resp[i].name
        });
      }
    })
}

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const friendOptions = async (search, prevOptions) => {
  await sleep(1000);
  console.log(options)
  let filteredOptions;
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ name }) =>
      name.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore
  };
};

export { getFriend, friendOptions };
