import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import { getUserDetail } from 'redux/actions/UserAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";

const api = `${process.env.REACT_APP_API}`;
let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
    'Content-Type': 'multipart/form-data'
  },
};

const optionsReligion = [
  { value: 'Islam', label: 'Islam' },
  { value: 'Kristen', label: 'Kristen' },
  { value: 'Hindu', label: 'Hindu' },
  { value: 'Budha', label: 'Budha' },
  { value: 'Katolik', label: 'Katolik' },
  { value: 'Khonghuchu', label: 'Khonghuchu' },
  { value: 'Aliran Kepercayaan & Tidak Beragama', label: 'Aliran Kepercayaan & Tidak Beragama' }
];
const optionsGender = [
  { value: '0', label: 'Laki-laki' },
  { value: '1', label: 'Perempuan' },
]
const optionsBlood = [
  { value: 'A', label: 'A' },
  { value: 'AB', label: 'AB' },
  { value: 'B', label: 'B' },
  { value: 'O', label: 'O' },
]
const optionsEdu = [
  { value: 'SD/Sederajat', label: 'SD/Sederajat' },
  { value: 'SMP/Sederajat', label: 'SMP/Sederajat' },
  { value: 'SMA/Sederajat', label: 'SMA/Sederajat' },
  { value: 'Diploma_1', label: 'Diploma 1' },
  { value: 'Diploma_2', label: 'Diploma 2' },
  { value: 'Diploma_3', label: 'Diploma 3' },
  { value: 'Strata_1', label: 'Strata 1' },
  { value: 'Strata_2', label: 'Strata 2' },
  { value: 'Strata_3', label: 'Strata 3' },
]

class ProfileGU extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      dataUser: {},

      genderLabel: null,
    }
    this.selectGender = this.selectGender.bind(this)
    this.selectReligion = this.selectReligion.bind(this)
    this.selectBlood = this.selectBlood.bind(this)
    this.selectEdu = this.selectEdu.bind(this)
    this.edit = this.edit.bind(this)
  }
  static getDerivedStateFromProps(nextProps, state) {
    let genderLabel = null;
    if (nextProps.data != state.data) {
      
      switch (nextProps.data.jenis_kelamin) {
        case 1:
          genderLabel = 'Laki-laki'
          break;
        case 0:
          genderLabel = 'Perempuan'
          break;
      }
      return {
        dataUser: nextProps.data,
        data: nextProps.data,
        genderLabel: genderLabel,
      }
    }
  }
  selectGender(e) {
    if (e) {
      this.setState({ dataUser: { ...this.state.dataUser, jenis_kelamin: e.label } })
    }
  }
  selectReligion(e) {
    if (e) {
      this.setState({ dataUser: { ...this.state.dataUser, agama: e.label } })
    }
  }
  selectBlood(e) {
    if (e) {
      this.setState({ dataUser: { ...this.state.dataUser, golongan_darah: e.label } })
    }
  }
  selectEdu(e) {
    if (e) {
      this.setState({ dataUser: { ...this.state.dataUser, pendidikan_terakhir: e.label } })
    }
  }
  edit(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
    };
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    // check group code
    let data = this.state.dataUser;
    delete data.password;
    requestData = {
      ...data,
      jenis_kelamin: data.jenis_kelamin,
      tempat_lahir: data.tempat_lahir,
      tanggal_lahir: data.tanggal_lahir,
      agama: data.agama,
      kk: data.kk,
      nik: data.nik,
      nip: data.nip,
      npwp: data.npwp,
      paspor: data.paspor,
      golongan_darah: data.golongan_darah,
      pendidikan_terakhir: data.pendidikan_terakhir,
      motto: data.motto
    };
    this.props.uploading()
    formData.append('json', JSON.stringify(requestData));
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }
  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data)
  }
  render() {
    return (
      <>
        <form action="" className="uk-grid" onSubmit={this.edit.bind(this)}>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Jenis Kelamin</label>
              <Select
                options={optionsGender}
                onChange={this.selectGender}
                defaultValue={this.state.dataUser.jenis_kelamin != null ?
                  { value: this.state.dataUser.jenis_kelamin, label: this.state.genderLabel }
                  : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Agama</label>
              <Select
                options={optionsReligion}
                onChange={this.selectReligion}
                defaultValue={this.state.dataUser.agama != null ? { value: this.state.dataUser.agama, label: this.state.dataUser.agama } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Tempat Lahir</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="text" placeholder="Input"
                  value={this.state.dataUser.tempat_lahir}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, tempat_lahir: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Tanggal Lahir</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="date" placeholder="Input"
                  value={this.state.dataUser.tanggal_lahir}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, tanggal_lahir: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Golongan Darah</label>
              <Select options={optionsBlood}
                onChange={this.selectBlood}
                defaultValue={this.state.dataUser.golongan_darah != null ? { value: this.state.dataUser.golongan_darah, label: this.state.dataUser.golongan_darah } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Pendidikan Terakhir</label>
              <Select options={optionsEdu}
                onChange={this.selectEdu}
                defaultValue={this.state.dataUser.pendidikan_terakhir != null ? { value: this.state.dataUser.pendidikan_terakhir, label: this.state.dataUser.pendidikan_terakhir } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Motto</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="text" placeholder="Input"
                  value={this.state.dataUser.motto}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, motto: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>

          <div className="uk-width-1-1@s uk-width-1-1 pr-2 pt-2">
            <hr />
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">KK</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  value={this.state.dataUser.kk}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, kk: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">NIK</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  value={this.state.dataUser.nik}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, nik: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">NIP</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  value={this.state.dataUser.nip}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, nip: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">NPWP</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  value={this.state.dataUser.npwp}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, npwp: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Paspor</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  value={this.state.dataUser.paspor}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, paspor: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1 pt-5">
              <input type="submit" className="ml-2 button small btn-schoolmedia" value="Simpan" />
            </div>
          </div>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
  getUserDetail: getUserDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGU);