const initialState = {
  SnackbarOpen: false,
  SnackbarMessage: 'tes',
  SnackbarDuration: false,
}
const toastsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_ON_PROGRESS":
      return {
        ...state,
        SnackbarOpen: true,
        SnackbarMessage: action.message,
        UploadStatus: action.status,
        UploadInfo: 'pending'
      };
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        SnackbarOpen: null,
        SnackbarDuration: 4000,
        SnackbarMessage: action.message,
        UploadStatus: action.status,
        UploadInfo: 'success'
      };
    case "UPLOAD_ERROR":
      return {
        ...state,
        SnackbarDuration: 4000,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        SnackbarMessage: action.message,
        UploadStatus: action.status,
        UploadInfo: 'error'
      };
    default:
      return state;
  }
};

export default toastsReducer;
