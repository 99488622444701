import React from "react";

export default class ActivityLogItem extends React.Component {

  convertDate(date) {
    let d = new Date(date)
    return d.toLocaleString().split('.').join(':')
  }

  render() {
    return (
      <li>
        <a href="javascript:void(0)" className="p-0" style={{ whiteSpace: 'unset' }}>
          <div className="uk-flex uk-flex-top p-2 w-100">
            <div className="page-card-media">
              <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
            </div>
            <div className="page-card-innr" style={{ margin: "0 10px" }}>
              <p className="small text-dark"> {this.props.information} </p>
              <p className="small">{this.convertDate(this.props.date)}</p>
            </div>
          </div>
        </a>
      </li>
    )
  }
}
