import React from "react";

export default class SuggestedGroupCard extends React.Component {
  componentDidMount() {
  }

  render() {
    return(
      <div className={this.props.className}>
          <div className="group-card bg-white">
              <div className="group-card-thumbnail">
                  <img src="/assets/images/group/group-cover-4.jpg" alt="" />
              </div>

              <div className="group-card-content">
                  <h3> Nature Lovers </h3>
                  <p className="info"> <a href="#"> <span> 232k members </span> </a>
                      <a href="#"> <span> 1.7k post a day </span> </a>
                  </p>
                  <div className="group-card-member-wrap">
                      <div className="avatar-group uk-width-auto">
                          <img alt="Image placeholder" src="/assets/images/avatars/avatar-3.jpg" className="avatar avatar-xs rounded-circle" />
                          <img alt="Image placeholder" src="/assets/images/avatars/avatar-1.jpg" className="avatar avatar-xs rounded-circle" />
                      </div>
                      <div className="uk-width-expand pl-2">
                          <p> <strong>Dolgove</strong> and 2 freind are members </p>
                      </div>
                  </div>

                  <div className="group-card-btns">
                      <a href="#" className="button btn-schoolmedia small">Join </a>
                      <a href="#" className="button btn-default small"> View </a>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}
