import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import timeAgo from 'utils/timeAgo';
import Skeleton from '@material-ui/lab/Skeleton';
import isEmpty from "lodash/isEmpty";

const api = `${process.env.REACT_APP_API}`;
const urlStatic = `${process.env.REACT_APP_SM_STATIC_URI}`;

export default class PostChats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChat: [],
      loadingGetChat: false,
      countToScroll: 1,
      lastPage: 1,
    }
    this.replyMessage = this.replyMessage.bind(this);
  }
  componentDidMount() {
    const receiptName = this.state.receiptName;
    if(this.props.receiptName != receiptName){
      this.setState({
        dataChat : [],
      })
    }
    this.setState({
      senderName: this.props.senderName,
      receiptName: this.props.receiptName,
    }, function(){
      if (this.props.refresh == true) {
        this.setState({ countToScroll: 1 }, function(){
          this.getChats(true, false, false);
        });
      } 
    })
  }
  componentWillReceiveProps(nextProps) {
    const receiptName = this.state.receiptName;
    if(nextProps.receiptName != receiptName){
      this.setState({
        dataChat : [],
      })
    }
    this.setState({
      senderName: nextProps.senderName,
      receiptName: nextProps.receiptName,
    }, function(){
      if (nextProps.refresh == true) {
        this.setState({ countToScroll: 1 }, function(){
          this.getChats(true, false, false);
        });
      }
    })
  }
  getChats(statusGetLastPage, statusGetData, statusPagination) {
    if (this.state.countToScroll > 0) {
      let jwtToken = localStorage.getItem("jwtToken")
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      }
      let params = {
        username: this.props.receiptName,
        page: { number : this.state.countToScroll },
      }
      axios.post(api + 'message/get-message', params, config)
        .then(res => {

          if (statusGetLastPage) {
            console.log("getLast");
            this.setState({
              countToScroll : res.data.data.last_page
            }, function(){
              this.getChats(false, true, false)
            })
          }

          if (statusGetData) {
            console.log("onComing");
            this.setState({
              dataChat : res.data.data.data
            }, function(){
              this.refreshChat(false)
            })
          }

          if (statusPagination) {
            console.log("Paginate");
            const nextDataChat = res.data.data.data
            if (res.data.data.data === []) {
              this.setState({
                dataChat: res.data.data.data,
              })
            }
            this.setState({
              dataChat: [
                ...nextDataChat,
                ...this.state.dataChat,
              ],
            })
          }
        })
        .catch(err => {
          this.refreshChat(false);
        })
    }
  }
  refreshChat() {
    var objDiv = document.getElementById("listChat");
    objDiv.scrollTop = objDiv.scrollHeight;
    this.props.refreshChat(false);
  }
  focusToChat(id) {
    document.getElementById(id+"-pesan").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
  }
  
  showChat() {
    var dateChat = null;
    var showDate = [];
    var monthList = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    
    return (
      this.state.dataChat.map((value, key) => {
        var dateChatNow = new Date(value.created_at);
        dateChatNow = String(dateChatNow.getDate() + " / " + monthList[dateChatNow.getMonth()] + " / " +dateChatNow.getFullYear())
        if (dateChat !== dateChatNow) {
          dateChat = dateChatNow;
          showDate.push(
            <div className="message-time-sign">
              <span>{dateChat}</span>
            </div>
          )
        } else {
          showDate = [];
        }
        return (
          <>
            {showDate}
            <div id={value.id+"-pesan"} className={value.sender_name === this.state.senderName ? "message-bubble me" : "message-bubble"}>
              <div className="message-bubble-inner">
                <div className="message-avatar">
                  {!isEmpty(value.sender_photo_profile) 
                    ? (<img src={`${urlStatic + value.sender_group_code}/photo/${value.sender_photo_profile}`} alt="" />) 
                    : (<img src='/assets/icons/user.svg' />)
                  }
                </div>
                <div className="message-text">
                  {!isEmpty(value.reply_message_content) ? (
                    <div onClick={this.focusToChat.bind(this, value.reply_to_message_id)} className="message-replied">
                      {value.reply_message_content}
                    </div>
                  ) : null}
                  <div className={value.sender_name === this.state.senderName ? "w-100 d-inline-flex flex-row-reverse" : "w-100 d-inline-flex"}>
                    <div>
                      <p className={value.sender_name === this.state.senderName ? "text-white" : ""} dangerouslySetInnerHTML={{ __html: this.urlify(value.content) }}></p>
                      <small><b>{timeAgo(value.created_at)}</b></small>
                    </div>
                    <a className={value.sender_name === this.state.senderName ? "mr-4 text-white" : "text-black ml-4"} href="#" uk-tooltip="More">
                      <i className="icon-material-outline-keyboard-arrow-down"></i>
                    </a>
                    <div uk-dropdown="pos: top ; mode: click ;animation: uk-animation-slide-bottom-small">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li><div onClick={this.replyMessage.bind(this, value)}> <i className="icon-feather-corner-up-left"> Balas</i> </div></li>
                      </ul>
                    </div>
                    {/* <div class={value.sender_name === this.state.senderName ? "btn-group btn-group-sm mr-4 bg-light" : "btn-group btn-group-sm ml-4"} role="group" style={{height : "20px"}}>
                      <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <div class="dropdown-item" onClick={this.replyMessage.bind(this, value)}><i className="icon-feather-corner-up-left"> Balas</i></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="uk-clearfix"></div>
            </div>
          </>
        )
      })
    )
  }

  replyMessage(data){
    this.props.replyMessage(data);
  }

  render() {
    if(!isEmpty(document.getElementById("listChat"))){
      var objDiv = document.getElementById("listChat");
      objDiv.onscroll = () => {
        if (objDiv.scrollTop == 0) {
          this.setState({countToScroll: this.state.countToScroll - 1}, function(){
            this.getChats(false, false, true);
          })
        }
      }
    }
    
    return(
      <>
        <div id="listChat" className="message-content-inner"> 
          {this.state.dataChat.length > 0 ? (
              this.showChat()
            ) : (
              <div className="message-time-sign"><span>Belum ada pesan</span></div>
            )
          }
        </div>
      </>
    )
  }
}

