import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import { withRouter, useLocation } from 'react-router-dom';

import Navbar from '../component/partials/Navbar';
import LeftSideBar from '../component/partials/LeftSideBar';

// Route for Global component
const GlobalRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} component={(props) => (
      <>
        <LeftSideBar />
        <Component {...props} />
      </>
    )}
    />
  )
}

export default GlobalRoute;