import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

// css
import '../../assets/css/sidebar.css';

class LeftSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      profilePhoto: null,
      groupCode: null,
      intGroupCode: null
    }
  }
  static getDerivedStateFromProps(props, nextProps) {
    if (props.userReducer !== nextProps.userReducer) {
      switch (props.userReducer.userDetail.group_code) {
        case 'NI':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.logo,
            groupCode: 'Institusi',
            intGroupCode: 'NI'
          })
          break;
        case 'GU':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.foto_profil,
            groupCode: 'Guru',
            intGroupCode: 'GU'
          })
          break;
        case 'LA':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.foto_profil,
            groupCode: 'Lainnya',
            intGroupCode: 'LA'
          })
          break;
        case 'SI':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.foto_profil,
            groupCode: 'Siswa',
            intGroupCode: 'SI'
          })
          break;
        case 'OW':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.foto_profil,
            groupCode: 'Orang Tua',
            intGroupCode: 'OW'
          })
          break;
        case 'PU':
          return ({
            userData: props.userReducer.userDetail,
            profilePhoto: props.userReducer.userDetail.foto_profil,
            groupCode: 'Publik',
            intGroupCode: 'PU'
          })
          break;
        default:
          break;
      }
      // if(props.userReducer.userDetail.group_code === 'NI'){
      //   return({
      //     groupCode: props.userReducer.userDetail.group_code,
      //     profilePhoto: props.userReducer.userDetail.logo
      //   })
      // } else {
      //   return({
      //     groupCode: props.userReducer.userDetail.group_code,
      //     profilePhoto: props.userReducer.userDetail.foto_profil
      //   })
      // }
    }
  }

  _handleSideBar() {
    const data = this.props.auth.user;
    if (!isEmpty(data)) {
      return (
        <>
        <div className="main_sidebar">
        <div className="side-overlay" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></div>

        {/* // <!-- sidebar header --> */}
        <div className="sidebar-header">
          <h4> Navigation</h4>
          <span className="btn-close" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></span>
        </div>

        {/* <!-- sidebar Menu --> */}
        <div className="sidebar">
          <div className="sidebar_innr" data-simplebar>
            <div className="sections">
              <ul>
                <li className="sidebar-profile pt-2 pb-2">
                  <Link to={`/${this.state.userData.username}`}>

                    {this.state.profilePhoto != null ?
                      this.state.profilePhoto === "null" ?
                        <img src="/assets/icons/user.svg" alt=""></img>
                        :
                        <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.intGroupCode}/photo/` + this.state.profilePhoto} style={{ borderRadius: "50%" }} alt=""/>
                      :
                      <img src="/assets/icons/user.svg" alt=""></img>
                    }
                    <span className="uk-flex uk-flex-column" style={{ lineHeight: "1.2", display: "flex" }}> {this.state.groupCode === "Institusi" ? this.state.userData.nama_institusi : this.state.userData.nama}
                      <span className="font-xs">{this.state.groupCode}</span>
                    </span>
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <NavLink exact to="/timeline">
                    <img src="/assets/icons/sidebar/timeline.svg" alt=""></img>
                    <span> Timeline </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chats">
                    <img src="/assets/icons/sidebar/pesan.svg" alt=""></img>
                    <span> Pesan </span>
                  </NavLink>
                </li>
                <li>
                  <a href="https://event.schoolmedia.id/">
                    <img src="/assets/icons/sidebar/siaran.svg" alt=""></img>
                    <span> Siaran Langsung </span>
                  </a>
                </li>
                <li>
                  <NavLink to={"/"+this.state.userData.username+"/photos"}>
                    <img src="/assets/icons/sidebar/foto.svg" alt=""></img>
                    <span> Foto </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/"+this.state.userData.username+"/videos"}>
                    <img src="/assets/icons/sidebar/video.svg" alt=""></img>
                    <span> Video / Siaran Ulang </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/content-exchange">
                    <img src="/assets/icons/sidebar/bursa.svg" alt=""></img>
                    <span> Bursa Konten </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/notes">
                    <img src="/assets/icons/sidebar/catatan.svg" alt=""></img>
                    <span> Catatan </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/groups">
                    <img src="/assets/icons/sidebar/following-grup.svg" alt=""></img>
                    <span> Forum </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/news">
                    <img src="/assets/icons/sidebar/berita.svg" alt=""></img>
                    <span> Berita </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/applications">
                    <img src="/assets/icons/sidebar/aplikasi.svg" alt=""></img>
                    <span> Aplikasi </span>
                  </NavLink>
                </li>
              </ul>

              {/* <a href="#" className="button btn-default px-5 btn-more" uk-toggle="target: #more-veiw; animation: uk-animation-fade">
                            <span id="more-veiw">Lihat Lainnya <i className="icon-feather-chevron-down ml-2"></i></span>
                            <span id="more-veiw" hidden>Lebih Sedikit <i className="icon-feather-chevron-up ml-2"></i></span>
                        </a> */}
            </div>

            <div className="sections">
              <h3> Personal </h3>
              <ul>
                <li>
                  <NavLink to={`/${this.state.userData.username}`}>
                    <img src="/assets/icons/sidebar/akun.svg" alt=""></img>
                    <span> Akun </span>
                    {/* <span className="dot-notiv"></span> */}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/content-exchange">
                    <img src="/assets/icons/sidebar/bursa.svg" alt=""></img>
                    <span> Konten </span>
                    {/* <span className="dot-notiv"></span> */}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/groups">
                    <img src="/assets/icons/sidebar/following-grup.svg" alt=""></img>
                    <span> Grup yang diikuti </span>
                    {/* <span className="dot-notiv"></span> */}
                  </NavLink>
                </li>
              </ul>

              {/* <a href="#" className="button btn-default px-5 btn-more" uk-toggle="target: #more-veiw-2; animation: uk-animation-fade">
                <span id="more-veiw-2">Lihat Lainnya <i className="icon-feather-chevron-down ml-2"></i></span>
                <span id="more-veiw-2" hidden>Lebih Sedikit<i className="icon-feather-chevron-up ml-2"></i> </span>
              </a> */}
            </div>

            {/* <!-- Optional Footer  --> */}
            <div id="foot">
              <ul>
                <li> <a href="page-term.html"> About Us </a></li>
                <li> <a href="page-setting.html"> Setting </a></li>
                <li> <a href="page-privacy.html"> Privacy Policy </a></li>
                <li> <a href="page-term.html"> Terms - Conditions </a></li>
              </ul>

              <div className="foot-content items-center" style={{ textAlign: "center" }}>
                <p>© 2018 <strong>Schoolmedia</strong>. All Rights Reserved. </p>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
      )
    }
  }

  render() {
    return (
      // <!-- sidebar -->
        <div>
        {this._handleSideBar()}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(LeftSideBar);
