import React from "react";
import axios from "axios";
import _, { map, pluck } from "underscore";
import CircularProgress from "@material-ui/core/CircularProgress";
import timeAgo from "utils/timeAgo";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const api = `${process.env.REACT_APP_API}`;

class PostComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupCode: null,
      userData: "",
      data: {},
      type: null,
      userName: null,
      commentList: [],
      repliedList: [],

      toReply: null,
      replyUsername: null,
      addCommentText: "",
      addRepliedText: null,

      picBeforeUp: null,
      URLpicBeforeUp: null,
      NamePicBeforeUp: null,

      picBeforeReply: null,
      URLpicBeforeReply: null,
      NamePicBeforeReply: null,

      viewAll: true,
      numberToShow: 3,
      numberShowMore: null,

      isLoading: false,
      isRepliedLoading: false,

      commentType: "feed",
      // thread
      forumCode: null,
      topicId: null,
      destId: null,
    };

    this.addComment = this.addComment.bind(this);
    this.addCommentText = this.addCommentText.bind(this);

    this.addReplied = this.addReplied.bind(this);
    this.addRepliedText = this.addRepliedText.bind(this);

    this._deletePicBeforeUp = this._deletePicBeforeUp.bind(this);
    this._deletePicBeforeReply = this._deletePicBeforeReply.bind(this);

    this.countMore = this.countMore.bind(this);
    this.viewMore = this.viewMore.bind(this);
    this.getJokes = this.getJokes.bind(this);

    this.destroyComment = this.destroyComment.bind(this);
    this.handleIncomingData = this.handleIncomingData.bind(this);
    this.countMore();
  }

  numberShowMore = null;

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, state) {
    // console.log(nextProps);
    if (nextProps.commentData != state.commentList) {
      // console.log(nextProps);
      let data = nextProps.commentData;
      if (nextProps.commentData != undefined) {
        let items = data;
        let commentArray = [];

        for (let i = 0; i < items.length; i++) {
          if (items[i].parent_id === null) {
            commentArray.push({
              id: items[i].id,
              comment: items[i],
              replied: [],
            });
          }
        }
        let commentList = _.indexBy(commentArray, "id");
        items.forEach((element, key) => {
          if (element.parent_id != null) {
            if (commentList[element.parent_id] != undefined) {
              commentList[element.parent_id].replied.push(element);
            }
          }
        });
        let result = _.toArray(commentList);
        // this.handleIncomingData(items);
        // console.log("res", items);
        return {
          groupCode: nextProps.userReducer.userDetail.group_code,
          userData: nextProps.userReducer.userDetail,
          data: nextProps.postData,
          userName: nextProps.userName,
          commentList: result,
          // numberToShow: nextProps.numberToShow,
          type: nextProps.type,
          commentType: nextProps.commentType,
          forumCode: nextProps.forumCode,
          topicId: nextProps.topicId,
          destId: nextProps.destId,
        };
      } else {
        return null;
      }
    }
  }

  handleIncomingData(data) {
    let items = data;
    let commentArray = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].parent_id === null) {
        commentArray.push({ id: items[i].id, comment: items[i], replied: [] });
      }
    }
    let commentList = _.indexBy(commentArray, "id");
    items.forEach((element, key) => {
      if (element.parent_id !== null) {
        if (commentList[element.parent_id] !== undefined) {
          commentList[element.parent_id].replied.push(element);
        }
      }
    });
    let result = _.toArray(commentList);
    this.setState({
      commentList: result,
    });
  }

  countMore() {
    let length = this.state.commentList.length;
    let total = null;
    if (length > 3) {
      total = length - 3;
    }
    if (total != null) {
      this.setState({
        numberShowMore: total,
      });
    }
  }

  viewMore(e) {
    let length = this.state.commentList.length;
    this.setState({
      numberToShow: length,
    });
  }

  countTime() {}

  async getJokes() {
    let url = "comment/get-comment";
    let forumCode = this.getForumCode();

    switch (this.state.commentType) {
      case "thread":
        url = "topic-comment/get-topic-comment";
        break;

      default:
        url = "comment/get-comment";
        break;
    }

    let requestData = {
      dest_id: this.state.data.id,
      type: this.state.type,
      "page[number]": this.state.currentPage,
    };
    if (this.state.commentType === "thread") {
      let requestDataThread = {
        forum_code: forumCode,
        topic_id: this.state.topicId,
        type: 1,
        dest_id: this.state.destId,
      };
      requestData = {
        ...requestData,
        ...requestDataThread,
      };
    }
    let getToken;

    try {
      getToken = await localStorage.getItem("jwtToken");
    } catch {}

    if (getToken) {
      let config = {
        headers: {
          Authorization: "Bearer " + getToken,
        },
      };
      axios.post(api + url, requestData, config).then((res) => {
        // this.setState({
        //   commentList: res.data.data.items,
        // });
        this.handleIncomingData(res.data.data.items);
      });
    }
  }

  getForumCode() {
    const url = window.location.pathname;
    let arr = [];
    arr = url.split("-");
    let res = [];
    let toRes = arr[arr.length - 1];
    res = toRes.split("/");

    return res[0];
  }

  addComment(e) {
    e.preventDefault();
    let url = "comment/post-comment";
    let forumCode = this.getForumCode();

    switch (this.state.commentType) {
      case "thread":
        url = "topic-comment/post-topic-comment";
        break;

      default:
        url = "comment/post-comment";
        break;
    }
    this.setState({
      isLoading: true,
    });
    const inputElm = document.getElementById("commentInput");
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    let formData = new FormData();
    formData.append("dest_id", this.state.data.id);
    formData.append("type", this.state.type);
    formData.append("comment", this.state.addCommentText);
    if (this.state.picBeforeUp != null) {
      formData.append("image", this.state.picBeforeUp);
    }
    // thread
    if (this.state.commentType === "thread") {
      formData.append("forum_code", forumCode);
      formData.append("topic_id", this.state.topicId);
    }
    axios
      .post(api + url, formData, config)
      .then(res => {
        console.log(res);
        this.setState({
          isLoading: false,
        });
        this._deletePicBeforeUp();
        this.getJokes();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
    this.setState({
      addCommentText: "",
    });
    inputElm.value = "";
  }

  addCommentText(e) {
    // console.log(e.target.value);
    if (e) {
      this.setState({
        addCommentText: e.target.value,
      });
    }
  }

  addReplied(e) {
    this.setState({
      isRepliedLoading: true,
    });

    let url = "comment/post-comment";
    let forumCode = this.getForumCode();

    switch (this.state.commentType) {
      case "thread":
        url = "topic-comment/post-topic-comment";
        break;

      default:
        url = "comment/post-comment";
        break;
    }

    const inputElm = document.getElementById("repliedInput");
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    let formData = new FormData();
    formData.append("dest_id", this.state.data.id);
    formData.append("type", this.state.type);
    formData.append("parent_id", e);
    formData.append("comment", this.state.addRepliedText);
    if (this.state.picBeforeReply != null) {
      formData.append("image", this.state.picBeforeReply);
    }

    if (this.state.commentType === "thread") {
      formData.append("forum_code", forumCode);
      formData.append("topic_id", this.state.topicId);
    }
    axios
      .post(api + url, formData, config)
      .then((res) => {
        this.getJokes();
        this._deletePicBeforeReply();
        // No Loading
        this.setState({
          isRepliedLoading: false,
        });
      })
      .catch((err) => {
        // No Loading
        this.setState({
          isRepliedLoading: false,
        });
      });
    this.setState({
      addRepliedText: null,
    });
    inputElm.value = "";
  }

  addRepliedText(e) {
    if (e) {
      this.setState({
        addRepliedText: e.target.value,
      });
    }
  }

  postHeaderComponent() {
    let time = "-";
    if (this.state.data.created_at != null) {
      time = timeAgo(this.state.data.created_at);
    }
    const dropDownSame = () => {
      return (
        <>
          <ul className="uk-nav uk-dropdown-nav">
            <li
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                this.destroyComment(this.state.commentList.id);
              }}
            >
              <a className="text-red">
                <i className="uil-trash-alt mr-1"></i> Delete
              </a>
            </li>
          </ul>
        </>
      );
    };

    return;
  }

  destroyComment(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    let requestData = {
      dest_id: e,
    };
    axios
      .post(api + "comment/destroy-comment", requestData, config)
      .then((res) => {
        this.getJokes();
      })
      .catch((err) => {});
  }

  _picBeforeUp(e) {
    const Img = e.target.files[0];
    const fSize = e.target.files[0].size;
    const fSizeKB = Math.round(fSize / 1024);
    const Name = Img.name;

    // image size validation
    if (fSizeKB < 5000) {
      this.setState({
        picBeforeUp: Img,
        URLpicBeforeUp: URL.createObjectURL(Img),
        NamePicBeforeUp: Name,
      });
    } else {
      alert("Cover anda melebihi 5 MB");
    }
  }

  _deletePicBeforeUp() {
    this.setState({
      picBeforeUp: null,
      URLpicBeforeUp: null,
      NamePicBeforeUp: null,
    });
  }

  _picBeforeReply(e) {
    const Img = e.target.files[0];
    const fSize = e.target.files[0].size;
    const fSizeKB = Math.round(fSize / 1024);
    const Name = Img.name;
    console.log(Img);
    // image size validation
    if (fSizeKB < 5000) {
      this.setState({
        picBeforeReply: Img,
        URLpicBeforeReply: URL.createObjectURL(Img),
        NamePicBeforeReply: Name,
      });
    } else {
      alert("Cover anda melebihi 5 MB");
    }
  }

  _deletePicBeforeReply() {
    this.setState({
      picBeforeReply: null,
      URLpicBeforeReply: null,
      NamePicBeforeReply: null,
    });
  }

  render() {
    let jokes = this.state.commentList;
    let show = jokes.length - this.state.numberToShow;
    if (jokes.length < this.state.numberToShow) {
      show = 0;
    }
    return (
      <div className="post-comments">
        {this.state.commentList.slice(show).map((cmnts, index) => {
          return (
            <>
              <div key={index} className="post-comments-single">
                <Link to={"/" + cmnts.comment.username}>
                  <div className="post-comment-avatar">
                    {this.state.groupCode === "NI" ? (
                      cmnts.comment.photo_profile === null ||
                      cmnts.comment.photo_profile === "null" ? (
                        <img src="/assets/icons/user.svg" />
                      ) : (
                        <img
                          src={`https://static.schoolmedia.id/public/assets/socmed/${cmnts.comment.group_code}/photo/${cmnts.comment.photo_profile}`}
                          alt=""
                        />
                      )
                    ) : cmnts.comment.photo_profile === null ? (
                      <img src="/assets/icons/user.svg" />
                    ) : (
                      <img
                        src={`https://static.schoolmedia.id/public/assets/socmed/${cmnts.comment.group_code}/photo/${cmnts.comment.photo_profile}`}
                        alt=""
                      />
                    )}
                  </div>
                </Link>
                <div className="post-comment-text">
                  <div className="post-comment-text-inner">
                    <Link to={"/" + cmnts.comment.username}>
                      <h6> {cmnts.comment.name}</h6>
                    </Link>
                    {cmnts.comment.image != null ? (
                      <img
                        src={
                          "http://sosmed-backend.schoolmedia.site/comments/" +
                          this.state.userName +
                          "/" +
                          cmnts.comment.image
                        }
                        alt=""
                      />
                    ) : null}
                    <p> {cmnts.comment.comment}</p>
                  </div>
                  <div className="uk-text-small">
                    <a
                      onClick={(e) => {
                        this.setState({
                          toReply: cmnts.comment.id,
                          replyUsername: cmnts.comment.name,
                        });
                      }}
                      className=" mr-1"
                    >
                      {" "}
                      Balas{" "}
                    </a>
                    <span> {timeAgo(cmnts.comment.created_at)}</span>
                  </div>
                </div>
                <div className="post-btn-action">
                  {cmnts.comment.username === this.state.userName ? (
                    <>
                      <span className="icon-more uil-ellipsis-h post-comment-opt"></span>
                      <div
                        className="mt-0 p-2"
                        uk-dropdown="pos: bottom-right;mode:hover"
                      >
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <a
                              onClick={(e) => {
                                this.destroyComment(cmnts.comment.id);
                              }}
                              className="text-red"
                            >
                              {" "}
                              <i className="uil-trash-alt mr-1"></i> Delete{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="post-comments pl-8">
                {cmnts.replied.map((items, key) => {
                  return (
                    <div
                      key={items.id}
                      className="post-comments-single-replied"
                    >
                      <Link to={"/" + items.username}>
                        <div className="post-comment-avatar">
                          {items.photo_profile != null ? (
                            <img
                              src={`https://static.schoolmedia.id/public/assets/socmed/${items.group_code}/photo/${items.photo_profile}`}
                              alt=""
                            />
                          ) : (
                            <img src="assets/icons/user.svg" />
                          )}
                        </div>
                      </Link>
                      <div className="post-comment-text">
                        <div className="post-comment-text-inner">
                          <Link to={"/" + items.username}>
                            <h6> {items.name}</h6>
                          </Link>
                          {items.image != null ? (
                            <img
                              src={
                                "http://sosmed-backend.schoolmedia.site/comments/" +
                                this.state.userName +
                                "/" +
                                items.image
                              }
                              alt=""
                            />
                          ) : null}
                          <p>{items.comment}</p>
                        </div>
                        <div className="uk-text-small">
                          <span> {timeAgo(items.created_at)}</span>
                        </div>
                      </div>
                      {/* Option Replied Comment */}

                      {items.username === this.state.userName ? (
                        <>
                          <span className="post-comment-opt"></span>
                          <div
                            className="mt-0 p-2"
                            uk-dropdown="pos: bottom-right;mode:hover"
                          >
                            <ul className="uk-nav uk-dropdown-nav">
                              <li
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                                onClick={(e) => {
                                  this.destroyComment(items.id);
                                }}
                              >
                                <a className="text-red">
                                  {" "}
                                  <i className="uil-trash-alt mr-1"></i> Delete{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}

                {this.state.toReply === cmnts.comment.id ? (
                  <div
                    className="post-add-comment"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="post-add-comment-avature">
                      {this.state.groupCode !== "NI" ? (
                        <>
                          {this.state.userData.foto_profil === null ||
                          this.state.userData.foto_profil === "null" ? (
                            <img src="/assets/icons/user.svg" alt=""></img>
                          ) : (
                            <img
                              src={
                                `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                                this.state.userData.foto_profil
                              }
                              alt=""
                            ></img>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.userData.logo === null ||
                          this.state.userData?.logo === "null" ? (
                            <img src="/assets/icons/user.svg" alt="" />
                          ) : (
                            <img
                              src={
                                `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                                this.state.userData.logo
                              }
                              alt=""
                            ></img>
                          )}
                        </>
                      )}
                    </div>

                    <form
                      className="post-add-comment-text-area"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.addReplied(cmnts.comment.id);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <input
                        type="text"
                        id="repliedInput"
                        value={this.state.addRepliedText}
                        placeholder="Ketik & tekan enter"
                        onChange={this.addRepliedText}
                      />
                      <div
                        className="icons uk-flex items-center"
                        style={{ right: "unset", position: "unset" }}
                      >
                        <a
                          className="font-sm text-sm ml-0 mr-1"
                          style={{ fontSize: "1rem", color: "#4c8ed6" }}
                        >
                          {this.state.replyUsername}
                        </a>
                        {this.state.URLpicBeforeReply != null ? (
                          <Chip
                            size="medium"
                            avatar={
                              <Avatar
                                alt={this.state.NamePicBeforeReply}
                                src={this.state.URLpicBeforeReply}
                              />
                            }
                            label={this.state.NamePicBeforeReply}
                            onDelete={this._deletePicBeforeReply}
                          />
                        ) : null}
                      </div>

                      <div className="icons">
                        {this.state.isRepliedLoading ? (
                          <CircularProgress
                            style={{ width: "1em", height: "1em" }}
                          />
                        ) : (
                          <>
                            <input
                              type="file"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                              id="comment-img"
                              className="none comment-img"
                              onChange={this._picBeforeReply.bind(this)}
                            />
                            <label for="comment-img">
                              <span className="uil-image"></span>
                            </label>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </>
          );
        })}

        <div className="more">
          {this.state.commentList.length > 3 ? (
            this.state.numberToShow === this.state.commentList.length ? null : (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.viewMore();
                }}
                className="view-more-comment"
              >
                lihat {this.numberShowMore} komentar lainnya
              </a>
            )
          ) : null}
        </div>

        <div className="post-add-comment">
          <div className="post-add-comment-avature">
            {this.state.groupCode !== "NI" ? (
              <>
                {this.state.userData.foto_profil === null ||
                this.state.userData.foto_profil === "null" ? (
                  <img src="/assets/icons/user.svg" alt=""></img>
                ) : (
                  <img
                    src={
                      `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                      this.state.userData.foto_profil
                    }
                    alt=""
                  ></img>
                )}
              </>
            ) : (
              <>
                {this.state.userData.logo === null ||
                this.state.userData.logo === "null" ? (
                  <img src="/assets/icons/user.svg" alt=""></img>
                ) : (
                  <img
                    src={
                      `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                      this.state.userData.logo
                    }
                    alt=""
                  ></img>
                )}
              </>
            )}

            {/* {this.state.repliedList.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.repliedList.group_code}/photo/${this.state.repliedList.photo_profile}`} alt="" /> :
            <img src='/assets/icons/user.svg' />
          } */}
          </div>
          <form
            className="post-add-comment-text-area"
            onSubmit={this.addComment}
            style={{
              borderRadius: `${
                this.state.URLpicBeforeUp != null ? "20px" : "50px"
              }`,
              marginTop: "20px",
            }}
          >
            {this.state.URLpicBeforeUp != null ? (
              <Chip
                size="medium"
                avatar={
                  <Avatar alt="Natacha" src={this.state.URLpicBeforeUp} />
                }
                label={this.state.NamePicBeforeUp}
                onDelete={this._deletePicBeforeUp}
              />
            ) : null}

            <textarea
              id="commentInput"
              type="text"
              onKeyDown={(e) => {
                if (e.keyCode == 13 && e.shiftKey == false) {
                  this.addComment(e);
                }
              }}
              placeholder="Ketik & tekan enter"
              value={this.state.addCommentText}
              onChange={this.addCommentText}
            />
            <div className="icons">
              {this.state.isLoading ? (
                <CircularProgress style={{ width: "1em", height: "1em" }} />
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                    id="comment-img"
                    className="none comment-img"
                    onChange={this._picBeforeUp.bind(this)}
                  />
                  <label for="comment-img">
                    <span className="uil-image"></span>
                  </label>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};

export default connect(mapStateToProps)(PostComments);
