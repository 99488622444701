import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import GroupHeader from './components/GroupHeader';
import TextField from '@material-ui/core/TextField';
import * as TopicAction from "redux/actions/TopicAction";
import { connect } from 'react-redux';
// import GroupPost from './components/GroupPost';

const api = `${process.env.REACT_APP_API}`;

class Group extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      dataTopic: [],
      forumCode: null,

      modalCreateTopic: false,
      deleteConfirmModal: false,
      toDeleteTopic: '',
      topicName: '',
      topicInfo: ''
    }

    this.openModalCreateTopic = this.openModalCreateTopic.bind(this)
    this.hideModalCreateTopic = this.hideModalCreateTopic.bind(this)

    this.createTopic = this.createTopic.bind(this)
    this.deleteTopic = this.deleteTopic.bind(this)
    this.deleteTopicConfirm = this.deleteTopicConfirm.bind(this)
    this.cancelDelete = this.cancelDelete.bind(this)
  }
  componentDidMount() {
    const url = window.location.pathname
    let arr = []
    arr = url.split('-')
    this.setState({ forumCode: arr[arr.length - 1] })
    this.getForum(arr[arr.length - 1])

    this.getTopic(arr[arr.length - 1])
  }
  static getDerivedStateFromProps(props, state){
    if(props.topicReducer.listTopic != state.dataTopic){
      return({
        dataTopic: props.topicReducer.listTopic
      })
    }
  }
  getForum(e) {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + "forum/show-forum", {
      forum_code: e,
    }, config)
      .then(res => {
        this.setState({ data: res.data.data.forum })
      })
  }
  getTopic(e) {
    let { dispatch } = this.props;
    let action = TopicAction.getTopicList(e)

    dispatch(action)
    // console.log(dispatch(action))
    
    // let jwtToken = localStorage.getItem("jwtToken")
    // let config = {
    //   headers: {
    //     Authorization: "Bearer " + jwtToken,
    //   },
    // }
    // axios.post(api + "topic/get-topic", {
    //   forum_code: e,
    // }, config)
    //   .then(res => {
    //     this.setState({ dataTopic: res.data.data.data })
    //   })
  }

  openModalCreateTopic() {
    this.setState({ modalCreateTopic: true })
  }
  hideModalCreateTopic() {
    this.setState({ modalCreateTopic: false })
  }

  createTopic() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + "topic/post-topic", {
      forum_code: this.state.forumCode,
      name: this.state.topicName,
      info: this.state.topicInfo,
      status: 1,
    }, config)
      .then(res => {
        this.getTopic(this.state.forumCode)
        this.hideModalCreateTopic()
      }).catch(err => {
        alert('gagal membuat topic baru', err.data)
      })
  }
  deleteTopicConfirm(e) {
    this.setState({ deleteConfirmModal: true, toDeleteTopic: e })
  }
  cancelDelete() {
    this.setState({ deleteConfirmModal: false, toDeleteTopic: '' })
  }
  deleteTopic(e) {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + "topic/destroy-topic", {
      forum_code: this.state.forumCode,
      topic_id: e.id,
    }, config)
      .then(res => {
        this.cancelDelete()
        this.getTopic(this.state.forumCode)
      }).catch(err => {
        alert('gagal menghapus topic', err.data)
      })
  }

  render() {
    return (
      <>

        <div className="main_content">
          <div className="main_content_inner">
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal"
              open={this.state.modalCreateTopic}
              onClose={this.hideModalCreateTopic}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.openModalCreateTopic}>
                <>
                  <div className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid" style={{ maxWidth: '510px' }}>
                    <div className=" uk-width-1-1">
                      <h2 className="uk-modal-title text-center">Buat Topic Baru</h2>
                      <div className="uk-flex uk-flex-column">
                        <TextField
                          id="outlined-basic"
                          label="Nama Topic"
                          variant="outlined"
                          defaultValue={this.state.topicName}
                          onChange={e => { this.setState({ topicName: e.target.value }) }}
                        />
                        <TextField
                          className="mt-4"
                          id="outlined-multiline-static"
                          label="Info Topic"
                          multiline
                          rows={4}
                          defaultValue={this.state.topicInfo}
                          variant="outlined"
                          onChange={e => { this.setState({ topicInfo: e.target.value }) }}
                        />
                        <button className="button btn-schoolmedia small mt-4" onClick={this.createTopic}>Buat</button>
                      </div>
                    </div>
                  </div>
                </>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal"
              open={this.state.deleteConfirmModal}
              onClose={this.cancelDelete}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.deleteConfirmModal}>
                <>
                  <div className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid" style={{ maxWidth: '510px' }}>
                    <div className=" uk-width-1-1">
                      <h2 className="uk-modal-title text-center">Hapus Topic {this.state.toDeleteTopic.name}</h2>
                      <div className="uk-flex uk-flex-column">

                        <button className="button btn-danger small mt-4" onClick={e => {this.deleteTopic(this.state.toDeleteTopic)}}>Hapus</button>
                        <button className="button btn-white small mt-4" onClick={this.cancelDelete}>Batal</button>
                      </div>
                    </div>
                  </div>
                </>
              </Fade>
            </Modal>
            <div className="single-group">
              <GroupHeader dataForum={this.state.data} />
              <div className="mt-5">
                <div className="uk-grid-large uk-grid" uk-grid="">
                  <div className="uk-width-expand uk-first-column">
                    {/* Blog Post */}
                    <div className="uk-first-column bg-white" style={{ boxShadow: "0px 2px 7px -1px #0000001a" }}>
                      <div className="blog-post-content-info p-3">
                        <span><b><h2 style={{ marginBottom: "0" }}>Topic Grup</h2></b></span>
                      </div>
                      <hr style={{ marginTop: "0", marginBottom: "0" }} />

                      <div className="blog-post-content-info p-3 mb-0">
                        <div className="uk-flex uk-flex-wrap uk-width-1-1 uk-child-width-1-4@m uk-child-width-1-2@s" uk-grid uk-height-match="target: > .topik-item > .course-card-category; row: false">
                          {this.state.dataTopic.map((val, index) => {
                            return (
                              <div className="p-3 topik-item" key={index}>
                                <div className="course-card-category">
                                  <Link to={{pathname: "/groups/" + val.slug + "/topics", topicData: val}}>
                                    <img src="/assets/images/group/group-3.jpg" alt="" className="rounded-1" />
                                    <h4> {val.name} </h4>
                                  </Link>

                                  <div className="post-btn-action">
                                    <span className="icon-more uil-ellipsis-h" aria-expanded="false"></span>
                                    <div className="mt-0 p-2 uk-dropdown" uk-dropdown="pos: bottom-right;mode:hover">
                                      <ul className="uk-nav uk-dropdown-nav">
                                        <li><a onClick={e => { this.deleteTopicConfirm(val) }} className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          <div className="p-3 topik-item">
                            <a className="uk-padding" onClick={this.openModalCreateTopic}>
                              <div className="course-card-category uk-flex uk-flex-center uk-flex-middle uk-flex-column" style={{ backgroundColor: "#EEE" }}>
                                <h1 className="mb-0" style={{ fontSize: "50px" }}> <i className="uil-plus-circle"></i> </h1>
                                <h4 className="mb-0"> Tambah Topik </h4>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    topicReducer: state.topicReducer,
  }
}

export default connect(mapStateToProps)(Group);
