import React, { useState, useEffect } from 'react';
import { connect,} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';


function UploadToast({ props }) {

  const [open, setOpen] = useState(false);
  let [type, setType] = useState(null)

  function handleClose() {
    // dispatch(clearSnackbar());
  }
  useEffect(() => {
    switch (props.toastsReducer.UploadInfo) {
      case 'pending':
        setOpen(true)
        setType('info')
        break;
      case 'success':
        setType('success')
        setTimeout(() => {
          setOpen(false)
        }, 5000);
        break;
      case 'error':
        setType('error')
        setTimeout(() => {
          setOpen(false)
        }, 5000);
        break;
      default:
        setOpen(false)
        break;
    }
  })

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
        >
          {props.toastsReducer.SnackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function mapStateToProps(state) {
  return { props: state };
}

export default connect(mapStateToProps)(UploadToast);