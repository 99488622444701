import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getUserDetail } from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";

const api = `${process.env.REACT_APP_API}`;
let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
    'Content-Type': 'multipart/form-data'
  },
};
// ik editan mu ? iyo wes bener
class SettingSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      userData: null,
      dataUser: null,
      dataNI: null,

      regExColor: '#eaeaea',
      regExText: null,

      newPW: null,
      newPWColor: '#eaeaea',
      newPWText: null,
      repeatedPW: null,

      hidePWSubmit: true,
    }
    this.editNoHP = this.editNoHP.bind(this)
    this.editEmail = this.editEmail.bind(this)
    this.regExValidator = this.regExValidator.bind(this)
    this.handleNewPW = this.handleNewPW.bind(this)
    this.editPW = this.editPW.bind(this)
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.userReducer.userDetail != state.data) {
      let data = nextProps.userReducer.userDetail;
      if (data.group_code === 'NI') {
        return {
          groupCode: 'NI',
          dataNI: data,
          data: data,
        }
      } else {
        return {
          groupCode: data.group_code,
          dataUser: data,
          data: data,
        }
      }
    }
  }
  editNoHP(e) {
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    let data = this.state.dataUser;
    let dataNI = this.state.dataNI;

    delete data.password;
    if (this.state.groupCode === 'NI') {
      requestData = {
        ...dataNI,
        telp_institusi: dataNI.telp_institusi,
      };
    } else {
      requestData = {
        ...data,
        no_handphone: data.no_handphone,
      };
    }

    formData.append('json', JSON.stringify(requestData));

    this.props.uploading()
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }

  editEmail(e) {
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    let data = this.state.dataUser;
    delete data.password;
    requestData = {
      ...data,
      email: data.email,
    };

    formData.append('json', JSON.stringify(requestData));

    this.props.uploading()
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }

  regExValidator(e) {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{5,})");
    const RegexLength = e.target.value.length;

    if (RegexLength > 5) {
      if (strongRegex.test(e.target.value)) {
        this.setState({ regExColor: "#0F9D58", regExText: 'Kuat', newPW: e.target.value });
      } else if (mediumRegex.test(e.target.value)) {
        this.setState({ regExColor: "#F4B400", regExText: 'Sedang', newPW: e.target.value });
      } else {
        this.setState({ regExColor: "#DB4437", regExText: 'Lemah', newPW: null });
      }
    } else {
      this.setState({
        regExColor: "#DB4437",
        regExText: 'Kurang dari 6 karakter',
        newPW: null,
        hidePWSubmit: true,
        newPWColor: "#F4B400",
        newPWText: ' Belum Sama',
        repeatedPW: null,
      });
    }
  }

  handleNewPW(e) {
    const newPW = this.state.newPW;
    const repeatedPW = e.target.value;

    if (e) {
      if (repeatedPW === newPW) {
        this.setState({ newPWColor: "#0F9D58", newPWText: ' Sama', hidePWSubmit: false, repeatedPW: repeatedPW });
      } else {
        this.setState({ newPWColor: "#F4B400", newPWText: ' Belum Sama', hidePWSubmit: true, });
      }
    }
  }

  editPW(e) {
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    // check group code
    let data = this.state.dataUser;
    requestData = {
      ...this.props.data,
      password: this.state.repeatedPW
    };
    formData.append('json', JSON.stringify(requestData));
    this.props.uploading()
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }
  showHideNewPW() {
    let x = document.getElementById("newPWInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data)
  }
  validateTelp(e) {
    if (e === '' || e === null || e === undefined) {
      this.setState({
        dataUser: { ...this.state.dataUser, no_handphone: e },
        dataNI: { ...this.state.dataNI, telp_institusi: e }
      })
    } else {
      if (e.match(/^[0-9 + () -]+$/)) {
        this.setState({
          dataUser: { ...this.state.dataUser, no_handphone: e },
          dataNI: { ...this.state.dataNI, telp_institusi: e }
        })
      }
    }
  }
  render() {
    return (
      <>
        <div className="bg-white rounded-sm mt-sm-3 pl-sm-0">
          {this.state.groupCode === 'NI' ?
            null :
            <div className="bg-white rounded-sm mb-4">
              <h5 className="mt-2 mb-1 uk-flex"><i className="mr-2 icon-feather-shield uk-text-large"></i> Email</h5>
              <p className="mb-3 font-sm">Untuk informasi, akan ternotif pada email</p>
              <div className="uk-flex uk-flex-column uk-flex-row@s">

                <div className="uk-width-1-1 pr-2">
                  <div className="uk-margin">
                    <label htmlFor="" className="font-sm">No. Handphone</label>
                    <form onSubmit={this.editNoHP.bind(this)}>
                      <div className="uk-flex">
                        <input className="uk-input uk-form-small" type="text" placeholder="Input"
                          value={this.state.groupCode === 'NI' ? this.state.dataNI.telp_institusi : this.state.dataUser.no_handphone}
                          onChange={
                            (e) => {
                              this.validateTelp(e.target.value)
                            }
                          }
                        />
                        <input type="submit" className="ml-2 button small btn-schoolmedia" value="Ubah" />
                      </div>
                    </form>
                    <span className="font-sm uk-text-green">Verifikasi melalui SMS</span>
                  </div>
                </div>
                {/* <div className="uk-width-1-1 pr-2">
                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">No. Handphone</label>
                  <form onSubmit={this.editNoHP.bind(this)}>
                    <div className="uk-flex">
                      <input className="uk-input uk-form-small" type="text" placeholder="Input"
                        value={this.state.groupCode === 'NI' ? this.state.dataNI.telp_institusi : this.state.dataUser.no_handphone}
                        onChange={
                          (e) => {
                            this.validateTelp(e.target.value)
                          }
                        }
                      />
                      <input type="submit" className="ml-2 button small btn-schoolmedia" value="Ubah" />
                    </div>
                  </form>
                  <span className="font-sm uk-text-green">Verifikasi melalui SMS</span>
                </div>
              </div> */}
                <div className="uk-width-1-1 pl-2@s pl-0">
                  <div className="uk-margin">
                    <label htmlFor="" className="font-sm">Email</label>
                    <form onSubmit={this.editEmail.bind(this)}>
                      <div className="uk-flex">
                        <input className="uk-input uk-form-small" type="email" placeholder="Input" disabled
                          value={this.state.groupCode === 'NI' ? this.state.dataNI.email : this.state.dataUser.email}
                          onChange={
                            (e) => {
                              this.setState({ dataUser: { ...this.state.dataUser, email: e.target.value } })
                            }
                          }
                        />
                        {/* <input type="submit" className="ml-2 button small btn-schoolmedia" value="Ubah" /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="bg-white rounded-sm mb-2">
            <h5 className="mt-2 mb-1 uk-flex"><i className="mr-2 icon-material-outline-fingerprint uk-text-large"></i> Ubah Kata Sandi</h5>
            <p className="mb-3 font-sm">Jika anda lupa kata sandi, notifikasi akan dikirim ke kontak ini</p>
            <div className="uk-flex uk-flex-row">
              <div className="uk-width-2-3@s uk-width-1-1 pr-2">
                {/* <div className="uk-margin">
                  <label htmlFor="" className="font-sm">Kata Sandi Lama</label>
                  <div className="uk-flex">
                    <input className="uk-input uk-form-small" type="text" placeholder="Input" />
                  </div>
                </div> */}
                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">Kata Sandi Baru
                    <span style={{ color: `${this.state.regExColor}` }}>- {this.state.regExText}</span>
                  </label>
                  <div className="uk-flex justify-center items-center">
                    <input className="uk-input uk-form-small" type="password" id="newPWInput" placeholder="Input"
                      style={{ borderColor: `${this.state.regExColor}` }}
                      onChange={this.regExValidator}
                    />
                    <input type="checkbox" className="mr-2 ml-2" onClick={this.showHideNewPW} /> <span className="font-sm">Lihat Password</span>
                  </div>
                  <span className="font-sm uk-text-green">Minimal terdiri 6 karakter, terdapat huruf kapital, angka dan symbol</span>
                </div>
                {this.state.newPW !== null ?
                  <>
                    <div className="uk-margin">
                      <label htmlFor="" className="font-sm">Ulangi Kata Sandi
                        <span style={{ color: `${this.state.newPWColor}` }}>- {this.state.newPWText}</span>
                      </label>
                      <div className="uk-flex">
                        <input className="uk-input uk-form-small" type="password" placeholder="Input"
                          onChange={this.handleNewPW}
                        />
                      </div>
                      {this.state.hidePWSubmit ? null : <button className="mt-2 button small btn-schoolmedia" onClick={this.editPW}>Simpan sandi baru</button>}
                    </div>
                  </> :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer;
  return {
    auth: state.auth,
    userReducer
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
  getUserDetail: getUserDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SettingSecurity);
