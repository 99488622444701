import React from 'react';
import FormAddress from './Components/FormAddress';
import FormProfile from './Components/FormProfile';

export default class SettingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      groupCode: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    switch (nextProps.data.group_code) {
      case 'NI':
        this.setState({
          groupCode: 'NI',
          userData: nextProps.data,
        }, function () {
        })
        break;

      case 'GU':
        break;

      default:
        this.setState({
          userData: nextProps.data
        })
        break;
    }
  }

  // render form
  settingProfileForm() {
    return (
      <FormProfile data={this.props.data} />
    )
  }
  settingAddressForm() {
    return (
      <FormAddress data={this.props.data} />
    )
  }
  render() {
    return (
      <>
        <div className="bg-white rounded-sm mt-sm-3 pl-sm-0">
          <div className=" bg-white rounded-sm mb-4">
            <h5 className="mt-2 mb-1 uk-flex items-center"><i className="mr-2 icon-material-outline-face uk-text-large"></i> Profil</h5>
            {/* <p className="mb-3 font-sm">Isi formulir dibawah dengan biodata anda</p> */}
            {this.settingProfileForm()}
          </div>
          <div className="bg-white rounded-sm mb-4">
            <h5 className="mt-2 mb-1 uk-flex items-center"><i className="mr-2 icon-feather-archive uk-text-large"></i> Alamat {this.state.groupCode === "NI"?"Institusi":"Anda"}</h5>
            <div className="uk-alert-success uk-alert font-sm" uk-alert>
              <a className="uk-alert-close uk-icon uk-close" uk-close></a>
              <p>Jika mengubah alamat pilih secara berurutan mulai dari provinsi
              </p>
            </div>
            {this.settingAddressForm()}
          </div>
        </div>
      </>
    )
  }
}