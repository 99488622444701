import React from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { bindActionCreators } from "redux";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Dropzone from "react-dropzone";
import Slider from "react-slick";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import {
  uploadError,
  uploadOnProgress,
  uploadSuccess,
} from "redux/actions/toastsAction";

const api = `${process.env.REACT_APP_API}`;

class CreateMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreateMedia: false,
      modalAddMedia: false,

      mediaContent: null,
      mediaName: null,
      files: [],
      maxUpFile: false,

      disableSendBtn: true,
    };
    this.showModalCreateMedia = this.showModalCreateMedia.bind(this);
    this.hideModalCreateMedia = this.hideModalCreateMedia.bind(this);

    this.showModalAddMedia = this.showModalAddMedia.bind(this);
    this.hideModalAddMedia = this.hideModalAddMedia.bind(this);

    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.deleteSelectedFiles = this.deleteSelectedFiles.bind(this);

    this.postMedia = this.postMedia.bind(this);
  }
  fileObj = [];
  fileArray = [];
  filesBinary = [];
  contentToUp = [];

  tempImg = [];
  showModalCreateMedia() {
    this.setState({
      modalCreateMedia: true,
    });
  }
  hideModalCreateMedia() {
    this.setState({
      modalCreateMedia: false,
    });
  }
  showModalAddMedia() {
    this.setState({
      modalAddMedia: true,
    });
  }
  hideModalAddMedia() {
    this.setState({
      modalAddMedia: false,
    });
  }

  uploadMultipleFiles(files) {
    if (files.length <= 5) {
      this.fileObj.push(files);
      this.fileObj.forEach((index, key) => {
        for (let i = 0; i < index.length; i++) {
          this.fileArray.push({
            url: URL.createObjectURL(index[i]),
            type: index[i].type,
            name: index[i].name,
            key: [i],
          });
          this.filesBinary.push(index[i]);
        }
        this.fileObj = [];
      });
      this.setState({ files: this.fileArray });
    } else {
      alert("Maksimum 5 file !");
    }
    if (this.filesBinary.length >= 5) {
      this.setState({
        maxUpFile: true,
      });
    } else {
      this.setState({
        maxUpFile: false,
      });
    }
    if (this.filesBinary.length >= 0 && this.contentToUp.length >= 0) {
      this.setState({
        disableSendBtn: false,
      });
    }
  }

  deleteSelectedFiles(e) {
    let filesBinaryTemp = [];
    let fileArrayTemp = [];
    if (e) {
      const hapus = e;

      // delete on binary
      this.filesBinary.forEach((value, key) => {
        if (value.name != hapus) filesBinaryTemp.push(value);
      });
      // delete on binary
      this.fileArray.forEach((value, key) => {
        if (value.name != hapus) fileArrayTemp.push(value);
      });

      this.filesBinary = filesBinaryTemp;
      this.fileArray = fileArrayTemp;

      this.setState({
        files: this.fileArray,
      });

      if (this.filesBinary.length >= 5) {
        this.setState({
          maxUpFile: true,
        });
      } else {
        this.setState({
          maxUpFile: false,
        });
      }
      if (this.filesBinary.length === 0 && this.contentToUp.length === 0) {
        this.setState({
          disableSendBtn: true,
        });
      }
    }
  }
  resetAllValue() {
    this.setState({
      name: null,
      content: null,
      file: [],
    });
    this.filesBinary = [];
  }

  postMedia() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: this.props.uploading,
    };

    const formData = new FormData();

    // append content
    let requestData = {
      name: this.state.mediaName,
      content: this.state.mediaContent,
      // status: this.state.statusSelected,
      status: 1,
    };
    formData.append("name", this.state.mediaName);
    formData.append("content", this.state.mediaContent);
    formData.append("status", 1);

    // append files
    if (this.filesBinary.length != 0) {
      this.filesBinary.forEach((index, key) => {
        formData.append("files[" + key + "]", index);
      });
    }

    this.setState({
      disableSendBtn: true,
    });
    axios
      .post(api + "media/post-media", formData, config)
      .then((res) => {
        const status = 200;
        this.props.upload_success(status);
        this.resetAllValue();
        this.props.getPhotos();
        this.props.getAlbums();
        this.setState({
          disableSendBtn: false,
          modalCreateMedia: false,
        });
        this.hideModalCreateMedia();
      })
      .catch((err) => {
        const status = err.response;
        // this.props.upload_error(status)
        this.setState({
          disableSendBtn: false,
        });
      });
  }
  render() {
    const files = this.state.files.map((file) => {
      if (file.type.match("video.*")) {
        return (
          <>
            <video controls>
              <source src={file.url} type={file.type} />
            </video>
            <button
              className="button small btn-danger mt-2 mb-2"
              onClick={(e) => {
                this.deleteSelectedFiles(file.name);
              }}
            >
              Hapus
            </button>
          </>
        );
      } else {
        return (
          <>
            <div
              style={{ maxHeight: "9rem", height: "15rem", overflow: "hidden" }}
            >
              <img src={file.url} alt="" />
            </div>
            <button
              className="button small btn-danger mt-2 mb-2"
              onClick={(e) => {
                this.deleteSelectedFiles(file.name);
              }}
            >
              Hapus
            </button>
          </>
        );
      }
    });
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
    };
    return (
      <>
        {/* parent modal */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
          open={this.state.modalCreateMedia}
          onClose={this.hideModalCreateMedia}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalCreateMedia}>
            <>
              <div
                className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid"
                style={{ maxWidth: "500px", minWidth: "500px" }}
              >
                <div className=" uk-width-1-1">
                  <button
                    className="button btn-white"
                    onClick={this.hideModalCreateMedia}
                    display="flex"
                    justifyContent="right"
                    style={{ marginLeft: "25rem" }}
                  >
                    X
                  </button>
                  <h2 className="uk-modal-title text-center">
                    Tambah Media Baru
                  </h2>
                  <div className="uk-flex uk-flex-column">
                    <TextField
                      id="outlined-basic"
                      label="Nama"
                      variant="outlined"
                      defaultValue={this.state.mediaName}
                      onChange={(e) => {
                        this.setState({ mediaName: e.target.value });
                      }}
                    />
                    <TextField
                      className="mt-4"
                      id="outlined-multiline-static"
                      label="Konten"
                      multiline
                      rows={4}
                      defaultValue={this.state.mediaContent}
                      variant="outlined"
                      onChange={(e) => {
                        this.setState({ mediaContent: e.target.value });
                      }}
                    />
                    <Slider {...settings}>{files}</Slider>
                    <button
                      className="button btn-white small mt-4"
                      onClick={this.showModalAddMedia}
                    >
                      Masukkan Media
                    </button>
                  </div>
                  <div className="uk-flex uk-flex-column">
                    <button
                      className="button btn-schoolmedia small mt-4"
                      onClick={this.postMedia}
                    >
                      Buat
                    </button>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
        {/* modal add photos */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
          open={this.state.modalAddMedia}
          onClose={this.hideModalAddMedia}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalAddMedia}>
            <>
              <div
                className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid"
                style={{ maxWidth: "500px", minWidth: "500px" }}
              >
                <div className=" uk-width-1-1">
                  <h2 className="uk-modal-title text-center">
                    Tambah Media Baru
                  </h2>
                  <div className="uk-flex uk-flex-column">
                    <Dropzone
                      onDrop={this.uploadMultipleFiles}
                      multiple={true}
                      accept={"image/jpeg, image/png, video/mp4"}
                      className="dropzone"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="container">
                          {this.state.maxUpFile ? (
                            <>
                              <div className="dropzone-max dropzone">
                                <p>mencapai batas maksimum upload file</p>
                              </div>
                            </>
                          ) : (
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <p className="font-sm">
                                Seret dan lepas file disini, atau klik untuk
                                memilih file
                              </p>
                            </div>
                          )}
                          <aside>
                            <Slider {...settings}>{files}</Slider>
                          </aside>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="uk-flex uk-flex-row">
                    <button
                      className="button btn-schoolmedia small mt-4 uk-width-1-2"
                      onClick={this.hideModalAddMedia}
                    >
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
        <button
          className="button small btn-schoolmedia"
          onClick={this.showModalCreateMedia}
        >
          Tambah foto / video
        </button>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploading: uploadOnProgress,
      upload_success: uploadSuccess,
      upload_error: uploadError,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateMedia);
