import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import { getUserDetail } from 'redux/actions/UserAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";

const api = `${process.env.REACT_APP_API}`;
let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
    'Content-Type': 'multipart/form-data'
  },
};
const optionsType = [
  { value: 'Negeri', label: 'Negeri' },
  { value: 'Swasta', label: 'Swasta' },
]
class ProfileNI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      dataNI: {},
    }
    this.selectType = this.selectType.bind(this)
    this.edit = this.edit.bind(this)
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data != state.data) {
      if (nextProps.data.group_code === 'NI') {
        return {
          dataNI: nextProps.data,
          data: nextProps.data
        }
      }
    }
  }
  selectType(e) {
    if (e) {
      this.setState({ dataNI: { ...this.state.dataNI, jenis_institusi: e.label } })
    }
  }
  edit(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
    };
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    // check group code
    if (this.props.data.group_code === 'NI') {
      let data = this.state.dataNI;
      delete data.password;
      requestData = {
        ...data,
        fax: data.fax,
        website: data.website,
        tagline: data.tagline,
        status_kepemilikan: data.status_kepemilikan,
        sk_pendirian: data.sk_pendirian,
        tgl_sk_pendirian: data.tgl_sk_pendirian,
        sk_izin_operasional: data.sk_izin_operasional,
        tgl_sk_izin_operasional: data.tgl_sk_izin_operasional,
        jenis_institusi: data.jenis_institusi,
      };
    }
    formData.append('json', JSON.stringify(requestData));
    this.props.uploading()
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }
  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data)
  }
  validateTelp(e) {
    if (e === '' || e === null || e === undefined) {
      this.setState({ dataNI: { ...this.state.dataNI, fax: e } })
    } else {
      if (e.match(/^[0-9 -+().]+$/)) {
        this.setState({ dataNI: { ...this.state.dataNI, fax: e } })
      }
    }
  }
  render() {
    return (
      <>
        <form action="" className="uk-grid" onSubmit={this.edit.bind(this)}>
          <h6 className="uk-width-1-1">Data 1</h6>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">NPSN</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input"
                disabled
                value={this.state.dataNI?.npsn}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Website</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input"
                disabled
                value={this.state.dataNI.website}
                onChange={
                  (e) => {
                    this.setState({ dataNI: { ...this.state.dataNI, website: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Jenis Institusi</label>
              <Select
                options={optionsType}
                onChange={this.selectType}
                defaultValue={this.state.dataNI.jenis_institusi != null ? { value: this.state.dataNI.jenis_institusi, label: this.state.dataNI.jenis_institusi } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Tagline</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input"
                value={this.state.dataNI.tagline}
                onChange={
                  (e) => {
                    this.setState({ dataNI: { ...this.state.dataNI, tagline: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Fax</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input" value={this.state.dataNI.fax}
                onChange={
                  (e) => {
                    this.validateTelp(e.target.value)
                  }
                }
              />
            </div>
          </div>
          <h6 className="uk-width-1-1 mt-2 mb-1">Data 2</h6>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Status Kepemilikan</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="text" placeholder="Input"
                  value={this.state.dataNI.status_kepemilikan}
                  onChange={
                    (e) => {
                      this.setState({ dataNI: { ...this.state.dataNI, status_kepemilikan: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">SK Pendirian</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input" value={this.state.dataNI.sk_pendirian}
                onChange={
                  (e) => {
                    this.setState({ dataNI: { ...this.state.dataNI, sk_pendirian: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Tanggal SK Pendirian</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="date" placeholder="Input" value={this.state.dataNI.tgl_sk_pendirian}
                  onChange={
                    (e) => {
                      this.setState({ dataNI: { ...this.state.dataNI, tgl_sk_pendirian: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">SK izin operasional</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input" value={this.state.dataNI.sk_izin_operasional}
                onChange={
                  (e) => {
                    this.setState({ dataNI: { ...this.state.dataNI, sk_izin_operasional: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Tanggal SK izin Operasional</label>
              <div className="uk-flex">
                <input className="uk-input uk-form-small" type="date" placeholder="Input" value={this.state.dataNI.tgl_sk_izin_operasional}
                  onChange={
                    (e) => {
                      this.setState({ dataNI: { ...this.state.dataNI, tgl_sk_izin_operasional: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </div>

          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1 pt-5">
              <input type="submit" className="ml-2 button small btn-schoolmedia" value="Simpan" />
            </div>
          </div>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
  getUserDetail: getUserDetail,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileNI);