import React from "react";
import { Link } from "react-router-dom";
import Post from "../../partials/Timeline/Post";
import { connect } from "react-redux";
import SkeletonPost from "component/partials/skeleton/SkeletonPost";
import axios from "axios";
import CreatePostPop from "component/partials/Timeline/CreatePostPop";
import Skeleton from "@material-ui/lab/Skeleton";

const api = `${process.env.REACT_APP_API}`;
const apiUM = `${process.env.REACT_APP_UM_API}`;
const url = `${process.env.REACT_APP_BACKEND}media`;

class UserAbout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataFeed: [],
      dataFriend: [],
      alamatTinggal: null,
      photosList: [],
      loadingFeedPhotos: true,
      loadingFeedFriend: true,
      userName: null,
      userDetail: "",
      loadingFeed: false,
      countToScroll: 1,
      groupCode: null,
      userData: "",
    };
    this._getFeed = this._getFeed.bind(this);
    this.getFeedAfterCreate = this.getFeedAfterCreate.bind(this);
    this.afterDelete = this.afterDelete.bind(this);
  }
  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0];
    this.setState(
      {
        userName: username,
      },
      function () {

        this._getFeed(username);
        this._getAddress(username);
        this._getPhotos(username);
      }
    );
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data != state.data) {
      if (nextProps.data != undefined) {
        return {
          dataFriend: nextProps.dataFriend,
          userDetail: nextProps.userReducer.userDetail,
          loadingFeedFriend: false,
          groupCode: nextProps.userReducer.userDetail.group_code,
          userData: nextProps.userReducer.userDetail,
        };
      } else {
        this.setState({ loadingFeedFriend: false });
        return null;
      }
    }
  }

  _getFeed(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .post(
        api + "feed/get-feed",
        {
          username: e,
          page: { number: 1 },
        },
        config
      )
      .then((res) => {
        console.log(res);
        this.setState({
          dataFeed: res.data.data.data,
        });
      });
  }
  getFeedAfterCreate(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    axios
      .post(
        api + "feed/get-feed",
        {
          username: e,
          type: 1,
          page: { number: this.state.countToScroll },
        },
        config
      )
      .then((res) => {
        const nextFeed = res.data.data.data;
        if (res.data.data === []) {
          this.setState({
            countToScroll: this.state.countToScroll,
          });
        }
        this.setState({
          feed: res.data.data,
          dataFeed: nextFeed,
          loadingGetFeed: false,
          loadingNextFeed: false,
        });
      })
      .catch((err) => {
        this.setState({
          countToScroll: this.state.countToScroll,
        });
      });
  }
  afterDelete(e) {
    let fileArrayTemp = [];
    this.state.dataFeed.forEach((value, key) => {
      if (value.id != e) fileArrayTemp.push(value);
    });
    this.fileArray = fileArrayTemp;

    this.setState({
      dataFeed: this.fileArray,
    });
  }
  _getAddress(e) {
    axios.get(apiUM + "sosmed/get-address?username=" + e)
    .then((res) => {
      this.setState({
        alamatTinggal: res.data.data?.alamat_tinggal,
      });
    });
  }

  _getPhotos(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let requestData = {
      is_album: 0,
      type: 1,
      username: e,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.post(api + "media/get-media", requestData, config).then((res) => {
      this.setState({
        photosList: res.data.data.data,
        loadingFeedPhotos: false,
      });
    });
  }

  render() {
    console.log(this.state.dataFeed);
    return (
    <>
      <div className="section-small">
        <div
          className="uk-grid grid-content profile"
          style={{ paddingLeft: "unset" }}
        >
          <div className="uk-width-2-3@m fead-area">
            {this.state.userName === this.state.userDetail.username ? (
              <div className="post-newer">
                <div
                  className="post-new"
                  uk-toggle="target: body ; cls: post-focus"
                >
                  <CreatePostPop
                    data={this.state.userDetail}
                    getFeed={this.getFeedAfterCreate}
                    postType={"feed"}
                  />
                </div>
              </div>
            ) : null}

            {this.state.userName === this.state.userDetail.username ? (
              <>
                {this.state.dataFeed.map((feeds) => {
                  return (
                    <Post
                      data={feeds}
                      key={feeds.id}
                      getFeed={this.afterDelete}
                      userName={this.state.userName}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {this.state.dataFeed.map((feeds) => {
                  return (
                    <Post
                      data={feeds}
                      key={feeds.id}
                      getFeed={this.afterDelete}
                      userName={this.state.userName}
                    />
                  );
                })}
              </>
            )}
          </div>

          {/* <!-- sidebar --> */}
          <div className="uk-width-expand ml-lg-2 bg-white rounded-sm pr-4 pl-4 pt-4 pb-4">
            <h3> Tentang </h3>
            <div className="list-group-items">
              <i className="uil-home-alt"></i>
              <h6 className="font-sm uk-flex-between w-70">
                <span>Alamat</span>
              </h6>
              <span className="uk-flex uk-flex-column">
                <p className="font-sm font-schoolmedia">
                  {this.state.alamatTinggal}
                </p>
                {/* <p className="font-xs">13 Juni 2020</p> */}
              </span>
            </div>

            {/* <div className="list-group-items">
              <i className="uil-location-point"></i>
              <h6 className="font-sm uk-flex-between w-70">
                <span>Asal</span>
                <span className="uk-flex uk-flex-column">
                  <p className="font-sm font-schoolmedia">DKI Jakarta</p>
                  <p className="font-xs">13 Juni 2020</p>
                </span>
              </h6>
            </div>

            <div className="list-group-items">
              <i className="uil-home-alt"></i>
              <h6 className="font-sm uk-flex-between w-70">
                <span>Sekolah</span>
                <span className="uk-flex uk-flex-column">
                  <p className="font-sm font-schoolmedia">SMKN 1 Jakarta</p>
                  <p className="font-xs">13 Juni 2020</p>
                </span>
              </h6>
            </div> */}

            {/* <div className="list-group-items">
                              <i className="uil-rss"></i>
                              <h5> Flowwed by <span> 3,240 </span> <span> Peaple </span> </h5>
                          </div> */}

            {this.state.userName === this.state.userDetail.username ? (
              <Link
                to="/profile/settings"
                className="button btn-soft-schoolmedia block my-3"
              >
                {" "}
                edit{" "}
              </Link>
            ) : null}
            <hr className="mt-3 mb-0"></hr>

            <div className="section-header">
              <div className="section-header-left">
                <h3 className="mb-0"> Teman </h3>
                {/* <p className="uk-text-small"> 3,4510 friends</p> */}
              </div>
              <div className="section-header-right">
                <Link
                  to={"/" + this.state.userName + "/friends"}
                  className="see-all text-schoolmedia"
                >
                  {" "}
                  Lihat Semua
                </Link>
              </div>
            </div>

            <div className="uk-child-width-1-3 uk-grid-small uk-grid-match uk-grid">
              {this.state.loadingFeedPhotos ? (
                <div className="w-100 col-12 row">
                  <div className="col-4 px-1 text-center">
                    <Skeleton variant="circle" width={90} height={90} />
                    <Skeleton
                      variant="square"
                      className="mt-2 w-100"
                      height={10}
                    />
                  </div>
                  <div className="col-4 px-1 text-center">
                    <Skeleton variant="circle" width={90} height={90} />
                    <Skeleton
                      variant="square"
                      className="mt-2 w-100"
                      height={10}
                    />
                  </div>
                  <div className="col-4 px-1 text-center">
                    <Skeleton variant="circle" width={90} height={90} />
                    <Skeleton
                      variant="square"
                      className="mt-2 w-100"
                      height={10}
                    />
                  </div>
                </div>
              ) : this.state.dataFriend.length <= 0 ? (
                <p className="text-sm w-100">Belum Memiliki Pertemanan</p>
              ) : (
                this.state.dataFriend.slice(0, 7).map((val, key) => {
                  return (
                    <div key={key}>
                      <Link
                        to={"/" + val.username}
                        className="profile-friend-card"
                      >
                        <div className="profile-friend-card-thumbnail">
                          {val.photo_profile != null ? (
                            <img
                              src={`https://static.schoolmedia.id/public/assets/socmed/${val.group_code}/photo/${val.photo_profile}`}
                              alt=""
                            />
                          ) : (
                            <img src="/assets/icons/user.svg" />
                          )}
                          {/* <img src="/assets/images/avatars/avatars-1.png" alt=""></img> */}
                        </div>
                        <div className="profile-friend-card-content w-100 text-center">
                          <h4> {val.name} </h4>
                        </div>
                      </Link>
                    </div>
                  );
                })
              )}
            </div>

            <Link
              to={"/" + this.state.userName + "/friends"}
              className="button btn-default block my-3"
            >
              {" "}
              Lihat Semua{" "}
            </Link>
            <hr className="mt-3 mb-0"></hr>

            <div className="section-header">
              <div className="section-header-left">
                <h3> Foto </h3>
              </div>
              <div className="section-header-right">
                <a href="#" className="see-all text-schoolmedia">
                  {" "}
                  Lihat Semua
                </a>
              </div>
            </div>

            <div
              className="uk-child-width-1-3 uk-grid-collapse uk-overflow-hidden uk-grid"
              style={{ borderRadius: "16px" }}
            >
              {this.state.loadingFeedPhotos ? (
                <div className="w-100 col-12 row">
                  <Skeleton
                    variant="square"
                    className="col-3 mr-1"
                    height={100}
                  />
                  <Skeleton
                    variant="square"
                    className="col-3 mr-1"
                    height={100}
                  />
                  <Skeleton
                    variant="square"
                    className="col-3 mr-1"
                    height={100}
                  />
                  <Skeleton
                    variant="square"
                    className="col-2 mr-1"
                    height={100}
                  />
                </div>
              ) : this.state.photosList.length <= 0 ? (
                <p className="text-sm w-100">Tidak Ada Postingan Foto</p>
              ) : (
                this.state.photosList.map((val, key) => {
                  return (
                    <div key={key}>
                      <Link
                        to={
                          "/" +
                          this.state.userName +
                          "/posts/" +
                          val.composite_id
                        }
                        data-caption={val.caption}
                        className="w-100"
                      >
                        <div
                          className="group-catagroy-card animate-this"
                          style={{ height: "100px", margin: "5px" }}
                          data-src={
                            url + "/" + this.state.userName + "/" + val.media
                          }
                          data-uk-img=""
                        >
                          <div className="group-catagroy-card-content">
                            <h4> {val.caption} </h4>
                          </div>
                        </div>
                      </Link>
                      {/* <a href={url + this.state.userName + '/' + val.media} data-caption={val.caption}>
                        <div className="photo-card small" data-src={url + this.state.userName + '/' +  val.media}></div>
                      </a> */}
                    </div>
                  );
                })
              )}
            </div>

            <hr className="mt-3 mb-2"></hr>

            <div uk-sticky="offset:144 ; bottom:true ; meda : @m">
              <div className="section-header pt-2">
                {/* <div className="section-header-left">
                                      <h3> Siaran Langsung </h3>
                                  </div>
                                  <div className="section-header-right">
                                      <a href="#" className="see-all text-schoolmedia"> Lihat Semua</a>
                                  </div> */}
              </div>

              <div className="uk-child-width-1-1@m uk-grid-collapse">
                {/* <!-- list itme 1 --> */}
                {/* <div>
                                      <div className="list-items">
                                          <div className="list-item-media">
                                              <img src="/assets/images/group/group-2.jpg" alt=""></img>
                                          </div>
                                          <div className="list-item-content">
                                              <a href="group-feed.html">
                                                  <h5> Coffee Addicts </h5>
                                              </a>
                                              <p> Drinks , Food </p>
                                          </div>
                                          <div className="uk-width-auto">
                                              <span className="btn-option">
                                                  <i className="icon-feather-more-horizontal"></i>
                                              </span>
                                              <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                                                  <ul>
                                                      <li>
                                                          <span> <i className="uil-bell"></i> Joined </span>
                                                      </li>
                                                      <li>
                                                          <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                                      </li>
                                                      <li>
                                                          <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div> */}
              </div>

              {/* <a href="#" className="button btn-default block my-3"> See All </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};
export default connect(mapStateToProps, null)(UserAbout);
