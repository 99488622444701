import React from 'react';
import axios from 'axios';
import RightBar from '../partials/RightSidebar/RightBar';
import Post from '../partials/Timeline/Post';
import { connect } from "react-redux";
import CreatePostPop from 'component/partials/Timeline/CreatePostPop';
import SkeletonPost from 'component/partials/skeleton/SkeletonPost';

import InfiniteScroll from 'react-infinite-scroll-component';

const api = `${process.env.REACT_APP_API}`;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreatePost: false,

      authUser: [],
      userData: [],

      // feed
      feed: [],
      dataFeed: [],
      loadingGetFeed: false,
      loadingNextFeed: false,

      countToScroll: 1,
      noData: false,
    }
    this.getFeed = this.getFeed.bind(this)
    this.getFeedAfterCreate = this.getFeedAfterCreate.bind(this)
    this.afterDelete = this.afterDelete.bind(this)

    this.nextData = this.nextData.bind(this)

    // detect bottom scroll
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (!this.state.noData) {
          this.setState({
            loadingFeed: true,
            countToScroll: this.state.countToScroll + 1,
          }, function () {
            this.getFeed()
          })
        }
      }
    }
  }

  componentDidMount() {
    this.setState({ loadingGetFeed: true })
    this.getFeed()
  }

  getFeed(e) {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    let toScroll = e;
    if(e === undefined){
      toScroll = this.state.countToScroll
    }

    axios.post(api + 'feed/get-feed?type=all&page=1&page[number]=' + toScroll, config)
      .then(res => {
        const nextFeed = res.data.data.data
        if (res.data.data === []) {
          this.setState({
            noData: true,
          })
        }
        this.setState({
          feed: res.data.data,
          dataFeed: [
            ...this.state.dataFeed,
            ...nextFeed
          ],
          loadingGetFeed: false,
          loadingNextFeed: false,
        })
      })
      .catch(err => {
        this.setState({
          countToScroll: this.state.countToScroll
        })
      })
  }
  getFeedAfterCreate() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    axios.post(api + 'feed/get-feed?type=all&page=1&page[number]=' + this.state.countToScroll, config)
      .then(res => {
        const nextFeed = res.data.data.data

        this.setState({
          feed: res.data.data,
          dataFeed: nextFeed,
          loadingGetFeed: false,
          loadingNextFeed: false,
        })
      })
      .catch(err => {
        this.setState({
          countToScroll: this.setState.countToScroll
        })
      })
  }
  afterDelete(e) {
    let fileArrayTemp = []
    this.state.dataFeed.forEach((value, key) => {
      if (value.id != e) fileArrayTemp.push(value)
    })
    this.fileArray = fileArrayTemp

    this.setState({
      dataFeed: this.fileArray
    })
  }


  static getDerivedStateFromProps(prevProps, props) {
    if (props.userReducer !== prevProps.userReducer) {
      return ({
        userData: prevProps.userReducer.userDetail,
      })
    }
  }

  openModalCreatePost() {
    this.setState({
      modalCreatePost: true,
    })
  }
  hideModalCreatePost() {
    this.setState({
      modalCreatePost: false,
    })
  }

  nextData() {
    let toScroll = this.state.countToScroll + 1;
    this.setState({loadingNextFeed: true})
    this.getFeed(toScroll)
  }

  render() {
    const { match } = this.props;
    return (
      <>

        {/* // <!-- contents --> */}
        <div className="main_content">
          <div className="main_content_inner">
            <div className="uk-grid-large uk-grid gird-content">
              <div className="uk-width-2-3@m fead-area">

                <div className="post-newer">
                  <div className="post-new" uk-toggle="target: body ; cls: post-focus">
                    {/* <div className="post-new-media">
                      <div className="post-new-media-user">
                        <img src="assets/images/avatars/avatar-2.jpg" alt=""></img>
                      </div>
                      <div className="post-new-media-input">
                        <input type="text-area" className="uk-input" onClick={this.openModalCreatePost} placeholder="Apa yang kamu pikirkan?"></input>
                      </div>
                    </div> */}
                    <CreatePostPop data={this.state.userData} getFeed={this.getFeedAfterCreate} postType={"feed"} />
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={this.state.dataFeed.length}
                  next={this.nextData}
                  hasMore={true}
                >
                  {this.state.loadingGetFeed ?
                    <>
                      <SkeletonPost />
                      <SkeletonPost />
                      <SkeletonPost />
                    </> :
                    this.state.dataFeed.map((feeds) => {
                      return (
                        <Post data={feeds} key={feeds.id} getFeed={this.afterDelete} />
                      )
                    })
                  }
                </InfiniteScroll>
                <div style={{ height: '2rem' }}>
                  {this.state.loadingNextFeed ?
                    <>
                      <span>loading</span>
                    </> :
                    <>
                      <span>You did it! You reached the end!</span>
                    </>
                  }
                </div>
              </div>
              <RightBar />
            </div>
          </div>
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userReducer: state.userReducer,
  };
};

export default connect(mapStateToProps)(Home);
