import React from "react";
import GroupHeader from "./components/GroupHeader";
import MediaPhotos from "./media/Photos";
import MediaVideos from "./media/Videos";

export default class GroupMedia extends React.Component {

  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="single-group">
            <GroupHeader />
            <div className="mt-5">
              <div className="post-new">
                <h3> Media Grup </h3>
                <div className="uk-flex uk-flex-between">
                  <ul className="uk-tab"
                    uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                    <li className="uk-active"><a href="#">
                      {/* <i className="icon-feather-home mr-2"></i> */}
                      Foto</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                      Video</a>
                    </li>
                  </ul>
                  <div>
                  <a href="#" className="button btn-schoolmedia small circle ml-2"> <i className="uil-plus"> </i> Buat
                                  Album </a>
                  <a href="#" className="button btn-schoolmedia small circle ml-4"> Tambahkan Foto/Video </a>
                  </div>
                </div>

                <hr />
                <ul className="uk-switcher uk-margin" id="component-tab-left">
                  <li>
                    <MediaPhotos />
                  </li>
                  <li>
                    <MediaVideos />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
