import React, {useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LeftSideBar from '../component/partials/LeftSideBar';
import { MaintenanceOverlay } from "../component/pages/error_pages/Maintenance";

import {status_login} from "utils/sso";


// Route for Maintance component
const MaintenanceRoute = ({ component: Component, ...rest }) => {
  // state in stateless component
  let [authStatus, setauthStatus] = useState(true);

  if (localStorage.getItem('jwtToken') === null) {
    return <Redirect to="/" />;
  }

  status_login().catch((err) => {
    // if (err.response.statusText === 'Unauthorized') {
      // console.log(testVar);
      if (authStatus === true) {
        setauthStatus(false);
      }
    // }
  });

  if (authStatus === false) {
    return <Redirect to="/" />;
  }

  return (
    <Route {...rest} component={(props) => (
      <>
        <LeftSideBar />
        <MaintenanceOverlay />
        <Component {...props} />
      </>
    )}
    />
  )
}

export default MaintenanceRoute;