import React from 'react';
import { BrowserRouter as Router, Link, NavLink, Switch, Route, Redirect } from "react-router-dom";

export default class SearchLeftBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: null
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.searchKey != state.searchKey) {
      return({
        searchKey: nextProps.searchKey
      })
    }
  }
  render() {
    // const { path } = this.props.match;

    return (
      <>
        <div className="uk-width-1-4@m uk-first-column">
          <ul className="shadow p-3 pl-0 uk-tab uk-tab-right rounded bg-white">
            <div className="pl-4">
              <h4>Hasil Pencarian untuk {this.state.searchKey}</h4>
            </div>
            <li className="pl-2">
              <Link to={`/search`}> <i className="icon-feather-home mr-2"></i>Semua</Link>
            </li>
            <li className="pl-2">
              {/* <Link to={`/search/person`}>
                <i className="icon-feather-message-square mr-2"></i>
              Orang
            </Link> */}
            </li>
          </ul>
        </div>
      </>
    )
  }
}