import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export default class FriendHeader extends React.Component{
    render() {
        return (
            <div className="profile">
                <div className="profile-cover">
                {/* <!-- profile cover --> */}
                <img src="/assets/images/avatars/profile-cover.png" alt=""></img>
                
                </div>

                <div className="profile-details">
                <div className="profile-image">
                    <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                </div>
                <div className="profile-details-info">
                    <h1 className="mt-2"> Andika Prass </h1>
                    
                </div>
                </div>
                <div className="nav-profile" uk-sticky="offset:61;media : @s">
                <div>
                    <nav className="responsive-tab">
                    <ul>
                        <li>
                        <NavLink to="/profile">Tentang</NavLink>
                        </li>
                        <li>
                        <NavLink to="/friends">Teman</NavLink>
                        </li>
                        <li><NavLink to="/photos" href="timeline-friends.html">Foto</NavLink></li>
                        <li><NavLink to="/videos">Siaran</NavLink></li>
                        <li><NavLink to="/content">Konten</NavLink></li>
                        <li><NavLink to="/list-notes">Catatan</NavLink></li>
                        <li>
                        <NavLink to="/following-group">Group</NavLink>
                        </li>
                    </ul>
                    </nav>
                    {/* <div className="uk-visible@s">
                        <a href="#" className="nav-btn-more"> More</a>
                        <div uk-dropdown="pos: bottom-left ; mode:click ">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#">Moves</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                </div>
            </div>
        )
    }
}