import React from 'react';
import Post from '../../partials/Timeline/Post';

export default class AboutFriend extends React.Component{
    render() {
        return(
            <div className="section-small">
                <div className="uk-grid grid-content profile-page" uk-grid>
                    <div className="uk-width-2-3@m fead-area">

                    <Post/>

                    </div>

                    {/* <!-- sidebar --> */}
                    <div className="uk-width-expand ml-lg-2 bg-white rounded-sm pr-4 pl-4 pt-4 pb-4">
                        <h3> Tentang </h3>
                        <div className="list-group-items">
                            <i className="uil-home-alt"></i>
                            <h6 className="font-sm uk-flex-between w-70">
                            <span>
                            Tinggal
                            </span> 
                            <span className="uk-flex uk-flex-column">
                                <p className="font-sm font-schoolmedia">DKI Jakarta</p>
                                <p className="font-xs">13 Juni 2020</p>
                            </span>
                            </h6>
                        </div>

                        <div className="list-group-items">
                            <i className="uil-location-point"></i>
                            <h6 className="font-sm uk-flex-between w-70">
                            <span>Asal</span> 
                            <span className="uk-flex uk-flex-column">
                                <p className="font-sm font-schoolmedia">DKI Jakarta</p>
                                <p className="font-xs">13 Juni 2020</p>
                            </span>
                            </h6>
                        </div>

                        <div className="list-group-items">
                            <i className="uil-home-alt"></i>
                            <h6 className="font-sm uk-flex-between w-70"> 
                            <span>Sekolah</span>
                            <span className="uk-flex uk-flex-column">
                                <p className="font-sm font-schoolmedia">SMKN 1 Jakarta</p>
                                <p className="font-xs">13 Juni 2020</p>
                            </span>
                            </h6>
                        </div>

                        {/* <div className="list-group-items">
                            <i className="uil-rss"></i>
                            <h5> Flowwed by <span> 3,240 </span> <span> Peaple </span> </h5>
                        </div> */}

                        <hr className="mt-3 mb-0"></hr>

                        <div className="section-header">
                            <div className="section-header-left">
                                <h3 className="mb-0"> Teman </h3>
                                {/* <p className="uk-text-small"> 3,4510 friends</p> */}
                            </div>
                            <div className="section-header-right">
                                <a href="#" className="see-all text-schoolmedia"> Lihat Semua</a>
                            </div>
                        </div>

                        <div className="uk-child-width-1-3 uk-grid-small uk-grid-match uk-grid" uk-grid>
                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4> Jonathan Ali </h4>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4>Alex Dolgove</h4>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4> Stella Johnson </h4>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4> Mohamed Khalif </h4>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4> Adrian Mohani </h4>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="#" className="profile-friend-card">
                                    <div className="profile-friend-card-thumbnail">
                                        <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                                    </div>
                                    <div className="profile-friend-card-content">
                                        <h4>Alex Dolgove</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <a href="#" className="button btn-default block my-3"> Lihat Semua </a>
                        <hr className="mt-3 mb-0"></hr>

                        <div className="section-header">
                            <div className="section-header-left">
                                <h3> Foto </h3>
                            </div>
                            <div className="section-header-right">
                                <a href="#" className="see-all text-schoolmedia"> Lihat Semua</a>
                            </div>
                        </div>

                        <div className="uk-child-width-1-3 uk-grid-collapse uk-overflow-hidden uk-grid" style={{borderRadius: "16px"}} uk-grid>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/post/img-1.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/post/img-2.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/post/img-3.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/post/img-4.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/category/img6.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                            <div> 
                                <a href="#">
                                    <div className="photo-card small" data-src={"/assets/images/category/img3.jpg"} data-uk-img=""></div>
                                </a>
                            </div>
                        </div>

                        <hr className="mt-3 mb-2"></hr>

                        <div uk-sticky="offset:144 ; bottom:true ; meda : @m">
                            <div className="section-header pt-2">
                                {/* <div className="section-header-left">
                                    <h3> Siaran Langsung </h3>
                                </div>
                                <div className="section-header-right">
                                    <a href="#" className="see-all text-schoolmedia"> Lihat Semua</a>
                                </div> */}
                            </div>

                            <div className="uk-child-width-1-1@m uk-grid-collapse" uk-grid>
                                {/* <!-- list itme 1 --> */}
                                {/* <div>
                                    <div className="list-items">
                                        <div className="list-item-media">
                                            <img src="/assets/images/group/group-2.jpg" alt=""></img>
                                        </div>
                                        <div className="list-item-content">
                                            <a href="group-feed.html">
                                                <h5> Coffee Addicts </h5>
                                            </a>
                                            <p> Drinks , Food </p>
                                        </div>
                                        <div className="uk-width-auto">
                                            <span className="btn-option">
                                                <i className="icon-feather-more-horizontal"></i>
                                            </span>
                                            <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                                                <ul>
                                                    <li>
                                                        <span> <i className="uil-bell"></i> Joined </span>
                                                    </li>
                                                    <li>
                                                        <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                                    </li>
                                                    <li>
                                                        <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            {/* <a href="#" className="button btn-default block my-3"> See All </a> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}