import { GET_LIST_FORUM, GET_LIST_FORUM_ERR, GET_LIST_FORUM_SUCCESS } from "redux/actions/types";

// Global State
const globalState = {
  listForum: [],
  loading: false,
}

// Reducer
const forumReducer = (state = globalState, action) => {
  if(action.type === GET_LIST_FORUM){
    return{
      ...state,
      listForum: [],
      loading: true,
    }
  } else if(action.type === GET_LIST_FORUM_SUCCESS){
    return{
      ...state,
      listForum: action.data,
      loading: false,
    }
  } else if(action.type === GET_LIST_FORUM_ERR){
    return{
      ...state,
      listForum: action.data,
      loading: false,
    }
  }
  return state;
}

export default forumReducer;