import React from 'react';
import axios from 'axios';
import SkeletonPost from 'component/partials/skeleton/SkeletonPost';
import RightBar from "../../partials/RightSidebar/RightBar";

// material ui
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CreateNotes from '../../partials/notes/CreateNotes';
import NotePost from 'component/partials/notes/NotePost';
import { connect } from 'react-redux';

const api = `${process.env.REACT_APP_API}`;

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreate: false,
      userData: null,
      data: [],
      loadingGetNote: false,
      countToScroll: 1,
    }
    this.getNote = this.getNote.bind(this)

    this.modalCreateOpen = this.modalCreateOpen.bind(this);
    this.modalCreateClose = this.modalCreateClose.bind(this);

    // detect bottom scroll
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)  {
        this.setState({
          loadingNote: true,
          countToScroll: this.state.countToScroll + 1,
        })
        this.getNote()
      }
    }
  }
  static getDerivedStateFromProps(prevProps, props) {
    if (props.userReducer !== prevProps.userReducer) {
      return ({
        userData: prevProps.userReducer.userDetail,
      })
    }
  }
  modalCreateOpen() {
    this.setState({ modalCreate: true });
  }
  modalCreateClose() {
    this.setState({ modalCreate: false })
  }

  componentDidMount() {
    this.setState({ loadingGetNote: true })
    this.getNote()
  }

  // Get Note
  getNote() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    axios.post(api + 'note/get-note?type=all&page=1&page[number]=', config)
      .then(res => {
        this.setState({
          data: res.data.data.data,
          loadingGetNote: false,
        })
      })
      .catch(err => {
        this.setState({
          countToScroll: this.setState.countToScroll
        })
      })
  }


  render() {
    return (
      <>
        <div className="main_content">
          <div className="main_content_inner">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="modal"
              open={this.state.modalCreate}
              onClose={this.modalCreateClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.modalCreate}>
                <div className="bg-white uk-flex uk-flex-column uk-padding" style={{borderRadius: '1rem'}}>
                  <div className="uk-flex uk-items-center justify-beetwen">
                    <h3>Buat Catatan</h3>
                  </div>
                  <div className="" id="">
                    <CreateNotes retriveData={this.getNote} handleModal={this.modalCreateClose}/>
                  </div>
                </div>
              </Fade>
            </Modal>
            <div className="uk-flex uk-flex-between">
              <h3> Catatan </h3>
              <span onClick={this.modalCreateOpen} className="button btn-schoolmedia small circle uk-visible@s">
                <i className="uil-plus"></i> tulis Catatan
              </span>
            </div>
            <div className="uk-grid-large uk-grid gird-content">
              <div className="uk-width-2-3@m fead-area">
                {this.state.loadingGetNote ?
                  <>
                    <SkeletonPost />
                    <SkeletonPost />
                    <SkeletonPost />
                  </> :
                  this.state.data.map((notes) => {
                    return (
                      <NotePost
                        data={notes}
                        retriveData={this.getNote}
                        handleModal={this.modalCreateClose}
                        userData={this.state.userData}
                        getNote={this.getNote}
                        key={notes.id}
                      />
                    )
                  })
                }
              </div>
              <RightBar />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};
export default connect(mapStateToProps)(Notes);
