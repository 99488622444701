import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

const api = `${process.env.REACT_APP_API}`;

class CreateNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      images64: [],
      imgBlob: []
    }
    this._handleContent = this._handleContent.bind(this)
    this._handleImg = this._handleImg.bind(this)
    this.upload = this.upload.bind(this)
  }

  _handleContent(data) {
    var element = document.createElement('div');
    element.innerHTML = data;
    let res = element.getElementsByTagName('img')
    for (let i = 0; i < res.length; i++) {
      let urlValue = res[i].getAttribute("src");
      if (urlValue) {
        res[i].setAttribute("src", ``);
      }
    }
    let result = element

    this.setState({
      content: result.outerHTML
    })
  }

  _handleImg(e) {
    this.setState({
      images64: e
    })
  }

  b64Blob(data) {
    return fetch(data)
      .then(res => res.blob())
      .then(blob => {
        return Promise.resolve(URL.createObjectURL(blob));
      });
  }

  upload() {
    let data = this.state.images64
    // Promise.all(data.map(this.b64Blob)).then(res => {
    //   this.postData(res)
    // });
    let bin = []
    const dataURLtoFile = (dataurl, filename) => {

      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    }
    data.map((val, key) => {
      bin.push(dataURLtoFile(val, key))
    })

    this.postData(bin)
    
  }
  postData(data) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };
    let formData = new FormData();
    let requestData = null;
    requestData = {
      content: this.state.content,
      status: 1,
    };
    formData.append('json', JSON.stringify(requestData));
    data.forEach((val,key) =>{
      formData.append('images[' + key + ']', val);
    })
    // formData.append('images[]', data);
    axios.post(api + 'note/post-note', 
      formData,
      config
    ).then(res => {
      const status = res
      this.props.upload_success(status)
      this._handleRetrive()
      this.props.handleModal()
    }).catch( err => {
      const status = err.response
      this.props.upload_error(status)
    })
  }
  _handleRetrive(){
    this.props.retriveData()
  }
  render() {
    const editorConfiguration = {
      toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'bulletedList',
        'numberedList',
        '|',
        'blockQuote',
        'insertTable',
        '|',
        'imageUpload',
        'undo',
        'redo'
      ],
    };
    return (
      <>
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          // data="<p>Hello from CKEditor 5!</p>"
          onReady={editor => {
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            let imgs = []
            this._handleContent(editor.getData())
            Array.from(new DOMParser().parseFromString(editor.getData(), 'text/html')
              .querySelectorAll('img'))
              .forEach((value, key) => {
                imgs.push(value.getAttribute('src'))
              })
            this._handleImg(imgs)
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
        <div className="uk-flex uk-items-center pt-5 justify-center">
          <div className="post-title uk-width-1-1">
            <div className="post-heading uk-flex uk-justify-between uk-width-14" style={{ justifyContent: 'space-between' }}>
              <div className="post-btn-action" style={{ width: '100%!important' }}>
                <button className="button small bg-schoolmedia text-white" onClick={this.upload} style={{ width: '100%!important', height: '35px!important' }}>Posting</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
}, dispatch)
export default connect(null, mapDispatchToProps)(CreateNotes);