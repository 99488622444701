import React from "react";
import { Link } from "react-router-dom";
import JoinedGroupCard from "./components/JoinedGroupCard";
import SuggestedGroupCard from "./components/SuggestedGroupCard";
import * as ForumAction from "redux/actions/ForumAction";
import { connect } from 'react-redux';

class ListGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listForum: []
    }
  }
  componentDidMount() {
    this.getForumList()
  }
  getForumList() {
    let { dispatch } = this.props;
    let action = ForumAction.getForumList()

    dispatch(action)
  }
  static getDerivedStateFromProps(props, state) {
    if (props.forumReducer != state.forumReducer) {
      let list = props.forumReducer.listForum
      let group = list.reduce((r, a) => {
        r[a.forum_category_id] = [...r[a.forum_category_id] || [], a];
        return r;
      }, {});
      return ({
        listForum: group
      })
    }
  }
  render() {
    return (
      <>
        
        <div className="main_content" style={{ background: "none" }}>
          <div className="main_content_inner my-3">
            <div className="uk-flex uk-flex-middle uk-flex-between mb-3">
              <h1 className="mb-0">Grup Tetap</h1>
            </div>
            <div className="uk-position-relative" uk-slider="finite: true">
              <div className="uk-slider-container pb-3">
                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s  pr-lg-1 uk-grid" uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-small; delay: 100">
                  <li>
                    <div className="group-card bg-white">
                      <div className="group-card-thumbnail" style={{ height: "160px" }}>
                        <img src="/assets/images/group/group-cover-1.jpg" alt="" />
                      </div>

                      <div className="group-card-content">
                        <h3> Level 1 </h3>
                        <p className="info"> <a href="#"> <span> 232k members </span> </a>
                          <a href="#"> <span> 1.7k post a day </span> </a>
                        </p>

                        <div className="group-card-btns">
                          <Link to="/groups/level-1" className="button btn-default small"> View </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="group-card bg-white">
                      <div className="group-card-thumbnail" style={{ height: "160px" }}>
                        <img src="/assets/images/group/group-cover-2.jpg" alt="" />
                      </div>

                      <div className="group-card-content">
                        <h3> Level 2 </h3>
                        <p className="info"> <a href="#"> <span> 160k members </span> </a>
                          <a href="#"> <span> 1.1k post a day </span> </a>
                        </p>

                        <div className="group-card-btns">
                          <Link to="/groups/level-2" className="button btn-default small"> View </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="group-card bg-white">
                      <div className="group-card-thumbnail" style={{ height: "160px" }}>
                        <img src="/assets/images/events/img-2.jpg" alt="" />
                      </div>

                      <div className="group-card-content">
                        <h3> Level 3 </h3>
                        <p className="info"> <a href="#"> <span> 20k members </span> </a>
                          <a href="#"> <span> 1k post a day </span> </a>
                        </p>

                        <div className="group-card-btns">
                          <Link to="/groups/level-3" className="button btn-default small"> View </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="group-card bg-white">
                      <div className="group-card-thumbnail" style={{ height: "160px" }}>
                        <img src="/assets/images/group/group-cover-3.jpg" alt="" />
                      </div>

                      <div className="group-card-content">
                        <h3> Level 4 </h3>
                        <p className="info"> <a href="#"> <span> 410k members </span> </a>
                          <a href="#"> <span> 1.2k post a day </span> </a>
                        </p>

                        <div className="group-card-btns">
                          <a href="#" className="button btn-default small"> View </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <a className="uk-position-center-left-out uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous"></a>
                <a className="uk-position-center-right-out uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next"></a>
              </div>
            </div>

            <hr />

            <div className="uk-flex uk-flex-middle uk-flex-between mb-3">
              <h1 className="mb-0">Grup Saya</h1>
              <h6 className="mb-0"><a href="#">Lihat Selengkapnya</a></h6>
            </div>
            <div className="uk-position-relative" uk-slider="finite: true">
              <div className="uk-slider-container pb-3">
                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s  pr-lg-1 uk-grid" uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-small; delay: 100">
                  <JoinedGroupCard />
                  <JoinedGroupCard />
                  <JoinedGroupCard />
                  <JoinedGroupCard />
                  <JoinedGroupCard />
                  <JoinedGroupCard />
                </ul>

                <a className="uk-position-center-left-out uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous"></a>
                <a className="uk-position-center-right-out uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next"></a>
              </div>
            </div>

            <hr />

            <div className="uk-flex uk-flex-middle uk-flex-between mb-3">
              <h1 className="mb-0">Rekomendasi Grup</h1>
              <h6 className="mb-0"><a href="#">Lihat Selengkapnya</a></h6>
            </div>
            <div className="uk-position-relative" uk-slider="finite: true">
              <div className="uk-slider-container pb-3">
                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s  pr-lg-1 uk-grid" uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-small; delay: 100">
                  <SuggestedGroupCard />
                  <SuggestedGroupCard />
                  <SuggestedGroupCard />
                  <SuggestedGroupCard />
                  <SuggestedGroupCard />
                </ul>

                <a className="uk-position-center-left-out uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous"></a>
                <a className="uk-position-center-right-out uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next"></a>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    forumReducer: state.forumReducer,
  }
}

export default connect(mapStateToProps)(ListGroup);
