import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import UserAbout from "./UserAbout";
import UserPhotos from "./UserPhotos";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import ProfileHeader from "./components/ProfileHeader";
import Navbar from "../../partials/Navbar";
const api = `${process.env.REACT_APP_API}`;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      userName: null,
      dataFeed: [],
      dataFriend: [],
      countToScroll: 1,
      isLogin: true,
    };

    this._getuser = this._getUser.bind(this);
    this._getFriend = this._getFriend.bind(this);
  }
  componentDidMount() {
    const path = window.location.pathname;
    console.log(path);
    this.setState({
      userName: path.substr(1),
    });
    this._getUser(path.substr(1));
  }
  _getUser(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .post(
        api + "profile/get-user",
        {
          username: e,
        },
        config
      )
      .then((res) => {
        this.setState({
          data: res.data.data.item,
        });
      });
  }

  _getFriend(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .post(
        api + "friend/get-friend",
        {
          username: e,
        },
        config
      )
      .then((res) => {
        this.setState({
          dataFriend: res.data.data.items,
        });
      });
  }

  render() {
    console.log(this.state.data)
    return (
      <>
        <div id={!isEmpty(this.props.auth.user) ? "" : "wrapper"}>
          <Navbar />
          <div className={!isEmpty(this.props.auth.user) ? "main_content" : "main_page_container"}>
            <div className="main_content_inner pt-0">
              <ProfileHeader
                data={this.state.data}
                userName={this.state.userName}
              />
              <UserAbout
                data={this.state.data}
                dataFriend={this.state.dataFriend}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(UserProfile);
