import React from 'react';
import timeAgo from 'utils/timeAgo';
import { Link } from "react-router-dom";
import axios from 'axios';
import PostComments from 'component/partials/global/PostComments';

const api = `${process.env.REACT_APP_API}`;

export default class GroupPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      threadData: '',
      forumCode: null,
      medias: [], 

      dataTopicComment: [],
      type: null,
      passComment: false,

      liked: false,
      totalLikes: null,
      isLoading: false,
      isRepliedLoading: false
    }
    this.renderMedia = this.renderMedia.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.getTopicComment = this.getTopicComment.bind(this);
  }
  componentDidMount() {
    const url = window.location.pathname
    let arr = []
    arr = url.split('-')
    let res = []
    let toRes = arr[arr.length - 1]
    res = toRes.split('/')
    
    this.setState({ forumCode: res[0] })
    this.getTopicComment()
  }
  static getDerivedStateFromProps(props, state) {
    if (props.threadData != state.threadData) {
      return ({
        threadData: props.threadData,
        medias: props.threadData.topic_medias,
      })
    } else {
      return null;
    }
  }
  filterMedia(e, type) {
    if (e.endsWith(".mp4") || e.endsWith(".MP4")) {
      return (
        <video controls>
          <source src={e} />
        </video>
      )
    } else {
      return (
        <img 
          src={e} 
          alt="" 
          style={
            type == "album" ? { 
              width: '100%', 
              height: "250px", 
              maxHeight: "250px", 
              objectFit: "none", 
              objectPosition: "center" 
            } : {
              width: '100%', 
            }
          } 
        />
      )
    }
  }

  getForumCode() {
    const url = window.location.pathname
    let arr = []
    arr = url.split('-')
    let res = []
    let toRes = arr[arr.length - 1]
    res = toRes.split('/')

    return res[0]
  }

  // Like Postingan Group
  handleLike(e) {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    this.setState({ liked: !this.state.liked }, function(){
      if(this.state.liked) {
        var totalLike = this.state.totalLikes + 1;
      } else {
        var totalLike = this.state.totalLikes - 1;
      }
      this.setState({ totalLikes: totalLike });
    })
    axios.post(api + 'topic-like/post-topic-like', {
      dest_id: this.state.threadData.id,
      type: 1,
      forum_code: e,
      topic_id: this.state.threadData.topic_id,
    }, config)
      .then(res => {
        // 
      }).catch(err => {
        this.setState({ liked: !this.state.liked })
      })
  }

  getTopicComment() {
    let forumCode = this.getForumCode()
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    let requestData = {
      forum_code: forumCode,
      topic_id: this.state.threadData.topic_id,
      type: 1,
      dest_id: this.state.threadData.id,
    };
    axios.post(api + 'topic-comment/get-topic-comment', requestData, config)
    .then(res => {
      this.setState({
        passComment: true,
        dataTopicComment: res.data.data.items,
      })
    })
  }

  renderMedia() {
    let medias = JSON.parse(this.state.medias)
    // console.log(medias[0].endsWith(".mp4"))
    if (medias !== null) {
      // let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`

      const url = '';
      if (medias.length === 1) {
        return (
          <div className="fullsizeimg" uk-lightbox="animation: slide">
            {medias.map((val, key) => {
              let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`
              return (
                <a className="uk-inline" href={val} style={{ marginTop: '1rem', width: '100%' }}>
                  {this.filterMedia(val, "single")}
                </a>
              )
            }
            )}
          </div>
        )
      } else if (medias.length === 2) {
        return (
          <div className="uk-grid-small uk-grid" uk-grid="" uk-lightbox="animation: slide">
            {medias.map((val, key) => {
              let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`
              return (
                <div key={key} className="uk-width-1-2@m uk-width-1-2 uk-grid-margin uk-first-column">
                  <a className="uk-inline" href={val}>
                    {this.filterMedia(val, "album")}
                  </a>
                </div>
              )
            })}
          </div>

        )
      } else {
        const more = medias.length - 3;

        return (
          <div className="uk-grid-small uk-grid" uk-grid="" uk-lightbox="animation: slide">
            {medias.slice(0, 1).map((val, key) => (
                <div className="uk-width-1-1@m uk-first-column" key={key}>
                  <a className="uk-inline" href={val}>
                    {this.filterMedia(val, "album")}
                  </a>
                </div>
            ))}
            {medias.slice(1, 2).map((val, key) => (
              <div className="uk-width-1-2@m uk-width-1-2 uk-grid-margin uk-first-column" key={key}>
                <a className="uk-inline" href={val}>
                  {this.filterMedia(val, "album")}
                </a>
              </div>
            ))}
            {medias.slice(2, 3).map((val, key) => (
              <div className="uk-width-1-2@m uk-width-1-2 uk-position-relative uk-grid-margin" key={key}>
                <Link to={`/post/`}>
                  {this.filterMedia(val, "album")}
                </Link>
                {medias[medias.length - 1] ?
                  <div className="uk-position-center uk-light">
                    {more <= 0 ? null :
                      <a>
                        <h3> + {more} lainnya </h3>
                      </a>}
                  </div> : null
                }

              </div>
            ))}
          </div>

        )
      }
    }
  }
  render() {
    let test = this.state.dataTopicComment;
    console.log(test);
    let time = "-";
    if (this.state.threadData?.created_at != null) {
      time = timeAgo(this.state.threadData?.created_at)
    }
    return (
      <>
      <div className="post">
        <div className="post-heading">
        <Link to={"/"+this.state.threadData?.username}>
            <div className="post-avature">
              {this.state.threadData?.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.threadData?.group_code}/photo/${this.state.threadData?.photo_profile}`} alt="" /> :
                <img src='assets/icons/user.svg' />
              }
            </div>
          </Link>
          <div className="post-title">
            <h4> {this.state.threadData?.name}</h4>
            <p> {time} <i className="uil-users-alt"></i> </p>
          </div>
          <div className="post-btn-action">
            <span className="icon-more uil-ellipsis-h"></span>
            <div className="mt-0 p-2" uk-dropdown="pos: bottom-right;mode:hover">
              <ul className="uk-nav uk-dropdown-nav">
                <li><a href="#"> <i className="uil-share-alt mr-1"></i> Share </a></li>
                <li><a href="#"> <i className="uil-edit-alt mr-1"></i> Edit Post </a></li>
                <li><a href="#"> <i className="uil-comment-slash mr-1"></i> Disable comments </a></li>
                <li><a href="#"> <i className="uil-favorite mr-1"></i> Add favorites </a></li>
                <li><a href="#" className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="post-description">
          <p>{this.state.threadData?.content}</p>
          {this.state.threadData?.has_media ?
            this.renderMedia() :
            null
          }

          <div className="post-state-details">
            <div>
              {/* <img src="/assets/images/icons/reactions_like.png" alt=""></img> */}
              {/* <img src="/assets/images/icons/reactions_love.png" alt=""></img> */}
              <p> {this.state.totalLikes > 0 ? `${this.state.totalLikes} menyukai` : ''}</p>
            </div>
            <p> 24 Comments</p>
          </div>
        </div>

        <div className="post-state">
          <div className="post-state-btns" 
            style={{ color: `${this.state.liked ? "#007bff" : ""}` }} 
            onClick={e => { this.handleLike(this.state.forumCode) }}
          >
            <i className="uil-thumbs-up"></i>
            <span>
              {this.state.liked ? "Disukai" : "Suka"}
            </span>
          </div>
          <div className="post-state-btns">
            <i className="uil-heart"></i> 18 <span> Coments </span>
          </div>
          <div className="post-state-btns">
            <i className="uil-share-alt"></i> 193 <span> Shared </span>
          </div>
          <div className="post-state-btns">
            <i className="uil-bookmark"></i> 13 <span> Saved </span>
          </div>
        </div>

        {/* <!-- post comments --> */}
        {this.state.passComment ? 
          <PostComments
          commentData={this.state.dataTopicComment}
          postData={this.state.threadData}
          key={this.state.threadData?.id}
          numberToShow={3}
          type={1}
          commentType="thread"
          topicId={this.state.threadData.topic_id}
          destId={this.state.threadData.id}
        /> : null
        }
      </div>
      </>
    )
  }
}