import React from 'react';
import { Link } from 'react-router-dom';

export default class MediaVideos extends React.Component {
  render() {
    return (
      <div className="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small mt-5 uk-grid"
        uk-grid="">
        <div className="uk-first-column">
          <Link to="/videos/watch" className="video-post">
            {/* <!-- Blog Post Thumbnail --> */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-6.png" alt="" />
            </div>

            {/* <!-- Blog Post Content --> */}
            <div className="video-post-content">
              <h5> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h5>
              <div className="item-statistic font-sm">
                <span> <span className="count">Atta Gledek</span></span>
              </div>
              <div className="item-statistic font-xs">
                <span> <span className="count">2M</span> Tayangan </span>
                <span> <span className="count">-</span> </span>
                <span> <span className="count">1</span> Tahun lalu </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="">
          <Link to="/videos/watch" className="video-post">
            {/* <!-- Blog Post Thumbnail --> */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-6.png" alt="" />
            </div>

            {/* <!-- Blog Post Content --> */}
            <div className="video-post-content">
              <h5> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h5>
              <div className="item-statistic font-sm">
                <span> <span className="count">Atta Gledek</span></span>
              </div>
              <div className="item-statistic font-xs">
                <span> <span className="count">2M</span> Tayangan </span>
                <span> <span className="count">-</span> </span>
                <span> <span className="count">1</span> Tahun lalu </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="">
          <Link to="/videos/watch" className="video-post">
            {/* <!-- Blog Post Thumbnail --> */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-6.png" alt="" />
            </div>

            {/* <!-- Blog Post Content --> */}
            <div className="video-post-content">
              <h5> Cheesecake Eggless &amp; Without oven｜HidaMari Cooking  </h5>
              <div className="item-statistic font-sm">
                <span> <span className="count">Atta Gledek</span></span>
              </div>
              <div className="item-statistic font-xs">
                <span> <span className="count">2M</span> Tayangan </span>
                <span> <span className="count">-</span> </span>
                <span> <span className="count">1</span> Tahun lalu </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="">
          <Link to="/videos/watch" className="video-post">
            {/* <!-- Blog Post Thumbnail --> */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-6.png" alt="" />
            </div>

            {/* <!-- Blog Post Content --> */}
            <div className="video-post-content">
              <h5> Amazing Little Girl's VOICE LESSON w/Vocal Coach </h5>
              <div className="item-statistic font-sm">
                <span> <span className="count">Little Pony</span></span>
              </div>
              <div className="item-statistic font-xs">
                <span> <span className="count">2M</span> Tayangan </span>
                <span> <span className="count">-</span> </span>
                <span> <span className="count">1</span> Tahun lalu </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="uk-grid-margin uk-first-column">
          <a href="single-video.html" className="video-post">
            {/* Blog Post Thumbnail */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-2.png" alt="" />
            </div>

            {/* Blog Post Content */}
            <div className="video-post-content">
              <h3> GREAT RC JET MODEL SHOW WITH 2X SUKHOI SU-30 </h3>
            </div>
          </a>
        </div>

        <div className="uk-grid-margin">
          <a href="single-video.html" className="video-post">
            {/* Blog Post Thumbnail */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-3.png" alt="" />
            </div>

            {/* Blog Post Content */}
            <div className="video-post-content">
              <h3> Exploring an Abandoned Water Park in China </h3>
            </div>
          </a>
        </div>

        <div className="uk-grid-margin">
          <a href="single-video.html" className="video-post">
            {/* Blog Post Thumbnail */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-1.png" alt="" />
            </div>

            {/* Blog Post Content */}
            <div className="video-post-content">
              <h3> Amazing House Construction From Foundation To Last Detail </h3>
            </div>
          </a>
        </div>

        <div className="uk-grid-margin">
          <a href="single-video.html" className="video-post">
            {/* Blog Post Thumbnail */}
            <div className="video-post-thumbnail">
              <span className="video-post-time">23:00</span>
              <span className="play-btn-trigger"></span>
              <img src="/assets/images/video/img-6.png" alt="" />
            </div>

            {/* Blog Post Content */}
            <div className="video-post-content">
              <h3> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h3>
            </div>
          </a>
        </div>

      </div>
    )
  }
}
