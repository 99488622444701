import {
    UPLOAD_ON_PROGRESS,
    UPLOAD_SUCCESS,
    UPLOAD_ERROR,
    RETRIVE_DATA_ERROR
} from './types';

export const uploadOnProgress = (data) => {
    console.log('progress')
    return dispatch => {
        dispatch({ type: UPLOAD_ON_PROGRESS, message: 'Mengupdate...', status: 'loading' });
    };
};

export const uploadSuccess = (data) => {
    console.log('success')
    const status = data.status
    return dispatch => {
        dispatch({ type: UPLOAD_SUCCESS, message: 'Update berhasil', status: status});
    };
};

export const uploadError = (data) => {
    const status = data.status
    return dispatch => {
        dispatch({ type: UPLOAD_ERROR, message: 'Gagal update', status: status });
    };
};

export const retriveDataError = (data) => {
    const message = null;
    return dispatch => {
        dispatch({type: RETRIVE_DATA_ERROR, message: message, })
    }
}