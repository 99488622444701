import React from 'react';

export default class MediaPhotos extends React.Component{
  render(){
    return(
      <div className="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-collapse uk-overflow-hidden uk-grid"
        style={{borderRadius: "25px", overflow: "hidden"}}
        uk-lightbox="animation: scale"
      >
        <div>
          <a href="/assets/images/avatars/profile-cover.png" data-caption="Image caption">
              <div className="photo-card" data-src={"/assets/images/avatars/profile-cover.png"} data-uk-img="">
                  <div className="photo-card-content">
                    <div>
                        <h4> Image description </h4>
                        <p> <span>Like </span>
                            <span> Comment </span>
                            <span> Share</span>
                        </p>
                    </div>
                    <div>
                        <span className="uil-cloud-download btn-down"></span>
                      </div>
                  </div>
              </div>
          </a>
        </div>
        <div>
          <a href="/assets/images/avatars/profile-cover.png" data-caption="Image caption">
              <div className="photo-card" data-src={"/assets/images/avatars/profile-cover.png"} data-uk-img="">
                  <div className="photo-card-content">
                    <div>
                        <h4> Image description </h4>
                        <p> <span>Like </span>
                            <span> Comment </span>
                            <span> Share</span>
                        </p>
                    </div>
                    <div>
                        <span className="uil-cloud-download btn-down"></span>
                      </div>
                  </div>
              </div>
          </a>
        </div>
      </div>
    )
  }
}
