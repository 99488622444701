import { combineReducers } from "redux";

import globalReducer from "./globalReducer";
import auth from "./auth";
import userReducer from "./UserReducer";
import toastsReducer from "./toastsReducer";
import notifReducer from "./NotifReducer"
import searchReducer from "./SearchReducer"
import forumReducer from "./ForumReducer"
import topicReducer from "./TopicReducer"

export default combineReducers({
  globalReducer,
  auth,
  toastsReducer,
  userReducer,
  notifReducer,
  searchReducer,
  forumReducer,
  topicReducer
});
