import imageCompression from 'browser-image-compression'

export async function compressImage(thisObj, name, val) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
        const compressedFile = await imageCompression(val, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // convert on MB
    
        // console.log("loh",URL.createObjectURL(compressedFile));
        thisObj.setState((prevState) => { // prevState - previous state 
            const newState = { // new state object
                [name]: URL.createObjectURL(compressedFile),
            };
            return newState 
        });
      } catch (error) {
        console.log(error);
    }
    
 }
