import { USER_DETAIL, USER_DETAIL_ERROR } from './types';
import axios from 'axios';

const apiUM = `${process.env.REACT_APP_UM_API}`;

export const getUserDetail = (data) => dispatch => {
    try{
        const res = axios.post(apiUM + 'sosmed/detail-user', {
            uuid: data.uuid,
            group_code: data.group_code
            }).then(resp => {
                dispatch({
                    type: USER_DETAIL,
                    userDetail: resp.data[0]
                })
            }).catch(err => {
                dispatch( {
                    type:USER_DETAIL_ERROR,
                    userDetail: {},
                })
            })
        // dispatch({
        //     type: USER_DETAIL,
        //     userDetail: res.data
        // })
    }
    catch(e){
    }

}