import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import axios from 'axios';
const api = `${process.env.REACT_APP_API}`;
export default class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      noteId: null,
      noteData: {},
      convertLoading: false,
      imagesURLData: [],
      imagesDOM: [],
      imagesBase64: []
    }
    this._edit = this._edit.bind(this)
    this._handleContent = this._handleContent.bind(this)
    this.postData = this.postData.bind(this)
  }
  componentDidMount() {
    this._getNote()
  }
  static getDerivedStateFromProps(props, state) {
    if (props.noteId != state.noteId) {
      return ({
        noteId: props.noteId
      })
    }
  }
  _getNote() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
    };
    let formData = new FormData();
    formData.append('id', this.state.noteId)
    axios.post(api + 'note/show-note', formData, config).then(res => {
      this.setState({
        noteData: res.data.data.item
      })
    })
  }

  _handleContent(data) {
    var element = document.createElement('div');
    element.innerHTML = data;
    let res = element.getElementsByTagName('img')
    for (let i = 0; i < res.length; i++) {
      let urlValue = res[i].getAttribute("src");
      if (urlValue) {
        res[i].setAttribute("src", ``);
      }
    }
    let result = element
    this.setState({
      content: result.outerHTML
    })
  }

  _handleImg(e) {
    let data = e;
    e.map((val, key) => {
      var patt = new RegExp("schoolmedia");
      var res = patt.exec(val)
      if(res != null){
        var result = /[^/]*$/.exec(res.input)[0]
        this.setState({convertLoading: true,})
        axios.get(api + `media/get-image/${result}`).then(res => {
          data[key] = res.data.data.value
          this.setState({convertLoading: false,})
        })
      }
    })
    this.setState({
      imagesBase64: data
    })
  }
  _edit() {
    let dataImg = this.state.imagesBase64;
    this._base64Img(dataImg)
  }
  _base64Img(e){
    let data = e
    let binary = []
    const dataURLtoFile = (dataurl, filename) => {

      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    }
    data.map((val, key) => {
      binary.push(dataURLtoFile(val, key))
    })
    this.postData(binary)
  }
  postData(data) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };
    let formData = new FormData();
    let requestData = null;
    requestData = {
      content: this.state.content,
      status: 1,
    };
    formData.append('json', JSON.stringify(requestData));
    formData.append('note_id', this.state.noteId)
    data.forEach((val,key) =>{
      formData.append('images[' + key + ']', val);
    })
    // formData.append('images[]', data);
    axios.post(api + 'note/post-note', 
      formData,
      config
    ).then(res => {
      console.log(res)
      // const status = res
      // this.props.upload_success(status)
      this._handleRetrive()
      this.props.handleModal()
    }).catch( err => {
      // const status = err.response
      // this.props.upload_error(status)
    })
  }
  _handleRetrive(){
    this.props.retriveData()
  }
  render() {
    const editorConfiguration = {
      toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'bulletedList',
        'numberedList',
        '|',
        'blockQuote',
        'insertTable',
        '|',
        'imageUpload',
        'undo',
        'redo'
      ],
    };
    return (
      <>
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={this.state.noteData.content}
          onReady={editor => {
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            let imgs = []
            this._handleContent(editor.getData())
            Array.from(new DOMParser().parseFromString(editor.getData(), 'text/html')
              .querySelectorAll('img'))
              .forEach((value, key) => {
                console.log(value)
                imgs.push(value.getAttribute('src'))
              })
            this._handleImg(imgs)
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
        <div className="uk-flex uk-items-center pt-5 justify-center">
          <div className="post-title uk-width-1-1">
            <div className="post-heading uk-flex uk-justify-between uk-width-14" style={{ justifyContent: 'space-between' }}>
              <div className="post-btn-action" style={{ width: '100%!important' }}>
                {this.state.convertLoading ? "Sedang konversi gambar" : <button className="button small bg-schoolmedia text-white" onClick={this._edit} style={{ width: '100%!important', height: '35px!important' }}>Posting</button>}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}