import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

function Media() {
  const classes = useStyles();
  
  return (
    <Card className="m-0" style={{boxShadow: 'unset'}}>
      <CardHeader
        title={
          <Skeleton animation="wave" height={15} width="80%" style={{ marginBottom: 6 }} />
        }
        subheader={<Skeleton animation="wave" height={15} width="40%" />}
      />
    </Card>
  );
}

export default function SkeletonSquare() {
  return (
    <>
      <div className="row px-2">
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
      </div>
      <div className="row mt-2 px-2">
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100" height={180}/>
          <Media />
        </div>
      </div>
    </>
  );
}