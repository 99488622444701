import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getUserDetail } from "redux/actions/UserAction";
import { bindActionCreators } from "redux";
import {
  uploadError,
  uploadOnProgress,
  uploadSuccess,
} from "redux/actions/toastsAction";
import Slider from "react-slick";
import { compressImage } from "../../../../../../utils/photoManager.js";

const api = `${process.env.REACT_APP_API}`;
const smUri = `${process.env.REACT_APP_SM_STATIC_URI}`;

class GeneralPU extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.toastRef = React.createRef(null);
    this.state = {
      data: {},
      groupCode: "",
      userData: "",
      authUser: null,

      accountName: "",
      userName: "",
      noHP: "",
      logo: "",

      profilePhoto: null,
      coverPhoto: null,
      ktpPhoto: null,
      ktpFacePhoto: null,
      docPhoto: null,

      newCoverPhoto: null,
      newProfilePhoto: null,
      newKtpPhoto: null,
      newKtpFacePhoto: null,
      newDocPhoto: null,

      base64Cover: "",
      base64Profile: "",

      alert: false,
    };
    this.addProfilePhoto = this.addProfilePhoto.bind(this);
    this.addCoverPhoto = this.addCoverPhoto.bind(this);
    this.addKtpPhoto = this.addKtpPhoto.bind(this);
    this.addKtpFacePhoto = this.addKtpFacePhoto.bind(this);
    this.addDocPhoto = this.addDocPhoto.bind(this);

    this.cancelAddProfilePhoto = this.cancelAddProfilePhoto.bind(this);
    this.cancelAddCoverPhoto = this.cancelAddCoverPhoto.bind(this);
    this.cancelAddKtpPhoto = this.cancelAddKtpPhoto.bind(this);
    this.cancelAddKtpFacePhoto = this.cancelAddKtpFacePhoto.bind(this);
    this.cancelAddDocPhoto = this.cancelAddDocPhoto.bind(this);

    this.uploadData = this.uploadData.bind(this);
  }
  componentDidMount() {}
  static getDerivedStateFromProps(nextProps, state) {
    let dataUser = nextProps.userReducer.userDetail;
    if (nextProps.userReducer.userDetail != state.data) {
      if (dataUser.group_code === "PU") {
        return {
          data: dataUser,
          groupCode: "PU",
          userData: dataUser,
          accountName: dataUser.nama,
          userName: dataUser.username,
          noHP: dataUser.no_handphone,
          profilePhoto: dataUser.foto_profil,
          coverPhoto: dataUser.foto_sampul,
          ktpPhoto: dataUser.foto_ktp,
          ktpFacePhoto: dataUser.foto_ktp_dan_wajah,
          docPhoto: dataUser.foto_doc_pendukung,
        };
      }
    }
    return {
      authUser: nextProps.auth.user,
    };
  }

  addProfilePhoto(event) {
    // image size validation
    const imageFile = event.target.files[0];
    let x = compressImage(this, "newProfilePhoto", imageFile).then(function (
      x
    ) {});
  }

  addCoverPhoto(e) {
    const Img = e.target.files[0];
    let x = compressImage(this, "newCoverPhoto", Img).then(function (x) {});
  }

  addKtpPhoto(e) {
    const Img = e.target?.files[0];
    const fSize = e.target?.files[0].size;
    const fSizeKB = Math.round(fSize / 1024);

    // image size validation
    if (fSizeKB < 2000) {
      this.setState({
        newKtpPhoto: URL.createObjectURL(Img),
      });
    } else {
      alert("Foto anda melebihi 2 MB");
    }
  }

  addKtpFacePhoto(e) {
    const Img = e.target?.files[0];
    const fSize = e.target?.files[0].size;
    const fSizeKB = Math.round(fSize / 1024);

    // image size validation
    if (fSizeKB < 2000) {
      this.setState({
        newKtpFacePhoto: URL.createObjectURL(Img),
      });
    } else {
      alert("Foto anda melebihi 2 MB");
    }
  }

  addDocPhoto(e) {
    const Img = e.target?.files[0];
    const fSize = e.target?.files[0].size;
    const fSizeKB = Math.round(fSize / 1024);

    // image size validation
    if (fSizeKB < 2000) {
      this.setState({
        newDocPhoto: URL.createObjectURL(Img),
      });
    } else {
      alert("Foto anda melebihi 2 MB");
    }
  }

  cancelAddProfilePhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById("upload-profile").value = "";
      this.setState({
        newProfilePhoto: null,
      });
    }
  }

  cancelAddCoverPhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById("upload-cover").value = "";
      this.setState({
        newCoverPhoto: null,
      });
    }
  }

  cancelAddKtpPhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById("upload-ktp").value = "";
      this.setState({
        newKtpPhoto: null,
      });
    }
  }

  cancelAddKtpFacePhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById("upload-ktp-face").value = "";
      this.setState({
        newKtpFacePhoto: null,
      });
    }
  }

  cancelAddDocPhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById("upload-doc-photo").value = "";
      this.setState({
        newDocPhoto: null,
      });
    }
  }

  _handleProfileReader(readerEvt) {
    let binaryString = readerEvt.target.result;

    this.setState({
      base64Profile: btoa(binaryString),
    });
  }

  _handleCoverReader(readerEvt) {
    let binaryString = readerEvt.target.result;

    this.setState({
      base64Cover: btoa(binaryString),
    });
  }

  handleValidation() {
    let userName = this.state.userName;
    let errors = {};
    let formIsValid = true;

    // username
    if (typeof userName["userName"] !== "undefined") {
      if (!userName["userName"].match(/^[a-z0-9._]+$/)) {
        formIsValid = false;
        errors["userName"] =
          "Hanya boleh menggunakan huruf kecil, angka, garis bawah, dan titik";
      }

      this.setState({ errors: errors });
      return formIsValid;
    }
  }

  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      alert("Form submitted");
    } else {
      alert("Form has errors.");
    }
  }

  uploadData(e) {
    e.preventDefault();
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: this.props.uploading,
    };

    let requestData = null;
    const formData = new FormData();

    // pass photo
    let profile_pic = document.querySelector("#upload-profile").files[0];
    let cover_pic = document.querySelector("#upload-cover").files[0];
    let idCard_pic = document.querySelector("#upload-ktp").files[0];
    let idCardFace_pic = document.querySelector("#upload-ktp-face").files[0];
    let doc_pic = document.querySelector("#upload-doc-photo").files[0];

    if (this.state.newProfilePhoto === null) {
      profile_pic = this.state.profilePhoto;
    }
    if (this.state.newCoverPhoto === null) {
      cover_pic = this.state.coverPhoto;
    }
    if (this.state.newKtpPhoto === null) {
      idCard_pic = this.state.ktpPhoto;
    }
    if (this.state.newKtpFacePhoto === null) {
      idCardFace_pic = this.state.ktpFacePhoto;
    }
    if (this.state.newDocPhoto === null) {
      doc_pic = this.state.docPhoto;
    }

    // check group code
    if (this.state.groupCode === "PU") {
      const data = this.state.userData;
      delete data.password;
      requestData = {
        ...data,
        nama: this.state.accountName,
        username: this.state.userName,
      };
    }
    formData.append("json", JSON.stringify(requestData));

    if (this.state.newProfilePhoto !== null) {
      formData.append("profile_pic", profile_pic);
    }
    if (this.state.newCoverPhoto !== null) {
      formData.append("cover_pic", cover_pic);
    }
    if (this.state.newKtpPhoto !== null) {
      formData.append("idcard_pic", idCard_pic);
    }
    if (this.state.newKtpFacePhoto !== null) {
      formData.append("idcardface_pic", idCardFace_pic);
    }
    if (this.state.newDocPhoto !== null) {
      formData.append("doc_pic", doc_pic);
    }

    axios
      .post(api + "profile/update-user", formData, config)
      .then((res) => {
        const status = res;
        this.props.upload_success(status);
        this._getDetailUser();
        this.setState({ newCoverPhoto: null });
      })
      .catch((err) => {
        const status = err.response;
        this.props.upload_error(status);
      });
  }

  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data);
  }
  symbolValidate(e) {
    let field = e.target.value;
    if (field === "" || field === null || field === undefined) {
      this.setState({
        userName: e.target.value.split(" ").join("_").toLowerCase(),
      });
    } else {
      if (!field.match(/^[a-zA-Z0-9 _.]+$/)) {
      } else {
        this.setState({
          userName: e.target.value.split(" ").join("_").toLowerCase(),
        });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.data.group_code === "PU" ? (
          <form action="POST" onSubmit={this.uploadData}>
            <div className="profile-cover">
              {this.state.newCoverPhoto !== null ? (
                <img src={this.state.newCoverPhoto} alt="" />
              ) : (
                <img
                  src={
                    this.state.coverPhoto !== null
                      ? `${smUri}${this.state.groupCode}/cover/` +
                        this.state.coverPhoto
                      : "/assets/images/avatars/profile-cover.jpg"
                  }
                  alt=""
                />
              )}
              <span style={{ backgroundColor: "unset !important" }}>
                <input
                  type="file"
                  name="myImage"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                  id="upload-cover"
                  className="none"
                  ref={this.inputRef}
                  onChange={this.addCoverPhoto.bind(this)}
                />
                {this.state.newCoverPhoto === null ? (
                  <>
                    <label
                      for="upload-cover"
                      className="label-btn-upload button small btn-schoolmedia animated-y"
                      ref={this.inputRef}
                    >
                      <span className="inner-visible">
                        <i className="icon-material-outline-add-a-photo mr-2"></i>
                        Upload cover baru
                      </span>
                      <span className="inner-hidden">
                        <i className="icon-feather-arrow-up"></i>
                      </span>
                    </label>
                  </>
                ) : (
                  <button
                    onClick={this.cancelAddCoverPhoto.bind(this)}
                    className="button small btn-gray animated-y"
                  >
                    <span className="inner-visible">
                      <i className="icon-feather-x mr-2"></i>Batalkan
                    </span>
                    <span className="inner-hidden">
                      <i className="icon-feather-x mr-2"></i>Batalkan
                    </span>
                  </button>
                )}
              </span>
            </div>

            <div
              className="profile-details pl-0"
              style={{
                position: "relative",
                marginTop: "unset",
                marginBottom: "unset",
              }}
            >
              <div className="profile-image uk-padding uk-flex uk-flex-column justify-center">
                {this.state.newProfilePhoto === null ? (
                  <img
                    src={
                      this.state.profilePhoto !== null
                        ? `${smUri}${this.state.groupCode}/photo/` +
                          this.state.profilePhoto
                        : "/assets/icons/user.svg"
                    }
                    alt=""
                    style={{ margin: "0px auto", objectFit: "cover" }}
                  />
                ) : (
                  <img
                    src={this.state.newProfilePhoto}
                    alt=""
                    style={{ margin: "0px auto", objectFit: "cover" }}
                  />
                )}

                <div className="uk-flex uk-flex-column mt-2">
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                    id="upload-profile"
                    className="none upload-profile"
                    onChange={this.addProfilePhoto.bind(this)}
                  />
                  <label
                    for="upload-profile"
                    className="label-btn-upload button small btn-schoolmedia animated-y"
                  >
                    <span className="inner-visible">
                      <i className="icon-material-outline-add-a-photo mr-2"></i>
                      Upload foto baru
                    </span>
                    <span className="inner-hidden">
                      <i className="icon-feather-arrow-up"></i>
                    </span>
                  </label>
                  {this.state.newProfilePhoto === null ? null : (
                    <button
                      onClick={this.cancelAddProfilePhoto.bind(this)}
                      className="button small btn-gray mt-2"
                    >
                      Batalkan
                    </button>
                  )}
                </div>
              </div>
              <div className="profile-details-info uk-width-1-1 pt-2">
                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">
                    Nama Anda
                  </label>
                  <div className="uk-flex">
                    <input
                      className="uk-input uk-form-small"
                      type="text"
                      placeholder="Input"
                      value={this.state.accountName}
                      onChange={(e) => {
                        this.setState({ accountName: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">
                    Username
                  </label>
                  <div className="uk-flex">
                    <input
                      className="uk-input uk-form-small"
                      type="text"
                      placeholder="Input"
                      value={this.state.userName.toLowerCase()}
                      onChange={(e) => {
                        this.symbolValidate(e);
                      }}
                    />
                    {/* <span style={{color: "red"}}>{this.state.errors["userName"]}</span> */}
                  </div>
                </div>

                {/* <div className="uk-margin">
                  <label htmlFor="" className="font-sm">No. Handphone</label>
                  <div className="uk-flex">
                    <input className="uk-input uk-form-small" type="number" value={this.state.noHP} onChange={e => { this.setState({ noHP: e.target.value }) }} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="uk-width-1-1 uk-flex">
              {/* foto ktp */}
              {/* <div className="uk-width-1-3 uk-flex uk-flex-column mt-2 p-1">
                <p className="font-sm">Foto KTP :</p>
                {this.state.newKtpPhoto === null ? (
                  <img
                    src={
                      this.state.ktpPhoto !== null
                        ? `${smUri}${this.state.groupCode}/ktp/` +
                          this.state.ktpPhoto
                        : "/assets/images/avatars/profile-cover.jpg"
                    }
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={this.state.newKtpPhoto}
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <div className="uk-flex uk-flex-column mt-2">
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                    id="upload-ktp"
                    className="none upload-ktp"
                    onChange={this.addKtpPhoto.bind(this)}
                  />
                  <label
                    for="upload-ktp"
                    className="label-btn-upload button small btn-schoolmedia animated-y"
                  >
                    <span className="inner-visible">
                      <i className="icon-material-outline-add-a-photo mr-2"></i>
                      Upload foto baru
                    </span>
                    <span className="inner-hidden">
                      <i className="icon-feather-arrow-up"></i>
                    </span>
                  </label>
                  {this.state.newKtpPhoto === null ? null : (
                    <button
                      onClick={this.cancelAddKtpPhoto.bind(this)}
                      className="button small btn-gray mt-2"
                    >
                      Batalkan
                    </button>
                  )}
                </div>
              </div> */}

              {/* foto ktp wajah*/}
              {/* <div className="uk-width-1-3 uk-flex uk-flex-column mt-2 p-1">
                <p className="font-sm">Foto KTP & Wajah:</p>
                {this.state.newKtpFacePhoto === null ? (
                  <img
                    src={
                      this.state.ktpFacePhoto !== null
                        ? `${smUri}${this.state.groupCode}/ktp-face/` +
                          this.state.ktpFacePhoto
                        : "/assets/images/avatars/profile-cover.jpg"
                    }
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={this.state.newKtpFacePhoto}
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <div className="uk-flex uk-flex-column mt-2">
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                    id="upload-ktp-face"
                    className="none upload-ktp-face"
                    onChange={this.addKtpFacePhoto.bind(this)}
                  />
                  <label
                    for="upload-ktp-face"
                    className="label-btn-upload button small btn-schoolmedia animated-y"
                  >
                    <span className="inner-visible">
                      <i className="icon-material-outline-add-a-photo mr-2"></i>
                      Upload foto baru
                    </span>
                    <span className="inner-hidden">
                      <i className="icon-feather-arrow-up"></i>
                    </span>
                  </label>
                  {this.state.newKtpFacePhoto === null ? null : (
                    <button
                      onClick={this.cancelAddKtpFacePhoto.bind(this)}
                      className="button small btn-gray mt-2"
                    >
                      Batalkan
                    </button>
                  )}
                </div>
              </div> */}

              {/* foto doc */}
              {/* <div className="uk-width-1-3 uk-flex uk-flex-column mt-2 p-1">
                <p className="font-sm">Dokumen Pendukung :</p>
                {this.state.newDocPhoto === null ? (
                  <img
                    src={
                      this.state.docPhoto !== null
                        ? `${smUri}${this.state.groupCode}/img-support/` +
                          this.state.docPhoto
                        : "/assets/images/avatars/profile-cover.jpg"
                    }
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={this.state.newDocPhoto}
                    alt=""
                    style={{
                      margin: "0px auto",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <div className="uk-flex uk-flex-column mt-2">
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png"
                    id="upload-doc-photo"
                    className="none upload-doc-photo"
                    onChange={this.addDocPhoto.bind(this)}
                  />
                  <label
                    for="upload-doc-photo"
                    className="label-btn-upload button small btn-schoolmedia animated-y"
                  >
                    <span className="inner-visible">
                      <i className="icon-material-outline-add-a-photo mr-2"></i>
                      Upload foto baru
                    </span>
                    <span className="inner-hidden">
                      <i className="icon-feather-arrow-up"></i>
                    </span>
                  </label>
                  {this.state.newDocPhoto === null ? null : (
                    <button
                      onClick={this.cancelAddDocPhoto.bind(this)}
                      className="button small btn-gray mt-2"
                    >
                      Batalkan
                    </button>
                  )}
                </div>
              </div> */}
            </div>
            <input
              type="submit"
              className="button small btn-schoolmedia animated-x mt-3"
              style={{ float: "right" }}
              value="Simpan Perubahan"
            />
          </form>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer;
  return {
    auth: state.auth,
    userReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploading: uploadOnProgress,
      upload_success: uploadSuccess,
      upload_error: uploadError,
      getUserDetail: getUserDetail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPU);
