import React from 'react';

export default class NoteComment extends React.Component {
  render() {
    return (
      <div className="post-comments">
        <div className="post-comments-single">
          <div className="post-comment-avatar">
            <img src="/assets/images/avatars/avatar-5.jpg" alt=""></img>
          </div>
          <div className="post-comment-text">
            <div className="post-comment-text-inner">
              <h6></h6>
              <p></p>
            </div>
            <div className="uk-text-small">
              <a href="#" className="text-red mr-1">
                <i className="uil-heart"></i> Suka
              </a>
              <span> </span>
            </div>
          </div>
          {/* Option Replied Comment */}
          <span className="post-comment-opt"></span>
          <div className="mt-0 p-2" uk-dropdown="pos: bottom-right;mode:hover">
            <ul className="uk-nav uk-dropdown-nav">
              <li ><a className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
            </ul>
          </div>
        </div>
        <div className="post-add-comment">
          <div className="post-add-comment-avature">
            <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
          </div>
          <div className="post-add-comment-text-area">
            <input type="text" placeholder="Write your comment..."></input>
            <div className="icons">
              <span className="uil-link-alt"></span>
              <span className="uil-grin"></span>
              <span className="uil-image"></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}