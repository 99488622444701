import { NOTIF_SUCCESS } from "redux/actions/types";
import _ from 'underscore';
import isEmpty from "lodash/isEmpty";

// Global State
const globalState = {
  dataNotif: [],
  loading: true,
  hiddenFriend: false,
}

// Reducer
const notifReducer = (state = globalState, action) => {
  if(action.type === NOTIF_SUCCESS){
    let unread = _.countBy(action.data, 'status');
    let fixUnread = 0;
    if (typeof unread[0] !== 'undefined')
      fixUnread = unread[0];

    return{
      ...state,
      dataNotif: action.data,
      countUnread: fixUnread,
      loading: false,
    }
  }
  return state;
}

export default notifReducer;
