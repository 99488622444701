export const SET_CURRENT_USER = "SET_CURRENT_USER";

// User Detail
export const USER_DETAIL = "USER_DETAIL";
export const USER_DETAIL_ERROR = "USER_DETAIL_ERROR";

// Upload Toast
export const UPLOAD_ON_PROGRESS = "UPLOAD_ON_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const UPLOAD_ERR_REPEAT = "UPLOAD_ERR_REPEAT";

// Notification
export const NOTIF_SUCCESS = "NOTIF_SUCCESS";
export const NOTIF_ERROR = "NOTIF_ERROR";

// Search
export const SEARCH = "SEARCH";
export const SEARCHING = "SEARCHING";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const SEARCH_MORE = "SEARCH_MORE";

// Feed
export const GET_FEED = "GET_FEED";
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const GET_FEED_ERROR = "GET_FEED_ERROR";

// Forum
export const GET_LIST_FORUM = "GET_LIST_FORUM";
export const GET_LIST_FORUM_SUCCESS = "GET_LIST_FORUM_SUCCESS";
export const GET_LIST_FORUM_ERR = "GET_LIST_FORUM_ERR";

// Topic
export const GET_LIST_TOPIC = "GET_LIST_TOPIC";
export const GET_LIST_TOPIC_SUCCESS = "GET_LIST_TOPIC_SUCCESS";
export const GET_LIST_TOPIC_ERR = "GET_LIST_TOPIC_ERR";


// Retrive Data
export const RETRIVE_DATA_ERROR = "RETRIVE_DATA_ERROR";