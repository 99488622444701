import React from 'react';
import SearchLeftBar from './SearchLeftBar';
import { connect } from 'react-redux';
import ButtonAddFriend from 'component/partials/global/ButtonAddFriend';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SkeletonList from 'component/partials/skeleton/SkeletonList';

const api = `${process.env.REACT_APP_API}`;

class SearchPerson extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchKey: null,
      loading: false,
      dataResult: {},
      userData: {},

      countNextData: 2,
      loadingNext: false,
    }
    this._loadMore = this._loadMore.bind(this)
  }
  semuaData = {}
  static getDerivedStateFromProps(nextProps, state, semuaData) {
    if (nextProps.userReducer.userDetail != state.userData) {
      let e = nextProps.searchReducer.data
      let group = e.reduce((r, a) => {
        r[a.type] = [...r[a.type] || [], a];
        return r;
      }, {});
      semuaData = group
      return ({
        dataResult: group,
        searchKey: nextProps.searchReducer.searchKey,
        loading: nextProps.searchReducer.loading,
        userData: nextProps.userReducer.userDetail,
      })
    }
  }
  _loadMore() {

    this.setState({
      countNextData: this.state.countNextData + 1,
    })
    this._getNextData(this.state.countNextData)
  }
  _getNextData(e) {
    this.setState({ loadingNext: true })
    axios.get(api + `search/get-search?keyword=${this.state.searchKey}&page=${e}`)
      .then(res => {
        this._resGroupBy(res.data.data.data)
        this.setState({ loadingNext: false })
      }).catch(err => {
        this.setState({ loadingNext: false })
      })
  }
  _resGroupBy(e) {
    let temp = this.state.dataResult.user;
    let group = e.reduce((r, a) => {
      r[a.type] = [...r[a.type] || [], a];
      return r;
    }, {});
    group.user.forEach(item => {
      temp.push(item)
    })
    this.setState({
      dataResult: { user: temp }
    })
  }
  render() {
    return (
      <>
        <div style={{ marginTop: '76px' }}>
          <div uk-grid="" className="uk-grid">
            <SearchLeftBar searchKey={this.state.searchKey} />
            <div className="uk-width-expand@m">
              <ul id="component-tab-right" className="uk-switcher">
                <li className="uk-active bg-white uk-padding">
                  {this.state.dataResult.user != undefined ?
                    <>
                      <div className="friend-card">
                        <h2 className="mb-4"> Orang </h2>
                      </div>
                      <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                        {this.state.dataResult.user.map((val, key) => {
                          return (
                            <div key={key}>
                              <div className="friend-card mt-4">
                                <div className="uk-width-auto">
                                  {val.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${val.group_code}/photo/${val.photo_profile}`} alt="" /> :
                                    <img src='/assets/icons/user.svg' />
                                  }
                                  <span className="online-dot"></span>
                                </div>
                                <div className="uk-width-expand">
                                  <Link to={`/${val.username}`}><h3> {val.name} </h3></Link>
                                  <p> {val.username} </p>
                                  <span> 1week ago </span>

                                  {val.username != this.state.userData.username ?
                                    <div className="friend-card-btns">
                                      <ButtonAddFriend username={val.username} />
                                      {/* <a href="#" className="button btn-default small"> Remove </a> */}
                                    </div> : null
                                  }
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                      {this.state.loadingNext ?
                        <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                          <div>
                            <SkeletonList />
                          </div>
                          <div>
                            <SkeletonList />
                          </div>
                          <div>
                            <SkeletonList />
                          </div>
                          <div>
                            <SkeletonList />
                          </div>
                          <div>
                            <SkeletonList />
                          </div>
                          <div>
                            <SkeletonList />
                          </div>
                        </div> :
                        <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                          <button className="button btn-soft-schoolmedia large mx-auto mt-4" onClick={this._loadMore}>Tampilkan lebih</button>
                        </div>
                      }
                    </>
                    : null
                  }

                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    searchReducer: state.searchReducer,
    userReducer: state.userReducer,
  }
}

export default connect(mapStateToProps)(SearchPerson);