import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link, useHistory, withRouter, Redirect } from "react-router-dom";
import * as NotifAction from "redux/actions/NotifAction";
import * as SearchAction from "redux/actions/SearchAction";
import notifReducer from "redux/reducers/NotifReducer";
import timeAgo from "utils/timeAgo";
import isEmpty from "lodash/isEmpty";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import "../../assets/css/alert-fixedbar.css";
import ReactDOM from "react-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const api = `${process.env.REACT_APP_API}`;

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePhoto: null,
      groupCode: null,

      dataNotif: [],
      readComment: false,
      dataStatus: "",
      notifUnread: 0,
      tabTeman: true,

      searchKey: null,
      searchData: null,
      redirect: false,
      displayNone: "block",
    };
    this._handleSearch = this._handleSearch.bind(this);
    this._handleOnSearch = this._handleOnSearch.bind(this);
    this._handleOnSelect = this._handleOnSelect.bind(this);
    this.redirectToSettings = this.redirectToSettings.bind(this);
    this._handleRedirectSearch = this._handleRedirectSearch.bind(this);
    this._handleRequestFriend = this._handleRequestFriend.bind(this);

    this._handleAlert = this._handleAlert.bind(this);
  }

  componentDidMount() {
    var decodedCookie = decodeURIComponent(document.cookie);
    let temp = [];
    let res = decodedCookie.split("=");
    temp = res;
    let cookie = temp[1];
    if (cookie === "false") {
      this.setState({ displayNone: "none" });
    } else if (cookie === undefined) {
      this.setState({ displayNone: "block" });
    } else {
      this.setState({ displayNone: "block" });
    }
    this._handleNotif(true);
  }

  redirectToSettings() {
    return <Redirect to={`/${this.props.auth.user.uuid}/settings`} />;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notifReducer != prevState.notifReducer) {
      let notif = nextProps.notifReducer.dataNotif;
      /* let group = notif.reduce((r,a) => {
        r[a.status] = [...r[a.status] || [], a];
        return r;
      }, {}); */
      return {
        dataNotif: nextProps.notifReducer.dataNotif,
        notifUnread: nextProps.notifReducer.countUnread,
        dataStatus: nextProps.notifReducer.dataStatus,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userReducer !== prevProps.userReducer) {
      let userDetail = this.props.userReducer.userDetail;
      this.setState({
        groupCode: userDetail.group_code,
      });
      switch (userDetail.group_code) {
        case "NI":
          this.setState({ profilePhoto: userDetail.logo });
          break;
        case "GU":
          this.setState({ profilePhoto: userDetail.foto_profil });
          break;
        case "SI":
          this.setState({ profilePhoto: userDetail.foto_profil });
          break;
        case "OW":
          this.setState({ profilePhoto: userDetail.foto_profil });
          break;
        case "LA":
          this.setState({ profilePhoto: userDetail.foto_profil });
          break;
        case "PU":
          this.setState({ profilePhoto: userDetail.foto_profil });
          break;
      }
    }
  }

  readNotif() {
    this.setState({
      readNotif: true,
    });
  }

  _handleNotif(type) {
    const data = this.props.auth.user;

    if (!isEmpty(data)) {
      let { dispatch } = this.props;
      let action = NotifAction.getNotif(type);

      dispatch(action);
    }
  }

  handleNightMode(e) {
    e.preventDefault();
    if (e) {
      document.documentElement.classList.toggle("night-mode");
      if (document.documentElement.classList.contains("night-mode")) {
        localStorage.setItem("gmtNightMode", true);
        return;
      }
      localStorage.removeItem("gmtNightMode");
    }
  }
  _handleSearch(e) {
    e.preventDefault();
    if (e.target.value) {
      this.setState({
        searchKey: e.target.value,
      });
    } else {
      this.setState({
        searchKey: null,
      });
    }
  }

  _handleRedirectSearch(e) {
    e.preventDefault();
    if (this.state.searchKey != null) {
      this.setState({ redirect: true });
    }
  }

  _handleAlert() {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "betaALert=false;" + expires + ";path=/";
    this.setState({ displayNone: "none" });
  }
  _handleOnSearch(string) {
    this.setState({ searchKey: string }, function () {
      axios
        .get(api + `search/get-search?keyword=${string}`)
        .then((res) => {
          var user = [];
          var data = res.data.data.data;
          var x;
          for (x in data) {
            if (data[x].type == "user") {
              user.push(data[x]);
            }
          }
          this.setState({ dataSearch: user });
        })
        .catch((err) => {});
    });
  }
  _handleOnSelect(item) {
    this.setState({
      redirectUser: true,
      toUser: item,
    });
  }
  renderRedirect() {
    this.setState({ redirect: !this.state.redirect });
    let { dispatch } = this.props;
    let action = SearchAction.getSearchResult(this.state.searchKey, 1);

    dispatch(action);
    return (
      <Redirect
        to={{
          pathname: "/search",
          state: {
            search_key: this.state.searchKey,
            loading: true,
            res_data: this.state.searchData,
          },
        }}
      />
    );
  }

  _handleRequestFriend(username, status, action) {
    this.setState({ disableBtnReq: true }, function () {
      let jwtToken = localStorage.getItem("jwtToken");
      const buttonReq = document.getElementById(username + "-1");
      let requestData = {
        is_accept_friend: status,
        username: username,
      };
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      };

      axios
        .post(api + "friend/post-accept-friend", requestData, config)
        .then((res) => {
          if (action === "acc") {
            ReactDOM.render("Permintaan pertemanan telah diterima", buttonReq);
          } else if (action === "decline") {
            ReactDOM.render("Permintaan pertemanan telah ditolak", buttonReq);
          }
          this.setState({ disableBtnReq: false });
        })
        .catch((err) => {
          this.setState({ disableBtnReq: false });
        });
    });
  }

  iconStatus(status) {
    switch (status) {
      case 1:
        return <i className="uil-user-plus"></i>;
        break;
      case 2:
        return <i className="uil-comment-alt-message"></i>;
        break;
      case 3:
        return <i className="icon-feather-at-sign"></i>;
        break;
      case 4:
        return <i className="uil-thumbs-up"></i>;
        break;
      case 5:
        return <i className="uil-user-check"></i>;
        break;
      case 6:
        return <i className="uil-chat-bubble-user"></i>;
        break;
      case 7:
        return <i className="uil-envelope-alt"></i>;
        break;
      default:
        break;
    }
  }

  _handleOptionAccount() {
    const data = this.props.auth.user;
    if (!isEmpty(data)) {
      return (
        <>
          <a
            href="#"
            className="opts_icon uk-visible@s"
            uk-tooltip="title: Apps ; pos: bottom ;offset:7"
          >
            <img src="/assets/icons/apps.svg" alt=""></img>
          </a>

          {/* <!-- Apps dropdown --> */}
          <div
            uk-dropdown="mode:click ; pos: bottom-center ; animation: uk-animation-scale-up"
            className="icon-browse"
          >
            <div className="dropdown-apps-content">
              <a
                href="https://schoolmedia.co.id"
                target="_blank"
                className="icon-menu-item"
                uk-tooltip="title: Hosting ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/1.hosting.svg"
                  alt="Hosting"
                  className="icon-app-content"
                />
                <br />
                Hosting
              </a>
              <a
                href="https://um.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: User Management ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/3.um.svg"
                  alt="User Management"
                  className="icon-app-content"
                />
                <br />
                UM
              </a>
              <a
                href="https://jurnal.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Jurnal Akademik ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/4.jurnal.svg"
                  alt="Jurnal Akademik"
                  className="icon-app-content"
                />
                <br />
                JA
              </a>
              <a
                href="http://cmssma.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: CMS Sekolah (SD / SMP / SMA / SMK) ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/5.cms-sekolah.svg"
                  alt="CMS Sekolah"
                  className="icon-app-content"
                />
                <br />
                CMS Sekolah
              </a>
              <a
                href="https://rpp.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Rencana Pelaksana Pembelajaran ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/6.rpp.svg"
                  alt="RPP"
                  className="icon-app-content"
                />
                <br />
                RPP
              </a>
              <a
                href="https://video.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Video Belajar ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/7.video-belajar.svg"
                  alt="Video Belajar"
                  className="icon-app-content"
                />
                <br />
                Videos
              </a>
              <a
                href="https://mau.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Materi Ajar dan Ujian ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/8.mau.svg"
                  alt="Materi Ajar Ujian"
                  className="icon-app-content"
                />
                <br />
                MAU
              </a>
              <a
                href="https://ujianluring.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Ujian Luar Jaringan ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/9.ujian-luring.svg"
                  alt="Ujian Luring"
                  className="icon-app-content"
                />
                <br />
                Ujian Luring
              </a>
              <a
                href="https://testonline.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Test Online ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/10.test-online.svg"
                  alt="Test Online"
                  className="icon-app-content"
                />
                <br />
                Test Online
              </a>
              <a
                href="https://tryout.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Try Out ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/11.tryout.svg"
                  alt="Try Out"
                  className="icon-app-content"
                />
                <br />
                Try Out
              </a>
              <a
                href="https://materi.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Materi Belajar ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/12.materi-belajar.svg"
                  alt="Materi Belajar"
                  className="icon-app-content"
                />
                <br />
                Materi Belajar
              </a>
              <a
                href="https://tesbakat.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Tes Bakat ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/13.tes-bakat.svg"
                  alt="Tes Bakat"
                  className="icon-app-content"
                />
                <br />
                Tes Bakat
              </a>
              <a
                href="https://schoolmedia.biz.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Biz Store Center ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/14.biz.svg"
                  alt="Biz Store Center"
                  className="icon-app-content"
                />
                <br />
                Biz
              </a>
              <a
                href="https://scoin.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Scoin Management ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/15.scoin.svg"
                  alt="Scoin Management"
                  className="icon-app-content"
                />
                <br />
                Scoin
              </a>
              <a
                href="https://news.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: News ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/16.news.svg"
                  alt="News"
                  className="icon-app-content"
                />
                <br />
                News
              </a>
              <a
                href="https://pitakon.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Pitakon ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/17.pitakon.svg"
                  alt="Pitakon"
                  className="icon-app-content"
                />
                <br />
                Pitakon
              </a>
              <a
                href="https://kuesioner.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Kuisioner ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/18.kuisioner.svg"
                  alt="Kuesioner"
                  className="icon-app-content"
                />
                <br />
                Kuesioner
              </a>
              <a
                href="https://ppdb.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Penerimaan Peserta Didik Baru ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/19.ppdb.svg"
                  alt="PPDB"
                  className="icon-app-content"
                />
                <br />
                PPDB
              </a>
              <a
                href="https://schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Sosial Media ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/20.social-media.svg"
                  alt="Sosial Media"
                  className="icon-app-content"
                />
                <br />
                Sosial Media
              </a>
              <a
                href="https://event.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Event ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/21.event.svg"
                  alt="Event"
                  className="icon-app-content"
                />
                <br />
                Event
              </a>
              <a
                href="https://help.schoolmedia.id"
                className="icon-menu-item"
                target="_blank"
                uk-tooltip="title: Help Center ; pos: bottom ;offset:7"
              >
                <img
                  src="/assets/images/logo-apps/22.help-center.svg"
                  alt="Help Center"
                  className="icon-app-content"
                />
                <br />
                Help Center
              </a>
            </div>
          </div>

          <a
            href="#"
            className="opts_icon"
            uk-tooltip="title: Messages ; pos: bottom ;offset:7"
          >
            <img src="/assets/icons/chat.svg" alt=""></img>
            {/* <span>{this.props.test}</span> */}
          </a>

          <div
            uk-dropdown="mode:click ; animation: uk-animation-slide-bottom-small"
            className="dropdown-notifications"
          >
            <div className="dropdown-notifications-content" data-simplebar>
              {/* <!-- notivication header --> */}
              <div className="dropdown-notifications-headline">
                <h4>Pesan</h4>
                <a href="#">
                  <i
                    className="icon-feather-settings"
                    uk-tooltip="title: Message settings ; pos: left"
                  ></i>
                </a>

                <input
                  type="text"
                  className="uk-input"
                  placeholder="Cari Pesan"
                ></input>
              </div>

              {/* <!-- notiviation list --> */}
              <ul>
                {/* <li>
                <a href="#">
                  <span className="notification-avatar status-online">
                    <img src="/assets/images/avatars/avatar-2.jpg" alt=""></img>
                  </span>
                  <div className="notification-text notification-msg-text">
                    <strong>Jonathan Madano</strong>
                    <p>Thanks for The Answer ... <span className="time-ago"> 2 h </span></p>
                  </div>
                </a>
              </li> */}
              </ul>
            </div>
            <div className="dropdown-notifications-footer">
              {/* <a href="#"> See all in Messages</a> */}
            </div>
          </div>

          {/* <!-- notificiation icon  --> */}
          <a
            onClick={this._handleNotif.bind(this, false)}
            className="opts_icon"
            uk-tooltip="title: Notifications ; pos: bottom ;offset:7"
          >
            <img src="/assets/icons/bell.svg" alt=""></img>
            {this.state.notifUnread > 0 ? (
              <span>{this.state.notifUnread}</span>
            ) : null}
          </a>

          {/* <!-- notificiation dropdown --> */}
          <div
            uk-dropdown="mode:click ; animation: uk-animation-slide-bottom-small"
            className="dropdown-notifications"
          >
            {/* <!-- notification contents --> */}
            <div className="dropdown-notifications-content" data-simplebar>
              {/* <!-- notivication header --> */}
              <div className="dropdown-notifications-headline">
                <h4>Notifikasi</h4>
                <a href="#">
                  <i
                    className="icon-feather-settings"
                    uk-tooltip="title: Notifications settings ; pos: left"
                  ></i>
                </a>
              </div>

              {/* <!-- notiviation list --> */}
              {this.renderNotif()}
            </div>
          </div>

          {/* <!-- profile -image --> */}
          <a className="opts_account" href="#">
            {this.state.profilePhoto === null ||
            this.state.profilePhoto === "null" ? (
              <img src="/assets/icons/user.svg" alt=""></img>
            ) : (
              <img
                src={
                  `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                  this.state.profilePhoto
                }
                alt=""
              ></img>
            )}
            <span className="text-sm-right">{this.props.auth.user.name}</span>
          </a>

          {/* <!-- profile dropdown--> */}
          <div
            uk-dropdown="mode:click ; animation: uk-animation-slide-bottom-small"
            className="dropdown-notifications rounded"
          >
            {/* <!-- User Name / Avatar --> */}
            {this.state.userName === undefined ? (
              <>
                <Link to={`/${this.props.auth.user.username}`} style={{ width: "100%" }}>
                  <div className="dropdown-user-details">
                    <div className="dropdown-user-avatar">
                      {this.state.profilePhoto === null ||
                      this.state.profilePhoto === "null" ? (
                        <img src="/assets/icons/user.svg" alt=""></img>
                      ) : (
                        <img
                          src={
                            `https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` +
                            this.state.profilePhoto
                          }
                          alt=""
                        ></img>
                      )}
                    </div>
                    <div className="dropdown-user-name">
                      {" "}
                      {this.props.auth.user.name} <span>Lihat profil anda</span>{" "}
                    </div>
                  </div>
                </Link>
              </>
            ) : null}

            <hr className="m-0"></hr>
            <ul className="dropdown-user-menu">
              <li>
                <Link to={`/${this.props.auth.user.username}`}>
                  {" "}
                  <i className="uil-user"></i> Profil saya{" "}
                </Link>
              </li>
              {/* <li><Link href="group-feed.html"> <i className="uil-thumbs-up"></i> Liked Pages </Link></li> */}
              <li>
                <Link to="/profile/settings">
                  {" "}
                  <i className="uil-cog"></i> Pengaturan Akun{" "}
                </Link>
              </li>
              <li>
                <a
                  id="night-mode-toggle"
                  onClick={(e) => {
                    this.handleNightMode(e);
                  }}
                  className="btn-night-mode"
                >
                  <i className="uil-moon"></i> Mode Gelap
                  <span className="btn-night-mode-switch">
                    <span className="uk-switch-button"></span>
                  </span>
                </a>
              </li>
              <li>
                <Link to="/auth/sso/logout">
                  {" "}
                  <i className="uil-sign-out-alt"></i>Log Out
                </Link>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="navbar-btn btn-login"
            style={{ display: "flex", marginLeft: "50rem" }}
          >
            <Link to="/auth" className="main-btn" data-scroll-nav="0">
              Login
            </Link>
          </div>
        </>
      );
    }
  }

  renderNotif() {
    if (this.state.dataNotif != []) {
      return (
        <ul>
          {this.state.dataNotif.map((val, idx) => {
            return (
              <li key={val.id}>
                <a href="#">
                  <span className="notification-avatar">
                    {val.sender_photo_profile === null ? (
                      <img src="/assets/icons/user.svg" alt=""></img>
                    ) : (
                      <img
                        src={
                          `https://static.schoolmedia.id/public/assets/socmed/${val.sender_group_code}/photo/` +
                          val.sender_photo_profile
                        }
                        alt=""
                      ></img>
                    )}
                  </span>
                  <span className="notification-icon bg-gradient-schoolmedia">
                    {this.iconStatus(val.type_notification_id)}
                  </span>
                  <span className="notification-text">
                    <Link to={val.link} style={{ margin: "unset" }}>
                      {/* {val.information} */}
                      <div dangerouslySetInnerHTML={{ __html: val.information }}>

                      </div>
                    </Link>
                    {/* {val.information} */}
                    {val.type_notification_id == 1 ? (
                      <div>
                        <span
                          id={val.sender_username + "-1"}
                          className="text-left w-100"
                        >
                          <button
                            className="button small btn-schoolmedia mr-2"
                            disabled={this.state.disableBtnReq}
                            onClick={this._handleRequestFriend.bind(
                              this,
                              val.sender_username,
                              1,
                              "acc"
                            )}
                          >
                            Terima
                          </button>
                          <button
                            className="button small btn-default mr-2"
                            disabled={this.state.disableBtnReq}
                            onClick={this._handleRequestFriend.bind(
                              this,
                              val.sender_username,
                              0,
                              "decline"
                            )}
                          >
                            Tolak
                          </button>
                        </span>
                      </div>
                    ) : null}
                    <span className="time-ago">
                      {" "}
                      {timeAgo(val.created_at)}{" "}
                    </span>
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <span>Belum ada notif</span>;
    }
  }

  _handleSearch() {
    if (!isEmpty(this.props.auth.user)) {
      return (
        <>
          <div className="head_search">
            <form onSubmit={this._handleRedirectSearch}>
              <ReactSearchAutocomplete
                items={this.state.dataSearch}
                onSearch={this._handleOnSearch}
                onSelect={this._handleOnSelect}
                onSubmit={this._handleRedirectSearch}
                fuseOptions={{ keys: ["name"] }}
                ReactSearchAutocomplete="name"
                className="form-control"
                placeholder="Cari ..."
              />
              {/* <div className="head_search_cont uk-flex">
                  <input type="text" className="form-control" placeholder="Cari ..." autoComplete="off" onChange={e => this._handleSearch(e)}></input>
                  <i className="s_icon uil-search-alt"></i>
                </div> */}

              {/* <div uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small" className="dropdown-search">
                  <ul className="dropdown-search-list">
                    <li className="list-title"> Recent Searches </li>
                    <li>
                    <a href="#">
                      <img src="/assets/images/avatars/avatar-2.jpg" alt=""></img> Erica Jones
                    </a>
                  </li>
                    <li className="list-footer">
                      <a href="your-history.html"> Searches History </a>
                    </li>
                  </ul>
                </div> */}
            </form>
          </div>
        </>
      );
    }
  }

  render() {
    console.log(this.props.auth)
    const { redirect } = this.state;

    //  if (redirect) {
    //    return <Redirect to='/search'/>;
    //  }
    return (
      <>
        {this.state.redirectUser ? (
          <Redirect to={{ pathname: "/" + this.state.toUser.username }} />
        ) : null}
        <div id="main_header">
          <header>
            <div
              style={{
                display: this.state.displayNone,
                position: "absolute",
                width: "100vw",
                zIndex: "999",
                top: "65px",
              }}
            >
              <div className="alert">
                <span className="closebtn" onClick={this._handleAlert}>
                  &times;
                </span>
                <center>
                  Anda sedang menggunakan aplikasi sosmed versi beta
                </center>
              </div>
            </div>
            <div className="header-innr">
              <div
                className="header-btn-traiger"
                uk-toggle="target: #wrapper ; cls: mobile-visible"
              >
                <span></span>
              </div>

              <div id="logo-schoolmedia">
                <Link to="/timeline">
                  <img
                    src="/assets/images/logo-light-icon.png"
                    alt=""
                  ></img>
                </Link>
              </div>
              <div id="logo-sm">
                <Link to="/timeline">
                  <img
                    src="/assets/images/logo-light.png"
                    className="logo-inverse"
                    alt=""
                  ></img>
                </Link>
                <span className="badge" style={{ color: "#fff" }}>
                  BETA
                </span>
              </div>

              <div className="uk-width-expand head_right_container">
                {this._handleSearch()}

                <div className="head_user">{this._handleOptionAccount()}</div>
              </div>
            </div>
          </header>
        </div>
        {redirect ? this.renderRedirect() : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    test: state.test,
    auth: state.auth,
    userReducer: state.userReducer,
    notifReducer: state.notifReducer,
  };
};

export default connect(mapStateToProps)(Navbar);
