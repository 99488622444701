import React from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import Slider from "react-slick";
import { Skeleton } from '@material-ui/lab';
import isEmpty from "lodash/isEmpty";
import {init_sso} from "../../utils/sso";

const api = `${process.env.REACT_APP_SM_NEWS}`;
var str = "...";

export default class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listNews: [],
      loadingGetNews: false,
    }
  }
  componentDidMount() {
    let instance = axios.create();
    if (!isEmpty(localStorage.getItem('redirect'))) 
      localStorage.removeItem('redirect');

    this.addClass();
    this.setState({
      loadingGetNews: true,
    })

    instance.get(api + 'SosmedLandingPage').then((res) => {
      this.setState({
        listNews: res.data.news,
        loadingGetNews: false,
      })
    }).catch((err) => {
      console.log(err);
    })

    init_sso();
  }

  componentWillUnmount() {
    var screen = document.getElementById("wrapper");
    screen.className -= "display-none";
  }

  addClass() {
    var screen = document.getElementById("wrapper");
    screen.className += "n display-none";
  }

  renderNews() {
    if (this.state.loadingGetNews !== true) {
      if (this.state.listNews) {
        const settings = {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2500,
          arrows: true,
          responsive: [
            
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  arrows: false,
                  centerMode: false
                }
              },
              {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                  }
              },
          ]
        };
        return (
          <div className="col-lg-12">

            <Slider {...settings}>
              {this.state.listNews.map(item => {
                return (
                  <div className="mr-2 ml-2">
                    <div className="single-services berita mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon mb-2 imgCard">
                          <img src={item.url_gambar || <Skeleton variant="rect" width={210} height={118} />} />
                      </div>

                      <a href="#" className="badge badge-primary">{item.tipe_berita}</a>
                      
                      <div className="services-content mt-20">
                        <h5 className="services-title"><a href={item.url_konten} className="title-news" target="_blank">{item.judul}</a></h5>
                        <p className="mt-2 text-news-preview">{item.konten.substring(0, 125)}</p>
                        <a className="more" href={item.url_konten} target="_blank">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>

          </div>
        )

      }
    }
  }

  render() {
    return (
      <>

        {/* PRELOADER PART START */}

        {/* <div className="preloader">
                  <div className="loader">
                      <div className="ytp-spinner">
                          <div className="ytp-spinner-container">
                              <div className="ytp-spinner-rotator">
                                  <div className="ytp-spinner-left">
                                      <div className="ytp-spinner-circle"></div>
                                  </div>
                                  <div className="ytp-spinner-right">
                                      <div className="ytp-spinner-circle"></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}

        {/* <!--====== PRELOADER PART ENDS ======--> */}

        {/* <!--====== HEADER PART START ======--> */}

        <header className="header-area" style={{ height: '100vh' }}>
          <div className="navbar-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg">
                    <NavLink to="/LandingPage">
                      <img src="/assets/images/logo.png" width="60%" alt="Logo"></img>
                    </NavLink>

                    <div className="navbar-btn btn-login ml-auto d-sm-inline-block">
                      <Link to="/auth" className="main-btn" data-scroll-nav="0">Login</Link>
                    </div>
                  </nav> {/* <!-- navbar --> */}
                </div>
              </div> {/* <!-- row --> */}
            </div>{/* <!-- container --> */}
          </div>
          
          <div id="home" className="header-hero bg_cover" style={{ backgroundColor: "#326AD7" }}>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <div className="header-hero-content">
                    <h2 className="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s" style={{ textTransform: "none" }}>Connecting People with</h2>
                    <h2 className="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">School Communities</h2>
                    <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">Schoolmedia merupakan website aplikasi pendidikan yang dilengkapi dengan sosial media. Schoolmedia menghubungkan masyarakat dengan komunitas sekolah untuk saling berbagi pengetahuan.
                    Schoolmedia tempat bertemunya lembaga dan individu, antara guru, siswa, orangtua siswa, alumni sekolah, personil lembaga, maupun masyarakat umum untuk saling berinteraksi dan saling memberikan manfaat.
                                      </p>
                    {/* <a href="#" className="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="1.1s">Get Started</a> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="header-hero-image atas text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s" style={{ display: "none" }}>
                    <img src="/assets/images/new/head3.svg" style={{ width: "90%", marginBottom: "100px" }} alt="hero" />
                  </div>
                </div>
              </div>
            </div>
            <div id="particles-1" className="particles"></div>
          </div>
        </header>

        {/* <!--====== HEADER PART ENDS ======--> */}

        {/* <!--====== SERVICES PART START ======--> */}


        <section id="features" className="services-area pt-50">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center">
                  <div className="line m-auto"></div>
                  <h3 className="title">Berita, Warnai harimu dengan membaca kabar baik tentang pendidikan</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* <div className="col-lg-12">
                <div className="myslider">
                <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/2133490057.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h5 className="services-title"><a href="#">Pendaftaran</a></h5>
                        <p className="mt-2">DPR Minta Kemendikbud Jelaskan Nasib Guru Honorer.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/109848350.jpeg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h5 className="services-title"><a href="#">Pendaftaran Guru</a></h5>
                        <p className="mt-2">Banjir Peminat, Masa Pendaftaran Guru Penggerak Diperpanjang.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/822172999.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h4 className="services-title"><a href="#">15 DUDI Sepakati Kerja Sama</a></h4>
                        <p className="mt-2">15 DUDI Sepakati Kerja Sama dengan Forum Kursus Perhotelan dan Kapal Pesiar.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/2133490057.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h4 className="services-title"><a href="#">Antusiasme Ribuan Siswa</a></h4>
                        <p className="mt-2">Antusiasme Ribuan Siswa SD/SMP Ikuti Kompetisi Olahraga Siswa Nasional Secara Daring.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/1806204911.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h4 className="services-title"><a href="#">Optimalkan Teknologi</a></h4>
                        <p className="mt-2">Optimalkan Teknologi untuk Kompetisi di Tengah Pandemi, Ini Kata Puspresnas.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/1300967345.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h4 className="services-title"><a href="#">Kontes Kapal</a></h4>
                        <p className="mt-2">Kontes Kapal Cepat, Wujud Literasi Kebencanaan.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-8">
                    <div className="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon mb-10 imgCard">
                        <img src="https://news.schoolmedia.id/uploads/konten/4337eb42fd10918d23e0cc18f90e2511945a1fa9/314000577.jpg" />
                      </div>

                      <a href="#" className="badge badge-primary">Tema 1</a>
                      <a href="#" className="badge badge-secondary">Tema 2</a>
                      <a href="#" className="badge badge-success">Tema 3</a>

                      <div className="services-content mt-20">
                        <h4 className="services-title"><a href="#">Keterbatasan Fisik</a></h4>
                        <p className="mt-2">Keterbatasan Fisik Bukan Halangan ABK Hasilkan Karya.</p>
                        <a className="more" href="#">Selengkapnya <i className="lni lni-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                {this.renderNews()}

                </div>
              </div> */}

              {this.renderNews()}

            </div>
          </div>
        </section>

        {/* <!--====== SERVICES PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h6 className="title">Media <span>Keuangan</span></h6>
                  </div>
                  <p className="text">Merupakan aplikasi untuk management keuangan dan juga belanja.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/14.biz.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Biz</a></h4>
                        <p className="text">Merupakan aplikasi untuk belanja bagi institusi (sekolah).</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/15.scoin.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Scoin</a></h4>
                        <p className="text">Merupakan aplikasi untuk me-manage coin scoin.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Materi <span>dan Soal</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi pembuatan materi dan juga soal yang nanti akan digunakan pada Media Tes dan juga Media Belajar.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps//8.mau.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Materi Ajar dan Ujian</a></h4>
                        <p className="text">Merupakan kepanjangan dari MAU, aplikasi yang digunakan untuk pembuatan butir dan paket materi serta juga soal.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Media <span>Ujian dan Tes</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi untuk melakukan sebuah ujian dan juga tes.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/10.test-online.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Test Online</a></h4>
                        <p className="text">Merupakan aplikasi untuk test online yang dilakukan oleh guru dan juga siswanya.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/11.tryout.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Try Out</a></h4>
                        <p className="text">Merupakan aplikasi ujian yang dilakukan oleh perorangan tidak terikat oleh institusi.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps//9.ujian-luring.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Ujian Luring</a></h4>
                        <p className="text">Merupakan singkatan dari Ujian Luar Jaringan yaitu tidak membutuhkan sebuah internet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======-->*/}
        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Media <span>Belajar</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi pembuatan materi dan juga soal yang nanti akan digunakan pada Media Tes dan juga Media Belajar.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/7.video-belajar.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Video</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk media pembelajaran berupa video.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/12.materi-belajar.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Materi Belajar</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk media pembelajaran berupa paket.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/12.materi-belajar.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">E - Book</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk media pembelajaran berupa e - book.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Media <span>Bertanya</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi untuk membebaskan user didalamnya untuk melakukan tanya jawab.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/17.pitakon.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Pitakon</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk bertanya jawab antar user.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/18.kuisioner.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Kuisioner</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk melakukan sebuah kusioner.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}


        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Media <span>Sosial</span></h3>
                  </div>
                  <p className="text">Merupakan sebuah media daring yang digunakan satu sama lain dimana para penggunanya bisa dengan mudah berpartisipasi, berinteraksi, berbagi, dan menciptakan isi blog, jejaring sosial, wiki, forum dan dunia virtual tanpa dibatasi oleh ruang dan waktu.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/20.social-media.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Schoolmedia</a></h4>
                        <p className="text">Merupakan sebuah media daring yang digunakan satu sama lain dimana para penggunanya bisa dengan mudah berpartisipasi, berinteraksi, berbagi.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}


        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Management <span>dan Akademik</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi untuk membebaskan user didalamnya untuk melakukan tanya jawab.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/3.um.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">User Management</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk management data sekolah sekolah.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/5.cms-sekolah.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">CMS Sekolah</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk management website sekolah.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/4.jurnal.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Jurnal Akademik</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk jurnal didalam yang ada sekolah.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/6.rpp.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">RPP</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan rencana pelaksanaan pembelajaran untuk guru pengajar.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}


        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Berita <span>dan Event</span></h3>
                  </div>
                  <p className="text">Merupakan sebuah aplikasi yang digunakan untuk menampilkan sebuah berita dan juga event.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/16.news.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">News</a></h4>
                        <p className="text">Merupakan sebuah aplikasi portal berita yang menampilkan berita berita pendidikan.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/21.event.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Event</a></h4>
                        <p className="text">Merupakan sebuah aplikasi yang digunakan untuk menampilkan event event yang ada.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}


        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Hosting <span>dan PPDB</span></h3>
                  </div>
                  <p className="text">Merupakan aplikasi untuk pendaftaran peserta didik baru dan juga jasa hosting.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/1.hosting.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Hosting</a></h4>
                        <p className="text">Merupakan aplikasi yang digunakan untuk pesan domain, hosting, mail server, dan vps.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/21.event.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">PPDB</a></h4>
                        <p className="text">Merupakan aplikasi untuk pendaftaran peserta didik baru untuk sekolah sekolah.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        <section id="about" className="about-area pt-40 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line"></div>
                    <h3 className="title">Help <span>Center</span></h3>
                  </div>
                  <p className="text">Merupakan sebuah aplikasi pusat bantuan semua aplikasi aplikasi dari schoolmedia.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sliderapp">
                  <div className="col-lg-12">
                    <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/logo-apps/22.help-center.svg" width="100px" alt="shape" />
                      </div>
                      <div className="services-content mt-30">
                        <h4 className="services-title"><a href="#">Help Center</a></h4>
                        <p className="text">Merupakan sebuah aplikasi pusat bantuan semua aplikasi aplikasi dari schoolmedia.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--====== ABOUT PART ENDS ======--> */}


        <footer id="footer" className="footer-area">
          <div className="container">
            <div className="footer-copyright">
              <div className="row">
                <div className="col-lg-12">
                  <div className="copyright d-sm-flex justify-content-center">
                    <div className="copyright-content">
                      <p className="text">Copyright @ 2020 Schoolmedia.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* <!--====== FOOTER PART ENDS ======--> */}

        {/* <!--====== BACK TOP TOP PART START ======--> */}

        <a href="#" className="back-to-top"><i className="lni-chevron-up"></i></a>

        {/* <!--====== BACK TOP TOP PART ENDS ======--> */}

      </>
    )
  }
}
