import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const api = `${process.env.REACT_APP_API}`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

class SettingNonactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupCode: null,
      userName: null,
      dataUser: {},
      data: {},
      dataValidation: false,

      showReason: false,

      modalOpen: false,
    }
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleInputVal = this.handleInputVal.bind(this);
  }

  jwtToken = null;

  componentDidMount(){
    this.jwtToken = localStorage.getItem("jwtToken");
  }

  componentDidUpdate(){
    this.jwtToken = localStorage.getItem("jwtToken");
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.userReducer.userDetail != state.data) {
      let data = nextProps.userReducer.userDetail;
      return {
        groupCode: data.group_code,
        userName: data.username,
        dataUser: data,
        data: data,
      }
    }
  }

  // reason
  handleShow() {
    this.setState({
      showReason: true
    })
  }
  handleHide() {
    this.setState({
      showReason: false
    })
  }

  // modal
  handleOpenModal() {
    this.setState({
      modalOpen: true
    })
  }
  handleCloseModal() {
    this.setState({
      modalOpen: false,
      dataValidation: false,
    })
  }
  handleInputVal(e) {
    // e.preventDefault()
    const value = e.target.value;
    if (value === this.state.userName) {
      this.setState({
        dataValidation: true,
      })
    } else {
      this.setState({
        dataValidation: false,
      })
    }
  }
  handleNonActive(e) {
    e.preventDefault();

    let config = {
      headers: {
        Authorization: "Bearer " + this.jwtToken,
        'Content-Type': 'multipart/form-data'
      },
    };
    
    if (e) {
      const formData = new FormData();
      let requestData = null;

      if (this.state.dataUser) {
        let data = this.state.dataUser;
        delete data.password;
        requestData = {
          ...data,
          status: 0,
        }
      }

      formData.append('json', JSON.stringify(requestData));
      axios.post(api + 'profile/update-user',
        formData,
        config
      ).then((res) => {
        alert('Berhasil')
      }).catch((err) => {
      })
    }
  }
  render() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="bg-white modal-sm uk-grid-collapse uk-grid uk-padding">
              <h2 id="transition-modal-title">Non aktifkan akun. Anda benar benar yakin ?</h2>
              <div className="uk-alert-danger uk-alert font-sm" uk-alert>
                <a className="uk-alert-close uk-icon uk-close" uk-close></a>
                <p>
                  Menon-aktifkan akun dapat membuat akun profile anda tidak dapat ditemukan orang lain, dan juga anda tidak bisa melakukan aktifitas apapun pada semua aplikasi
              </p>
              </div>
              <div className="uk-flex uk-flex-column">
                <p id="transition-modal-description" className="font-sm text-sm">Silahkan ketik berikut ini untuk konfirmasi :</p>
                <code>{this.state.userName}</code>
                {this.state.dataValidation}
              </div>
              <form className="w-full uk-width-1-1 mt-4">
                <div className="uk-flex">
                  <input className="uk-input uk-form-small" type="text" placeholder="Ketik disini"
                    onChange={this.handleInputVal.bind(this)}
                  />
                </div>
              </form>
              <div className="uk-flex mt-4">
                <button className="mr-2 button small btn-light" onClick={this.handleCloseModal}>Batalkan</button>
                <button
                  className={`ml-2 button small ${this.state.dataValidation ? 'btn-schoolmedia' : 'btn-disabled'}`}
                  onClick={this.handleNonActive.bind(this)}

                >Oke, saya yakin</button>
              </div>
            </div>
          </Fade>
        </Modal>

        <div className="uk-padding bg-white rounded-sm uk-width-2-3@m mt-sm-3 pl-sm-0 p-sm-4">
          <div className="bg-white rounded-sm mb-2">
            <h5 className="mt-2 mb-2 uk-flex"><i className="mr-2 icon-feather-power uk-text-large"></i> Nonaktifkan Akun Anda </h5>
            <div className="uk-flex uk-flex-column">
              <div className="uk-flex uk-flex-column">
                <span>Apakah anda ingin menonaktifkan akun anda?</span>
                <div className="uk-flex">
                  <button className="button small btn-schoolmedia ml-2 mr-2" onClick={this.handleShow}>Ya</button>
                  <button className="button small btn-white" onClick={this.handleHide}>Tidak</button>
                </div>
              </div>
              {this.state.showReason ?
                <>
                  {/* <div className="uk-padding pt-3">
                  <p>Jika Ya pilihlah salah satu alasan dibawah ini.</p>
                  <form>
                    <input type="radio" id="male" name="gender" value="male" />
                    <label for="male">Male</label><br />
                    <input type="radio" id="female" name="gender" value="female" />
                    <label for="female">Female</label><br />
                    <input type="radio" id="other" name="gender" value="other" />
                    <label for="other">Other</label>
                  </form>
                </div> */}
                  <div className="uk-flex">
                    <span>Jika anda login ke akun anda maka akun anda akan aktif kembali</span>
                    <button className="button small btn-schoolmedia ml-2 mr-2" onClick={this.handleOpenModal}>Lanjutkan</button>
                  </div>
                </> : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer;
  return {
    auth: state.auth,
    userReducer
  }
}

export default connect(mapStateToProps)(SettingNonactive);