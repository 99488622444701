import React from 'react';
import { Link } from 'react-router-dom';
import ProfileHeader from './components/ProfileHeader';

export default class UserGroups extends React.Component {
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner pt-0">
          <ProfileHeader />
          <div className="section-small">
            <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
              <h2>Grup Saya</h2>

              <div className="uk-flex uk-flex-between">
                <ul className="uk-tab"
                  uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                  <li className="uk-active"><a href="#">
                    {/* <i className="icon-feather-home mr-2"></i> */}
                        Grup Umum</a>
                  </li>
                  <li><a href="#">
                    {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Grup Tetap</a>
                  </li>
                </ul>
                <button className="button small btn-schoolmedia">Tambah Grup</button>
              </div>
            </div>
            <ul className="uk-switcher uk-margin" id="component-tab-left">
              <li>
                <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                  <ul className="uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid">
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                  <ul className="uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid">
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/group-about">
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> TKJ SMKN 3 Metro </h5>
                            </a>
                            <span className="font-xs">567 Anggota</span>
                            <p className="font-sm"> Kumpulan Anak Teknik Komputer dan Jaringan </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}