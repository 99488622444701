import React from 'react';
import { Link, NavLink } from "react-router-dom";

// css
import '../../../../assets/css/sidebar.css';

export default class ThemeLeftBar extends React.Component {
  render() {
    return (
      // <!-- sidebar -->
      <div className="main_sidebar">
        <div className="side-overlay" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></div>

        {/* // <!-- sidebar header --> */}
        <div className="sidebar-header">
          <h4> Menu Tema Grup</h4>
          <span className="btn-close" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></span>
        </div>

        {/* <!-- sidebar Menu --> */}
        <div className="sidebar">
          <div className="sidebar_innr" data-simplebar>
            <div className="sections">
              <ul>
                <li className="sidebar-profile pt-2 pb-2">
                  <Link to="/groups">
                    <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                      <span className="uk-flex uk-flex-column" style={{ lineHeight: "1.5", display: "flex" }}> Grup Kelas 12
                      <span className="font-xs">SMKN 1 Jakarta</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <div className="p-0">
                    <input type="text" className="form-control" placeholder="Cari grup" />
                  </div>
                </li>
              </ul>
              <h3 className="mt-4"> Grup </h3>
              <ul>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/groups/example-group">
                    <div className="uk-flex uk-flex-middle p-2 w-100">
                      <div className="page-card-media">
                        <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="page-card-innr" style={{ margin: "0 10px" }}>
                        <h5 className="mb-0"> Grup Lainnya </h5>
                        <p className="small">1 Postingan baru</p>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>

            </div>

            <div className="sections">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
