import axios from 'axios';
import { GET_LIST_TOPIC, GET_LIST_TOPIC_SUCCESS, GET_LIST_TOPIC_ERR } from './types';

const api = `${process.env.REACT_APP_API}`;
export const getTopicList = (data) => {
  let jwtToken = localStorage.getItem("jwtToken")
  let config = {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  }
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TOPIC,
      data: [],
    })
    axios.post(api + `topic/get-topic`, {
      forum_code: data,
    }, config)
      .then(res => {
        dispatch({
          type: GET_LIST_TOPIC_SUCCESS,
          data: res.data.data.data,
        })
      }).catch(err => {
        dispatch({
          type: GET_LIST_TOPIC_ERR,
          data: []
        })
      })
  }

}