import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import ActivityLeftBar from "../component/pages/Profile/components/ActivityLeftBar";

// Route for groups component
const ActivityRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} component={(props) => (
      <>
        <ActivityLeftBar />
        {/* <MaintenanceOverlay /> */}
        <Component {...props} />
      </>
    )}
    />
  );
};

export default ActivityRoute;
