import React from 'react';
import ThemeGroupHeader from '../components/ThemeGroupHeader';

export default class ThemeGroupFile extends React.Component {
  render() {
    return (
      <>
        <div className="main_content">
          <div className="main_content_inner">
            <div className="single-group">
              <ThemeGroupHeader/>

              <div className="mt-5">
                <div className="post-new">

                  <div className="uk-flex uk-flex-between">
                    <h3> File Topik </h3>
                    <div>
                      <a href="#" className="button btn-schoolmedia small circle ml-2"> <i className="uil-file"> </i> Buat Dokumen </a>
                      <a href="#" className="button btn-schoolmedia small circle ml-4"> <i className="uil-upload"></i> Unggah File </a>
                    </div>
                  </div>

                  <hr />

                  <table className="uk-table uk-table-divider">
                    <thead>
                      <tr>
                        <th className="text-center"><b> Nama File </b></th>
                        <th className="text-center"><b> Jenis </b></th>
                        <th className="text-center"><b> Diubah </b></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center"><i className="uil-file"></i> Tugas_Bahasa_Indo_Bab1.pdf</td>
                        <td className="text-center">PDF</td>
                        <td className="text-center">20 Oktober 2020, 16.09 WIB</td>
                        <td className="text-center">
                          <span className="btn-option" aria-expanded="false">
                            <i className="icon-feather-more-horizontal"></i>
                          </span>

                          <div className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small" style={{left: "-153px", top: "44px"}}>
                            <ul>
                              <li>
                                <span> <i className="uil-pen"></i> Ubah </span>
                              </li>
                              <li>
                                <span> <i className="uil-trash"></i> Hapus </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="uil-file"></i> Tugas_Bahasa_Indo_Bab1.pdf</td>
                        <td className="text-center">PDF</td>
                        <td className="text-center">20 Oktober 2020, 16.09 WIB</td>
                        <td className="text-center">
                          <span className="btn-option" aria-expanded="false">
                            <i className="icon-feather-more-horizontal"></i>
                          </span>

                          <div className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small" style={{left: "-153px", top: "44px"}}>
                            <ul>
                              <li>
                                <span> <i className="uil-pen"></i> Ubah </span>
                              </li>
                              <li>
                                <span> <i className="uil-trash"></i> Hapus </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="uil-file"></i> Tugas_Bahasa_Indo_Bab1.pdf</td>
                        <td className="text-center">PDF</td>
                        <td className="text-center">20 Oktober 2020, 16.09 WIB</td>
                        <td className="text-center">
                          <span className="btn-option" aria-expanded="false">
                            <i className="icon-feather-more-horizontal"></i>
                          </span>

                          <div className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small" style={{left: "-153px", top: "44px"}}>
                            <ul>
                              <li>
                                <span> <i className="uil-pen"></i> Ubah </span>
                              </li>
                              <li>
                                <span> <i className="uil-trash"></i> Hapus </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="uil-file"></i> Tugas_Bahasa_Indo_Bab1.pdf</td>
                        <td className="text-center">PDF</td>
                        <td className="text-center">20 Oktober 2020, 16.09 WIB</td>
                        <td className="text-center">
                          <span className="btn-option" aria-expanded="false">
                            <i className="icon-feather-more-horizontal"></i>
                          </span>

                          <div className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small" style={{left: "-153px", top: "44px"}}>
                            <ul>
                              <li>
                                <span> <i className="uil-pen"></i> Ubah </span>
                              </li>
                              <li>
                                <span> <i className="uil-trash"></i> Hapus </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="uil-file"></i> Tugas_Bahasa_Indo_Bab1.pdf</td>
                        <td className="text-center">PDF</td>
                        <td className="text-center">20 Oktober 2020, 16.09 WIB</td>
                        <td className="text-center">
                          <span className="btn-option" aria-expanded="false">
                            <i className="icon-feather-more-horizontal"></i>
                          </span>

                          <div className="dropdown-option-nav uk-dropdown uk-dropdown-bottom-right" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small" style={{left: "-153px", top: "44px"}}>
                            <ul>
                              <li>
                                <span> <i className="uil-pen"></i> Ubah </span>
                              </li>
                              <li>
                                <span> <i className="uil-trash"></i> Hapus </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
