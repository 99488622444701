import React from 'react';
import axios from 'axios';
// material ui
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditPost from './EditPost';
import timeAgo from 'utils/timeAgo';
import NoteComment from './NoteComment';
import PostComments from '../global/PostComments';
import { Link } from "react-router-dom";

const api = `${process.env.REACT_APP_API}`;

let jwtToken = localStorage.getItem("jwtToken")
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
  },
}

export default class NotePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      userData: null,
      modalEdit: false,
      liked: false,
      totalLikes: null,

      userName: null,
      passComment: false,
      dataComments: []
    }
    this._getComment = this._getComment.bind(this)
    this._handleEditModal = this._handleEditModal.bind(this)
    this._handleEditModalClose = this._handleEditModalClose.bind(this)
    this._handleLike = this._handleLike.bind(this)

    this._delete = this._delete.bind(this)
  }
  componentDidMount() {
    this._getComment()
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data != state.data) {
      if (nextProps.data != undefined) {
        return ({
          data: nextProps.data,
          userData: nextProps.userData,
          like: (nextProps.data.is_like === 0 ? false : true),
          totalLikes: (nextProps.data.is_like === 0 ? null : nextProps.data.is_like),
        })
      } else {
        return null;
      }
    }
  }
  _getComment() {
    let requestData = {
      dest_id: this.state.data.id,
      type: 3,
    };
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + 'comment/get-comment',
      requestData,
      config
    ).then(res => {
      console.log(res.data.data)
      this.setState({
        passComment: true,
        dataComments: res.data.data.items,
      })
    })
  }
  _handleEditModal() {
    this.setState({
      modalEdit: true
    })
  }
  _handleEditModalClose() {
    this.setState({
      modalEdit: false
    })
  }
  _handleLike() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    this.setState({ liked: !this.state.liked }, function(){
      if(this.state.liked) {
        var totalLike = this.state.totalLikes + 1;
      } else {
        var totalLike = this.state.totalLikes - 1;
      }
      this.setState({ totalLikes: totalLike });
    })
    axios.post(api + 'like/post-like', {
      dest_id: this.state.data.id,
      type: 3,
    }, config)
      .then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
        this.setState({
          like: !this.state.like,
        })
      })
  }
  _delete() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + 'note/destroy-note', {
      note_id: this.state.data.id,
    }).then(res => {
      this.props.getNote(this.state.data.id)
    }).catch(err => {
    })
  }
  statusIcon(status) {
    if(status == 1) {
      return (<i className="uil-globe"></i>);
    } else if (status == 2){
      return (<i className="uil-users-alt"></i>);
    } else if (status == 0){
      return (<i className="uil-lock"></i>);
    }
  }
  render() {
    console.log(this.state.data)
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.modalEdit}
          onClose={this._handleEditModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalEdit}>
            <div className="bg-white modal-md uk-flex uk-flex-column uk-padding">
              <div className="uk-flex uk-items-center justify-beetwen">
                <h3>Edit Catatan</h3>
              </div>
              <div className="">
                <EditPost retriveData={this.props.retriveData} handleModal={this._handleEditModalClose} noteId={this.state.data.id}/>
              </div>
            </div>
          </Fade>
        </Modal>
        <div className="post">
          <div className="post-heading">
            <Link to={"/"+this.state.userData.username}>
              <div className="post-avature">
                {this.state.data?.group_code === 'NI' ?
                this.state.data.photo_profile === null || this.state.data.photo_profile === "null" ?
                <img src='/assets/icons/user.svg' />
                :
                <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data.group_code}/photo/${this.state.data.photo_profile}`} alt="" />
                
                :
                this.state.data.photo_profile === null || this.state.data.photo_profile === "null" ?
                <img src='/assets/icons/user.svg' />
                :
                <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data.group_code}/photo/${this.state.data.photo_profile}`} alt="" />
                
                }
              </div>
            </Link>
            <div className="post-title">
              <Link to={"/"+this.state.data.username}>
                <h4> {this.state.data.name} </h4>
              </Link>
              <p className="text-xs"> {timeAgo(this.state.data.created_at)} {this.statusIcon(this.state.data.status)} </p>
            </div>
            <div className="post-btn-action">
              <span className="icon-more uil-ellipsis-h"></span>
              <div className="mt-0 p-2" uk-dropdown="pos: bottom-right;mode:hover">
                <ul className="uk-nav uk-dropdown-nav">
                  <li><a href="#"> <i className="uil-share-alt mr-1"></i> Share </a></li>
                  <li><a onClick={this._handleEditModal}> <i className="uil-edit-alt mr-1"></i> Edit Post </a></li>
                  <li><a href="#"> <i className="uil-comment-slash mr-1"></i> Disable comments </a></li>
                  <li><a href="#"> <i className="uil-favorite mr-1"></i> Add favorites </a></li>
                  <li><a onClick={this._delete} className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="post-description">
            <div className="card uk-padding" dangerouslySetInnerHTML={{ __html: this.state.data.content }}>
            </div>
            <div className="post-state-details">
              <div>
                {/* <img src="/assets/images/icons/reactions_like.png" alt=""></img>
                <img src="/assets/images/icons/reactions_love.png" alt=""></img> */}
                <p> {this.state.liked
                      ? this.state.totalLikes != null
                        ? `${this.state.totalLikes} menyukai`
                        : ''
                      : this.state.totalLikes}</p>
              </div>
              {/* <p> {this.state.commentLength.length} Komentar</p> */}
            </div>
          </div>

          <div className="post-state">
            <div className="post-state-btns"
              style={{ color: `${this.state.liked ? "#007bff" : ""}` }}
              onClick={e => { this._handleLike() }}
            >
              <i className="uil-thumbs-up"></i>
              <span>
                {this.state.like ? "Disukai" : "Suka"}
              </span>
            </div>
            <div className="post-state-btns">
              <i className="uil-heart"></i> {this.state.dataComments.length} <span> Coments </span>
            </div>
            {/* <div className="post-state-btns">
              <i className="uil-share-alt"></i> 193 <span> Shared </span>
            </div> */}
            {/* <div className="post-state-btns">
              <i className="uil-bookmark"></i> 13 <span> Saved </span>
            </div> */}
          </div>

          {/* <!-- post comments --> */}
          {this.state.passComment ?
            <PostComments
              commentData={this.state.dataComments}
              userName={this.state.userData.username}
              postData={this.state.data}
              key={this.state.data.id}
              numberToShow={3}
              type={3}
              commentType="note"
            /> : null}
        </div>
      </>
    )
  }
}