import React from "react";
import Modal from "@material-ui/core/Modal";
import Skeleton from "@material-ui/lab/Skeleton";

export default class InviteMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    }
    this.openModal = this.openModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  openModal() {
    this.setState({
      openModal: true
    })
  }

  hideModal() {
    this.setState({
      openModal: false
    })
  }

  render() {
    return (
      <div>
        <button type="button" onClick={this.openModal} className="button btn-schoolmedia">
          <i className="uil-user-plus"></i> Invite
        </button>
        <Modal
          open={this.state.openModal}
          onClose={this.hideModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <div className="bg-white pt-2 modal-xs uk-grid-collapse uk-grid" style={{ minWidth: "50%", maxWidth: "60%", minHeight: "100px", maxHeight: "750px" }}>
            <div className="uk-width-1-1">
              <div className="modal-header">
                <h4 className="modal-title text-center">Undang Teman</h4>
                <button className="close" onClick={this.hideModal}>&times;</button>
              </div>
              {/*<hr className="mb-0" />*/}
              <div className="col-12" style={{ height: "67%", minHeight: "67%", maxHeight: "67%" }}>
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-7" style={{ height: "100%" }}>
                    <input type="text" className="form-control my-2" placeholder="Cari teman"/>

                    <div style={{ height: "90%", overflowY: "auto" }}>
                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card mb-0">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <input type="checkbox" name="" id=""/>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <Skeleton variant="circle" width={40} height={40} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <Skeleton variant="text" width="50%" height={30} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 bg-light" style={{ height: "100%" }}>
                    <h5 className="mt-3 mb-2">0 Teman Dipilih</h5>

                    <div style={{ height: "90%", overflowY: "auto" }}>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                      <div className="uk-flex uk-flex-middle mb-0 py-2">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr uk-flex uk-flex-middle">
                          <h5 className="mb-0"> Rudiyanto </h5>
                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <button className="button mr-2" style={{ padding: "0 10px" }}>&times;</button>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              {/*<hr className="mt-0" />*/}
              <div className="modal-footer">
                <button className="button" onClick={this.hideModal}>Batalkan</button>
                <button className="button btn-schoolmedia">Tambahkan</button>
              </div>
            </div>
          </div>

        </Modal>
      </div>
    )
  }
}
