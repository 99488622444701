import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";

// Route
import GlobalRoute from './GlobalRoute';
import MaintenanceRoute from './MaintenanceRoute';
import ForumGlobalRoute from './ForumGlobalRoute';
import ForumRoute from './ForumRoute';
import AuthenticatedRoute from './AuthenticatedRoute';


// sidebar
import Home from '../component/pages/Home';
import Chats from '../component/pages/chats/Chats';
import ListLiveVideos from '../component/pages/live-video/ListLiveVideos';
import UserVideos from '../component/pages/Profile/UserVideos';
import ListNews from '../component/pages/news/NewsList';
import Notes from '../component/pages/notes/Notes';
import ListGroups from '../component/pages/groups/ListGroups';
import ListApps from '../component/pages/applications/ListApps';

// profile
import UserProfile from '../component/pages/Profile/UserProfile';
import UserFriends from '../component/pages/Profile/UserFriends';
import UserPhotos from '../component/pages/Profile/UserPhotos';
import UserNotes from '../component/pages/Profile/UserNotes';
import SettingSecurity from '../component/pages/Profile/profile-settings/SettingSecurity';
import SettingProfile from '../component/pages/Profile/profile-settings/SettingProfile';
import SettingNonactive from '../component/pages/Profile/profile-settings/SettingNonactive';
// friend
import DetailFriend from '../component/pages/friends/DetailFriend';

// content
import Content from '../component/pages/content/Content';
// list grup
import UserGroups from '../component/pages/Profile/UserGroups';
import ListGroup from "../component/pages/groups/ListGroup";
import Group from '../component/pages/groups/Group';
import GroupLevel from "../component/pages/groups/level/GroupLevel";
import GroupAbout from '../component/pages/groups/GroupAbout';
import GroupMember from '../component/pages/groups/GroupMember';
import GroupFile from "../component/pages/groups/GroupFile";
import GroupMedia from "../component/pages/groups/GroupMedia";

import ThemeGroup from '../component/pages/groups/themes/ThemeGroup';
import ThemeGroupFile from '../component/pages/groups/themes/ThemeGroupFile';
import ThemeGroupMedia from '../component/pages/groups/themes/ThemeGroupMedia';

import ContentExchange from '../component/pages/content-exchange/ContentExchange';
import Video from '../component/pages/videos/Video';
import NewsDetail from '../component/pages/news/NewsDetail';

// Content
import ContentMAU from '../component/pages/content/ContentMau';
import ContentBahanAjar from '../component/pages/content/ContentBahanAjar';
import ContentUSBK from '../component/pages/content/ContentUSBK';
import ContentTesOnline from '../component/pages/content/ContentTesOnline';
import ContentTryOut from '../component/pages/content/ContentTryOut';
import ContentPitakon from '../component/pages/content/ContentPitakon';
import MapView from '../component/pages/playground/MapView';
// import Crypto from '../component/pages/playground/Crypto';

import DetailPost from "component/partials/Timeline/DetailPost";
import SettingIndex from "../component/pages/Profile/profile-settings/SettingIndex";

import Search from "component/pages/search/Search"
import SearchPerson from "component/pages/search/SearchPerson";
import UserActivity from "../component/pages/Profile/UserActivity";
import ActivityRoute from "./ActivityRoute";
import NotFound from "component/pages/404";

const Page404 = () => {
  return (
    <NotFound />
  )
}

const SMRouter = () => {

  return (
    <Switch>

      <AuthenticatedRoute exact path="/timeline" component={Home} />
      <Route exact path="/search" component={Search}/>
      <Route exact path="/search/person" component={SearchPerson}/>
      <Route path="/:username/posts/:id" render={(props) => <DetailPost {...props} />} />
      {/* BEGIN PLAYGROUND */}
      <GlobalRoute Route exact path="/playground/map" component={MapView} />
      {/* <GlobalRoute Route exact path="/playground/Crypto" component={Crypto} /> */}
      {/* END PLAYGROUND */}

      {/* Group */}
      <GlobalRoute exact path="/groups" component={ListGroup} />
      {/* Group Tetap */}
      <Route path="/groups/level-1" component={GroupLevel} />
      <Route path="/groups/level-2" component={GroupLevel} />
      <Route path="/groups/level-3" component={GroupLevel} />
      <Route path="/groups/level-4" component={GroupLevel} />

      <ForumGlobalRoute exact path="/groups/:group" component={Group} />
      <ForumGlobalRoute path="/groups/:group/about" component={GroupAbout} />
      <ForumGlobalRoute path="/groups/:group/member" component={GroupMember} />
      <ForumGlobalRoute path="/groups/:group/file" component={GroupFile} />
      <ForumGlobalRoute path="/groups/:group/media" component={GroupMedia} />

      {/* Group Topic */}
      <ForumRoute exact path="/groups/:group/:topics" component={ThemeGroup} />
      <ForumRoute path="/groups/:group/:topics/file" component={ThemeGroupFile} />
      <ForumRoute path="/groups/:group/:topics/media" component={ThemeGroupMedia} />

      {/* Sidebar Menuu */}
      <GlobalRoute path="/chats" component={Chats} />
      <MaintenanceRoute path="/live-video" component={ListLiveVideos} />
      <GlobalRoute path="/:username/photos" component={UserPhotos} />
      <GlobalRoute path="/:username/videos" component={UserVideos} />
      <GlobalRoute path="/notes" component={Notes} />
      <MaintenanceRoute path="/:username/list-groups" component={ListGroups} />
      <MaintenanceRoute
        path="/content-exchange"
        component={ContentExchange}
      />
      <GlobalRoute exact path="/news" component={ListNews} />
      <GlobalRoute path="/applications" component={ListApps} />
      {/* <MaintenanceRoute exact path={`/${this.props.auth.user.uuid}`} component={Profile} /> */}
      <GlobalRoute exact path="/:username" component={UserProfile} />
      <MaintenanceRoute path="/:username/content" component={Content} />
      <MaintenanceRoute
        path="/:username/following-group"
        component={UserGroups}
      />

      {/* menu profile */}
      <GlobalRoute path="/:username/friends" component={UserFriends} />
      <GlobalRoute path="/:username/list-notes" component={UserNotes} />
      <ActivityRoute path="/:username/activity" component={UserActivity} />

      {/* Friend */}
      <MaintenanceRoute
        path="/detail-friend"
        component={DetailFriend}
      />

      {/* Videos */}
      <MaintenanceRoute path="/videos/watch" component={Video} />
      {/* News */}
      <MaintenanceRoute path="/news/detail" component={NewsDetail} />
      {/* Profile Settings */}
      <AuthenticatedRoute
        exact
        path="/profile/settings"
        component={SettingIndex}
      />
      <AuthenticatedRoute
        path="/profile/settings-security"
        component={SettingSecurity}
      />
      <AuthenticatedRoute
        path="/profile/settings-profile"
        component={SettingProfile}
      />
      <AuthenticatedRoute
        path="/profile/settings-nonactive"
        component={SettingNonactive}
      />

      {/* Content */}
      <MaintenanceRoute path="/content/mau" component={ContentMAU} />
      <MaintenanceRoute
        path="/content/bahan-ajar"
        component={ContentBahanAjar}
      />
      <MaintenanceRoute path="/content/usbk" component={ContentUSBK} />
      <MaintenanceRoute
        path="/content/tes-online"
        component={ContentTesOnline}
      />
      <MaintenanceRoute
        path="/content/try-out"
        component={ContentTryOut}
      />
      <MaintenanceRoute
        path="/content/pitakon"
        component={ContentPitakon}
      />

      <Route path="" component={Page404} />
    </Switch>
  )
}

export default SMRouter;
