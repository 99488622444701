import React from 'react';
import { connect } from 'react-redux';
import GeneralNI from './form-general/GeneralNI';
import GeneralGU from './form-general/GeneralGU';
import GeneralSI from './form-general/GeneralSI';
import GeneralOW from './form-general/GeneralOW';
import GeneralLA from './form-general/GeneralLA';
import GeneralPU from './form-general/GeneralPU';

class FormGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      authUser: [],
    }
    this.formRender = this.formRender.bind(this)
  }
  componentDidMount() {
    this.setState({
      userData: this.props.userReducer.userDetail
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.userReducer !== prevProps.userReducer) {
      this.setState({
        userData: this.props.userReducer.userDetail
      })
      
    }
    if (this.props.auth !== prevProps.auth) {
      this.state({
        authUser: this.props.auth.user
      })
    }
  }
  formRender(){
    let data = this.state.userData
    // switch (data.group_code) {
    //   case 'NI':
    //     return(
    //         <GeneralNI data={this.state.userData}/>
    //     )
    //     break;
    // }
  }
  render() {
    return (
      <>
        <GeneralNI data={this.state.userData} />
        <GeneralGU data={this.state.userData} />
        <GeneralSI data={this.state.userData} />
        <GeneralOW data={this.state.userData} />
        <GeneralLA data={this.state.userData} />
        <GeneralPU data={this.state.userData} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}

export default connect(mapStateToProps)(FormGeneral);