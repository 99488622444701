import React, { useState } from "react";
import axios from 'axios';

const api = `${process.env.REACT_APP_API}`;

// class ButtonAddFriend extends React.Component {
//   _handleAddFriend() {
//     let jwtToken = localStorage.getItem("jwtToken")
//     let config = {
//       headers: {
//         Authorization: "Bearer " + jwtToken,
//       },
//     }
//     axios.post(api + 'friend/post-request-friend', {

//     })
//   }
//   render() {
//     return (
//       <>
//         <button className="button small btn-schoolmedia">Teman</button>
//       </>
//     )
//   }
// }

const ButtonAddFriend = (props) => {
  let [sent, setSent] = useState(props.isrequest);
  const [loading, setloading] = useState(false);

  function _handleAddFriend(e, status) {
    e.preventDefault();
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    setloading(true)
    axios.post(api + 'friend/post-request-friend', {
      username: props.username,
      is_friend_request: status
    }, config).then(res => {
      if(status === 1) {
        setSent(true)
        setloading(false)
      } else {
        setSent(false)
        setloading(false)
      }
    }).catch(err => {
      setSent(false)
      setloading(false)
    })
  }

  if(props.isfriend){
    return (<p>Sudah Berteman</p>)
  } else {
    if(sent != undefined){
      if (sent === false) {
        return (
          <>
            {
              loading 
              ? <button className="button small btn-default">Loading</button>
              : <button className="button small btn-schoolmedia" onClick={e => _handleAddFriend(e, 1)}>Tambah Teman</button>
            }
          </>
        )
      } else {
        return (
          <>
            <button className="button small btn-default" onClick={e => _handleAddFriend(e, 0)}>Batalkan Permintaan</button>
          </>
        )
      }
    }
  }
}

export default ButtonAddFriend;