import React from 'react';
import HeaderContent from './component/HeaderContent';
import { Link } from 'react-router-dom';

export default class ContentTryOut extends React.Component{
    render(){
        return(
            <div className="main_content">
              <div className="main_content_inner pt-0">
                <HeaderContent/>
                <div className="section-small">
                  <div className="uk-grid" uk-grid>
                      <div className="uk-width-2-3@m fead-area">
                        <ul className="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2@s uk-child-width-1-2 uk-grid-small uk-grid">
                          <li>
                              <Link to="/content">
                                  <div className="market-list">
                                    <div className="course-card-thumbnail">
                                          <img src="/assets/images/product/12.jpg" alt=""/>
                                      </div>
                                      <div className="item-inner">
                                          <div className="item-price"> Pola Pemakaian </div>
                                          <div className="item-statistic">
                                              <span> <span className="count">5</span> menit yang lalu </span>
                                          </div>
                                      </div>
                                  </div>
                              </Link>
                          </li>
                          <li>
                              <Link to="/content">
                                  <div className="market-list">
                                    <div className="course-card-thumbnail">
                                          <img src="/assets/images/product/12.jpg" alt=""/>
                                      </div>
                                      <div className="item-inner">
                                          <div className="item-price"> Pola Pemakaian </div>
                                          <div className="item-statistic">
                                              <span> <span className="count">5</span> menit yang lalu </span>
                                          </div>
                                      </div>
                                  </div>
                              </Link>
                          </li>
                          <li>
                              <Link to="/content">
                                  <div className="market-list">
                                    <div className="course-card-thumbnail">
                                          <img src="/assets/images/product/12.jpg" alt=""/>
                                      </div>
                                      <div className="item-inner">
                                          <div className="item-price"> Pola Pemakaian </div>
                                          <div className="item-statistic">
                                              <span> <span className="count">5</span> menit yang lalu </span>
                                          </div>
                                      </div>
                                  </div>
                              </Link>
                          </li>
                          <li>
                              <Link to="/content">
                                  <div className="market-list">
                                      <div className="course-card-thumbnail">
                                          <img src="/assets/images/product/12.jpg" alt=""/>
                                      </div>
                                      <div className="item-inner">
                                          <div className="item-price"> Pola Pemakaian </div>
                                          <div className="item-statistic">
                                              <span> <span className="count">5</span> menit yang lalu </span>
                                          </div>
                                      </div>
                                  </div>
                              </Link>
                          </li>
                          <li>
                              <Link to="/content">
                                  <div className="market-list">
                                    <div className="course-card-thumbnail">
                                          <img src="/assets/images/product/12.jpg" alt=""/>
                                      </div>
                                      <div className="item-inner">
                                          <div className="item-price"> Pola Pemakaian </div>
                                          <div className="item-statistic">
                                              <span> <span className="count">5</span> menit yang lalu </span>
                                          </div>
                                      </div>
                                  </div>
                              </Link>
                          </li>
                        </ul>
                      </div>

                      {/* <!-- sidebar --> */}
                      <div className="uk-width-expand ml-lg-2">
                        <div>
                            {/* MAU */}
                            <div className="side-news bg-white">
                            <div className="uk-flex uk-flex-between">
                                <h3> MAU </h3>
                                <Link>
                                <p className="font-sm">Lihat Semua</p>
                                </Link>
                            </div>
                            
                            <ul className="uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-2 uk-grid-small uk-grid">
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            </div>
                            {/* Bahan Ajar */}
                            <div className="side-news bg-white">
                            <div className="uk-flex uk-flex-between">
                                <h3> Bahan Ajar </h3>
                                <Link>
                                <p className="font-sm">Lihat Semua</p>
                                </Link>
                            </div>
                            
                            <ul className="uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-2 uk-grid-small uk-grid">
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/content">
                                        <div className="market-list">
                                        <div className="course-card-thumbnail">
                                                <img src="/assets/images/product/12.jpg" alt=""/>
                                            </div>
                                            <div className="item-inner">
                                                <div className="item-price"> Pola Pemakaian </div>
                                                <div className="item-statistic">
                                                    <span> <span className="count">5</span> menit yang lalu </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              </div>
            </div>
        )
    }
}