import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

function Media() {
  const classes = useStyles();

  return (
    <Card className="mb-4" style={{boxShadow: 'unset'}}>
      <CardHeader
        avatar={
          <Skeleton animation="wave" width={50} height={100} />
        }
        action={null}
        title={
          <Skeleton animation="wave" height={15} width="80%" style={{ marginBottom: 6 }} />
        }
        subheader={<Skeleton animation="wave" height={15} width="40%" />}
        subheader={<Skeleton animation="wave" height={15} width="50%" />}
        subheader={<Skeleton animation="wave" height={15} width="30%" />}
      />
    </Card>
  );
}


export default function SkeletonNewsList() {
  return (
    <div>
      <Media />
    </div>
  );
}