import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getUserDetail } from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";
import Slider from "react-slick";
import { compressImage } from '../../../../../../utils/photoManager.js';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const api = `${process.env.REACT_APP_API}`;
const smUri = `${process.env.REACT_APP_SM_STATIC_URI}`;

class GeneralNI extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.toastRef = React.createRef(null);
    this.state = {
      data: {},
      groupCode: '',
      userData: '',
      authUser: null,

      accountName: '',
      userName: '',
      noHP: '',
      logo: '',
      profilePhoto: null,
      coverPhoto: null,

      usernameIsChange: false,
      usernameLengthFilled: true,
      usernameAlert: false,

      newCoverPhoto: null,
      newProfilePhoto: null,

      base64Cover: '',
      base64Profile: '',

      alert: false,
    }
    this.addProfilePhoto = this.addProfilePhoto.bind(this)
    this.addCoverPhoto = this.addCoverPhoto.bind(this)
    this.cancelAddProfilePhoto = this.cancelAddProfilePhoto.bind(this);
    this.cancelAddCoverPhoto = this.cancelAddCoverPhoto.bind(this);

    this.uploadData = this.uploadData.bind(this)
    this.uploadAfterValidate = this.uploadAfterValidate.bind(this)
  }

  componentDidMount() {
  }

  static getDerivedStateFromProps(nextProps, state) {
    let dataUser = nextProps.userReducer.userDetail
    if (nextProps.userReducer.userDetail != state.data) {
      if (dataUser.group_code === 'NI') {
        return ({
          data: dataUser,
          groupCode: 'NI',
          userData: dataUser,
          accountName: dataUser.nama_institusi,
          userName: dataUser.username,
          noHP: dataUser.telp_institusi,
          profilePhoto: dataUser.logo,
          coverPhoto: dataUser.cover,
        })
      }
    }
    return ({
      authUser: nextProps.auth.user
    })
  }

  addProfilePhoto(event) {
    // image size validation
    const imageFile = event.target.files[0];
    let x = compressImage(this, 'newProfilePhoto', imageFile).then(function (x) {
    });
  }

  cancelAddProfilePhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById('upload-profile').value = "";
      this.setState({
        newProfilePhoto: null
      })
    }
  }

  addCoverPhoto(e) {
    const Img = e.target.files[0];
    let x = compressImage(this, 'newCoverPhoto', Img).then(function (x) {
    });
  }

  cancelAddCoverPhoto(e) {
    e.preventDefault();
    if (e) {
      document.getElementById('upload-cover').value = "";
      this.setState({
        newCoverPhoto: null,
      })
    }
  }

  _handleProfileReader(readerEvt) {
    let binaryString = readerEvt.target.result;

    this.setState({
      base64Profile: btoa(binaryString)
    })
  }

  _handleCoverReader(readerEvt) {
    let binaryString = readerEvt.target.result;

    this.setState({
      base64Cover: btoa(binaryString)
    })
  }

  handleValidation() {
    let userName = this.state.userName;
    let errors = {};
    let formIsValid = true;

    // username
    if (typeof userName["userName"] !== "undefined") {
      if (!userName["userName"].match(/^[a-z0-9._]+$/)) {
        formIsValid = false;
        errors["userName"] = "Hanya boleh menggunakan huruf kecil, angka, garis bawah, dan titik";
      }

      this.setState({ errors: errors });
      return formIsValid;
    }
  }


  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      alert("Form submitted");
    } else {
      alert("Form has errors.")
    }
  }

  uploadData(e) {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };

    let requestData = null;
    const formData = new FormData();
    let profile_pic = document.querySelector('#upload-profile').files[0];
    let cover_pic = document.querySelector('#upload-cover').files[0];

    if (this.state.newProfilePhoto === null) {
      profile_pic = this.state.profilePhoto
    }
    if (this.state.newCoverPhoto === null) {
      cover_pic = this.state.coverPhoto
    }

    // check group code
    if (this.state.groupCode === 'NI') {
      const data = this.state.userData;
      delete data.password;
      requestData = {
        ...data,
        nama_institusi: this.state.accountName,
        username: this.state.userName,
        telp_institusi: this.state.noHP,
        logo: this.state.userData.logo,
        cover: this.state.userData.cover,
      };
    }
    formData.append('json', JSON.stringify(requestData));

    if (this.state.newProfilePhoto !== null) {
      formData.append('profile_pic', profile_pic);
    }
    if (this.state.newCoverPhoto !== null) {
      formData.append('cover_pic', cover_pic);
    }
    axios.post(api + 'profile/update-user',
      formData,
      config,
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this._getDetailUser();
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }

  uploadAfterValidate(e) {
    e.preventDefault()
    if (this.state.usernameIsChange === true) {
      if (this.state.usernameLengthFilled === true) {
        this.uploadData()
      } else {
        this.setState({
          usernameAlert: true,
        })
      }
    } else {
      this.uploadData()
    }
  }

  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data)
  }

  symbolValidate(e) {
    let field = e.target.value

    // validate length
    if (field.length >= 6 && field.length <= 20) {
      this.setState({
        usernameLengthFilled: true
      })
    } else {
      this.setState({
        usernameLengthFilled: false
      })
    }

    // check if username changed
    if (field === this.state.userName) {
      this.setState({
        usernameIsChange: false,
      })
    } else {
      this.setState({
        usernameIsChange: true,
      })
    }

    // checking symbol
    if (field === '' || field === null || field === undefined) {
      this.setState({ userName: e.target.value.split(' ').join('_').toLowerCase() })
    } else {
      if (!field.match(/^[a-zA-Z0-9 _.]+$/)) {
      } else {
        this.setState({ userName: e.target.value.split(' ').join('_').toLowerCase() })
      }
    }
  }

  validateTelp(e){
    if(e === '' || e === null || e === undefined){
      this.setState({
        noHP: e
      })
    } else {
      if(e.match(/^[0-9 + () -]+$/)){
        this.setState({
          noHP: e
        })
      } 
    }
  }

  render() {
    const vertical = 'top';
    const horizontal = 'center'
    return (
      <>
        <Snackbar
          open={this.state.usernameAlert}
          onClose={ e => {this.setState({usernameAlert: false})}}
        >
          <MuiAlert elevation={6} variant="filled" onClose={ e => {this.setState({usernameAlert: false})}} severity="warning">
            Panjang username harus 6 sampai 20 karakter
          </MuiAlert>
        </Snackbar>
        
        {this.props.data.group_code === 'NI' ?
          <form action="POST" onSubmit={this.uploadAfterValidate}>
            <div className="profile-cover">
              {this.state.newCoverPhoto !== null ?
                <img src={this.state.newCoverPhoto} alt="" />
                :
                <img
                  src={
                    this.state.coverPhoto === null || this.state.coverPhoto === "null" ?
                    '/assets/images/avatars/profile-cover.jpg'
                    :
                      `${smUri}${this.state.groupCode}/cover/` + this.state.coverPhoto
                      
                  }
                  alt=""
                />
              }
              <span style={{ backgroundColor: "unset !important" }}>
                <input type="file" name="myImage" accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png" id="upload-cover" className="none"
                  ref={this.inputRef}
                  onChange={this.addCoverPhoto.bind(this)}
                />
                {this.state.newCoverPhoto === null ?
                  <>
                    <label for="upload-cover" className="label-btn-upload button small btn-schoolmedia animated-y" ref={this.inputRef}>
                      <span className="inner-visible"><i className="icon-material-outline-add-a-photo mr-2"></i>Upload cover baru</span>
                      <span className="inner-hidden"><i className="icon-feather-arrow-up"></i></span>
                    </label>
                  </>
                  :
                  <button onClick={this.cancelAddCoverPhoto.bind(this)}
                    className="button small btn-gray animated-y">
                    <span className="inner-visible"><i className="icon-feather-x mr-2"></i>Batalkan</span>
                    <span className="inner-hidden"><i className="icon-feather-x mr-2"></i>Batalkan</span>
                  </button>
                }
              </span>
            </div>

            <div className="profile-details pl-0" style={{ position: "relative", marginTop: 'unset', marginBottom: 'unset' }}>
              <div className="profile-image uk-padding uk-flex uk-flex-column justify-center">
                {this.state.newProfilePhoto === null ?
                  <img
                    src={
                      this.state.profilePhoto === null || this.state.profilePhoto === "null" ?
                      "/assets/icons/user.svg"
                      :
                        `${smUri}${this.state.groupCode}/photo/` + this.state.profilePhoto
                        
                    }
                    alt=""
                    style={{ margin: '0px auto', objectFit: "cover" }}
                  /> :
                  <img
                    src={this.state.newProfilePhoto} alt="" style={{ margin: '0px auto', objectFit: "cover" }}
                  />
                }

                <div className="uk-flex uk-flex-column mt-2">
                  <input type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg,/image/png" id="upload-profile" className="none upload-profile"
                    onChange={this.addProfilePhoto.bind(this)}
                  />
                  <label for="upload-profile" className="label-btn-upload button small btn-schoolmedia animated-y">
                    <span className="inner-visible"><i className="icon-material-outline-add-a-photo mr-2"></i>Upload foto baru</span>
                    <span className="inner-hidden"><i className="icon-feather-arrow-up"></i></span>
                  </label>
                  {this.state.newProfilePhoto === null ?
                    null
                    :
                    <button onClick={this.cancelAddProfilePhoto.bind(this)}
                      className="button small btn-gray mt-2">
                      Batalkan
                      </button>
                  }

                </div>
              </div>
              <div className="profile-details-info uk-width-1-1 pt-2">
                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">Nama Akun Anda</label>
                  <div className="uk-flex">
                    <input className="uk-input uk-form-small" type="text" placeholder="Input" value={this.state.accountName} onChange={e => { this.setState({ accountName: e.target.value }) }} />
                  </div>
                </div>

                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">Username</label>
                  <div className="uk-flex uk-flex-column">
                    <input className="uk-input uk-form-small mb-2" type="text" placeholder="Input" value={this.state.userName.toLowerCase()} onChange={e => {
                      this.symbolValidate(e)
                    }} />
                    {/* <span style={{color: "red"}}>{this.state.errors["userName"]}</span> */}
                    <Alert severity="warning">
                      {/* <AlertTitle>Alert!</AlertTitle> */}
                      Hanya boleh menggunakan huruf kecil, angka, garis bawah, dan titik
                    </Alert>
                  </div>
                </div>

                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">No. Handphone</label>
                  <div className="uk-flex">
                    <input className="uk-input uk-form-small" type="text" value={this.state.noHP} onChange={e => { this.validateTelp(e.target.value) }} />
                  </div>
                </div>
                <div className="uk-margin">
                  <label htmlFor="" className="font-sm">Email</label>
                  <div className="uk-flex">
                    <input className="uk-input uk-form-small" disabled type="text" value={this.state.data?.email} onChange={e => { this.validateTelp(e.target.value) }} />
                  </div>
                </div>
              </div>
            </div>
            <input type="submit" className="button small btn-schoolmedia animated-x" style={{ float: 'right' }} value="Simpan Perubahan" />
          </form>
          :
          null
        }
      </>
    )
  }
}

// Redux
const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
  getUserDetail: getUserDetail,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GeneralNI);