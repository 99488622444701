import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Link, NavLink, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import SearchAll from './SearchAll';
import SearchLeftBar from './SearchLeftBar';
import SkeletonList from 'component/partials/skeleton/SkeletonList';

const api = `${process.env.REACT_APP_API}`;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: null,
      loading: false,
      allData: {},
      userData: {},
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.searchReducer.data != state.allData) {
      let e = nextProps.searchReducer.data
      let group = e.reduce((r, a) => {
        r[a.type] = [...r[a.type] || [], a];
        return r;
      }, {});
      return ({
        searchKey: nextProps.searchReducer.searchKey,
        allData: group,
        loading: nextProps.searchReducer.loading,
        userData: nextProps.userReducer.userDetail,
      })
    }
  }
  // _searchResult() {
  //   axios.get(api + `search/get-search?keyword=${this.state.searchKey}&page=1`)
  //     .then(res => {
  //       this._resGroupBy(res.data.data.data)
  //     }).catch(err => {
  //     })
  // }
  // _resGroupBy(e) {
  //   let group = e.reduce((r, a) => {
  //     r[a.type] = [...r[a.type] || [], a];
  //     return r;
  //   }, {});
  //   this.setState({
  //     allData: group
  //   })
  // }
  render() {
    console.log(this.state.allData)
    return (
      <>
        <div style={{ marginTop: '76px' }}>
          <div uk-grid="" className="uk-grid">
            <SearchLeftBar searchKey={this.state.searchKey}/>
            <div className="uk-width-expand@m">
              <ul id="component-tab-right" className="uk-switcher">
                <li className="uk-active bg-white">
                  {this.state.loading ?
                    <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                      <div>
                        <SkeletonList/>
                      </div>
                      <div>
                        <SkeletonList/>
                      </div>
                      <div>
                        <SkeletonList/>
                      </div>
                      <div>
                        <SkeletonList/>
                      </div>
                      <div>
                        <SkeletonList/>
                      </div>
                      <div>
                        <SkeletonList/>
                      </div>
                    </div>
                  :
                    <SearchAll dataResult={this.state.allData} userData={this.state.userData}/>
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    searchReducer: state.searchReducer,
  }
}

export default connect(mapStateToProps)(Search);