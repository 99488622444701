import React from 'react';
import ProfileHeader from './components/ProfileHeader';
import { Link } from "react-router-dom";
import axios from 'axios';
import timeAgo from 'utils/timeAgo';
import ReactPlayer from 'react-player';
import SkeletonSquare from '../../partials/skeleton/SkeletonSquare';

const api = `${process.env.REACT_APP_API}`;
const url = `http://sosmed-backend.schoolmedia.site/media/`;

export default class UserVideos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName : null,
      videoList : [],
      detailVideo : null,
      loadingFeed : true,
      countToScroll: 1,
    }

    // detect bottom scroll
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.setState({countToScroll: this.state.countToScroll + 1}, function(){
          this._getVideo();
        })
      }
    }
  }

  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0]
    this.setState({
      userName: username
    }, function(){
      this._getVideo();
    })
  }

  _getVideo() {
    let jwtToken = localStorage.getItem("jwtToken")
    let requestData = {
      is_album : 0,
      type : 0,
      username : this.state.userName,
      page : {number : this.state.countToScroll},
    };
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + 'media/get-media', requestData, config)
      .then(res => {
        const nextVideoList = res.data.data.data
        if (res.data.data.data === []) {
          this.setState({
            videoList: res.data.data.data,
            loadingFeed: false,
          })
        }
        this.setState({
          videoList: [
            ...this.state.videoList,
            ...nextVideoList
          ],
          loadingFeed: false,
        })
      })
  }

  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner pt-0">
          <ProfileHeader />
          <div className="section-small">
            <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
              <h2>Video</h2>

              <div className="uk-flex uk-flex-between">
                <ul className="uk-tab"
                  uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                  <li className="uk-active"><a href="#">
                    {/* <i className="icon-feather-home mr-2"></i> */}
                        Siaran Tentang Anda</a>
                  </li>
                  <li><a href="#">
                    {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Siaran Anda</a>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="uk-switcher uk-margin" id="component-tab-left">
              <li>
                <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                  <ul className="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid"
                    style={{ borderRadius: "25px", overflow: "hidden" }}
                    uk-lightbox="animation: scale"
                  >
                    {this.state.loadingFeed ? (
                      <div className="col-12">
                        <SkeletonSquare/>
                      </div>
                    ) : (
                      this.state.videoList.length <= 0 ? (
                        <p className="text-sm w-100">Belum Mempunyai Video</p>
                      ): (
                        this.state.videoList.map((val, key) => {
                          return (
                            <li className="col-3">
                              <a href={url+'/'+this.state.userName+'/'+ val.media} data-caption={val.caption} className="video-post w-100">
                                {/* <!-- Blog Post Thumbnail --> */}
                                <div className="video-post-thumbnail">
                                  {/* <span className="video-post-time">23:00</span> */}
                                  <span className="play-btn-trigger"></span>
                                  <ReactPlayer
                                    url={url+'/'+this.state.userName+'/'+ val.media} 
                                    width="100%"
                                    height="140px"
                                    playIcon={<i className="uil-play-circle" style={{fontSize: "4rem"}}></i>}
                                    light="/assets/images/video/black-video.png"
                                  />
                                </div>
    
                                {/* <!-- Blog Post Content --> */}
                                <div className="video-post-content">
                                  <h5> {val.caption}</h5>
                                  <div className="item-statistic font-sm">
                                    <span> <span className="count">{this.state.userName}</span></span>
                                  </div>
                                  <div className="item-statistic font-xs">
                                    {/* <span> <span className="count">2M</span> Tayangan </span>
                                    <span> <span className="count">-</span> </span> */}
                                    <span> {timeAgo(val.created_at)} </span>
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })
                      )
                    )}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}