import React from 'react';
import { Link, NavLink } from "react-router-dom";
import InviteMember from "./InviteMember";

export default class GroupHeader extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      data: ''
    }
  }
  static getDerivedStateFromProps(props, state){
    if(props.dataForum != state.data){
      if(props.dataForum != undefined){
        return({
          data: props.dataForum
        })
      }
    }
  }
  render() {
    return (
      <>
        <div className="group-cover">

          {/* <!--Signle group cover --> */}
          <img src="/assets/images/group/group-cover.jpg" alt="" />

        </div>

        <div className="single-group-details">
          <div className="left-side">
            <div className="single-group-image">
              <a href="#">
                <img src="/assets/images/group/group-avature.jpg" alt="" />
              </a>
            </div>
            <div className="single-group-details-info">
              <h3> {this.state.data.name} </h3>
              <p>
                <p><i className="uil-users-alt"></i> 12k Joined this </p>
              </p>
            </div>
          </div>
          <div className="right-side uk-flex">
            <div className="btn-join mr-2">
              <a href="#" className="button btn-schoolmedia">
                <i className="uil-user-plus"></i> Join
              </a>
            </div>
            <div className="btn-join">
              <InviteMember />
            </div>
          </div>
        </div>

        <div className="nav-single-group" uk-sticky="offset:61;media : @s">
          <nav className="responsive-tab">
            <ul>
              <li ><NavLink to={"/groups/"+this.state.data.slug}>Topik</NavLink></li>
              <li ><NavLink to={`/groups/`+ this.state.data.slug +`/about`}>Tentang</NavLink></li>
              <li ><NavLink to={`/groups/`+ this.state.data.slug +`/member`}>Anggota</NavLink></li>
              <li><NavLink to={`/groups/`+ this.state.data.slug +`/file`}>File</NavLink></li>
              <li><NavLink to={`/groups/`+ this.state.data.slug +`/media`}>Media</NavLink></li>
            </ul>
          </nav>

        </div>
      </>
    )
  }
}
