import React from 'react';
import axios from 'axios';
import RightBar from '../../partials/RightSidebar/RightBar';
import SkeletonNewsList from 'component/partials/skeleton/SkeletonNewsList';

const api = `${process.env.REACT_APP_API}`;

export default class ListApps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listApp: [],
      isLoading: false,
    }
  }
  componentDidMount() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
    };
    this.setState({
      isLoading: true,
    })
    axios.get(api + 'application/show', config)
      .then((res) => {
        this.setState({
          listApp: res.data.data.items,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  renderApp() {
    return (
      this.state.listApp.map((data) => {
        return (
          <div key={data.id}>
            <div className="friend-card app-card pr-2 pl-2 pb-2 pt-2 bg-white mb-3">
              <div className="uk-width-auto">
                <img className="img-rect" src={`/assets/images/logo-apps/${data.image}`} alt="" />
              </div>
              <div className="uk-width-expand">
                <h3 className="font-sm"> {data.name} </h3>
                <p className="font-sm"> {data.information} </p>
                {/* <span> 1week ago </span> */}

                <div className="friend-card-btns">
                  <a href={data.link} target="_blank" className="button btn-schoolmedia small"> Bergabung </a>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="uk-grid-large uk-grid gird-content">
            <div className="uk-width-2-3@m fead-area">
              <h3>Daftar Aplikasi Schoolmedia</h3>
              <div className="uk-child-width-1-2@m uk-grid">
                {this.state.isLoading ?
                  <>
                    <SkeletonNewsList />
                    <SkeletonNewsList />
                    <SkeletonNewsList />
                    <SkeletonNewsList />
                    <SkeletonNewsList />
                    <SkeletonNewsList />
                  </> : this.renderApp()
                }
              </div>
            </div>
            <RightBar />
          </div>
        </div>
      </div>
    )
  }
}