import axios from 'axios';
import { NOTIF_SUCCESS, NOTIF_ERROR } from "./types";

const api = `${process.env.REACT_APP_API}`;

export const getNotif = (isInit) => {
  let reqData = {
    page: 1,
    "page[number]": 1
  };

  if (!isInit) {
    delete reqData['status'];
    reqData['is_update'] = 1;
  }

  let jwtToken = localStorage.getItem("jwtToken")
  let config = {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  }
  return (dispatch) => {
    axios.post(api + 'notification/my-notification', reqData, config)
      .then(res => {
        dispatch({
          type: NOTIF_SUCCESS,
          data: res.data.data.data
        })
      })
      .catch(err => {
        dispatch({
          type: NOTIF_ERROR,
          data: []
        })
      })
  }

}