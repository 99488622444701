// import axios from 'axios';
import jwtDecode from "jwt-decode";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { SET_CURRENT_USER } from "./types";
// import jwt from 'jsonwebtoken';

// export function login(data) {
export const login = (data) => {
    //data is token
    return (dispatch) => {
      // let host = `${process.env.REACT_APP_API_URL}`;
      // return axios.post(host + "v1/getToken", data).then((res) => {
        // const token = res.data.token;
        let jwtToken = localStorage.getItem("jwtToken");
        setAuthorizationToken(jwtToken);
        dispatch(setCurrentUser(jwtDecode(jwtToken)));
      // });
    }
  };

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}