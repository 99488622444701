import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PostComments from '../global/PostComments';
import axios from 'axios';
import timeAgo from 'utils/timeAgo';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "react-router-dom";

const api = `${process.env.REACT_APP_API}`;

export default class DetailPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataMedia: [],
      loadingFeed: true,
      modalDetail: true,
      passComment: false,
      dataComments: [],
      commentLength: [],

      liked: false,
      userData: null,
      totalLikes: null,
    }
    this.getFeed = this.getFeed.bind(this)
    this.getComment = this.getComment.bind(this)
    this.detailClose = this.detailClose.bind(this);
    this.handleLike = this.handleLike.bind(this);
  }
  componentDidMount(){
    this.getFeed()
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (state.data != undefined) {
      return ({
        data: state.data,
        userData: state.userData,
        liked: (state.data.is_like === 0 ? false : true),
        totalLikes: (state.data.is_like === 0 ? null : state.data.is_like),
      })
    } else {
      return null;
    }
  }
  getFeed(){
    let getToken = localStorage.getItem("jwtToken");
    let requestData = {
      type: "all",
      page: 1,
      'page[number]' : 1,
      id: this.props.match.params.id
    };
    let config = {
      headers: {
        Authorization: "Bearer " + getToken,
      },
    }
    axios.post(api + 'feed/show-feed', requestData, config)
    .then(res => {
      this.getComment();
      if(res.data.data.item.medias != null){
        var arrayMedia = res.data.data.item.medias.replace('[', '').replace(']', '').replace(/["']/g, "");
        var dataMedia = arrayMedia.split(',');
        this.setState({
          data: res.data.data.item,
          dataMedia: dataMedia,
          loadingFeed: false,
        });
      } else {
        this.setState({loadingFeed: false});
      }
    })
  }
  async getComment() {
    let requestData = {
      dest_id: this.props.match.params.id,
      type: 1,
    };
    let getToken;

    try {
      getToken = await localStorage.getItem("jwtToken");
    } catch {
    }

    if (getToken) {
      let config = {
        headers: {
          Authorization: "Bearer " + getToken,
        },
      }
      axios.post(api + 'comment/get-comment',
        requestData,
        config
      ).then(res => {
        this.setState({
          dataComments: res.data.data.items,
          commentLength: res.data.data.items,
          passComment: true,
        })
      })
    }
  }
  delete() {
    axios.post(api + 'feed/delete-feed', {
      feed_id: this.state.data.id,
    }).then(res => {
      this.props.getFeed(this.state.data.id)
    }).catch(err => {

    })
  }
  detailClose(){
    this.props.history.goBack()
  }
  handleLike() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    this.setState({ liked: !this.state.liked }, function(){
      if(this.state.liked) {
        var totalLike = this.state.totalLikes + 1;
      } else {
        var totalLike = this.state.totalLikes - 1;
      }
      this.setState({ totalLikes: totalLike });
    })
    axios.post(api + 'like/post-like', {
      dest_id: this.state.data.id,
      type: 1,
    }, config)
      .then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
        this.setState({
          like: !this.state.like,
        })
      })
  }
  postHeaderComponent() {
    let time = "-";
    if (this.state.data.created_at != null) {
      time = timeAgo(this.state.data.created_at)
    }
    const statusIcon = () => {
      if(this.state.data.status == 1) {
        return (<i className="uil-globe"></i>);
      } else if (this.state.data.status == 2){
        return (<i className="uil-users-alt"></i>);
      } else if (this.state.data.status == 0){
        return (<i className="uil-lock"></i>);
      }
    }
    return (

      <div className="post-heading">
        <Link to={'/'+this.state.data.username}>
          <div className="post-avature">
            {this.state.data.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data.group_code}/photo/${this.state.data.photo_profile}`} alt="" /> :
              <img src='/assets/icons/user.svg' />
            }
          </div>
        </Link>
        <div className="post-title">
          <Link to={'/'+this.state.data.username}>
            <h4> {this.state.data.name} </h4>
          </Link>
          <p> {time} {statusIcon()} </p>
        </div>
        <div className="post-btn-action">
          <span className="icon-more uil-ellipsis-h" aria-expanded="false"></span>
          <div className="mt-0 p-2 uk-dropdown" uk-dropdown="pos: bottom-right;mode:hover">
            {this.state.data.username ?
              <>
              <ul className="uk-nav uk-dropdown-nav">
                <li><a href="#"> <i className="uil-share-alt mr-1"></i> Share </a></li>
                <li><a href="#"> <i className="uil-edit-alt mr-1"></i> Edit Post </a></li>
                <li><a href="#"> <i className="uil-comment-slash mr-1"></i> Disable comments </a></li>
                <li><a href="#"> <i className="uil-favorite mr-1"></i> Add favorites </a></li>
                <li><a onMouseDown={e => {e.stopPropagation()}} onClick={this.delete} className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
              </ul>
            </> :
              <>
                <ul className="uk-nav uk-dropdown-nav">
                  <li><a href="#"> <i className="uil-share-alt mr-1"></i> Share </a></li>
                  <li><a href="#"> <i className="uil-favorite mr-1"></i> Add favorites </a></li>
                </ul>
              </>
            }
          </div>
        </div>
      </div>

    )
  }
  
  friendTagList() {
    var friendName = this.state.data.name_friend_tags;
    var friendUsername = this.state.data.username_friend_tags;
    if(friendUsername != null) {
      var dataNameTag = friendName.replace('[', '').replace(']', '').replace(/["']/g, "");
      var dataUsernameTag = friendUsername.replace('[', '').replace(']', '').replace(/["']/g, "");
      dataNameTag = dataNameTag.split(',');
      dataUsernameTag = dataUsernameTag.split(',');
      var rowLen = dataUsernameTag.length;
      return( 
        <div className="mb-0" style={{fontSize: "2px !important"}}> 
          Bersama : 
          {dataUsernameTag.map((val, key) => (
            <span className="ml-1">
              <Link to={"/"+val}>
                <p>{dataNameTag[key]}</p>
              </Link>
              {key + 1 != rowLen ? (",") : ("")}
            </span>
          ))}
        </div>
      )
    }
  }
  
  render() {
    console.log(this.props)
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.modalDetail}
          onClose={this.detailClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalDetail}>
            <>
              <button className="uk-modal-close- uk-close-large uk-icon text-white uk-close" style={{right: "unset", left: '10px'}} type="button" onClick={this.detailClose}>
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close-large">
                  <line fill="none" stroke="#000" stroke-width="1.4" x1="1" y1="1" x2="19" y2="19"></line>
                  <line fill="none" stroke="#000" stroke-width="1.4" x1="19" y1="1" x2="1" y2="19"></line>
                </svg>
              </button>
              <div className="bg-white modal-xxl uk-grid-collapse uk-grid" style={{ height: '100%'}} >
                <div className="uk-width-expand@s h-full">
                  <div className="uk-position-relative uk-visible-toggle uk-light h-full" tabindex="-1" data-uk-slideshow={"finite: true"}>
                    <ul className="uk-slideshow-items h-full" uk-height-viewport="offset-top: true; offset-bottom: 30">
                      {this.state.loadingFeed ? (
                        <Skeleton animation="wave" height="100%" width="100%" />
                      ) : (
                        this.state.dataMedia.length > 1 ? (
                          this.state.dataMedia.map((val, key) => (
                            <li style={{ backgroundImage: `url(${val})` }}>
                              <div className="dark-backdrop uk-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <img src={val} alt="" uk-cover style={{ maxWidth: '100%', maxHeight: '100%' }} />
                              </div>
                            </li>
                          ))
                        ) : (
                          <li style={{ backgroundImage: `url(${this.state.dataMedia[0]})` }}>
                            <div className="dark-backdrop uk-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <img src={this.state.dataMedia[0]} alt="" uk-cover style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                    <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
                      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
                    </div>
                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item={"previous"}></a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item={"next"}></a>
                  </div>
                </div>
                <div className="uk-width-1-3@s" style={{ height: '100%', overflowY: "scroll"}}>
                  <div className="post post-modal" style={{ height: '100%'}}>
                    {this.postHeaderComponent()}
                    <div className="post-description" style={{width: "100%"}}>
                      <div className="post-state-details">
                        <div>
                          <small className="text-xs mb-0">{this.friendTagList()} </small>
                          <p>{this.state.data.content}</p>
                        </div>
                      </div>
                      <div className="post-state-details">
                        <div>
                          <p>{this.state.liked ? (
                                this.state.totalLikes != null? `${this.state.totalLikes} menyukai` : ''
                              ) : (null)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Like, Coments, Shared */}
                    <div className="post-state">
                      <div className="post-state-btns"
                        style={{ color: `${this.state.liked ? "#007bff" : ""}` }}
                        onClick={e => { this.handleLike() }}
                      >
                        <i className="uil-thumbs-up"></i>
                        <span>
                          {this.state.liked ? "Disukai" : "Suka"}
                        </span>
                      </div>
                      <div className="post-state-btns">
                        <i className="uil-heart"></i> {this.state.commentLength.length} <span>Komentar </span>
                      </div>
                      {/* <div className="post-state-btns">
                        <i className="uil-share-alt"></i> 193 <span> Shared </span>
                      </div> */}
                    </div>
                    {this.state.passComment ? (
                      <PostComments 
                        commentData={this.state.dataComments} 
                        postData={this.state.data} 
                        userName={this.state.data.username}
                        key={this.state.data.id} 
                        numberToShow={10} 
                        type={1}
                      />
                    ) : null }
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
      </>
    )
  }
}
