import { GET_LIST_TOPIC, GET_LIST_TOPIC_ERR, GET_LIST_TOPIC_SUCCESS } from "redux/actions/types";

// Global State
const globalState = {
  listTopic: [],
  loading: false,
}

// Reducer
const topicReducer = (state = globalState, action) => {
  if(action.type === GET_LIST_TOPIC){
    return{
      ...state,
      listTopic: [],
      loading: true,
    }
  } else if(action.type === GET_LIST_TOPIC_SUCCESS){
    return{
      ...state,
      listTopic: action.data,
      loading: false,
    }
  } else if(action.type === GET_LIST_TOPIC_ERR){
    return{
      ...state,
      listTopic: action.data,
      loading: false,
    }
  }
  return state;
}

export default topicReducer;