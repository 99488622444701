import React from 'react';
import ProfileHeader from './components/ProfileHeader';
import axios from 'axios';
import timeAgo from 'utils/timeAgo';
import SkeletonPost from '../../partials/skeleton/SkeletonPost';
import { Link } from "react-router-dom";

const api = `${process.env.REACT_APP_API}`;

export default class UserNotes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: null,
      noteList: [],
      loadingFeedNotes: true,
      countToScroll: 1,
    }

    // detect bottom scroll
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.setState({countToScroll: this.state.countToScroll + 1}, function(){
          this._getNote();
        })
      }
    }
  }

  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0]
    this.setState({
      userName: username
    }, function(){
      this._getNote();
    })
  }

  _getNote() {
    let jwtToken = localStorage.getItem("jwtToken")
    let requestData = {
      type : "me",
      page : {number : this.state.countToScroll}
    };
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + 'note/get-note', requestData, config)
      .then(res => {
        const nextNoteList = res.data.data.data
        if (res.data.data.data === []) {
          this.setState({
            noteList: res.data.data.data,
            loadingFeedNotes: false,
          })
        }
        this.setState({
          noteList: [
            ...this.state.noteList,
            ...nextNoteList
          ],
          loadingFeedNotes: false,
        })
      })
  }

  statusIcon(status) {
    if(status == 1) {
      return (<i className="uil-globe"></i>);
    } else if (status == 2){
      return (<i className="uil-users-alt"></i>);
    } else if (status == 0){
      return (<i className="uil-lock"></i>);
    }
  }

  render() {
    return (
      <div className="main_content">

        <div className="main_content_inner pt-0">
          <ProfileHeader />
          <div className="section-small">
            <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
              <div className="uk-flex uk-flex-between">
                <h2>Catatan</h2>
                <div className="uk-flex uk-flex-column">
                  <button className="button small btn-schoolmedia">Tambahkan Catatan</button>
                </div>
              </div>

              <div className="uk-flex uk-flex-between">
                <ul className="uk-tab"
                  uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                  <li className="uk-active uk-flex uk-flex-row">
                    <a href="#" className="uk-flex uk-flex-row">
                      {/* <i className="icon-feather-home mr-2"></i> */}
                                Catatan Tentang Anda
                                {/* <p style={{fontSize: '.7rem'}}>235 Orang</p> */}
                    </a>
                  </li>
                  <li><a href="#">
                    {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Konsep Anda</a>
                  </li>
                  <li><a href="#">
                    {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Catatan Anda</a>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="uk-switcher uk-margin" id="component-tab-left">
              <li>
                <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                  {this.state.loadingFeedNotes ? (
                    <div className="row w-100 col-12">
                      <div className="col-6">
                        <SkeletonPost/>
                      </div>
                      <div className="col-6">
                        <SkeletonPost/>
                      </div>
                    </div>
                  ) : ("")}
                  <ul className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid">
                    {this.state.noteList.map((val, key) => {
                      return (
                        <li>
                          <div className="post">
                            <div className="post-heading">
                              <Link to={'/'+val.username}>
                                <div className="post-avature">
                                  {val.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${val.group_code}/photo/${val.photo_profile}`} alt="" /> :
                                    <img src='/assets/icons/user.svg' />
                                  }
                                </div>
                              </Link>
                              <div className="post-title">
                                <Link to={'/'+val.username}>
                                  <h4>{val.username}</h4>
                                </Link>
                                <p className="text-xs"><span> {timeAgo(val.created_at)} </span> {this.statusIcon(val.status)} </p>
                              </div>
                              <div className="post-btn-action">
                                <span className="icon-more uil-ellipsis-h"></span>
                                <div className="mt-0 p-2" uk-dropdown="pos: bottom-right;mode:hover">
                                  <ul className="uk-nav uk-dropdown-nav">
                                    <li><a href="#" className="text-red"> <i className="uil-trash-alt mr-1"></i> Delete </a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="post-description">
                              <div className="card uk-padding" dangerouslySetInnerHTML={{ __html: val.content }}>
                              </div>
                            </div>

                            <div className="post-state">
                              <div className="post-state-btns">
                                <i className="uil-eye"></i><span> Lihat Catatan </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}