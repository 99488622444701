import React from 'react';

export default class NewsDetail extends React.Component {
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="uk-width-4-4@m uk-padding m-auto bg-white rounded">
            <div className="mt-lg-4 uk-grid" uk-grid>
              <div className="uk-width-2-3@m">
                <h1>Developing a Wordpress Theme from Scratch</h1>

                <div className="user-details-card pt-0">
                  <div className="user-details-card-avatar" style={{ maxWidth: "40px" }}>
                    <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                  </div>
                  <div className="user-details-card-name">
                    Stella Johnson <span> Developer <span> April 2nd, 2020 </span> </span>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@m text-right">
                <img src="/assets/images/brand/brand-avatar-5.png" className="rounded" alt="" />
              </div>
            </div>

            <a href="#" className="button btn-default small"> Css </a>
            <a href="#" className="button btn-default small"> Design</a>

            <div className="blog-content mt-3 mt-lg-6">
              <p> You know HTML, CSS, and JavaScript. You can make beautiful websites. Maybe you've heard
              about WordPress, but aren't entirely sure how to implement it, or why you might need it.
              Maybe a client asked for WordPress, but you're not really familiar with it. Maybe you've
              worked with it before, but don't know how to make your own theme from scratch. Whatever the
              case, this article is for you.
                        </p>
              <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
              tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
              Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod
              mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                        </p>
              <p> In ut odio libero, at vulputate urna. Nulla tristique mi a massa convallis cursus. Nulla eu
              mi magna. Etiam suscipit commodo gravida. Cras suscipit, quam vitae adipiscing faucibus,
              risus nibh laoreet odio, a porttitor metus eros ut enim. Morbi augue velit,
                        </p>
              <h2> Maecenas pretium lorem fermentum</h2>
              <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
              tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
              Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming
                        </p>
              <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
              tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
              Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod
              mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                        </p>
              <p> In ut odio libero, at vulputate urna. Nulla tristique mi a massa convallis cursus. Nulla eu
              mi magna. Etiam suscipit commodo gravida. Cras suscipit, quam vitae adipiscing faucibus,
              risus nibh laoreet odio, a porttitor metus eros ut enim. Morbi augue velit,
                        </p>
            </div>

            <br />
            <br />

            <div className="comments mt-4">
              <h3>Comments
                            <span className="comments-amount">5210</span>
              </h3>

              <ul>
                <li>
                  <div className="comments-avatar">
                    <img src="/assets/images//avatars/avatar-1.jpg" alt="" />
                  </div>
                  <div className="comment-content">
                    <div className="comment-by">Jonathan Madano <span>Student</span>
                      <a href="#" className="reply"><i className="icon-line-awesome-undo"></i> Reply</a>
                    </div>
                    <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                    euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                    minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                    aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis eleifend
                    option
                    congue
                                    </p>
                  </div>

                  <ul>
                    <li>
                      <div className="comments-avatar">
                        <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="comment-content">
                        <div className="comment-by">Stella Johnson<span>Student</span>
                          <a href="#" className="reply">
                            <i className="icon-line-awesome-undo"></i>
                                                    Reply
                                                </a>
                        </div>
                        <p> Nam liber tempor cum soluta nobis eleifend option congue ut laoreet
                        dolore
                        magna aliquam erat volutpat nihil imperdiet doming id quod mazim
                        placerat
                        facer possim assum. Lorem ipsum dolor sit amet
                                            </p>
                      </div>
                    </li>
                    <li>
                      <div className="comments-avatar">
                        <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
                      </div>
                      <div className="comment-content">
                        <div className="comment-by">Adrian Mohani <span>Student</span>
                          <a href="#" className="reply">
                            <i className="icon-line-awesome-undo"></i>
                                                    Reply
                                                </a>
                        </div>
                        <p>tempor cum soluta nobis eleifend option congue ut laoreet dolore magna
                        aliquam erat volutpat.
                                            </p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="comments-avatar">
                    <img src="/assets/images/avatars/avatar-4.jpg" alt="" />
                  </div>
                  <div className="comment-content">
                    <div className="comment-by">
                      Alex Dolgove<span>Student</span>
                      <a href="#" className="reply">
                        <i className="icon-line-awesome-undo"></i> Reply
                                        </a>
                    </div>
                    <p>Nam liber tempor cum soluta nobis eleifend option congue ut laoreet dolore magna
                    aliquam erat volutpat nihil imperdiet doming id quod mazim placerat facer possim
                    assum. Lorem ipsum dolor sit amet
                                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <hr />
            <div className="comments">
              <h3>Add Comment </h3>
              <ul>
                <li>
                  <div className="comments-avatar"><img src="..//assets/images/avatars/avatar-2.jpg" alt="" />
                  </div>
                  <div className="comment-content">
                    <form className="uk-grid-small uk-grid" uk-grid="">
                      <div className="uk-width-1-2@s uk-first-column">
                        <label className="uk-form-label">Name</label>
                        <input className="uk-input" type="text" placeholder="Name" />
                      </div>
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Email</label>
                        <input className="uk-input" type="text" placeholder="Email" />
                      </div>
                      <div className="uk-width-1-1@s uk-grid-margin uk-first-column">
                        <label className="uk-form-label">Comment</label>
                        <textarea className="uk-textarea" placeholder="Enter Your Comments her..."
                          style={{ height: "160px" }}></textarea>
                      </div>
                      <div className="uk-grid-margin uk-first-column">
                        <input type="submit" value="submit" className="button btn-schoolmedia" />
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}