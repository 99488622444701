import React from "react";
import { Link } from "react-router-dom";
// material ui
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PostComments from "../global/PostComments";
import axios from "axios";
import timeAgo from "utils/timeAgo";
import { connect } from "react-redux";
import EditFeed from "./EditFeed";
import ReactPlayer from "react-player";
import parse from "html-react-parser";

const api = `${process.env.REACT_APP_API}`;

let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
  },
};

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      userName: null,
      data: {},
      medias: [],

      passComment: false,
      dataComments: [],
      commentLength: [],
      modalDetail: false,
      modalEdit: false,

      liked: false,
      totalLikes: null,
    };
    this.detailOpen = this.detailOpen.bind(this);
    this.detailClose = this.detailClose.bind(this);
    this.openEdit = this.openEdit.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.getJokes = this.getJokes.bind(this);
    this.renderMedia = this.renderMedia.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.delete = this.delete.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data != state.data) {
      if (nextProps.data != undefined) {
        console.log(nextProps);
        return {
          data: nextProps.data,
          medias: nextProps.data.medias,
          userId: nextProps.userReducer.userDetail.id,
          userName: nextProps.userReducer.userDetail.username,
          liked: nextProps.data.is_like === 0 ? false : true,
          totalLikes: nextProps.data.total_likes,
        };
      } else {
        return null;
      }
    }
  }

  componentDidMount() {
    this.getJokes();
  }

  async getJokes() {
    let requestData = {
      dest_id: this.state.data.id,
      type: 1,
    };
    let getToken;

    try {
      getToken = await localStorage.getItem("jwtToken");
    } catch {}

    if (getToken) {
      let config = {
        headers: {
          Authorization: "Bearer " + getToken,
        },
      };
      axios
        .post(api + "comment/get-comment", requestData, config)
        .then((res) => {
          this.setState({
            passComment: true,
            dataComments: res.data.data.items,
            commentLength: res.data.data.items,
          });
        });
    }
  }

  handleLike() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    this.setState({ liked: !this.state.liked }, function () {
      if (this.state.liked) {
        var totalLike = this.state.totalLikes + 1;
      } else {
        var totalLike = this.state.totalLikes - 1;
      }
      this.setState({ totalLikes: totalLike });
    });
    axios
      .post(
        api + "like/post-like",
        {
          dest_id: this.state.data.id,
          type: 1,
        },
        config
      )
      .then((res) => {
        //
      })
      .catch((err) => {
        this.setState({
          liked: !this.state.liked,
        });
      });
  }

  detailOpen() {
    this.setState({ modalDetail: true });
  }
  detailClose() {
    this.setState({ modalDetail: false });
  }

  openEdit() {
    this.setState({ modalEdit: true });
  }
  closeEdit() {
    this.setState({ modalEdit: false });
  }

  delete() {
    axios
      .post(api + "feed/destroy-feed", {
        feed_id: this.state.data.id,
      })
      .then((res) => {
        this.props.getFeed(this.state.data.id);
      })
      .catch((err) => {});
  }

  filterMedia(e, type) {
    if (e.endsWith(".mp4") || e.endsWith(".MP4")) {
      return (
        <video controls>
          <source src={e} />
        </video>
      );
    } else {
      return (
        <img
          src={e}
          alt=""
          style={
            type == "album"
              ? {
                  width: "100%",
                  height: "250px",
                  maxHeight: "250px",
                  objectFit: "none",
                  objectPosition: "center",
                }
              : {
                  width: "100%",
                }
          }
        />
      );
    }
  }

  renderMedia() {
    let medias = JSON.parse(this.state.medias);
    // console.log(medias[0].endsWith(".mp4"))
    if (medias !== null) {
      // let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`

      const url = "";
      if (medias.length === 1) {
        return (
          <div className="fullsizeimg" uk-lightbox="animation: slide">
            {medias.map((val, key) => {
              let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`;
              return (
                <a
                  className="uk-inline"
                  href={val}
                  style={{ marginTop: "1rem", width: "100%" }}
                >
                  {this.filterMedia(val, "single")}
                </a>
              );
            })}
          </div>
        );
      } else if (medias.length === 2) {
        return (
          <div
            className="uk-grid-small uk-grid"
            uk-grid=""
            uk-lightbox="animation: slide"
          >
            {medias.map((val, key) => {
              let url = `http://sosmed-backend.schoolmedia.site/media/${val.username}/`;
              return (
                <div
                  key={key}
                  className="uk-width-1-2@m uk-width-1-2 uk-grid-margin uk-first-column"
                >
                  <a className="uk-inline" href={val}>
                    {this.filterMedia(val, "album")}
                  </a>
                </div>
              );
            })}
          </div>
        );
      } else {
        const more = medias.length - 3;

        return (
          <div
            className="uk-grid-small uk-grid"
            uk-grid=""
            uk-lightbox="animation: slide"
          >
            {medias.slice(0, 1).map((val, key) => (
              <div className="uk-width-1-1@m uk-first-column" key={key}>
                <a className="uk-inline" href={val}>
                  {this.filterMedia(val, "album")}
                </a>
              </div>
            ))}
            {medias.slice(1, 2).map((val, key) => (
              <div
                className="uk-width-1-2@m uk-width-1-2 uk-grid-margin uk-first-column"
                key={key}
              >
                <a className="uk-inline" href={val}>
                  {this.filterMedia(val, "album")}
                </a>
              </div>
            ))}
            {medias.slice(2, 3).map((val, key) => (
              <div
                className="uk-width-1-2@m uk-width-1-2 uk-position-relative uk-grid-margin"
                key={key}
              >
                <Link
                  to={
                    `/${this.state.data.username}/posts/` + this.state.data.id
                  }
                >
                  {this.filterMedia(val, "album")}
                </Link>
                {medias[medias.length - 1] ? (
                  <div className="uk-position-center uk-light">
                    {more <= 0 ? null : (
                      <a>
                        <h3> + {more} lainnya </h3>
                      </a>
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        );
      }
    }
  }

  // components
  postHeaderComponent() {
    let time = "-";
    if (this.state.data.created_at != null) {
      time = timeAgo(this.state.data.created_at);
    }

    const dropDownSame = () => {
      return (
        <>
          <ul className="uk-nav uk-dropdown-nav">
            {/* <li><a href="#"> <i className="uil-share-alt mr-1"></i> Share </a></li> */}
            {/* <li><a onClick={this.openEdit}> <i className="uil-edit-alt mr-1"></i> Edit Post </a></li> */}
            {/* <li><a href="#"> <i className="uil-comment-slash mr-1"></i> Disable Comments </a></li> */}
            {/* <li><a href="#"> <i className="uil-favorite mr-1"></i> Add favorites </a></li> */}
            <li>
              <a
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={this.delete}
                className="text-red"
              >
                {" "}
                <i className="uil-trash-alt mr-1"></i> Delete{" "}
              </a>
            </li>
          </ul>
        </>
      );
    };
    const statusIcon = () => {
      if (this.state.data.status == 1) {
        return <i className="uil-globe"></i>;
      } else if (this.state.data.status == 2) {
        return <i className="uil-users-alt"></i>;
      } else if (this.state.data.status == 0) {
        return <i className="uil-lock"></i>;
      }
    };
    return (
      <>
        <div className="post-heading">
          <Link to={"/" + this.state.data.username}>
            <div className="post-avature">
              {this.state.data?.photo_profile === null ||
              this.state.data?.photo_profile === "null" ? (
                <img src="/assets/icons/user.svg" />
              ) : (
                <img
                  src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data.group_code}/photo/${this.state.data.photo_profile}`}
                  alt=""
                />
              )}
            </div>
          </Link>
          <div className="post-title">
            <Link to={"/" + this.state.data.username}>
              <h4>
                {this.state.data.name}
                {this.state.data.feeling_name != null
                  ? ` merasa ${this.state.data.feeling_name}`
                  : ""}
              </h4>
            </Link>
            <p>
              {" "}
              {time} {statusIcon()}{" "}
            </p>
          </div>
          <div className="post-btn-action">
            <span
              className="icon-more uil-ellipsis-h"
              aria-expanded="false"
            ></span>
            <div
              className="mt-0 p-2 uk-dropdown"
              uk-dropdown="pos: bottom-right;mode:hover"
            >
              {this.state.data.username === this.state.userName ? (
                dropDownSame()
              ) : (
                <>
                  <ul className="uk-nav uk-dropdown-nav">
                    <li>
                      <a href="#">
                        {" "}
                        <i className="uil-share-alt mr-1"></i> Share{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i className="uil-favorite mr-1"></i> Add favorites{" "}
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  friendTagList() {
    var friendName = this.state.data.name_friend_tags;
    var friendUsername = this.state.data.username_friend_tags;
    if (friendUsername != null) {
      var dataNameTag = friendName
        .replace("[", "")
        .replace("]", "")
        .replace(/["']/g, "");
      var dataUsernameTag = friendUsername
        .replace("[", "")
        .replace("]", "")
        .replace(/["']/g, "");
      dataNameTag = dataNameTag.split(",");
      dataUsernameTag = dataUsernameTag.split(",");
      var rowLen = dataUsernameTag.length;
      return (
        <div className="mb-0" style={{ fontSize: "2px !important" }}>
          Bersama :
          {dataUsernameTag.map((val, key) => (
            <span className="ml-1">
              <Link to={"/" + val}>
                <p>{dataNameTag[key]}</p>
              </Link>
              {key + 1 != rowLen ? "," : ""}
            </span>
          ))}
        </div>
      );
    }
  }

  // Like
  totalLike() {
    if (this.state.liked == true) {
      return "Anda";
    }
  }

  render() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.modalEdit}
          onClose={this.closeEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalEdit}>
            <>
              {/* <button className="uk-modal-close- uk-close-large uk-icon text-white uk-close" type="button" onClick={this.detailClose}>
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close-large">
                  <line fill="none" stroke="#000" stroke-width="1.4" x1="1" y1="1" x2="19" y2="19"></line>
                  <line fill="none" stroke="#000" stroke-width="1.4" x1="19" y1="1" x2="1" y2="19"></line>
                </svg>
              </button> */}
              <div
                className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-grid"
                style={{ maxWidth: "510px" }}
              >
                <div className=" uk-width-1-1">
                  <h2 className="uk-modal-title text-center">Edit Postingan</h2>
                  <span
                    className="uk-modal-close-default"
                    style={{ fontSize: "20px", cursor: "pointer", zIndex: 11 }}
                    onClick={this.hideModalCreatePost}
                  >
                    <i className="icon-feather-x"></i>
                  </span>
                  <hr />
                  <EditFeed
                    postId={this.state.data.id}
                    postData={this.state.data}
                  />
                </div>
              </div>
            </>
          </Fade>
        </Modal>

        <div className="post">
          {this.postHeaderComponent()}

          <div className="post-description" style={{ width: "100%" }}>
            <small className="text-xs mb-0">{this.friendTagList()} </small>
            {/* {this.state.data.content} */}
            {parse(this.state.data.content)}

            {this.renderMedia()}

            <div className="post-state-details">
              <div>
                {/* <img src="/assets/images/icons/reactions_like.png" alt=""></img>
                <img src="/assets/images/icons/reactions_love.png" alt=""></img> */}
                <p>
                  {" "}
                  {this.state.totalLikes > 0
                    ? `${this.state.totalLikes} menyukai`
                    : ""}
                </p>
              </div>
              {/* <p> {this.state.commentLength.length} Komentar</p> */}
            </div>
          </div>

          {/* Like, Coments, Shared */}
          <div className="post-state">
            <div
              className="post-state-btns"
              style={{ color: `${this.state.liked ? "#007bff" : ""}` }}
              onClick={(e) => {
                this.handleLike();
              }}
            >
              <i className="uil-thumbs-up"></i>
              <span>{this.state.liked ? "Disukai" : "Suka"}</span>
            </div>
            <div className="post-state-btns">
              <i className="uil-heart"></i> {this.state.commentLength.length}{" "}
              <span>Komentar </span>
            </div>
            {/* <div className="post-state-btns">
              <i className="uil-share-alt"></i> 193 <span> Shared </span>
            </div> */}
          </div>

          {this.state.passComment ? (
            <PostComments
              commentData={this.state.dataComments}
              userName={this.state.userName}
              postData={this.state.data}
              key={this.state.data.id}
              numberToShow={3}
              type={1}
              commentType="feed"
            />
          ) : null}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    notifReducer: state.notifReducer,
  };
};
export default connect(mapStateToProps)(Post);
