import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import Fade from '@material-ui/core/Fade';
import Dropzone from 'react-dropzone';
import Modal from '@material-ui/core/Modal';
import Slider from "react-slick";
import { useSpring, animated } from 'react-spring/web.cjs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AsyncPaginate } from "react-select-async-paginate";
import { friendOptions, getFriend } from "./friendOptions";
import { Link, Switch } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import axios from 'axios';
import _, { map, pluck } from 'underscore';
import isEmpty from "lodash/isEmpty";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const api = `${process.env.REACT_APP_API}`;

const apiKey = 'pk.eyJ1IjoiMHgwMGIwIiwiYSI6ImNraXNhcjJxaTBhM3ozMG1wbXEwZ3F2bjEifQ.nwoYdiHXuJEUjrZ33OXPbw';

const ModalAnm = React.forwardRef(function ModalAnm(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { transform: 'scale(0.5)', opacity: 0 },
    to: { transform: open ? 'scale(1)' : 'scale(0.9)', opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  })
  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

ModalAnm.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

let location = [];
let arrCoords = [];

class CreatePostPop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postType: 'feed',
      userData: [],
      groupCode: null,
      profilePhoto: null,
      namaUser: null,

      handleFromProps: null,
      modalCreatePost: false,
      modalPic: false,
      modalFriend: false,
      stackModal: false,

      // default select status
      statusSelected: 1,

      contentToUpload: null,

      files: [],
      maxUpFile: false,

      searchResult: null,
      friendTagSelected: [],
      friendSelectedMore: null,
      friendSelectedValue: null,

      feelingList: [],
      feelingSelected: null,
      feelingSelectedName: null,
      locationSelected: null,

      disableSendBtn: true,
      geolocation: 'jakarta', //set capital city kuvukiland
      coords: [],

      topicData: ''
    }
    this.onDrop = (files) => {
      this.setState({ files })
    };

    // modal
    this.openModalCreatePost = this.openModalCreatePost.bind(this);
    this.hideModalCreatePost = this.hideModalCreatePost.bind(this);

    this.openModalPic = this.openModalPic.bind(this);
    this.hideModalPic = this.hideModalPic.bind(this);

    this.openModalFriend = this.openModalFriend.bind(this);
    this.hideModalFriend = this.hideModalFriend.bind(this);

    this.contentChange = this.contentChange.bind(this);

    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.deleteSelectedFiles = this.deleteSelectedFiles.bind(this);

    this.handleSearchFriend = this.handleSearchFriend.bind(this);
    this.handleFriendTagSelect = this.handleFriendTagSelect.bind(this);

    this.handleFeelingSelect = this.handleFeelingSelect.bind(this);

    this.locationSelected = this.locationSelected.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);

    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadThread = this.handleUploadThread.bind(this);
    // this.searchGeolocation = this.searchGeolocation.bind(this);
    this.getGeolocation = this.getGeolocation.bind(this);
    this.getAllFeelings = this.getAllFeelings.bind(this);
  }

  fileObj = []
  fileArray = []
  filesBinary = []
  contentToUp = []

  componentDidMount() {
    this.getGeolocation();
    this.getAllFeelings();
  }

  getGeolocation(e) {
    if (!isEmpty(e)) {
      let val = e.target.value;
      this.setState({ geolocation: val });
    }
    axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + this.state.geolocation + '.json?access_token=' + apiKey)
      .then((response) => {
        location = _.pluck(response.data.features, 'text');
        let arr = [];
        location = _.map(location, function (x) {
          arr.push({ geo: x });
        });
        location = arr;

        let coords = _.pluck(response.data.features, 'geometry');
        coords = _.map(coords, function (x) {
          // arr.push({geo:x});
          arrCoords.push(x.coordinates);
        });
      })
      .catch((error) => {
      });
  }

  getAllFeelings(e) {

    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.get(api + 'feel/all-feel', config)
      .then(res => {
        if (res != null) {
          this.setState({
            feelingList: res.data.data.items
          })
        }
      })
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data !== state.handleFromProps) {
      if (nextProps.userReducer.userDetail?.group_code === "NI") {
        return {
          postType: nextProps?.postType,
          namaUser: nextProps.userReducer.userDetail.nama_institusi,
          userData: nextProps.userReducer.userDetail,
          groupCode: nextProps.userReducer.userDetail?.group_code,
          profilePhoto: nextProps.userReducer.userDetail?.logo,
          topicData: nextProps.topicData
        }
      } else {
        return {
          postType: nextProps?.postType,
          namaUser: nextProps.userReducer.userDetail?.nama,
          userData: nextProps.userReducer.userDetail,
          groupCode: nextProps.userReducer.userDetail?.group_code,
          profilePhoto: nextProps.userReducer.userDetail?.foto_profil,
          topicData: nextProps.topicData
        }
      }
    }
  }


  openModalCreatePost() {
    this.setState({
      modalCreatePost: true,
    })
    if (this.filesBinary.length === 0 && this.contentToUp.length === 0) {
      this.setState({
        disableSendBtn: true,
      })
    }
  }
  hideModalCreatePost() {
    if (this.state.stackModal != true) {
      this.setState({
        modalCreatePost: false,
        contentToUpload: null,
      })
      this.contentToUp = []
    }
  }

  openModalPic() {
    this.setState({
      modalPic: true,
    })
  }
  hideModalPic() {
    this.setState({
      modalPic: false,
      modalCreatePost: true,
    })
  }
  openModalFriend() {
    let userName = this.state.userData.username
    getFriend(userName)
    this.setState({
      modalFriend: true,
    })
  }
  hideModalFriend() {
    this.setState({
      modalFriend: false,
      modalCreatePost: true,
    })
  }

  handleSelectStatus(e) {
    if (e) {
      this.setState({
        statusSelected: e.target.value
      })
    }
  }

  contentChange(e) {
    let content = draftToMarkdown(convertToRaw(e.getCurrentContent()))
    if (content.trim().length != 0 && this.filesBinary.length >= 0) {
      this.contentToUp.push(content)
      this.setState({
        contentToUpload: content,
        disableSendBtn: false,
      })
    }

    if (content.trim().length === 0 && this.filesBinary.length === 0) {
      this.contentToUp = []
      this.setState({
        contentToUpload: null,
        disableSendBtn: true,
      })
    }

  }

  uploadMultipleFiles(files) {
    if (files.length <= 5) {
      this.fileObj.push(files)
      this.fileObj.forEach((index, key) => {
        for (let i = 0; i < index.length; i++) {
          this.fileArray.push({ url: URL.createObjectURL(index[i]), type: index[i].type, name: index[i].name, key: [i] })
          this.filesBinary.push(index[i])
        }
        this.fileObj = []
      })
      this.setState({ files: this.fileArray })
    } else {
      alert('Maksimum 5 file !')
    }
    if (this.filesBinary.length >= 5) {
      this.setState({
        maxUpFile: true
      })
    } else {
      this.setState({
        maxUpFile: false
      })
    }
    if (this.filesBinary.length >= 0 && this.contentToUp.length >= 0) {
      this.setState({
        disableSendBtn: false,
      })
    }
  }

  deleteSelectedFiles(e) {
    let filesBinaryTemp = []
    let fileArrayTemp = []
    if (e) {
      const hapus = e;

      // delete on binary
      this.filesBinary.forEach((value, key) => {
        if (value.name != hapus) filesBinaryTemp.push(value)
      })
      // delete on binary
      this.fileArray.forEach((value, key) => {
        if (value.name != hapus) fileArrayTemp.push(value)
      })

      this.filesBinary = filesBinaryTemp
      this.fileArray = fileArrayTemp

      this.setState({
        files: this.fileArray
      })

      if (this.filesBinary.length >= 5) {
        this.setState({
          maxUpFile: true
        })
      } else {
        this.setState({
          maxUpFile: false
        })
      }
      if (this.filesBinary.length === 0 && this.contentToUp.length === 0) {
        this.setState({
          disableSendBtn: true,
        })
      }
    }
  }

  handleSearchFriend(e) {

    let results = null;

    var toSearch = e.target.value;

    var searchString = toSearch.trim().toLowerCase();
    if (searchString.length > 0) {
      results = this.searchDemo.filter(function (i) {
        return (i.foo.toLowerCase().match(searchString));
      });
    }

    this.setState({
      searchResult: results
    })

  }

  handleFriendTagSelect(e) {
    let selectFriend = e
    let selectedFriend = []
    let selectedMore = null

    if (selectFriend != null) {
      selectFriend.forEach((index, key) => {
        selectedFriend.push(index.value)
      })
      if (selectFriend.length > 2) {
        selectedMore = selectFriend.length - 2
      }
    }
    this.setState({
      friendSelectedValue: selectFriend,
      friendTagSelected: selectedFriend,
      friendSelectedMore: selectedMore,
    })
  }

  handleFeelingSelect(e) {
    if (e != null) {
      this.setState({
        feelingSelected: e.id,
        feelingSelectedName: e.name
      })
    } else {
      this.setState({
        feelingSelected: null,
        feelingSelectedName: null
      })
    }
  }

  locationSelected(e) {
    if (e != null) {
      let idx = _.findIndex(location, function (v) {
        return v.geo == e.geo;
      });
      this.state.coords = arrCoords[idx];
      this.setState({
        locationSelected: e.geo
      })
    } else {
      this.setState({
        locationSelected: null
      })
    }
  }

  handleUpload() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };

    const formData = new FormData();

    // append content
    let requestData = {
      content: this.state.contentToUpload,
      status: this.state.statusSelected,
      feeling: this.state.feelingSelected,
      friend_tags: this.state.friendTagSelected
    };
    formData.append('json', JSON.stringify(requestData));

    // append files
    if (this.filesBinary.length != 0) {
      this.filesBinary.forEach((index, key) => {
        formData.append('files[' + key + ']', index);
      })
    }

    this.setState({
      disableSendBtn: true,
      stackModal: true,
    })
    axios.post(api + 'feed/post-feed',
      formData,
      config,
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this.resetAllValue()
      this.props.getFeed()
      this.setState({
        disableSendBtn: false,
        stackModal: false
      })
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
      this.setState({
        disableSendBtn: false,
        stackModal: false
      })
    })
  }

  handleUploadThread() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };

    const formData = new FormData();

    // append content
    let requestData = {
      content: this.state.contentToUpload,
      status: this.state.statusSelected,
      feeling: this.state.feelingSelected,
      topic_id: this.state.topicData?.id,
    };
    formData.append('json', JSON.stringify(requestData));

    // append files
    if (this.filesBinary.length != 0) {
      this.filesBinary.forEach((index, key) => {
        formData.append('files[' + key + ']', index);
      })
    }

    this.setState({
      disableSendBtn: true,
      stackModal: true,
    })
    axios.post(api + 'topic-post/post-topic-post',
      formData,
      config,
    ).then((res) => {
      const status = res
      this.props.upload_success(status)
      this.resetAllValue()
      this.props.getThread()
      this.setState({
        disableSendBtn: false,
        stackModal: false
      })
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
      this.setState({
        disableSendBtn: false,
        stackModal: false
      })
    })
  }

  resetAllValue() {
    // reset files
    this.fileObj = []
    this.fileArray = []
    this.filesBinary = []
    this.setState({
      modalCreatePost: false,
      // reset content
      contentToUpload: null,
      // reset feeling
      feelingSelected: null,
      // reset loc
      locationSelected: null,
      // reset friend
      friendTagSelected: [],
      friendSelectedMore: null,
      friendSelectedValue: null,
      // reset files
      files: [],
      maxUpFile: false,
    })
  }
  handlePostType() {
    let postType = this.state.postType;
    switch (postType) {
      case 'feed':
        return (
          <>
            {this.state.disableSendBtn ?
              <button className="uk-button btn-disable" type="button" style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button> :
              <button className="uk-button btn-schoolmedia" type="button" onClick={this.handleUpload} style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button>
            }
          </>
        )
        break;
      case 'thread':
        return(
          <>
            {this.state.disableSendBtn ?
              <button className="uk-button btn-disable" type="button" style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button> :
              <button className="uk-button btn-schoolmedia" type="button" onClick={this.handleUploadThread} style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button>
            }
          </>
        )
    }
  }
  render() {
    const files = this.state.files.map(file => {
      if (file.type.match('video.*')) {
        return (
          <>
            <video controls>
              <source src={file.url} type={file.type} />
            </video>
            <button className="button small btn-danger mt-2 mb-2" onClick={e => { this.deleteSelectedFiles(file.name) }}>Hapus</button>
          </>
        )
      } else {
        return (
          <>
            <div style={{ maxHeight: '9rem', height: '15rem', overflow: 'hidden' }}>
              <img src={file.url} alt="" />
            </div>
            <button className="button small btn-danger mt-2 mb-2" onClick={e => { this.deleteSelectedFiles(file.name) }}>Hapus</button>
          </>
        )
      }
    });
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };
    return (
      <>

        <div className="post-new-media">
          <div className="post-new-media-user">
            {this.state.profilePhoto === null || this.state.profilePhoto === "null" ?
              <img src="/assets/icons/user.svg" alt=""></img>
              :
              <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` + this.state.profilePhoto} alt=""></img>
            }
          </div>
          <div className="post-new-media-input">
            <input type="text-area" className="uk-input" onClick={this.openModalCreatePost} placeholder="Apa yang kamu pikirkan?"></input>
          </div>
        </div>
        <hr></hr>
        <div className="post-new-type">
          <a onClick={this.openModalPic}>
            <span className="uk-link uil-image mr-1" style={{ fontSize: "20px" }}></span>
                Foto/Video
            </a>
          <a onClick={this.openModalFriend} className="uk-visible@s">
            <span className="uk-link uil-user mr-1" style={{ fontSize: "20px" }}></span>
                  Tag Teman
            </a>
          <a onClick={this.openModalCreatePost}>
            <span className="uk-link uil-grin mr-1" style={{ fontSize: "20px" }}></span>
                  Perasaan
            </a>
        </div>

        {/* Modal create post */}
        <Modal
          open={this.state.modalCreatePost}
          onClose={this.hideModalCreatePost}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <Fade in={this.state.modalCreatePost}>
            <div className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-grid" style={{ maxWidth: '510px' }}>
              <div className=" uk-width-1-1">
                <h2 className="uk-modal-title text-center">Buat Postingan</h2>
                <span className="uk-modal-close-default" style={{ fontSize: "20px", cursor: "pointer", zIndex: 11 }} onClick={this.hideModalCreatePost}>
                  <i className="icon-feather-x"></i>
                </span>
                <hr></hr>

                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col-2">
                      <Link to="" className="opts_account" style={{ width: '3rem', height: '3rem', overflow: 'hidden', borderRadius: '3rem' }}>
                        {this.state.profilePhoto === null ?
                          <img src="/assets/icons/user.svg" alt=""></img>
                          :
                          <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` + this.state.profilePhoto} alt=""></img>
                        }
                      </Link>
                    </div>
                    <div className="col-10" style={{ marginLeft: "-25px" }}>
                      <h6 className="mb-0">
                        {this.state.namaUser}
                        {this.state.feelingSelected != null ? ` merasa ${this.state.feelingSelectedName}` : null}
                        {this.state.friendTagSelected.length != 0 ? ` bersama ${this.state.friendTagSelected.slice(0, 2)} ` : null}
                        {this.state.friendSelectedMore != null ?
                          <>
                            <span>dan &#0;</span>
                            <span onClick={this.openModalFriend} style={{ background: 'unset', cursor: 'pointer' }}> {this.state.friendSelectedMore} lainnya</span>
                          </>
                          : null}
                        {this.state.locationSelected != null ?
                          ` di ${this.state.locationSelected}` :
                          null
                        }
                      </h6>

                      <div className="uk-width-1-3" uk-form-custom="target: > * > span:last-child">

                        <FormControl>
                          <Select
                            value={this.state.statusSelected}
                            onChange={this.handleSelectStatus}
                            style={{ fontSize: '12px' }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value={1}>Publik</MenuItem>
                            <MenuItem value={2}>Teman</MenuItem>
                            <MenuItem value={0}>Hanya Saya</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12" style={{ maxHeight: '20rem', overflowX: 'hidden', overflowY: 'scroll' }}>
                  <Editor
                    editorStyle={{ width: '100%' }}
                    toolbarHidden
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.contentChange}
                    mention={{
                      separator: ' ',
                      trigger: '@',
                      suggestions: [
                        { text: 'rosed goblok', value: 'rosed goblok', url: 'rosed_goblok' },
                        { text: 'BANANA', value: 'banana', url: 'banana' },
                        { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                        { text: 'DURIAN', value: 'durian', url: 'durian' },
                        { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                        { text: 'FIG', value: 'fig', url: 'fig' },
                        { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                        { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                      ],
                    }}
                    hashtag={{
                      separator: ' ',
                      trigger: '#',
                      suggestions: [
                        { text: 'rosed', value: 'rosed goblok', url: 'rosed_goblok' },
                      ],
                    }}
                    placeholder="Apa yang kamu pikirkan?"
                  />
                  <div className="pl-4 pr-4">
                    {files}
                  </div>
                </div>


                <div className="col-12">
                  <div className="post-new-type pt-3">
                    <button className="button button-white" onClick={this.openModalPic}><span className="uil-image mr-2" style={{ fontSize: "20px" }}></span> Foto/Video</button>
                    <div className="uk-inline">
                      <button className="button button-white mx-1" onClick={this.openModalFriend} type="button"><span className="uil-user mr-2" style={{ fontSize: "20px" }}></span> Tag Teman</button>
                    </div>

                    <div className="uk-inline">
                      <button className="button button-white" type="button"><span className="uil-grin mr-2" style={{ fontSize: "20px" }}></span> Perasaan</button>
                      <div uk-dropdown="mode: click">
                        <List>
                          <ListItem
                            button
                            onClick={(event) => this.handleFeelingSelect(null)}
                          >
                            <ListItemText primary="Batalkan" />
                          </ListItem>
                          {this.state.feelingList.map(items => {
                            return (
                              <ListItem
                                key={items.id}
                                button
                                selected={this.state.feelingSelected === items.id}
                                onClick={(event) => this.handleFeelingSelect(items)}
                              >
                                <ListItemIcon>

                                </ListItemIcon>
                                <ListItemText primary={items.name} />
                              </ListItem>
                            )
                          })}
                        </List>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1 mt-2 mb-2">
                    <Autocomplete
                      id="combo-box-demo"
                      className="uk-width-1-1"
                      options={location}
                      onKeyDown={this.getGeolocation}
                      onKeyPress={this.getGeolocation}
                      onKeyUp={this.getGeolocation}
                      onChange={(event, newValue) => {
                        this.locationSelected(newValue)
                      }}
                      getOptionLabel={(option) => option.geo}
                      style={{ width: "100%" }}
                      renderInput={(params) => <TextField {...params} label="Tambah Lokasi" variant="outlined" />}
                    />
                  </div>
                </div>


                <div className="col-12 uk-flex">
                  {this.handlePostType()}
                  <button className="uk-button btn-white" type="button" onClick={this.hideModalCreatePost.bind(this)} style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Batal</button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* Modal up foto & video */}
        <Modal
          open={this.state.modalPic}
          onClose={this.hideModalPic}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <Fade in={this.state.modalPic}>
            <div className="bg-white modal-sm uk-grid-collapse uk-grid uk-padding">
              <div className=" uk-width-1-1">
                <h2 className="uk-modal-title text-center">Tambah Foto / Video</h2>
                <span className="uk-modal-close-default" style={{ fontSize: "20px", cursor: "pointer" }} onClick={this.hideModalPic}>
                  <i className="icon-feather-x"></i>
                </span>
                <hr></hr>

                <div className="col-12">
                  <Dropzone
                    onDrop={this.uploadMultipleFiles}
                    multiple={true}
                    accept={'image/jpeg, image/png, video/mp4'}
                    className="dropzone"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        {this.state.maxUpFile ?
                          <>
                            <div className='dropzone-max dropzone'>
                              <p>mencapai batas maksimum upload file</p>
                            </div>
                          </> :
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p className="font-sm">Seret dan lepas file disini, atau klik untuk memilih file</p>
                          </div>
                        }
                        <aside>
                          <Slider {...settings}>
                            {files}
                          </Slider>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>


                <div className="col-12">
                  <button
                    className={`button uk-button ${this.filesBinary !== [] ? 'btn-schoolmedia' : 'btn-disable btn-disabled'}`}
                    onClick={this.hideModalPic}
                    type="button"
                    style={{ width: "100%", borderRadius: "8px", fontWeight: "bold" }}
                  >
                    Tambahkan
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        {/* modal friend */}
        <Modal
          open={this.state.modalFriend}
          onClose={this.hideModalFriend}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <Fade in={this.state.modalFriend}>
            <div className="bg-white modal-sm uk-grid-collapse uk-grid pt-4 uk-padding">
              <div className=" uk-width-1-1">
                <h2 className="uk-modal-title text-center">Tandai Teman</h2>
                <span className="uk-modal-close-default" style={{ fontSize: "20px", cursor: "pointer" }} onClick={this.hideModalFriend}>
                  <i className="icon-feather-x"></i>
                </span>
                <hr></hr>

                <AsyncPaginate
                  value={this.state.friendSelectedValue}
                  isMulti
                  closeMenuOnSelect={false}
                  loadOptions={friendOptions}
                  onChange={this.handleFriendTagSelect}
                />

              </div>
              <div className="uk-width-1-1 mt-2">
                <button
                  className={`button uk-button btn-schoolmedia`}
                  onClick={this.hideModalFriend}
                  type="button"
                  style={{ width: "100%", borderRadius: "8px", fontWeight: "bold" }}
                >
                  Tambahkan
                  </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostPop);