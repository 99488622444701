import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import ProfileHeader from "./components/ProfileHeader";
import SkeletonPhotos from "../../partials/skeleton/SkeletonPhotos";
import { Link } from "react-router-dom";
import CreateMedia from "./components/CreateMedia";
import InfiniteScroll from "react-infinite-scroller";

const api = `${process.env.REACT_APP_API}`;
const url = `${process.env.REACT_APP_BACKEND}media`;
const albumPhotosStyle = {
  width: "100%",
  maxHeight: "250px",
  objectPosition: "center",
  objectFit: "none",
};

export default class ListPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      photosList: [],
      albumList: [],
      albumPiece: [],
      listIndexAlbum: true,
      listDetailAlbum: [],
      nameDetailAlbum: null,
      loadingFeedPhotos: true,
      loadingFeedAlbums: true,
      countToScroll: 1,

      page: 0,
      prevY: 0,
    };

    // detect bottom scroll
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        console.log("bawah");
        this.setState(
          { countToScroll: this.state.countToScroll + 1 },
          function () {
            console.log("Scroll");
            // this._getPhotos();
            // this._getAlbums();
          }
        );
      }
    };
    this.showDetailAlbum = this.showDetailAlbum.bind(this);
    this.hideDetailAlbum = this.hideDetailAlbum.bind(this);

    this._getPhotos = this._getPhotos.bind(this);
    this._getAlbums = this._getAlbums.bind(this);
  }
  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0];
    this.setState(
      {
        userName: username,
      },
      function () {
        this._getPhotos();
        this._getAlbums();
      }
    );

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    // this.observer = new IntersectionObserver(
    //   this.handleObserver.bind(this),
    //   options
    // );
    // this.observer.observe();
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      // const lastPhoto = this.state.photos[this.state.photos.length - 1];
      // const curPage = lastPhoto.albumId;
      // this.getPhotos(curPage);
      // this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }
  _getPhotos() {
    let jwtToken = localStorage.getItem("jwtToken");
    let requestData = {
      is_album: 0,
      type: 1,
      username: this.state.userName,
      page: { number: this.state.countToScroll },
    };
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.post(api + "media/get-media", requestData, config).then((res) => {
      const nextPhotoList = res.data.data.data;
      if (res.data.data.data === []) {
        this.setState({
          photosList: res.data.data.data,
          loadingFeedPhotos: false,
        });
      }
      this.setState({
        photosList: res.data.data.data,
        loadingFeedPhotos: false,
      });
    });
  }
  _getAlbums() {
    let jwtToken = localStorage.getItem("jwtToken");
    let requestData = {
      is_album: 1,
      type: 1,
      username: this.state.userName,
      page: { number: this.state.countToScroll },
    };
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.post(api + "media/get-media", requestData, config).then((res) => {
      const nextAlbumPiece = res.data.data.data;
      if (res.data.data.data === []) {
        this.setState(
          { loadingFeedAlbums: false, albumPiece: res.data.data.data },
          function () {
            this._albumGroupBy(this.state.albumPiece);
          }
        );
      }
      this.setState(
        {
          albumPiece: [...this.state.albumPiece, ...nextAlbumPiece],
          loadingFeedAlbums: false,
        },
        function () {
          this._albumGroupBy(this.state.albumPiece);
        }
      );
    });
  }
  _albumGroupBy(e) {
    var data = [];
    var x;

    var group = e.reduce(function (rv, x) {
      (rv[x.media_album_id] = rv[x.media_album_id] || []).push(x);
      return rv;
    }, {});

    for (x in group) {
      data.push(group[x]);
    }

    this.setState({ albumList: data });
  }
  showAlbumPhotos(data) {
    var photos = "";
    var album = [];
    var photoStyle = [
      "uk-width-1-1@m uk-first-column",
      "uk-width-1-2@m uk-width-1-2 uk-grid-margin uk-first-column",
      "uk-width-1-2@m uk-width-1-2 uk-position-relative uk-grid-margin",
    ];

    for (var i = 0; i < 3; i++) {
      photos += `<div className="${photoStyle[i]}">`;
      if (data[i] != undefined) {
        photos += `<img src="${
          url + "/" + this.state.userName + "/" + data[i].media
        }"
                      style="object-fit: none;
                        object-position: center;
                        width: 100%;
                        height: 100px;
                        max-height: 100px;
                        margin-bottom: 1rem;"
                      className="rounded"
                      alt=""
                  />`;
      } else {
        photos += `<img src="/assets/images/video/img-1.png"
                      style="object-fit: none;
                        object-position: center;
                        width: 100%;
                        height: 100px;
                        max-height: 100px;
                        margin-bottom: 1rem;"
                      className="rounded"
                      alt=""
                  />`;
      }
      photos += `</div>`;
    }
    album.push(
      <div
        className="uk-grid-small uk-grid"
        data-uk-img=""
        dangerouslySetInnerHTML={{ __html: photos }}
      ></div>
    );
    return album;
  }
  showDetailAlbum(data, name) {
    this.setState({
      listIndexAlbum: false,
      listDetailAlbum: data,
      nameDetailAlbum: name,
    });
  }
  hideDetailAlbum() {
    this.setState({
      listIndexAlbum: true,
      listDetailAlbum: [],
      nameDetailAlbum: null,
    });
  }

  render() {
    console.log(this.state.prevY);
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <ProfileHeader />
          <div className="section-small">
            <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
              <div className="uk-flex uk-flex-between">
                <h2>Foto</h2>
                <div className="uk-flex uk-flex-column">
                  <CreateMedia
                    getPhotos={this._getPhotos}
                    getAlbums={this._getAlbums}
                  />
                </div>
              </div>

              <div className="uk-flex uk-flex-between">
                <ul
                  className="uk-tab"
                  uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
                >
                  <li className="uk-active uk-flex uk-flex-row">
                    <a href="#" className="uk-flex uk-flex-row">
                      {/* <i className="icon-feather-home mr-2"></i> */}
                      Foto
                      {/* <p style={{fontSize: '.7rem'}}>235 Orang</p> */}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                      Album
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="uk-switcher uk-margin" id="component-tab-left">
              <li>
                <div
                  className="bg-white p-4 row rounded uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-collapse uk-overflow-hidden uk-grid"
                  style={{ borderRadius: "25px", overflow: "hidden" }}
                >
                  {this.state.loadingFeedPhotos ? (
                    <div className="bg-white w-100 col-12 pt-4">
                      <SkeletonPhotos />
                    </div>
                  ) : this.state.photosList.length <= 0 ? (
                    <p className="text-sm w-100">Belum Mempunyai Foto</p>
                  ) : (
                    this.state.photosList.map((val, key) => {
                      return (
                        <div className="col-3 mb-4">
                          <Link
                            to={
                              "/" +
                              this.state.userName +
                              "/posts/" +
                              val.composite_id
                            }
                            data-caption={val.caption}
                            className="w-100"
                          >
                            <div
                              className="group-catagroy-card animate-this"
                              style={{ height: "200px" }}
                              data-src={
                                url +
                                "/" +
                                this.state.userName +
                                "/" +
                                val.media
                              }
                              data-uk-img=""
                            >
                              <div className="group-catagroy-card-content">
                                <h4> {val.caption} </h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  )}
                </div>
              </li>
              <li>
                {this.state.listIndexAlbum ? (
                  <>
                    <div
                      className="bg-white p-4 rounded uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-collapse uk-overflow-hidden uk-grid"
                      style={{ borderRadius: "25px", overflow: "hidden" }}
                    >
                      {this.state.loadingFeedAlbums ? (
                        <div className="bg-white w-100 col-12">
                          <SkeletonPhotos />
                        </div>
                      ) : this.state.photosList.length <= 0 ? (
                        <p className="text-sm w-100">Belum Mempunyai Album</p>
                      ) : (
                        this.state.albumList.map((val, key) => {
                          let albumName =
                            val[0].name != undefined ? val[0].name : null;
                          return (
                            <div key={key} className="col-3 post pr-0">
                              <a
                                onClick={(e) => {
                                  this.showDetailAlbum(val, albumName);
                                }}
                                data-caption={val.caption}
                                className="w-100"
                              >
                                <div className="post-description">
                                  {this.showAlbumPhotos(val)}
                                </div>
                                <div className="post-state mb-0">
                                  <h4 className="mt-2 mb-2 text-sm">
                                    {" "}
                                    {albumName}{" "}
                                  </h4>
                                </div>
                              </a>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="bg-white p-4 row rounded uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-collapse uk-overflow-hidden uk-grid"
                    style={{ borderRadius: "25px", overflow: "hidden" }}
                  >
                    <div className="mb-4 uk-padding">
                      <button
                        className="button btn-white icon-label bg-facebook"
                        onClick={this.hideDetailAlbum}
                      >
                        <span className="inner-icon">
                          <i className="icon-material-outline-arrow-back"></i>
                        </span>
                        <span className="inner-text">Kembali</span>
                      </button>
                      <h2 className="mt-2">
                        Album {this.state.nameDetailAlbum}
                      </h2>
                    </div>
                    {/* <div className="uk-flex"> */}
                    {this.state.listDetailAlbum.map((val, key) => {
                      return (
                        <div className="col-3 mb-4">
                          <Link
                            to={"/" + this.state.userName + "/posts/" + val.id}
                            data-caption={val.caption}
                            className="w-100"
                          >
                            <div
                              className="group-catagroy-card animate-this"
                              style={{ height: "200px" }}
                              data-src={
                                url +
                                "/" +
                                this.state.userName +
                                "/" +
                                val.media
                              }
                              data-uk-img=""
                            >
                              <div className="group-catagroy-card-content">
                                <h4> {val.caption} </h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                    {/* </div> */}
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
