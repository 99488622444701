import axios from "axios";
import { dontCry } from "./KiritoHelper";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

const sso = `${process.env.REACT_APP_SSO_URL}`;
const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
const clientSecret = `${process.env.REACT_APP_CLIENT_SECRET}`;
const caughtUri = `${process.env.REACT_APP_SSO_CAUGHT_URI}`;
const api = `${process.env.REACT_APP_API}`;
let is405Executed = false;
let is401Executed = false;

export function init_sso() {
    let callback = "";
    if (localStorage.getItem('isChecked') == null) {
        callback = "&callback=true"
        localStorage.setItem('isChecked', true);
        window.location.replace(
            sso + "auth/is_login?client_id=" + clientId + "&redirect_uri=" + caughtUri + callback
        );
    } else {
        localStorage.removeItem('isChecked');
    }
}

export function login_sso() {
    window.location.replace(
        sso + "auth/is_login?client_id=" + clientId + "&redirect_uri=" + caughtUri
    );
}

export function redirect_sso() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const encodedWord = Utf8.parse(clientId+':'+clientSecret); // encodedWord Array object
    const base64 = Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='

    let config = {
        headers: { Authorization: "Basic " + base64 },
    };

    let res = {};
    return axios
        .post(
            api + "auth/sso/login",
            {
                type: "redirect",
                code : code
            }
            // config
        )
        .then((response) => {
            var response = response.data;
            return response;
        });
}

export function caught_sso() {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoToken = urlParams.get("SSO_TOKEN");
    let x = JSON.parse(dontCry(ssoToken, "d"));

    return x;
}

export function logout_sso() {
    return axios
        .post(
            api + "auth/sso/logout",
            {
                type: "logout"
            }
            // config
        )
        .then((response) => {
            var response = response.data;
            return response;
        });
        /*.catch(function (error) {
            return error;
        });*/
}

export function status_login() {
    let jwtToken = localStorage.getItem("jwtToken");

    let config = {
        headers: { Authorization: "Bearer " + jwtToken },
    };
    // let res = {};
    return axios
        .post(
            api + "auth/sso/status-login",
            {
            // type: "redirect",
            // code: code,
            },
            // config
            config
        )
        .then(function(response) {
            let resp = response.data;
            return resp;
        });
        /*.catch(function (error) {
            console.log('kntl',error);
            return error;
        });*/
}

export function update_token(error) {
  let code = error.response.status;
  var newToken = null;
  const config = error.config;

  if (error.config && error.response && code === 405) {
    newToken = error.response.data.data.value;
    // 1) put token to LocalStorage
    if (!is405Executed) {
      console.log('405 not exec in 405, set item');
      localStorage.setItem('jwtToken', newToken);
    } else { 
      console.log('405 exec in 405, get item');
      localStorage.setItem('jwtToken', newToken);
      // newToken = localStorage.getItem('jwtToken');
    }
    // 2) retry failed request causing token expired
    config.headers['Authorization'] = `Bearer ${newToken}`;
    // if (!is405Executed) {
      console.log('newtoken 405', newToken);
      is405Executed = true;
      return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
          console.log("405", newToken);
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    // }
  }

  if (error.config && error.response && code === 401) {
    if (!is405Executed) {
      console.log('405 not exec in 401');
      if (typeof error.response.data != undefined)
        localStorage.setItem('jwtToken', error.response.data.data?.value);
    }
    // 1) retry failed request causing token expired
    config.headers['Authorization'] = `Bearer `+ localStorage.getItem('jwtToken');
    if (!is401Executed) {
      is401Executed = true;
      return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
          console.log("cokk",config);
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    }
  }

  return Promise.reject(error);
}