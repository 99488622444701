import React from 'react';
import axios from 'axios';
import GroupPost from '../components/GroupPost';
import ThemeGroupHeader from '../components/ThemeGroupHeader';
import CreateThread from '../components/CreateThread';
import CreatePostPop from 'component/partials/Timeline/CreatePostPop';
import { connect } from 'react-redux';

const api = `${process.env.REACT_APP_API}`;

class ThemeGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topicData: '',
      dataListTopic: [],
      listThread: [],
      forumCode: ''
    }
    this.getThread = this.getThread.bind(this)
  }
  componentDidMount() {
    const url = window.location.pathname
    let arr = []
    arr = url.split('/')
    let nameCode = arr[2]
    let code = []
    code = nameCode.split('-')
    this.getThread(code[code.length - 1])
    this.setState({ forumCode: code[code.length - 1] })
  }
  static getDerivedStateFromProps(props, state) {
    if (props.topicReducer.listTopic != state.dataListTopic) {
      const url = window.location.pathname
      let arr = []
      arr = url.split('/')
      let nameCode = arr[2]
      let topic = props.topicReducer.listTopic;
      let group = topic.reduce((r, a) => {
        r[a.slug] = [...r[a.slug] || [], a];
        return r;
      }, {});
      let res = group[nameCode]
      if(res != undefined) {
        return ({
          topicData: res[0]
        })
      } else {
        return ({
          topicData: ''
        })
      }
    }
  }
  getThread(e) {
    let forumCode = e;
    if (forumCode === undefined || forumCode === null || forumCode === '') {
      forumCode = this.state.forumCode
    }
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(api + "topic-post/get-topic-post", {
      forum_code: forumCode,
      topic_id: this.state.topicData?.id,
    }, config)
      .then(res => {
        console.log(res)
        this.setState({ listThread: res.data.data.data })
      })
  }
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="single-group">
            <ThemeGroupHeader />
            <div className="section-small mt-3">
              <div className="uk-grid" uk-grid>
                <div className="uk-width-2-3@m fead-area">
                  {/* <CreateThread topicData={this.state.topicData}/> */}
                  <div className="bg-white uk-padding mb-4">
                    <CreatePostPop postType={'thread'} getThread={this.getThread} topicData={this.state.topicData} />
                  </div>

                  {this.state.listThread.map((val, key) => {
                    return <GroupPost key={key} threadData={val} />
                  })}
                </div>

                {/* sidebar */}
                <div className="uk-width-expand">
                  <div className="post-new">
                    <h3> Info </h3>

                    <div className="list-group-items">
                      <i className="uil-thumbs-up"></i>
                      <h5>
                        <span className="mr-1"> 120 </span> Members Joined
                                      <p className="text-green mb-0 ml-2">
                          +40 This week
                                      </p>
                      </h5>
                    </div>

                    <div className="list-group-items">
                      <i className="uil-list-ul"></i>
                      <h5> <span className="mr-1"> 12 </span> posts
                                      <p className="text-schoolmedia mb-0 ml-2">
                          +20 Daily
                                      </p>
                      </h5>
                    </div>

                    <div className="list-group-items">
                      <i className="uil-bag-alt"> </i>
                      <h5> <span className="mr-1"> 16 </span> jobs </h5>
                    </div>

                    <div className="list-group-items">
                      <i className="uil-tag-alt"> </i>
                      <h5> Blogs , Magazine </h5>
                    </div>

                    <a href="#" className="button btn-soft-schoolmedia block my-3"> edit </a>
                  </div>

                  <hr className="my-3" />

                  <div className="post-new">
                    <h3> Description </h3>
                    <p className="mb-0"> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                    nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip
                    </p>
                    <a href="#"> Read more </a>
                  </div>

                  <hr className="my-3" />

                  <div className="post-new z-0" uk-sticky="offset:150 ; bottom:true ; meda : @m" style={{ zIndex: '0 !important' }}>
                    <div className="section-header pt-2">
                      <div className="section-header-left">
                        <h3> Suggested Groups </h3>
                      </div>
                    </div>

                    <div className="uk-child-width-1-1@m uk-grid uk-grid-collapse" uk-grid>
                      {/* list itme 1 */}
                      <div>
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="/assets/images/group/group-2.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> Coffee Addicts </h5>
                            </a>
                            <p> Drinks , Food </p>
                          </div>
                          <div className="uk-width-auto">
                            <span className="btn-option">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                              <ul>
                                <li>
                                  <span> <i className="uil-bell"></i> Joined </span>
                                </li>
                                <li>
                                  <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                </li>
                                <li>
                                  <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* list itme 2 */}
                      <div>
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="/assets/images/group/group-1.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> Architecture </h5>
                            </a>
                            <p> Engineering, artchects </p>
                          </div>
                          <div className="uk-width-auto">
                            <span className="btn-option">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                              <ul>
                                <li>
                                  <span> <i className="uil-bell"></i> Joined </span>
                                </li>
                                <li>
                                  <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                </li>
                                <li>
                                  <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* list itme 3 */}
                      <div>
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="/assets/images/group/group-3.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> Graphic Design </h5>
                            </a>
                            <p> Design </p>
                          </div>
                          <div className="uk-width-auto">
                            <span className="btn-option">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                              <ul>
                                <li>
                                  <span> <i className="uil-bell"></i> Joined </span>
                                </li>
                                <li>
                                  <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                </li>
                                <li>
                                  <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* list itme 4 */}
                      <div>
                        <div className="list-items">
                          <div className="list-item-media">
                            <img src="/assets/images/group/group-4.jpg" alt="" />
                          </div>
                          <div className="list-item-content">
                            <a href="group-feed.html">
                              <h5> Property Rent </h5>
                            </a>
                            <p> Listing , Homes </p>
                          </div>
                          <div className="uk-width-auto">
                            <span className="btn-option">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                              <ul>
                                <li>
                                  <span> <i className="uil-bell"></i> Joined </span>
                                </li>
                                <li>
                                  <span> <i className="uil-bookmark"></i> Add Bookmark </span>
                                </li>
                                <li>
                                  <span> <i className="uil-share-alt"></i> Share Your Friends </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <a href="#" className="button btn-default block my-3"> See All </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    topicReducer: state.topicReducer,
  }
}

export default connect(mapStateToProps)(ThemeGroup);
