import {USER_DETAIL} from '../actions/types';

const initialState = {
    userDetail: {},
    loading:true
}

export default (state = initialState, action = {}) => {
    switch(action.type){
        case USER_DETAIL:
        return {
            ...state,
            userDetail: action.userDetail,
            loading:false
        }
        default: return state
    }

}