import React from 'react';
import axios from 'axios';
import RightBar from '../../partials/RightSidebar/RightBar';
import NewsCard from './components/NewsCard';

const api = `${process.env.REACT_APP_SM_NEWS}`;

export default class ListNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listNews: [],
      isLoading: false,
    }
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        console.log("yeah you got it!")
        // this.setState({
          
        // })
      }
    }
  }
  componentDidMount() {
    var instance = axios.create();

    delete instance.defaults.headers.common.Authorization
    this.setState({
      isLoading: true,
    })
    
    instance.get(api + 'SosmedLandingPage')
      .then((res) => {
        this.setState({
          listNews: res.data,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="uk-grid-large uk-grid gird-content">
            <div className="uk-width-2-3@m fead-area">
              {/* <h3>Artikel Teratas</h3> */}
              <NewsCard data={this.state.listNews} loading={this.state.isLoading}/>
            </div>
            <RightBar />
          </div>
        </div>
      </div>
    )
  }
}