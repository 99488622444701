import React from 'react';
import GroupHeader from './components/GroupHeader';
import Skeleton from "@material-ui/lab/Skeleton";

export default class GroupMember extends React.Component {
  render() {
    return (
      <>
        <div className="main_content">
          <div className="main_content_inner">
            <div className="single-group">
              <GroupHeader />
              <div className="mt-5">
                <div className="uk-grid-large uk-grid" uk-grid="">
                  <div className="uk-width-expand uk-first-column">
                    {/* Blog Post */}
                    <div className="uk-first-column bg-white" style={{boxShadow: "0px 2px 7px -1px #0000001a"}}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Admin dan Moderator</b> 20 Orang </span>
                        <span className="blog-post-info-date"><a href="#"> Lihat Lainnya </a></span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto <span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <Skeleton variant="circle" width={60} height={60} />
                        </div>
                        <div className="page-card-innr">
                          <Skeleton variant="text" width="30%" height={35} />
                          <Skeleton variant="text" width="50%" />
                        </div>
                      </div>
                    </div>

                    {/* Blog Post */}
                    <div className="uk-first-column bg-white mt-8" style={{boxShadow: "0px 2px 7px -1px #0000001a"}}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Teman</b> 20 Orang </span>
                        <span className="blog-post-info-date"><a href="#"> Lihat Lainnya </a></span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto <span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="uk-first-column bg-white mt-8" style={{boxShadow: "0px 2px 7px -1px #0000001a"}}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Baru di Grup</b> 20 Orang </span>
                        <span className="blog-post-info-date"><a href="#"> Lihat Lainnya </a></span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto <span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" />
                        </div>
                        <div className="page-card-innr">
                          <h3> Rudiyanto<span> Guru di SMA Negeri 1 Padang</span> </h3>

                        </div>
                        <div className="uk-flex uk-flex-middle">
                          <a href="#" className="button btn-soft-schoolmedia">
                            <i className="uil-plus"></i>
                              Tambah Teman
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="uk-width-1-3@s">
                    <div uk-sticky="offset:138;media: @m ; bottom:true" className="uk-sticky">
                      <div className="uk-card-default rounded mb-4">
                        <div className="blog-post-content-info p-3" style={{ marginBottom: "0" }}>
                          <span><b className="mr-2">Riwayat</b></span>
                          <span className="blog-post-info-date"><a href="#"> Lihat Selengkapnya </a></span>
                        </div>

                        <ul className="uk-switcher" style={{touchAction: "pan-y pinch-zoom"}}>
                          {/* tab 1 */}
                          <li className="uk-active">
                            <div className="py-3 px-4">
                              <div className="uk-grid-small uk-grid" uk-grid="">
                                <i className="icon-material-outline-outlined-flag"></i><span className="blog-post-info-date">Grub dibuat pada 27 April 2020</span>
                              </div>
                              <div className="uk-grid-small uk-grid" uk-grid="">
                                <i className="icon-feather-edit"></i><span className="blog-post-info-date">Terakhir diubah pada 14 Maret 2020</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="uk-card-default rounded mb-4">
                        <div className="blog-post-content-info p-3" style={{ marginBottom: "0" }}>
                          <span><b className="mr-2">Grub Teman</b></span>
                          <span className="blog-post-info-date"><a href="#"> Lihat Semua </a></span>
                        </div>

                        <hr style={{ margin: "0" }} />

                        <ul className="uk-switcher" style={{touchAction: "pan-y pinch-zoom"}}>
                          {/* tab 1 */}
                          <li className="uk-active">
                            <div className="py-3 px-4">
                              <div className="group-card">
                                {/* Group Card Thumbnail */}
                                <div className="group-card-thumbnail">
                                  <img src="/assets/images/group/group-cover-2.jpg" alt="" />
                                </div>

                                {/* Group Card Content */}
                                <div className="group-card-content">
                                  <h3> RPL SMKN 1 Dlanggu </h3>
                                  <p className="info"> <a href="#"> <span> 160k Anggota </span> </a>
                                    <a href="#"> <span> 1.1k Post per Hari </span> </a>
                                  </p>

                                  <div className="group-card-member-wrap">
                                    <div className="avatar-group uk-width-auto">
                                      <img alt="Image placeholder" src="/assets/images/avatars/avatar-2.jpg" className="avatar avatar-xs rounded-circle" />
                                      <img alt="Image placeholder" src="/assets/images/avatars/avatar-1.jpg" className="avatar avatar-xs rounded-circle" />
                                    </div>
                                    <div className="uk-width-expand pl-2">
                                      <p> <strong>Chou</strong> dan 5 Teman menjadi anggota </p>
                                    </div>
                                  </div>

                                  <div className="group-card-btns">
                                    <a href="#" className="button btn-schoolmedia small">Gabung </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>

                      </div>
                    </div><div className="uk-sticky-placeholder" style={{height: "540px", margin: "0px"}} hidden=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
