import React from 'react';
import AboutFriend from './AboutFriend';
import FriendHeader from './components/FriendHeader';

export default class DetailFriend extends React.Component{
    render(){
        return(
            <div className="main_content">
            <div className="main_content_inner pt-0">
              <FriendHeader/>
              <AboutFriend/>
            </div>
          </div>
        )
    }
}