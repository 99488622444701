import React from 'react';
import { Link } from "react-router-dom";

export default class ContentExchange extends React.Component{
  render(){
    return(
      <div className="main_content">
        <div className="main_content_inner">
          <div className="uk-grid-large uk-grid gird-content" uk-grid={true}>
            <div className="uk-width-2-3@m fead-area">
              <div className="p-4 bg-white rounded-sm">
                <h4>Bursa Konten</h4>
              </div>
            </div>
            <div className="sidebar-to-mobile uk-width-expand right-bar">
              <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-4 rounded shadow">
                <h4>Grup Teman</h4>

                <div className="uk-flex uk-flex-column">
                  <div className="uk-child-width-1-1@s uk-grid-row-small uk-grid-collapse uk-grid">
                    <Link to="/group">
                      <div className="list-items">
                        <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt=""/>
                        </div>
                        <div className="list-item-content">
                          <a>
                              <h5> Mountain Riders </h5>
                          </a>
                          <p> 376 Member </p>
                          <button className="button small btn-schoolmedia">Gabung</button>
                        </div>
                        <div className="uk-width-auto">
                            <span className="btn-option" aria-expanded="false">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                                <ul>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/group">
                      <div className="list-items">
                        <div className="list-item-media">
                            <img src="assets/images/group/group-4.jpg" alt=""/>
                        </div>
                        <div className="list-item-content">
                          <a>
                              <h5> Mountain Riders </h5>
                          </a>
                          <p> 376 Member </p>
                          <button className="button small btn-schoolmedia">Gabung</button>
                        </div>
                        <div className="uk-width-auto">
                            <span className="btn-option" aria-expanded="false">
                              <i className="icon-feather-more-horizontal"></i>
                            </span>
                            <div className="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover ;animation: uk-animation-slide-bottom-small">
                                <ul>
                                </ul>
                            </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}