import SkeletonNewsList from 'component/partials/skeleton/SkeletonNewsList';
import React from 'react';
import { Link } from 'react-router-dom';

export default class NewsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data != state.data) {
      return {
        data: props.data.news,
        isLoading: props.loading
      }
    }
  }
  renderNews() {
    if (this.state.data) {
      return (this.state.data.map((news) => {
        return (
          <a href={news.url_konten} target="_blank" className="blog-post">
            {/* <!-- Blog Post Thumbnail --> */}
            <div className="blog-post-thumbnail">
              <div className="blog-post-thumbnail-inner">
                <span className="blog-item-tag">{news.tipe_berita}</span>
                <img src={news.url_gambar} alt="" />
              </div>
            </div>
            {/* <!-- Blog Post Content --> */}
            <div className="blog-post-content">
              <div className="blog-post-content-info">
                <span className="blog-post-info-tag button btn-soft-red flex"> Schoolmedia News </span>
                <span className="blog-post-info-date">{news.jadwal_tayang}</span>
              </div>
              <h3>{news.judul} </h3>
              <p>{news.konten}
              </p>
            </div>
          </a>
        )
      })
      )
    }
  }
  render() {
    return (
      <>
        {this.state.isLoading ?
          <>
            <SkeletonNewsList />
            <SkeletonNewsList />
            <SkeletonNewsList />
            <SkeletonNewsList />
          </> :
          this.renderNews()
        }
      </>
    )
  }
}