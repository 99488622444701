import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Axios from 'axios';
import * as serviceWorker from './serviceWorker';

// CSS
import './index.css';
import './assets/css/framework.css';
import './assets/css/night-mode.css';
import './assets/css/style.css';

// Import Redux
// import {createStore} from 'redux';
import {Provider} from 'react-redux';
import globalReducer from './redux/reducers/globalReducer';
import setAuthorizationToken from "./utils/setAuthorizationToken";
import jwt from "jsonwebtoken";
import { setCurrentUser } from './redux/actions/SsoAction';
import store from "./redux/store";

// store
// const storeRedux = createStore(globalReducer);

const storeRedux = store();

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  storeRedux.dispatch(setCurrentUser(jwt.decode(localStorage.jwtToken)));
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={storeRedux}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
