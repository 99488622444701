import React from 'react';
import { connect } from 'react-redux';
import { getUserDetail } from 'redux/actions/UserAction';
import UserReducer from 'redux/reducers/UserReducer';
import FormGeneral from './Components/FormGeneral';
import Skeleton from '@material-ui/lab/Skeleton';

class SettingGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }
  componentDidMount() {
    this.setState({
      loading: this.props.userReducer.loading
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.userReducer !== prevProps.userReducer) {
      this.setState({
        loading: this.props.userReducer.loading
      })
    }
  }

  render() {
    return (
      <>
        <div className="uk-margin">
          <div className="profile">
            {this.state.loading ?
              <>
                <Skeleton variant="rect" height={218} />
                <div className="uk-flex uk-flex-row uk-padding">
                  <Skeleton variant="circle" width={100} height={100} />
                  <div className="ml-4" style={{width: '80%'}}>
                    <Skeleton variant="text" height={40}/>
                    <Skeleton variant="text" height={40}/>
                  </div>
                </div>
              </> :
              <FormGeneral />
            }
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer;
  return{
    auth: state.auth,
    userReducer
  }
}

export default connect(mapStateToProps, { getUserDetail })(SettingGeneral);