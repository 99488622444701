import React from 'react';
import GroupHeader from './components/GroupHeader';

export default class GroupAbout extends React.Component {
  render() {
    return (
      <>
        <div className="main_content">
          <div className="main_content_inner">
            <div className="single-group">
              <GroupHeader />
              <div className="mt-5">
                <div className="uk-grid-large uk-grid" uk-grid="">
                  <div className="uk-width-expand uk-first-column">
                    {/* Blog Post */}
                    <div className="uk-first-column bg-white" style={{ boxShadow: "0px 2px 7px -1px #0000001a" }}>
                      <div className="blog-post-content-info p-3">
                        <span><b><h2 style={{ marginBottom: "0" }}>Tentang Grub</h2></b></span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="blog-post-content-info p-3">
                        <div className="section-header-left">
                          <h3 className="mb-2"> Deskripsi </h3>
                          <p className="uk-text-small"> Group untuk para guru dan penggerak dalam berbagi untuk menginspirasi sesama guru
                                                agar Pendidikan Indonesia menjadi lebih baik.</p>
                        </div>
                      </div>


                      <div className="blog-post-content-info p-3 mb-0 pt-0">
                        <i className="uil-plus"></i>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Publik </h6>
                          <p className="uk-text-small"> Semua Orang Bisa Melihat Postingan Grub Ini.</p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0 pt-0">
                        <i className="uil-plus"></i>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Terlihat </h6>
                          <p className="uk-text-small"> Semua Orang Bisa Menemukan Grub Ini.</p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0 pt-0">
                        <i className="uil-plus"></i>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Umum </h6>
                          <p className="uk-text-small"> Grub ini bersifat umum.</p>
                        </div>
                      </div>
                    </div>

                    {/* Blog Post */}
                    <div className="uk-first-column mt-8 pb-4 bg-white" style={{ boxShadow: "0px 2px 7px -1px #0000001a" }}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Anggota</b> 1866 Orang </span>
                        <span className="blog-post-info-date"><a href="#"> Lihat Lainnya </a></span>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                        </div>
                        <br />
                      </div>

                      <span className="ml-4">Rudi dan 5 Teman Lainnya Adalah Anggota.</span>

                      <h5 className="ml-4 mt-2">Admin dan Moderator</h5>

                      <div className="pages-card">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                          <img src="/assets/images/avatars/avatar-7.jpg" className="mr-2" alt="" />
                        </div>
                        <br />
                      </div>

                      <span className="ml-4">Rudi dan 5 Teman Lainnya Adalah Anggota.</span>

                    </div>

                    <div className="uk-first-column mt-8 bg-white" style={{ boxShadow: "0px 2px 7px -1px #0000001a" }}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Peraturan Grub</b></span>

                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>1.</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Bersikap Baik dan Sopan </h6>
                          <p className="uk-text-small">Kita semua Bersama sama menciptakan lingkungan yang ramah. Demi mencapai
                          tujuan tersebut ayo bersikap secara sopan dan santun terhadap semua anggota.
                                            </p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>2.</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Tidak Ada Ujaran Kebencian </h6>
                          <p className="uk-text-small">Pastikan Semua Orang Merasakan Aman. Tidak ada perundungan dan komentar yang
                          bersifat merendahkan Ras, Suku, dan Agama.
                                            </p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>3.</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Tidak Ada Promosi atau Spam </h6>
                          <p className="uk-text-small">Beri lebih banyak daripada yang anda dapat dari grub ini. Pastikan tidak ada Promosi
                          atau Spam dalam postingan.
                                            </p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>4.</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Tidak Perlu Say Hello, Thanks </h6>
                          <p className="uk-text-small">Jika sudah ditambahkan oleh admin ke grub, tidak perlu mengucapkan hello, atau thanks.
                                            </p>
                        </div>
                      </div>


                    </div>

                    <div className="uk-first-column mt-8 bg-white" style={{ boxShadow: "0px 2px 7px -1px #0000001a" }}>
                      <div className="blog-post-content-info p-3">
                        <span><b className="mr-2"> Aktifitas Grub</b></span>

                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>17</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> 17 Postingan Baru Hari Ini </h6>
                          <p className="uk-text-small">448 Postingan dalam 30 Hari Terakhir.
                                            </p>
                        </div>
                      </div>

                      <div className="blog-post-content-info p-3 mb-0">
                        <h4>1866</h4>
                        <div className="section-header-left ml-4">
                          <h6 className="mb-0"> Anggota </h6>
                          <p className="uk-text-small">+700 dalam 30 Hari Terakhir.
                                            </p>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="uk-width-1-3@s">
                    <div uk-sticky="offset:135;media: @m ; bottom:true" className="uk-sticky">
                      <div className="uk-card-default rounded mb-4">
                        <div className="blog-post-content-info p-3" style={{ marginBottom: "0" }}>
                          <span><b className="mr-2">Riwayat</b></span>
                          <span className="blog-post-info-date"><a href="#"> Lihat Selengkapnya </a></span>
                        </div>

                        <ul className="uk-switcher" style={{ touchAction: "pan-y pinch-zoom" }}>
                          {/* tab 1 */}
                          <li className="uk-active">
                            <div className="py-3 px-4">
                              <div className="uk-grid-small uk-grid" uk-grid="">
                                <i className="icon-material-outline-outlined-flag"></i><span className="blog-post-info-date">Grub dibuat pada 27 April 2020</span>
                              </div>
                              <div className="uk-grid-small uk-grid" uk-grid="">
                                <i className="icon-feather-edit"></i><span className="blog-post-info-date">Terakhir diubah pada 14 Maret 2020</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="uk-card-default rounded mb-4">
                        <div className="blog-post-content-info p-3" style={{ marginBottom: "0" }}>
                          <span><b className="mr-2">Grub Teman</b></span>
                          <span className="blog-post-info-date"><a href="#"> Lihat Semua </a></span>
                        </div>

                        <hr style={{ margin: "0" }} />

                        <ul className="uk-switcher" style={{ touchAction: "pan-y pinch-zoom" }}>
                          {/* tab 1 */}
                          <li className="uk-active">
                            <div className="py-3 px-4">
                              <div className="group-card">
                                {/* Group Card Thumbnail */}
                                <div className="group-card-thumbnail">
                                  <img src="/assets/images/group/group-cover-2.jpg" alt="" />
                                </div>

                                {/* Group Card Content */}
                                <div className="group-card-content">
                                  <h3> RPL SMKN 1 Dlanggu </h3>
                                  <p className="info"> <a href="#"> <span> 160k Anggota </span> </a>
                                    <a href="#"> <span> 1.1k Post per Hari </span> </a>
                                  </p>

                                  <div className="group-card-member-wrap">
                                    <div className="avatar-group uk-width-auto">
                                      <img alt="Image placeholder" src="/assets/images/avatars/avatar-2.jpg" className="avatar avatar-xs rounded-circle" />
                                      <img alt="Image placeholder" src="/assets/images/avatars/avatar-1.jpg" className="avatar avatar-xs rounded-circle" />
                                    </div>
                                    <div className="uk-width-expand pl-2">
                                      <p> <strong>Chou</strong> dan 5 Teman menjadi anggota </p>
                                    </div>
                                  </div>

                                  <div className="group-card-btns">
                                    <a href="#" className="button btn-schoolmedia small">Gabung </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div><div className="uk-sticky-placeholder" style={{ height: "540px", margin: "0px" }} hidden=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
