import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import ButtonAddFriend from "component/partials/global/ButtonAddFriend";
const api = `${process.env.REACT_APP_API}`;

class ProfileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      myData: "",
      myUserName: null,
      userName: null,
      dataFriend: [],
      profilePic: null,
      coverPic: null,
    };

    // this._getUser = this._getUser.bind(this);
  }
  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0];
    this.setState({
      userName: username,
    });
    // this._getUser(username);
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.data != state.data) {
      if (nextProps.userReducer.userDetail.group_code === "NI") {
        return {
          profilePic: nextProps.userReducer.userDetail.logo,
          coverPic: nextProps.userReducer.userDetail.cover,
          myData: nextProps.userReducer.userDetail,
          myUserName: nextProps.auth.user.username,
          data : nextProps.data,
          // userName: nextProps.userName,
          dataFriend: nextProps.dataFriend,
        };
      } else {
        return {
          profilePic: nextProps.userReducer.userDetail.foto_profil,
          coverPic: nextProps.userReducer.userDetail.foto_sampul,
          myData: nextProps.userReducer.userDetail,
          myUserName: nextProps.auth.user?.username,
          // userName: nextProps.userName,
          data : nextProps.data,
          dataFriend: nextProps.dataFriend,
        };
      }
    }
  }

  _handleTabFriend() {
    const data = this.props.auth.user;
    if (!isEmpty(data)) {
      return(
        <li>
          <NavLink to={"/" + this.state.userName + "/friends"}>
            Teman
          </NavLink>
        </li>
      )

    }
  }

  _handleButtonAddFriend() {
    if (!isEmpty(this.props.auth.user)) {
      return (
          <>
            <h1 className="mt-2"> {this.state.data?.name} </h1>
            <span>
                {this.state.data?.is_friend ? (
                    <Link>
                      <button className="button small btn-schoolmedia">
                        <i className=""></i> Pesan
                      </button>
                    </Link>
                ) : this.state.data != "" ? (
                    <ButtonAddFriend
                        username={this.state.data?.username}
                        isfriend={this.state.data?.is_friend}
                        isrequest={this.state.data?.is_requested ? true : false}
                    />
                ) : null}
              </span>
          </>
      )
    }
  }

  // _getUser(e) {
  //   const username = e;
  //   let jwtToken = localStorage.getItem("jwtToken");
  //   let config = {
  //     headers: {
  //       Authorization: "Bearer " + jwtToken,
  //     },
  //   };
  //   axios
  //     .post(
  //       api + "profile/get-user",
  //       {
  //         username: e,
  //       },
  //       config
  //     )
  //     .then((res) => {
  //       this.setState(
  //         {
  //           data: res.data.data.item,
  //         },
  //         function () {
  //           console.log(this.state.data?.is_requested);
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       this.setState({ getGuru: false })
  //     });
  // }
  render() {
    const data = this.props.auth.user;
    if (this.state.myUserName === this.state.userName) {
        return (
          <div className="profile profile-header">
            <div className="profile-cover">
              {/* <!-- profile cover --> */}
              {this.state.coverPic === null || this.state.coverPic === "null" ? (
                <img src="/assets/icons/user.svg" alt=""></img>
              ) : (
                <img
                  src={
                    `https://static.schoolmedia.id/public/assets/socmed/` +
                    this.state.myData.group_code +
                    `/cover/` +
                    this.state.coverPic
                  }
                  alt="cover"
                />
              )}
              <Link to="/profile/settings">
                {" "}
                <i className="uil-camera"></i> Edit{" "}
              </Link>
            </div>

            <div className="profile-details">
              <div className="profile-image">
                {this.state.profilePic === null ||
                this.state.profilePic === "null" ? (
                  <img src="/assets/icons/user.svg" alt=""></img>
                ) : (
                  <img
                    src={
                      `https://static.schoolmedia.id/public/assets/socmed/` +
                      this.state.myData.group_code +
                      `/photo/` +
                      `${
                        this.state.myData.group_code != "NI"
                          ? this.state.myData.foto_profil
                          : this.state.myData.logo
                      }`
                    }
                    alt="foto-profile"
                  />
                )}
                <Link to="/profile/settings"> </Link>
              </div>
              <div className="profile-details-info">
                <h1 className="mt-2"> {this.state.data?.name} </h1>
                <Link to="/profile/settings">
                  <button className="button small btn-schoolmedia">
                    <i className="uil-cog "></i> Edit
                  </button>
                </Link>
              </div>
            </div>
            <div className="nav-profile" uk-sticky="offset:61;media : @s">
              <div>
                <nav className="responsive-tab">
                  <ul>
                    <li>
                      <NavLink to={"/" + this.state.userName}>Tentang</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/" + this.state.userName + "/friends"}>
                        Teman
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/" + this.state.userName + "/photos"}>
                        Foto
                      </NavLink>
                    </li>
                    {/* <li><NavLink to={"/" + this.state.userName + "/videos"}>Siaran</NavLink></li> */}
                    <li>
                      <NavLink to={"/" + this.state.userName + "/content"}>
                        Konten
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/" + this.state.userName + "/list-notes"}>
                        Catatan
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/" + this.state.userName + "/following-group"}
                      >
                        Group
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/" + this.state.userName + "/activity"}>
                        Log Aktivitas
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                {/* <div className="uk-visible@s">
                        <a href="#" className="nav-btn-more"> More</a>
                        <div uk-dropdown="pos: bottom-left ; mode:click ">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#">Moves</a></li>
                            </ul>
                        </div>
                    </div>
                */}
              </div>
            </div>
          </div>
        );
    }else {
      return (
        <div className="profile profile-header">
          <div className="profile-cover">
            {/* <!-- profile cover --> */}
            {this.state.data?.photo_cover !== null ? (
              <img
                src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data?.group_code}/cover/${this.state.data?.photo_cover}`}
                alt=""
              />
            ) : (
              <img src="/assets/icons/user.svg" />
            )}
          </div>

          <div className="profile-details">
            <div className="profile-image">
              {this.state.data?.photo_profile !== null ? (
                <img
                  src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.data?.group_code}/photo/${this.state.data?.photo_profile}`}
                  alt=""
                />
              ) : (
                <img src="/assets/icons/user.svg" />
              )}
              {/* <a href="#"> </a> */}
            </div>

            <div className="profile-details-info">
              {this._handleButtonAddFriend()}
            </div>

          </div>
          <div className="nav-profile" uk-sticky="offset:61;media : @s">
            <div>
              <nav className="responsive-tab">
                <ul>
                  <li>
                    <NavLink to={"/" + this.state.userName}>Tentang</NavLink>
                  </li>
                    {this._handleTabFriend()}
                  <li>
                    <NavLink to={"/" + this.state.userName + "/photos"}>
                      Foto
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/" + this.state.userName + "/videos"}>
                      Siaran
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/" + this.state.userName + "/content"}>
                      Konten
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/" + this.state.userName + "/list-notes"}>
                      Catatan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/" + this.state.userName + "/following-group"}>
                      Group
                    </NavLink>
                  </li>
                </ul>
              </nav>
              {/* <div className="uk-visible@s">
                                      <a href="#" className="nav-btn-more"> More</a>
                                      <div uk-dropdown="pos: bottom-left ; mode:click ">
                                          <ul className="uk-nav uk-dropdown-nav">
                                              <li><a href="#">Moves</a></li>
                                          </ul>
                                      </div>
                                  </div> */}
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userReducer: state.userReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    plusState: () => dispatch({ type: "PLUS" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
