import React from 'react'
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AsyncPaginate } from "react-select-async-paginate";
import { friendOptions, getFriend } from "./friendOptions";
import Dropzone from 'react-dropzone';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _, { map, pluck } from 'underscore';
import isEmpty from "lodash/isEmpty";

const api = `${process.env.REACT_APP_API}`;
const apiKey = 'pk.eyJ1IjoiMHgwMGIwIiwiYSI6ImNraXNhcjJxaTBhM3ozMG1wbXEwZ3F2bjEifQ.nwoYdiHXuJEUjrZ33OXPbw';

let location = [];
let arrCoords = [];

export default class EditFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedData: '',
      postContent: null,
      postId: null,
      namaUser: null,
      profilePhoto: null,
      friendTagSelected: [],
      friendSelectedMore: null,
      friendSelectedValue: null,
      files: [],

      feelingList: [],
      feelingSelected: null,
      feelingSelectedName: null,
      locationSelected: null,

      modalPic: false,
      modalFriend: false,
      geolocation: 'jakarta', //set capital city kuvukiland
      coords: []
    }
    // modal
    this.openModalPic = this.openModalPic.bind(this);
    this.hideModalPic = this.hideModalPic.bind(this);

    this.openModalFriend = this.openModalFriend.bind(this);
    this.hideModalFriend = this.hideModalFriend.bind(this);

    this.getAllFeelings = this.getAllFeelings.bind(this);
  }
  componentDidMount() {
    this.getGeolocation()
  }
  static getDerivedStateFromProps(props, state) {
    console.log(props.postData)
    if (props.postId != state.postId) {
      return ({
        postId: props.postId,
        feedData: props.postData,
        postContent: props.postData.content,
        profilePhoto: props.postData.photo_profile,
        groupCode: props.postData.group_code,
        files: JSON.parse(props.postData.medias)
      })
    }
  }
  getGeolocation(e) {
    if (!isEmpty(e)) {
      let val = e.target.value;
      this.setState({ geolocation: val });
    }
    axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + this.state.geolocation + '.json?access_token=' + apiKey)
      .then((response) => {
        location = _.pluck(response.data.features, 'text');
        let arr = [];
        location = _.map(location, function (x) {
          arr.push({ geo: x });
        });
        location = arr;

        let coords = _.pluck(response.data.features, 'geometry');
        coords = _.map(coords, function (x) {
          // arr.push({geo:x});
          arrCoords.push(x.coordinates);
        });
      })
      .catch((error) => {
      });
  }
  openModalPic() {
    this.setState({
      modalPic: true,
    })
  }
  hideModalPic() {
    this.setState({
      modalPic: false,
      modalCreatePost: true,
    })
  }
  openModalFriend() {
    // let userName = this.state.userData.username
    // getFriend(userName)
    this.setState({
      modalFriend: true,
    })
  }
  hideModalFriend() {
    this.setState({
      modalFriend: false,
      modalCreatePost: true,
    })
  }

  getAllFeelings(e) {

    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.get(api + 'feel/all-feel', config)
      .then(res => {
        if (res != null) {
          this.setState({
            feelingList: res.data.data.items
          })
        }
      })
  }

  deleteSelectedFiles(e) {
    console.log(e)
    // let filesBinaryTemp = []
    // let fileArrayTemp = []
    // if (e) {
    //   const hapus = e;

    //   // delete on binary
    //   this.filesBinary.forEach((value, key) => {
    //     if (value.name != hapus) filesBinaryTemp.push(value)
    //   })
    //   // delete on binary
    //   this.fileArray.forEach((value, key) => {
    //     if (value.name != hapus) fileArrayTemp.push(value)
    //   })

    //   this.filesBinary = filesBinaryTemp
    //   this.fileArray = fileArrayTemp

    //   this.setState({
    //     files: this.fileArray
    //   })

    //   if (this.filesBinary.length >= 5) {
    //     this.setState({
    //       maxUpFile: true
    //     })
    //   } else {
    //     this.setState({
    //       maxUpFile: false
    //     })
    //   }
    //   if (this.filesBinary.length === 0 && this.contentToUp.length === 0) {
    //     this.setState({
    //       disableSendBtn: true,
    //     })
    //   }
    // }
  }

  render() {
    let medias = this.state.files
    const files = medias != null ? medias.map(file => {
      return (
        <>
          <div style={{ maxHeight: '9rem', height: '15rem', overflow: 'hidden' }}>
            <img src={file} alt="" />
          </div>
          <button className="button small btn-danger mt-2 mb-2" onClick={e => { this.deleteSelectedFiles(file) }}>Hapus</button>
        </>
      )
      // if (file.type.match('video.*')) {
      //   return (
      //     <>
      //       <video controls>
      //         <source src={file.url} type={file.type} />
      //       </video>
      //       <button className="button small btn-danger mt-2 mb-2" onClick={e => { this.deleteSelectedFiles(file.name) }}>Hapus</button>
      //     </>
      //   )
      // } else {
      //   return (
      //     <>
      //       <div style={{ maxHeight: '9rem', height: '15rem', overflow: 'hidden' }}>
      //         <img src={file.url} alt="" />
      //       </div>
      //       <button className="button small btn-danger mt-2 mb-2" onClick={e => { this.deleteSelectedFiles(file.name) }}>Hapus</button>
      //     </>
      //   )
      // }
    }): null ;
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };
    return (
      <>
        <div className="col-12 mb-2">
          <div className="row">
            <div className="col-2">
              <Link to="" className="opts_account" style={{ width: '3rem', height: '3rem', overflow: 'hidden', borderRadius: '3rem' }}>
                {this.state.profilePhoto === null ?
                  <img src="/assets/icons/user.svg" alt=""></img>
                  :
                  <img src={`https://static.schoolmedia.id/public/assets/socmed/${this.state.groupCode}/photo/` + this.state.profilePhoto} alt=""></img>
                }
              </Link>
            </div>
            <div className="col-10" style={{ marginLeft: "-25px" }}>
              <h6 className="mb-0">
                {this.state.feedData.name}
                {this.state.feedData.feeling_name != null ? ` merasa ${this.state.feedData.feeling_name}` : null}
                {/* {this.state.friendTagSelected.length != 0 ? ` bersama ${this.state.friendTagSelected.slice(0, 2)} ` : null}
                {this.state.friendSelectedMore != null ?
                  <>
                    <span>dan &#0;</span>
                    <span onClick={this.openModalFriend} style={{ background: 'unset', cursor: 'pointer' }}> {this.state.friendSelectedMore} lainnya</span>
                  </>
                  : null}
                {this.state.locationSelected != null ?
                  ` di ${this.state.locationSelected}` :
                  null
                } */}
              </h6>

              <div className="uk-width-1-3" uk-form-custom="target: > * > span:last-child">
                <FormControl>
                  <Select
                    value={this.state.statusSelected}
                    onChange={this.handleSelectStatus}
                    style={{ fontSize: '12px' }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={1}>Publik</MenuItem>
                    <MenuItem value={2}>Teman</MenuItem>
                    <MenuItem value={0}>Hanya Saya</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ maxHeight: '20rem', overflowX: 'hidden', overflowY: 'scroll' }}>

          <textarea name="" id="" cols="60" rows="3"
            value={this.state.postContent}
            onChange={
              (e) => {
                this.setState({ postContent: e.target.value })
              }
            }
          />
          {/* <Editor
            editorStyle={{ width: '100%' }}
            toolbarHidden
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            defaultEditorState={this.state.postContent}
            // onEditorStateChange={this.contentChange}
            mention={{
              separator: ' ',
              trigger: '@',
              suggestions: [
                { text: 'rosed goblok', value: 'rosed goblok', url: 'rosed_goblok' },
                { text: 'BANANA', value: 'banana', url: 'banana' },
                { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                { text: 'DURIAN', value: 'durian', url: 'durian' },
                { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                { text: 'FIG', value: 'fig', url: 'fig' },
                { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
              ],
            }}
            hashtag={{
              separator: ' ',
              trigger: '#',
              suggestions: [
                { text: 'rosed', value: 'rosed goblok', url: 'rosed_goblok' },
              ],
            }}
            placeholder="Apa yang kamu pikirkan?"
          /> */}
          <div className="pl-4 pr-4">
            {files}
          </div>
        </div>
        <div className="col-12">
          <div className="post-new-type pt-3">
            <button className="button button-white" onClick={this.openModalPic}><span className="uil-image mr-2" style={{ fontSize: "20px" }}></span> Foto/Video</button>
            <div className="uk-inline">
              <button className="button button-white mx-1" onClick={this.openModalFriend} type="button"><span className="uil-user mr-2" style={{ fontSize: "20px" }}></span> Tag Teman</button>
            </div>

            <div className="uk-inline">
              <button className="button button-white" type="button"><span className="uil-grin mr-2" style={{ fontSize: "20px" }}></span> Perasaan</button>
              <div uk-dropdown="mode: click">
                <List>
                  <ListItem
                    button
                    onClick={(event) => this.handleFeelingSelect(null)}
                  >
                    <ListItemText primary="Batalkan" />
                  </ListItem>
                  {this.state.feelingList.map(items => {
                    return (
                      <ListItem
                        key={items.id}
                        button
                        selected={this.state.feelingSelected === items.id}
                        onClick={(event) => this.handleFeelingSelect(items)}
                      >
                        <ListItemIcon>

                        </ListItemIcon>
                        <ListItemText primary={items.name} />
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 mt-2 mb-2">
            {/* <Autocomplete
              id="combo-box-demo"
              className="uk-width-1-1"
              options={location}
              onKeyDown={this.getGeolocation}
              onKeyPress={this.getGeolocation}
              onKeyUp={this.getGeolocation}
              onChange={(event, newValue) => {
                this.locationSelected(newValue)
              }}
              getOptionLabel={(option) => option.geo}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Tambah Lokasi" variant="outlined" />}
            /> */}
          </div>
        </div>
        <div className="col-12 uk-flex">
          {this.state.disableSendBtn ?
            <button className="uk-button btn-disable" type="button" style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button> :
            <button className="uk-button btn-schoolmedia" type="button" onClick={this.handleUpload} style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Kirim</button>
          }
          <button className="uk-button btn-white" type="button" onClick={this.hideModalCreatePost} style={{ width: "45%", borderRadius: "8px", fontWeight: "bold" }}>Batal</button>
        </div>
        {/* Modal up foto & video */}
        <Modal
          open={this.state.modalPic}
          onClose={this.hideModalPic}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <Fade in={this.state.modalPic}>
            <div className="bg-white modal-sm uk-grid-collapse uk-grid uk-padding">
              <div className=" uk-width-1-1">
                <h2 className="uk-modal-title text-center">Tambah Foto / Video</h2>
                <span className="uk-modal-close-default" style={{ fontSize: "20px", cursor: "pointer" }} onClick={this.hideModalPic}>
                  <i className="icon-feather-x"></i>
                </span>
                <hr></hr>

                <div className="col-12">
                  <Dropzone
                    onDrop={this.uploadMultipleFiles}
                    multiple={true}
                    accept={'image/jpeg, image/png, video/mp4'}
                    className="dropzone"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        {this.state.maxUpFile ?
                          <>
                            <div className='dropzone-max dropzone'>
                              <p>mencapai batas maksimum upload file</p>
                            </div>
                          </> :
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p className="font-sm">Seret dan lepas file disini, atau klik untuk memilih file</p>
                          </div>
                        }
                        <aside>
                          <Slider {...settings}>
                            {files}
                          </Slider>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                </div>


                <div className="col-12">
                  <button
                    className={`button uk-button ${this.filesBinary !== [] ? 'btn-schoolmedia' : 'btn-disable btn-disabled'}`}
                    onClick={this.hideModalPic}
                    type="button"
                    style={{ width: "100%", borderRadius: "8px", fontWeight: "bold" }}
                  >
                    Tambahkan
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* modal friend */}
        <Modal
          open={this.state.modalFriend}
          onClose={this.hideModalFriend}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <Fade in={this.state.modalFriend}>
            <div className="bg-white modal-sm uk-grid-collapse uk-grid pt-4 uk-padding">
              <div className=" uk-width-1-1">
                <h2 className="uk-modal-title text-center">Tandai Teman</h2>
                <span className="uk-modal-close-default" style={{ fontSize: "20px", cursor: "pointer" }} onClick={this.hideModalFriend}>
                  <i className="icon-feather-x"></i>
                </span>
                <hr></hr>

                <AsyncPaginate
                  value={this.state.friendSelectedValue}
                  isMulti
                  closeMenuOnSelect={false}
                  loadOptions={friendOptions}
                  onChange={this.handleFriendTagSelect}
                />

              </div>
              <div className="uk-width-1-1 mt-2">
                <button
                  className={`button uk-button btn-schoolmedia`}
                  onClick={this.hideModalFriend}
                  type="button"
                  style={{ width: "100%", borderRadius: "8px", fontWeight: "bold" }}
                >
                  Tambahkan
                  </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}