import React, {createRef} from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import SettingGeneral from './SettingGeneral';
import SettingNonactive from './SettingNonactive';
import SettingProfile from './SettingProfile';
import SettingSecurity from './SettingSecurity';
import isEmpty from "lodash/isEmpty";

class SettingIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      authUser: [],
    }
    this.contentRender = this.contentRender.bind(this);
  }
  wrapper = createRef();
  componentDidMount() {
    if (!isEmpty(localStorage.getItem('redirect'))) 
      localStorage.removeItem('redirect');
    
    const node = this.wrapper.current;
    this.setState({
      userData: this.props.userReducer.userDetail
    })
  }
  componentDidUpdate(prevProps){
    if(this.props.userReducer !== prevProps.userReducer){
      this.setState({
        userData: this.props.userReducer.userDetail
      })
    }
    if(this.props.auth !== prevProps.auth){
      this.state({
        authUser: this.props.auth.user
      })
    }
  }
  contentRender() {
      let userDetail = this.state.userData;
      let authData = this.state.authUser;

      return (
        <>
          <li>
            <SettingGeneral
              data={userDetail}
              authData={authData}
            />
          </li>
          <li>
            <SettingProfile data={userDetail} className="none" />
          </li>
          <li>
            <SettingSecurity data={userDetail} className="none" />
          </li>
          <li>
            <SettingNonactive data={userDetail} className="none" />
          </li>
        </>
      )
  }
  render() {
    return (
      <div className="main_content" ref={this.wrapper}>
        <div className="main_content_inner">
          <div className="uk-position-relative uk-grid pl-4">
            <div className="uk-width-1-4@m uk-flex-last@m pl-sm-2 mt-sm-3">
              <nav className="responsive-tab style-3 setting-menu" uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
                <ul className="shadow p-3 pr-0 pl-0 uk-tab uk-tab-right" style={{overflow: 'hidden'}} uk-tab="connect: #component-tab-right; animation: uk-animation-fade">
                  <li>
                    <a aria-expanded="true"> <i className="uil-cog"></i> Umum </a>
                  </li>
                  <li>
                    <a aria-expanded="false"> <i className="uil-user"></i> Profil </a>
                  </li>
                  <li>
                    <a aria-expanded="false">  <i className="uil-shield-check"></i> Keamanan</a>
                  </li>
                  {/* <li>
                    <a aria-expanded="false"> <i className="icon-material-outline-power-settings-new"></i> Nonaktifkan Akun</a>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className="uk-padding bg-white rounded-sm uk-width-2-3@m mt-sm-3 pl-sm-0 p-sm-4" id="">
              <ul id="component-tab-right" className="uk-switcher" style={{ touchAction: "pan-y pinch-zoom" }}>
                {this.contentRender()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}

export default connect(mapStateToProps)(SettingIndex);