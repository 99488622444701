import React from 'react';
import axios from 'axios';
import SideNews from './components/SideNews';

const api = `${process.env.REACT_APP_SM_NEWS}`;

export default class RightBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      listNews: [],
      isLoading: false,
    }
  }
  componentDidMount() {
    var instance = axios.create();

    delete instance.defaults.headers.common.Authorization
    this.setState({
      isLoading: true,
    })
    instance.get(api + 'SosmedLandingPage')
      .then((res) => {
        this.setState({
          listNews: res.data,
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  render() {
    return (
      // <!-- sidebar -->
      <div className="uk-width-expand right-bar">
        <SideNews data={this.state.listNews} loading={this.state.isLoading}/>
      </div>
    )
  }
}