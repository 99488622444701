/**
 * @author Nando - greycat
 * @since 2020
 * @require CryptoJS
 * Description : 
 * This is kirito helper translanguage from PHP to JS
 */

import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Hex from "crypto-js/format-hex";

class KiritoHelper {
    dontCry(string, action = "e") {
      let secret_key = "SchoolM3d14_S3{Curi}ty";
      let secret_iv = "SchoolM3d14_S3{Curi}ty";

      var output = false;
      let encrypt_method = "AES-256-CBC";
      let key = String(sha256(secret_key)).toString(Hex).substr(0, 32);
      let iv = String(sha256(secret_iv)).toString(Hex).substr(0, 16);

      if (action == 'e') { // Encryption
        output = AES.encrypt(String(string), Utf8.parse(key), {
          iv: Utf8.parse(iv),
        }).toString(); // First Base64 encoding, by default (see 1.)

        let output2ndB64 = Utf8.parse(output).toString(Base64); // Second Base64 encoding (see 1.)
        return output2ndB64;
      }
      else { // Decryption
        let cipher = Base64.parse(string).toString(Utf8);
        let decrypted = AES.decrypt(cipher, Utf8.parse(key), {
          iv: Utf8.parse(iv),
        }).toString(Utf8);
        console.log('dec1', string.toString(Utf8));
        output = decrypted;
      }

      return output;
    } 
}

export const { dontCry } = new KiritoHelper();