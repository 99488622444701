import axios from 'axios';
import { SEARCHING, SEARCH_SUCCESS, SEARCH_MORE, SEARCH_ERROR } from "./types";

const api = `${process.env.REACT_APP_API}`;

export const getSearchResult = (data, count) => {
  return (dispatch) => {
    if(count > 1){
      dispatch({
        type: SEARCH_MORE,
        searchKey: data,
        data: [],
      })
    } else {
      dispatch({
        type: SEARCHING,
        searchKey: data,
        data: [],
      })
    }
    axios.get(api + `search/get-search?keyword=${data}&page=${count}`)
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        data: res.data.data.data,
      })
    }).catch(err => {
      dispatch({
        type: SEARCH_ERROR,
        data: null
      })
    })
  }

}