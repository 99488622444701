// Global State
const globalState = {
  test: 0,
}

// Reducer
const globalReducer = (state = globalState, action) => {
  if(action.type === "PLUS"){
    return{
      ...state,
      test: state.test + 1,
    }
  }
  return state;
}

export default globalReducer;