import React from "react";
import {Link} from 'react-router-dom';

export default class JoinedGroupCard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      data: ''
    }
  }
  componentDidMount() {
  }

  static getDerivedStateFromProps(props, state){
    if(props.data != state.data){
      if(props.data != undefined){
        return({
          data: props.data
        })
      }
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="group-card bg-white">
          <div className="group-card-thumbnail">
            <img src="/assets/images/group/group-cover-1.jpg" alt="" />
          </div>

          <div className="group-card-content">
            <h3> {this.state.data.name} </h3>
            <p className="info"> <a href="#"> <span> {this.state.data.information} </span> </a>
              {/* <a href="#"> <span> 1.7k post a day </span> </a> */}
            </p>
            {/* <div className="group-card-member-wrap">
              <div className="avatar-group uk-width-auto">
                <img alt="Image placeholder" src="/assets/images/avatars/avatar-2.jpg" className="avatar avatar-xs rounded-circle" />
                <img alt="Image placeholder" src="/assets/images/avatars/avatar-4.jpg" className="avatar avatar-xs rounded-circle" />
              </div>
              <div className="uk-width-expand pl-2">
                <p> <strong>Stella</strong> and 2 freind are members </p>
              </div>
            </div> */}

            <div className="group-card-btns">
              <Link to={{pathname: `/groups/${this.state.data.slug}`, groupCode: this.state.data.code }} className="button btn-default small"> Lihat </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
