import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import { MaintenanceOverlay } from "../component/pages/error_pages/Maintenance";
import { withRouter, useLocation } from 'react-router-dom';
import ThemeLeftBar from '../component/pages/groups/components/ThemeLeftBar';

// Route for groups component
const ForumRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} component={(props) => (
      <>
        {/* <GroupLeftBar/> */}
        <ThemeLeftBar />
        {/* <MaintenanceOverlay /> */}
        <Component {...props} />
      </>
    )}
    />
  );
};

export default ForumRoute;
