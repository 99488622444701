import ButtonAddFriend from 'component/partials/global/ButtonAddFriend';
import React from 'react';
import { Link } from 'react-router-dom';

export default class SearchAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataResult: '',
      dataPerson: [],
      userData: ''
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    console.log(nextProps)
    if (nextProps.dataResult != state.dataResult) {
      return ({
        dataResult: nextProps.dataResult,
        userData: nextProps.userData,
      })
    }
  }
  render() {
    return (
      <>
        <div className="uk-padding">
          {this.state.dataResult.user != undefined ?
            <>
              <div className="friend-card">
                <h2 className="mb-4"> Orang </h2>
              </div>

              <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                {this.state.dataResult.user.slice(0, 4).map((val, key) => {
                  return (
                    <div key={key}>
                      <div className="friend-card mt-4">
                        <Link to={`/${val.username}`}>
                          <div className="uk-width-auto">
                            {val.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${val.group_code}/photo/${val.photo_profile}`} alt="" /> :
                              <img src='/assets/icons/user.svg' />
                            }
                            <span className="online-dot"></span>
                          </div>
                        </Link>
                        <div className="uk-width-expand">
                          <Link to={`/${val.username}`}><h3> {val.name} </h3></Link>
                          <p> {val.username} </p>
                          <span> 1week ago </span>

                          {val.username != this.state.userData.username ?
                            <div className="friend-card-btns">
                              <ButtonAddFriend 
                                username={val.username} 
                                isfriend={val.is_friend}
                                isrequest={val.is_requested}
                              />
                              {/* <a href="#" className="button btn-default small"> Remove </a> */}
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid" uk-grid>
                {/* <Link to="/search/person" className="button btn-soft-schoolmedia large mx-auto mt-4">Lihat Semua</Link> */}
              </div>
            </>
            : null
          }

        </div>
      </>
    )
  }
}