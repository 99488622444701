import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import { withRouter, useLocation } from 'react-router-dom';
import LeftSideBar from '../component/partials/LeftSideBar';
import { login_sso, redirect_sso, caught_sso, status_login, logout_sso } from "utils/sso";
// Route for auth component
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  // state in stateless component
  let [authStatus, setauthStatus] = useState(true);

  if (localStorage.getItem('jwtToken') === null) {
    return <Redirect to="/" />;
  }

  status_login().catch(function(err) {
    // console.log('stat', err.response.statusText);
    // if (err.response.statusText === 'Unauthorized') {
      // console.log(testVar);
      if (authStatus == true) {
        setauthStatus(false);
      }
    // }
  });

  if (authStatus === false) {
    return <Redirect to="/" />;
  }
  return (
    <Route {...rest} component={(props) => (
      <>
        <LeftSideBar />
        <Component {...props} />
      </>
    )}
    />
  );
};

export default AuthenticatedRoute;