import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getUserDetail } from 'redux/actions/UserAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadError, uploadOnProgress, uploadSuccess } from "redux/actions/toastsAction";

const api = `${process.env.REACT_APP_API}`;
const apiUM = `${process.env.REACT_APP_UM_API}`;
let jwtToken = localStorage.getItem("jwtToken");

class FormAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupCode: null,
      data: {},
      dataNI: {},
      dataUser: {},

      isSame: false,
      indo: true,

      dataCountry: null,
      dataProvince: null,
      dataRegency: null,
      dataDistrict: null,
      dataVillage: null,
      dataPostCode: null,

      dataDomisiliProvince: null,
      dataDomisiliRegency: null,
      dataDomisiliDistrict: null,
      dataDomisiliVillage: null,
      dataDomisiliPostCode: null,

      // Tinggal / Institusi
      selectedCountryId: null,
      selectedProvinceId: null,
      selectedRegencyId: null,
      selectedDistrictId: null,
      selectedVillageId: null,
      selectedPostCode: null,

      selectedCountryId: null,
      selectedProvinceName: null,
      selectedRegencyName: null,
      selectedDistrictName: null,
      selectedVillageName: null,

      // isDisable
      disableProvince: true,
      disableRegency: true,
      disableDistrict: true,
      disableVillage: true,
      disablePostCode: true,
      disableDomisiliRegency: true,
      disableDomisiliDistrict: true,
      disableDomisiliVillage: true,
      disableDomisiliPostCode: true,

      // Domisili / Selain Institusi
      domisiliSelectedProvinceId: null,
      domisiliSelectedRegencyId: null,
      domisiliSelectedDistrictId: null,
      domisiliSelectedVillageId: null,
      domisiliSelectedPostCode: null,

      domisiliSelectedProvinceName: null,
      domisiliSelectedRegencyName: null,
      domisiliSelectedDistrictName: null,
      domisiliSelectedVillageName: null,
    }
    this.isSame = this.isSame.bind(this);
    // Tinggal / Institusi
    this.selectCountry = this.selectCountry.bind(this)
    this.selectProvince = this.selectProvince.bind(this)
    this.selectRegency = this.selectRegency.bind(this)
    this.selectDistrict = this.selectDistrict.bind(this)
    this.selectVillage = this.selectVillage.bind(this)
    this.selectPostCode = this.selectPostCode.bind(this)

    // Domisili / Selain Institusi
    this.domisiliSelectProvince = this.domisiliSelectProvince.bind(this)
    this.domisiliSelectRegency = this.domisiliSelectRegency.bind(this)
    this.domisiliSelectDistrict = this.domisiliSelectDistrict.bind(this)
    this.domisiliSelectVillage = this.domisiliSelectVillage.bind(this)
    this.domisiliSelectPostCode = this.domisiliSelectPostCode.bind(this)

  }

  componentDidMount() {
    this.getCountry()
    this.getProvince('tinggal', 1)
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data != state.data) {
      if (props.data.group_code === 'NI') {
        return {
          groupCode: 'NI',
          data: props.data,
          dataNI: props.data,
          selectedCountryId: props.data.negara,
          selectedProvinceId: props.data.provinsi,
          selectedRegencyId: props.data.kabupaten,
          selectedDistrictId: props.data.kecamatan,
          selectedVillageId: props.data.desa,
          selectedCountryName: props.data.negara_nama,
          selectedProvinceName: props.data.provinsi_nama,
          selectedRegencyName: props.data.kabupaten_nama,
          selectedDistrictName: props.data.kecamatan_nama,
          selectedVillageName: props.data.desa_nama,
          selectedPostCode: props.data.kode_pos
        }
      } else {
        // check address
        // if(props.data.kabupaten_tinggal != null){
        //   return {
        //     disableRegency : true,
        //   }
        // }
        return {
          groupCode: props.data.group_code,
          data: props.data,
          dataUser: props.data,
          // Tinggal
          selectedCountryId: props.data.negara,
          selectedProvinceId: props.data.provinsi_tinggal,
          selectedRegencyId: props.data.kabupaten_tinggal,
          selectedDistrictId: props.data.kecamatan_tinggal,
          selectedVillageId: props.data.desa_tinggal,
          selectedCountryName: props.data.negara_nama,
          selectedProvinceName: props.data.provinsi_tinggal_nama,
          selectedRegencyName: props.data.kabupaten_tinggal_nama,
          selectedDistrictName: props.data.kecamatan_tinggal_nama,
          selectedVillageName: props.data.desa_tinggal_nama,
          // Domisili
          domisiliSelectedProvinceId: props.data.provinsi_domisili,
          domisiliSelectedRegencyId: props.data.kabupaten_domisili,
          domisiliSelectedDistrictId: props.data.kecamatan_domisili,
          domisiliSelectedVillageId: props.data.desa_domisili,
          domisiliSelectedProvinceName: props.data.provinsi_domisili_nama,
          domisiliSelectedRegencyName: props.data.kabupaten_domisili_nama,
          domisiliSelectedDistrictName: props.data.kecamatan_domisili_nama,
          domisiliSelectedVillageName: props.data.desa_domisili_nama,
          domisiliSelectedPostCode: props.data.kode_pos_domisili
        }
      }
    }
  }

  isSame(e) {
    e.preventDefault()
    this.setState({
      isSame: false,
      selectedProvinceId: this.state.domisiliSelectedProvinceId,
      selectedRegencyId: this.state.domisiliSelectedRegencyId,
      selectedDistrictId: this.state.domisiliSelectedDistrictId,
      selectedVillageId: this.state.domisiliSelectedVillageId,
      selectedPostCode: this.state.domisiliSelectedPostCode,

      selectedProvinceName: this.state.domisiliSelectedProvinceName,
      selectedRegencyName: this.state.domisiliSelectedRegencyName,
      selectedDistrictName: this.state.domisiliSelectedDistrictName,
      selectedVillageName: this.state.domisiliSelectedVillageName,

      dataUser: {
        ...this.state.dataUser,
        kecamatan_domisili: e.value,
        kecamatan_domisili_nama: e.label,
        desa_domisili: null,
        kode_pos_domisili: null,
        rt_tinggal: this.state.dataUser.rt_domisili,
        rw_tinggal: this.state.dataUser.rw_domisili,
        alamat_tinggal: this.state.dataUser.alamat_domisili
      }
    })
    setTimeout(() => {
      this.setState({
        isSame: true
      })
    }, 300);
  }

  notSame(e) {
    e.preventDefault()
    this.setState({
      isSame: false,
      selectedProvinceId: null,
      selectedRegencyId: null,
      selectedDistrictId: null,
      selectedVillageId: null,
      selectedPostCode: null,

      selectedProvinceName: null,
      selectedRegencyName: null,
      selectedDistrictName: null,
      selectedVillageName: null,

      dataUser: {
        ...this.state.dataUser,
        rt_tinggal: null,
        rw_tinggal: null,
        alamat_tinggal: null
      }
    })
    setTimeout(() => {
      this.setState({
        isSame: true
      })
    }, 500);
    // this.setState({
    //   isSame: true
    // })
  }

  getCountry() {
    axios.get(apiUM + 'wilayah/negara')
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        this.setState({
          dataCountry: data,
        })
      })
  }
  getProvince(ket, value) {
    axios.get(`${apiUM}wilayah/provinsi?kode_negara=${value}`)
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        if (ket == "domisili") {
          this.setState({
            dataDomisiliProvince: data,
          })
        } else if (ket == "tinggal") {
          this.setState({
            dataProvince: data,
          })
        }
      })
  }
  getRegency(ket, value) {
    axios.get(`${apiUM}wilayah/kota?kode_provinsi=${value}`)
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        if (this.state.groupCode === 'NI') {
          this.setState({
            dataRegency: data,
          })
        } else {
          if (ket == "domisili") {
            this.setState({
              dataDomisiliRegency: data,
            })
          } else if (ket == "tinggal") {
            this.setState({
              dataRegency: data,
            })
          }
        }
      })
  }

  getDistrict(ket, value) {
    axios.get(`${apiUM}wilayah/kecamatan?kode_kabupaten=${value}`)
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        if (this.state.groupCode === 'NI') {
          this.setState({
            dataDistrict: data,
          })
        } else {
          if (ket == "domisili") {
            this.setState({
              dataDomisiliDistrict: data,
            })
          } else if (ket == "tinggal") {
            this.setState({
              dataDistrict: data,
            })
          }
        }
      })
  }

  getVillage(ket, value) {
    axios.get(`${apiUM}wilayah/kelurahan?kode_kecamatan=${value}`)
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        if (this.state.groupCode === 'NI') {
          this.setState({
            dataVillage: data,
          })
        } else {
          if (ket == "domisili") {
            this.setState({
              dataDomisiliVillage: data,
            })
          } else if (ket == "tinggal") {
            this.setState({
              dataVillage: data,
            })
          }
        }
      })
  }

  getPostCode(ket, value) {
    axios.get(`${apiUM}wilayah/kodepos?kode_kelurahan=${value}`)
      .then((res) => {
        let data = res.data.results;
        data.forEach(obj => this.renameKey(obj, 'id', 'value'));
        data.forEach(obj => this.renameKey(obj, 'text', 'label'));
        if (this.state.groupCode === 'NI') {
          this.setState({
            dataPostCode: data,
          })
        } else {
          if (ket == "domisili") {
            this.setState({
              dataDomisiliPostCode: data,
            })
          } else if (ket == "tinggal") {
            this.setState({
              dataPostCode: data,
            })
          }
        }
      })
  }

  // ## Select ## //
  // Tinggal /Institusi
  selectProvince(e) {
    console.log(e)
    if (e) {
      this.setState({
        selectedProvinceId: e.value,
        selectedProvinceName: e.label,

        // reselect
        selectedRegencyId: null,
        selectedRegencyName: null,
        selectedDistrictId: null,
        selectedDistrictName: null,
        selectedVillageId: null,
        selectedVillageName: null,
        selectedPostCode: null,

        disableRegency: false,
        disableDistrict: true,
        disableVillage: true,
        disablePostCode: true,
      })
      this.setState({
        dataUser: {
          ...this.state.dataUser,
          kabupaten_tinggal: null,
          kecamatan_tinggal: null,
          desa_tinggal: null,
          kode_pos_tinggal: null
        },
        dataNI: {
          ...this.state.dataNI,
          kabupaten: null,
          kecamatan: null,
          desa: null,
          kode_pos: null
        }
      })
      this.getRegency('tinggal', e.value);
    }
  }
  selectRegency(e) {
    if (e) {
      this.setState({
        selectedRegencyId: e.value,
        selectedRegencyName: e.label,

        // reselect
        selectedDistrictId: null,
        selectedDistrictName: null,
        selectedVillageId: null,
        selectedVillageName: null,
        selectedPostCode: null,

        disableDistrict: false,
        disableVillage: true,
        disablePostCode: true,
        dataUser: {
          ...this.state.dataUser,
          kabupaten_tinggal: e.value,
          kabupaten_tinggal_nama: e.label,
          kecamatan_tinggal: null,
          desa_tinggal: null,
          kode_pos_tinggal: null
        },
        dataNI: {
          ...this.state.dataNI,
          kabupaten: e.value,
          kabupaten_nama: e.label,
          kecamatan: null,
          desa: null,
          kode_pos: null
        }
      })
      this.getDistrict('tinggal', e.value);
    }
  }
  selectDistrict(e) {
    if (e) {
      this.setState({
        selectedDistrictId: e.value,
        selectedDistrictName: e.label,

        // reselect
        selectedVillageId: null,
        selectedVillageName: null,
        selectedPostCode: null,

        disableVillage: false,
        disablePostCode: true,
        dataUser: {
          ...this.state.dataUser,
          kecamatan_tinggal: e.value,
          kecamatan_tinggal_nama: e.label,
          desa_tinggal: null,
          kode_pos_tinggal: null
        },
        dataNI: {
          ...this.state.dataNI,
          kecamatan: e.value,
          kecamatan_nama: e.label,
          desa: null,
          kode_pos: null
        }
      })
      this.getVillage('tinggal', e.value);
    }
  }
  selectVillage(e) {
    if (e) {
      this.setState({
        selectedVillageId: e.value,
        selectedVillageName: e.label,

        // reselect
        selectedPostCode: null,

        disablePostCode: false,
        dataUser: {
          ...this.state.dataUser,
          desa_tinggal: e.value,
          desa_tinggal_nama: e.label,
          kode_pos_tinggal: null
        },
        dataNI: {
          ...this.state.dataNI,
          desa: e.value,
          desa_nama: e.label,
          kode_pos: null
        }
      })
      this.getPostCode('tinggal', e.value)
    }
  }
  selectPostCode(e) {
    if (e) {
      this.setState({
        selectedPostCode: e.value,
        dataUser: {
          ...this.state.dataUser,
          kode_pos_tinggal: e.value
        },
        dataNI: {
          ...this.state.dataNI,
          kode_pos: e.value
        }
      })
    }
  }

  // Domisili / Selain Institusi
  selectCountry(e) {
    if (e.value != 1) {
      this.setState({
        indo: false,
      })
    } else {
      this.setState({
        indo: true,
      })
    }
    if (e) {
      this.setState({
        selectedCountryId: e.value,
        selectedCountryName: e.label,

        // reselect
        // reselect
        selectedRegencyId: null,
        selectedRegencyName: null,
        selectedDistrictId: null,
        selectedDistrictName: null,
        selectedVillageId: null,
        selectedVillageName: null,
        selectedPostCode: null,

        domisiliSelectedProvinceId: null,
        domisiliSelectedProvinceName: null,
        domisiliSelectedRegencyId: null,
        domisiliSelectedRegencyName: null,
        domisiliSelectedDistrictId: null,
        domisiliSelectedDistrictName: null,
        domisiliSelectedVillageId: null,
        domisiliSelectedVillageName: null,
        domisiliSelectedPostCode: null,

        disableDomisiliProvince: false,
        disableDomisiliRegency: true,
        disableDomisiliDistrict: true,
        disableDomisiliVillage: true,
        disableDomisiliPostCode: true,

        dataUser: {
          ...this.state.dataUser,
          rt_tinggal: this.state.dataUser.rt_domisili,
          rw_tinggal: this.state.dataUser.rw_domisili,
          alamat_tinggal: this.state.dataUser.alamat_domisili,
        }
      })
      this.getProvince('domisili', e.value);
    }
  }
  domisiliSelectProvince(e) {
    if (e) {
      this.setState({
        domisiliSelectedProvinceId: e.value,
        domisiliSelectedProvinceName: e.label,

        // reselect
        domisiliSelectedRegencyId: null,
        domisiliSelectedRegencyName: null,
        domisiliSelectedDistrictId: null,
        domisiliSelectedDistrictName: null,
        domisiliSelectedVillageId: null,
        domisiliSelectedVillageName: null,
        domisiliSelectedPostCode: null,

        disableDomisiliRegency: false,
        disableDomisiliDistrict: true,
        disableDomisiliVillage: true,
        disableDomisiliPostCode: true,

        dataUser: {
          ...this.state.dataUser,
          rt_tinggal: this.state.dataUser.rt_domisili,
          rw_tinggal: this.state.dataUser.rw_domisili,
          alamat_tinggal: this.state.dataUser.alamat_domisili,
        }
      })
      this.getRegency('domisili', e.value);
    }
  }

  domisiliSelectRegency(e) {
    if (e) {
      this.setState({
        domisiliSelectedRegencyId: e.value,
        domisiliSelectedRegencyName: e.label,

        // reselect
        domisiliSelectedDistrictId: null,
        domisiliSelectedDistrictName: null,
        domisiliSelectedVillageId: null,
        domisiliSelectedVillageName: null,
        domisiliSelectedPostCode: null,

        disableDomisiliDistrict: false,
        disableDomisiliVillage: true,
        disableDomisiliPostCode: true,

        dataUser: {
          ...this.state.dataUser,
          kabupaten_domisili: e.value,
          kabupaten_domisili_nama: e.label,
          kecamatan_domisili: null,
          desa_domisili: null,
          kode_pos_domisili: null
        }
      })
      this.getDistrict('domisili', e.value);
    }
  }
  domisiliSelectDistrict(e) {
    if (e) {
      this.setState({
        domisiliSelectedDistrictId: e.value,
        domisiliSelectedDistrictName: e.label,

        // reselect
        domisiliSelectedVillageId: null,
        domisiliSelectedVillageName: null,
        domisiliSelectedPostCode: null,

        disableDomisiliVillage: false,
        disableDomisiliPostCode: true,

        dataUser: {
          ...this.state.dataUser,
          kecamatan_domisili: e.value,
          kecamatan_domisili_nama: e.label,
          desa_domisili: null,
          kode_pos_domisili: null
        }
      })
      this.getVillage('domisili', e.value);
    }
  }
  domisiliSelectVillage(e) {
    if (e) {
      this.setState({
        domisiliSelectedVillageId: e.value,
        domisiliSelectedVillageName: e.label,

        // reselect
        domisiliSelectedPostCode: null,

        disableDomisiliPostCode: false,

        dataUser: {
          ...this.state.dataUser,
          desa_domisili: e.value,
          desa_domisili_nama: e.label,
          kode_pos_domisili: null
        }
      })
      this.getPostCode('domisili', e.value)
    }
  }
  domisiliSelectPostCode(e) {
    if (e) {
      this.setState({
        domisiliSelectedPostCode: e.value,
        dataUser: {
          ...this.state.dataUser,
          kode_pos_domisili: e.value
        }
      })
    }
  }

  renameKey(obj, old_key, new_key) {
    // check if old key = new key   
    if (old_key !== new_key) {
      Object.defineProperty(obj, new_key, // modify old key 
        // fetch description from object 
        Object.getOwnPropertyDescriptor(obj, old_key));
      delete obj[old_key]; // delete old key 
    }
  }

  edit(e) {
    e.preventDefault();
    let requestData = null;
    const formData = new FormData();
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: this.props.uploading
    };

    // check group code
    if (this.props.data.group_code === 'NI') {
      let data = this.state.dataNI;
      delete data.password;
      requestData = {
        ...data,
        negara: this.state.selectedCountryId,
        provinsi: this.state.selectedProvinceId,
        kabupaten: this.state.selectedRegencyId,
        kecamatan: this.state.selectedDistrictId,
        desa: this.state.selectedVillageId,
        // negara: this.state.selectedNegaraId,
        // negara_nama: this.state.selectedCountryName,
        provinsi_nama: this.state.selectedProvinceName,
        kabupaten_nama: this.state.selectedRegencyName,
        kecamatan_nama: this.state.selectedDistrictName,
        desa_nama: this.state.selectedVillageName,
        kode_pos: this.state.selectedPostCode,
        rt: data.rt,
        rw: data.rw,
        alamat_1: data.alamat_1,
        alamat_2: data.alamat_2,
        bujur: data.bujur,
        lintang: data.lintang
      };
    } else {
      let data = this.state.dataUser;
      delete data.password;
      requestData = {
        ...data,
        // tinggal
        negara: this.state.selectedCountryId,
        provinsi_tinggal: this.state.selectedProvinceId,
        kabupaten_tinggal: this.state.selectedRegencyId,
        kecamatan_tinggal: this.state.selectedDistrictId,
        desa_tinggal: this.state.selectedVillageId,
        // negara_nama_tinggal: this.state.selectedCountryName,
        provinsi_nama_tinggal: this.state.selectedProvinceName,
        kabupaten_nama_tinggal: this.state.selectedRegencyName,
        kecamatan_nama_tinggal: this.state.selectedDistrictName,
        desa_nama_tinggal: this.state.selectedVillageName,
        kode_pos_tinggal: this.state.selectedPostCode,
        rt_tinggal: data.rt_tinggal,
        rw_tinggal: data.rw_tinggal,
        alamat_tinggal: data.alamat_tinggal,
        // domisili
        provinsi_domisili: this.state.domisiliSelectedProvinceId,
        kabupaten_domisili: this.state.domisiliSelectedRegencyId,
        kecamatan_domisili: this.state.domisiliSelectedDistrictId,
        desa_domisili: this.state.domisiliSelectedVillageId,
        provinsi_nama_domisili: this.state.domisiliSelectedProvinceName,
        kabupaten_nama_domisili: this.state.domisiliSelectedRegencyName,
        kecamatan_nama_domisili: this.state.domisiliSelectedDistrictName,
        desa_nama_domisili: this.state.domisiliSelectedVillageName,
        kode_pos_domisili: this.state.domisiliSelectedPostCode,
        rt_domisili: data.rt_domisili,
        rw_domisili: data.rw_domisili,
        alamat_domisili: data.alamat_domisili,
      }
    }
    formData.append('json', JSON.stringify(requestData));
    axios.post(api + 'profile/update-user',
      formData,
      config
    ).then((res) => {
      const status = res
      this._getDetailUser();
      this.props.upload_success(status)
    }).catch((err) => {
      const status = err.response
      this.props.upload_error(status)
    })
  }

  _getDetailUser() {
    const data = this.props.auth.user;
    this.props.getUserDetail(data)
  }

  bujurValidate(e) {
    if (e === '' || e === null || e === undefined) {
      this.setState({ dataNI: { ...this.state.dataNI, bujur: e } })
    } else {
      if (e.match(/^[\.0-9-]*$/)) {
        this.setState({ dataNI: { ...this.state.dataNI, bujur: e } })
      }
    }
  }
  lintangValidate(e) {
    if (e === '' || e === null || e === undefined) {
      this.setState({ dataNI: { ...this.state.dataNI, lintang: e } })
    } else {
      if (e.match(/^[\.0-9-]*$/)) {
        this.setState({ dataNI: { ...this.state.dataNI, lintang: e } })
      }
    }
  }

  renderResidence() {
    if (this.state.isSame) {
      return (
        <form action="POST" className="uk-grid" onSubmit={this.edit.bind(this)}>
          {/* alamat tinggal */}
          <div className="uk-width-1-1@s uk-width-1-1 pr-2">
            <p className="mb-3 font-sm">Alamat Tinggal</p>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Provinsi</label>
              <Select
                options={this.state.dataProvince}
                onChange={this.selectProvince}
                defaultValue={this.state.selectedProvinceId !== null ? { value: this.state.selectedProvinceId, label: this.state.selectedProvinceName } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Kabupaten / Kota</label>
              <Select
                options={this.state.dataRegency}
                onChange={this.selectRegency}
                isDisabled={this.state.disableRegency}
                defaultValue={this.state.selectedRegencyId != null ? { value: this.state.selectedRegencyId, label: this.state.selectedRegencyName } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Kecamatan</label>
              <Select
                options={this.state.dataDistrict}
                onChange={this.selectDistrict}
                isDisabled={this.state.disableDistrict}
                defaultValue={this.state.selectedDistrictId != null ? { value: this.state.selectedDistrictId, label: this.state.selectedDistrictName } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Desa / Kelurahan</label>
              <Select
                options={this.state.dataVillage}
                onChange={this.selectVillage}
                isDisabled={this.state.disableVillage}
                defaultValue={this.state.selectedVillageId != null ? { value: this.state.selectedVillageId, label: this.state.selectedVillageName } : null}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Kode POS</label>
              <Select
                options={this.state.dataPostCode}
                onChange={this.selectPostCode}
                isDisabled={this.state.disablePostCode}
                defaultValue={this.state.selectedPostCode != null ? { value: this.state.selectedPostCode, label: this.state.selectedPostCode } : null}
              />
            </div>
          </div>
          {/*  */}
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">RT</label>
              <input className="uk-input uk-form-small" type="number" placeholder="Input"
                defaultValue={this.state.dataUser.rt_tinggal}
                onChange={
                  (e) => {
                    this.setState({ dataUser: { ...this.state.dataUser, rt_tinggal: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">RW</label>
              <input className="uk-input uk-form-small" type="number" placeholder="Input"
                defaultValue={this.state.dataUser.rw_tinggal}
                onChange={
                  (e) => {
                    this.setState({ dataUser: { ...this.state.dataUser, rw_tinggal: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Alamat</label>
              <input className="uk-input uk-form-small" type="text" placeholder="Input"
                defaultValue={this.state.dataUser.alamat_tinggal}
                onChange={
                  (e) => {
                    this.setState({ dataUser: { ...this.state.dataUser, alamat_tinggal: e.target.value } })
                  }
                }
              />
            </div>
          </div>
          {this.state.selectedPostCode != null ?
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1 pt-3">
                <button className="button small btn-schoolmedia">Simpan</button>
              </div>
            </div> :
            this.state.indo ?
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1 pt-3">
                  <div className="button small btn-disabled">Pilih hingga kode pos</div>
                </div>
              </div> :
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1 pt-3">
                  <button className="button small btn-schoolmedia">Simpan</button>
                </div>
              </div>
          }

        </form>
      )
    }
  }

  formRender() {
    if (this.props.data.group_code === 'NI') {
      return (
        <form action="POST" className="uk-grid" onSubmit={this.edit.bind(this)}>
          <div className="uk-width-1-2@s uk-width-1-1 pr-2">
            <div className="uk-margin mb-1">
              <label htmlFor="" className="font-sm">Negara</label>
              <Select
                options={this.state.dataCountry}
                onChange={this.selectCountry}
                defaultValue={this.state.dataNI.negara != null ? { value: this.state.dataNI.negara, label: this.state.dataNI.negara_nama } : null}
              />
            </div>
          </div>

          {this.state.indo ?
            <>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Provinsi</label>
                  <Select
                    options={this.state.dataProvince}
                    onChange={this.selectProvince}
                    defaultValue={this.state.dataNI.provinsi != null ? { value: this.state.dataNI.provinsi, label: this.state.dataNI.provinsi_nama } : null}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Kabupaten / Kota</label>
                  <Select
                    options={this.state.dataRegency}
                    onChange={this.selectRegency}
                    isDisabled={this.state.disableRegency}
                    defaultValue={this.state.dataNI.kabupaten != null ? { value: this.state.dataNI.kabupaten, label: this.state.dataNI.kabupaten_nama } : null}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Kecamatan</label>
                  <Select
                    options={this.state.dataDistrict}
                    onChange={this.selectDistrict}
                    isDisabled={this.state.disableDistrict}
                    defaultValue={this.state.dataNI.kecamatan != null ? { value: this.state.dataNI.kecamatan, label: this.state.dataNI.kecamatan_nama } : null}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Desa / Kelurahan</label>
                  <Select
                    options={this.state.dataVillage}
                    onChange={this.selectVillage}
                    isDisabled={this.state.disableVillage}
                    defaultValue={this.state.dataNI.desa != null ? { value: this.state.dataNI.desa, label: this.state.dataNI.desa_nama } : null}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Kode POS</label>
                  <Select
                    options={this.state.dataPostCode}
                    onChange={this.selectPostCode}
                    isDisabled={this.state.disablePostCode}
                    defaultValue={this.state.dataNI.kode_pos != null ? { value: this.state.dataNI.kode_pos, label: this.state.dataNI.kode_pos } : null}
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">RT</label>
                  <input className="uk-input uk-form-small" type="number" placeholder="Input"
                    defaultValue={this.state.dataNI.rt}
                    onChange={
                      (e) => {
                        this.setState({ dataNI: { ...this.state.dataNI, rt: e.target.value } })
                      }
                    }
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">RW</label>
                  <input className="uk-input uk-form-small" type="number" placeholder="Input"
                    defaultValue={this.state.dataNI.rw}
                    onChange={
                      (e) => {
                        this.setState({ dataNI: { ...this.state.dataNI, rw: e.target.value } })
                      }
                    }
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Alamat 1</label>
                  <input className="uk-input uk-form-small" type="text" placeholder="Input"
                    defaultValue={this.state.dataNI.alamat_1}
                    onChange={
                      (e) => {
                        this.setState({ dataNI: { ...this.state.dataNI, alamat_1: e.target.value } })
                      }
                    }
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Alamat 2</label>
                  <input className="uk-input uk-form-small" type="text" placeholder="Input"
                    defaultValue={this.state.dataNI.alamat_2}
                    onChange={
                      (e) => {
                        this.setState({ dataNI: { ...this.state.dataNI, alamat_2: e.target.value } })
                      }
                    }
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Lintang</label>
                  <input className="uk-input uk-form-small" type="text" placeholder="Input"
                    value={this.state.dataNI?.bujur}
                    onChange={
                      (e) => {
                        this.bujurValidate(e.target.value)
                      }
                    }
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1">
                  <label htmlFor="" className="font-sm">Bujur </label>
                  <input className="uk-input uk-form-small" type="text" placeholder="Input"
                    value={this.state.dataNI?.lintang}
                    onChange={
                      (e) => {
                        this.lintangValidate(e.target.value)
                      }
                    }
                  />
                </div>
              </div>
            </>
            :
            null
          }

          {this.state.selectedPostCode != null ?
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1 pt-3">
                <button className="button small btn-schoolmedia">Simpan</button>
              </div>
            </div> :
            this.state.indo ?
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1 pt-3">
                  <div className="button small btn-disabled">Pilih hingga kode pos</div>
                </div>
              </div> :
              <div className="uk-width-1-2@s uk-width-1-1 pr-2">
                <div className="uk-margin mb-1 pt-3">
                  <button className="button small btn-schoolmedia">Simpan</button>
                </div>
              </div>
          }
        </form>
      )
    } else if (this.props.data.group_code) {
      return (
        <>
          <form action="POST" className="uk-grid" onSubmit={this.edit.bind(this)}>
            {/* alamat domisili */}
            <div className="uk-width-1-1@s uk-width-1-1 pr-2">
              <p className="mb-3 font-sm">Alamat Sesuai KTP</p>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Negara</label>
                <Select
                  options={this.state.dataCountry}
                  onChange={this.selectCountry}
                  defaultValue={this.state.dataUser.negara != null ? { value: this.state.dataUser.negara, label: this.state.dataUser.negara_nama } : null}
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Provinsi</label>
                <Select
                  options={this.state.dataProvince}
                  onChange={this.domisiliSelectProvince}
                  defaultValue={this.state.dataUser.provinsi_domisili != null ? { value: this.state.dataUser.provinsi_domisili, label: this.state.dataUser.provinsi_domisili_nama } : null}
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Kabupaten / Kota</label>
                <Select
                  options={this.state.dataDomisiliRegency}
                  onChange={this.domisiliSelectRegency}
                  isDisabled={this.state.disableDomisiliRegency}
                  defaultValue={this.state.dataUser.kabupaten_domisili != null ? { value: this.state.dataUser.kabupaten_domisili, label: this.state.dataUser.kabupaten_domisili_nama } : null}
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Kecamatan</label>
                <Select
                  options={this.state.dataDomisiliDistrict}
                  onChange={this.domisiliSelectDistrict}
                  isDisabled={this.state.disableDomisiliDistrict}
                  defaultValue={this.state.dataUser.kecamatan_domisili != null ? { value: this.state.dataUser.kecamatan_domisili, label: this.state.dataUser.kecamatan_domisili_nama } : null}
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Desa / Kelurahan</label>
                <Select
                  options={this.state.dataDomisiliVillage}
                  onChange={this.domisiliSelectVillage}
                  isDisabled={this.state.disableDomisiliVillage}
                  defaultValue={this.state.dataUser.desa_domisili != null ? { value: this.state.dataUser.desa_domisili, label: this.state.dataUser.desa_domisili_nama } : null}
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Kode POS</label>
                <Select
                  options={this.state.dataDomisiliPostCode}
                  onChange={this.domisiliSelectPostCode}
                  isDisabled={this.state.disableDomisiliPostCode}
                  defaultValue={this.state.dataUser.kode_pos_domisili != null ? { value: this.state.dataUser.kode_pos_domisili, label: this.state.dataUser.kode_pos_domisili } : null}
                />
              </div>
            </div>
            {/*  */}
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">RT</label>
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  defaultValue={this.state.dataUser.rt_domisili}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, rt_domisili: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">RW</label>
                <input className="uk-input uk-form-small" type="number" placeholder="Input"
                  defaultValue={this.state.dataUser.rw_domisili}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, rw_domisili: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1 pr-2">
              <div className="uk-margin mb-1">
                <label htmlFor="" className="font-sm">Alamat</label>
                <input className="uk-input uk-form-small" type="text" placeholder="Input"
                  defaultValue={this.state.dataUser.alamat_domisili}
                  onChange={
                    (e) => {
                      this.setState({ dataUser: { ...this.state.dataUser, alamat_domisili: e.target.value } })
                    }
                  }
                />
              </div>
            </div>
          </form>

          <div className="uk-width-1-1">
            <Alert severity="info" className="my-3">
              <AlertTitle>Alamat tinggal sesuai KTP?</AlertTitle>
              <div className="uk-flex">
                <button className="button small btn-schoolmedia mr-2" onClick={e => {
                  this.isSame(e)
                }}
                >Ya</button>
                <button className="button small btn-white"
                  onClick={e => {
                    this.notSame(e)
                  }}
                >Tidak</button>
              </div>
            </Alert>
          </div>

          {this.renderResidence()}
        </>
      )
    }
  }
  render() {
    return (
      <>
        {this.formRender()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const userReducer = state.userReducer
  return {
    auth: state.auth,
    userReducer
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  uploading: uploadOnProgress,
  upload_success: uploadSuccess,
  upload_error: uploadError,
  getUserDetail: getUserDetail,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormAddress);
