import React from 'react';
import { Link } from 'react-router-dom';

export default class ListLiveVideos extends React.Component {
  render() {
    return (
      <div className="main_content">
        <div className="main_content_inner">
          <div className="pr-4 pl-4 pt-4 pb-4 bg-white rounded-sm mb-2 min-h-8">
            <h3 className="mt-2 mb-2"> Siaran Langsung </h3>
            <div className="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid">
              <div className="mb-2">
                <Link to="videos/watch" className="video-post">
                  {/* <!-- Blog Post Thumbnail --> */}
                  <div className="video-post-thumbnail">
                    <span className="video-post-time">23:00</span>
                    <span className="play-btn-trigger"></span>
                    <img src="/assets/images/video/img-6.png" alt="" />
                  </div>

                  {/* <!-- Blog Post Content --> */}
                  <div className="video-post-content">
                    <h5> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h5>
                    <div className="item-statistic font-sm">
                      <span> <span className="count">Atta Gledek</span></span>
                    </div>
                    <div className="item-statistic font-xs">
                      <span> <span className="count">2M</span> Tayangan </span>
                      <span> <span className="count">-</span> </span>
                      <span> <span className="count">1</span> Tahun lalu </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="mb-2">
                <Link to="videos/watch" className="video-post">
                  {/* <!-- Blog Post Thumbnail --> */}
                  <div className="video-post-thumbnail">
                    <span className="video-post-time">23:00</span>
                    <span className="play-btn-trigger"></span>
                    <img src="/assets/images/video/img-6.png" alt="" />
                  </div>

                  {/* <!-- Blog Post Content --> */}
                  <div className="video-post-content">
                    <h5> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h5>
                    <div className="item-statistic font-sm">
                      <span> <span className="count">Atta Gledek</span></span>
                    </div>
                    <div className="item-statistic font-xs">
                      <span> <span className="count">2M</span> Tayangan </span>
                      <span> <span className="count">-</span> </span>
                      <span> <span className="count">1</span> Tahun lalu </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="mb-2">
                <Link to="videos/watch" className="video-post">
                  {/* <!-- Blog Post Thumbnail --> */}
                  <div className="video-post-thumbnail">
                    <span className="video-post-time">23:00</span>
                    <span className="play-btn-trigger"></span>
                    <img src="/assets/images/video/img-6.png" alt="" />
                  </div>

                  {/* <!-- Blog Post Content --> */}
                  <div className="video-post-content">
                    <h5> TIMELAPSE OF THE FUTURE A Journey to the End of Time </h5>
                    <div className="item-statistic font-sm">
                      <span> <span className="count">Atta Gledek</span></span>
                    </div>
                    <div className="item-statistic font-xs">
                      <span> <span className="count">2M</span> Tayangan </span>
                      <span> <span className="count">-</span> </span>
                      <span> <span className="count">1</span> Tahun lalu </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}