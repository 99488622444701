import React from 'react';

class MaintenanceOverlay extends React.Component {
    componentDidMount() {
        document.onkeydown = function (e) {
            // e.preventDefault();
            if (e.keyCode == 123) {
                return false;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                alert('Hayo mau ngapain?');
                return false;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                alert('What are you doing?');
                return false;
            }
            if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                alert('What are you doing?');
                return false;
            }
        }
        // document.oncontextmenu = (e) => {
        //     alert('Website masih dalam perbaikan');
        //     return false;
        // }

    }
    componentWillUnmount(){
        document.onkeydown = function (e) {
            // e.preventDefault();
            if (e.keyCode == 123) {
                return true;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                return true;
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                return true;
            }
            if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                return true;
            }
        }
        document.oncontextmenu = (e) => {
            return true;
        }
    }
    render() {
        return (
            <div className="overlay_maintenance_main" >
                <div className="overlay_maintenance_inner main_content_inner">
                    <div>
                        <img src="assets/icons/Coding.png" alt="" />
                        <h1>Under Maintenance</h1>
                    </div>
                </div>
            </div>
        )
    }
}

class Maintenance extends React.Component {
    render() {
        return (
            <div className="maintenance_main">
                <div className="maintenance_inner main_content_inner">
                    <div>
                        <img src="assets/icons/Coding.png" alt="" />
                        <h1>Under Maintenance</h1>
                    </div>
                </div>
            </div>
        )
    }
}

export { MaintenanceOverlay, Maintenance };
