import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

export default function SkeletonPhotos() {
  return (
    <>
      <div className="row px-2">
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100 rounded" height={200}/>
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100 rounded" height={200}/>
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100 rounded" height={200}/>
        </div>
        <div className="col-3 px-2">
          <Skeleton animation="wave" variant="square" className="w-100 rounded" height={200}/>
        </div>
      </div>
    </>
  );
}