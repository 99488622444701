import React from 'react';
import { Link, NavLink } from "react-router-dom";

export default class ThemeGroupHeader extends React.Component {
  render() {
    return (
      <>
        <div className="group-cover">

          {/* <!--Signle group cover --> */}
          <img src="/assets/images/group/group-cover.jpg" alt="" />

        </div>

        <div className="single-group-details">
          <div className="left-side">
            <div className="single-group-image">
              <a href="#">
                <img src="/assets/images/group/group-avature.jpg" alt="" />
              </a>
            </div>
            <div className="single-group-details-info">
              <h3> Tema MIPA </h3>
              <p>
                <p><i className="uil-users-alt mr-2"></i>Grup Kelas 12 </p>
              </p>
            </div>
          </div>
          <div className="right-side">
          </div>
        </div>

        <div className="nav-single-group" uk-sticky="offset:61;media : @s">
          <nav className="responsive-tab">
            <ul>
              <li ><NavLink to="/groups/example-group/topics">Feed</NavLink></li>
              <li ><NavLink to="/groups/example-group/topics/file">File</NavLink></li>
              <li ><NavLink to="/groups/example-group/topics/media">Media</NavLink></li>
            </ul>
          </nav>
          <form className="nav-single-group-saerchbox">
            <i className="uil-search"></i>
            <input className="uk-input" type="text" value="Search Feed..." />
          </form>
        </div>
      </>
    )
  }
}
