import SkeletonList from 'component/partials/skeleton/SkeletonList';
import React from 'react';
import { Link } from 'react-router-dom';

export default class SideNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data != state.data) {
      return {
        data: props.data.news,
        isLoading: props.loading
      }
    }
  }

  renderNews() {
    if (this.state.data) {
      return (
        this.state.data.slice(0, 5).map(item => {
          return (
            <li key={item.id}>
              <a href={item.url_konten} target="_blank" className=" uk-flex uk-flex-column">
                <span className="font-xs">{item.jadwal_tayang}</span>
                <p className="font-sm text-schoolmedia">{item.judul}</p>
              </a>
            </li>
          )
        })
      )
    }
  }

  render() {
    return (
      <>
        <div uk-sticky="offset:90; bottom:true" className="side-news bg-white hidden-mobile">
          <div className="uk-flex uk-flex-between">
            <h3> Berita </h3>
            <a terget="_blank" href="https://news.schoolmedia.id/berita" >
              <p className="font-sm">Lihat Semua</p>
            </a>
          </div>


          <ul className="uk-list uk-list-divider">
            {this.state.isLoading ?
              <>
                <SkeletonList/>
                <SkeletonList/>
                <SkeletonList/>
                <SkeletonList/>
                <SkeletonList/>
              </> :
              this.renderNews()
            }
          </ul>
        </div>
      </>
    )
  }
}