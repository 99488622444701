import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export default class HeaderContent extends React.Component {
  render() {
    return (
      <div className="profile">
        <div className="profile-cover">
          {/* <!-- profile cover --> */}
          <img src="/assets/images/avatars/profile-cover.png" alt=""></img>
          <a href="#"> <i className="uil-camera"></i> Edit </a>
        </div>

        <div className="profile-details">
          <div className="profile-image">
            <img src="/assets/images/avatars/avatar-2.jpg" alt=""></img>
            <a href="#"> </a>
          </div>
          <div className="profile-details-info">
            <h1 className="mt-2"> Username </h1>
            <Link to="/profile/settings-general">
              <button className="button small btn-schoolmedia">
                <i className="uil-cog "></i> Edit
                          </button>
            </Link>
          </div>
        </div>
        <div className="nav-profile" uk-sticky="offset:61;media : @s">
          <div>
            <nav className="responsive-tab">
              <ul>
                <li>
                  <NavLink to="/content">Konten</NavLink>
                </li>
                <li>
                  <Link to="/content/mau">Mau</Link>
                </li>
                <li><Link to="/content/bahan-ajar">Bahan Ajar</Link></li>
                <li><Link to="/content/usbk">USBK</Link></li>
                <li><Link to="/content/tes-online">Tes Online</Link></li>
                <li><Link to="/content/try-out">Try Out</Link></li>
                <li>
                  <NavLink to="/content/pitakon">Pitakon</NavLink>
                </li>
              </ul>
            </nav>
            {/* <div className="uk-visible@s">
                                  <a href="#" className="nav-btn-more"> More</a>
                                  <div uk-dropdown="pos: bottom-left ; mode:click ">
                                      <ul className="uk-nav uk-dropdown-nav">
                                          <li><a href="#">Moves</a></li>
                                      </ul>
                                  </div>
                              </div> */}
          </div>
        </div>
      </div>
    )
  }
}