import axios from 'axios';
import {GET_LIST_FORUM, GET_LIST_FORUM_SUCCESS, GET_LIST_FORUM_ERR} from './types';

const api = `${process.env.REACT_APP_API}`;
export const getForumList = (data) => {
  let jwtToken = localStorage.getItem("jwtToken")
  let config = {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  }
  return (dispatch) => {
    dispatch({
      type: GET_LIST_FORUM,
      data: [],
    })
    axios.post(api + `forum/get-forum`, config)
    .then(res => {
      console.log(res)
      dispatch({
        type: GET_LIST_FORUM_SUCCESS,
        data: res.data.data.data,
      })
    }).catch(err => {
      dispatch({
        type: GET_LIST_FORUM_ERR,
        data: []
      })
    })
  }

}