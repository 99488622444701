import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import { withRouter, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './App.css';
import './assets/css/main.css'
import './assets/css/loader.css'

import Navbar from './component/partials/Navbar';
import LandingPage from './component/pages/LandingPage';
import Home from './component/pages/Home';

// import { setCurrentUser } from "../redux/actions/ssoAction";
import { Provider, connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { login } from "./redux/actions/SsoAction";
import { getUserDetail } from "./redux/actions/UserAction";


// import { auth, caught, redirect, Welcome } from "./utils/ssoService";
import { login_sso, redirect_sso, caught_sso, status_login, logout_sso, update_token } from "./utils/sso";
import { dontCry } from "./utils/KiritoHelper";
import axios from "axios";
import preload from "./utils/preload";
import isEmpty from "lodash/isEmpty";
import { retriveDataError } from "redux/actions/toastsAction";
import LoadingComponent from 'route/LoadingComp';
import AuthRoute from 'route/AuthenticatedRoute';
import SMRouter from "route/Router";
import UploadToast from "utils/UploadToast";
import NotFound from "../src/component/pages/404";

const sso = `${process.env.REACT_APP_SSO_URL}`;
const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
const clientSecret = `${process.env.REACT_APP_CLIENT_SECRET}`;
const caughtUri = `${process.env.REACT_APP_SSO_CAUGHT_URI}`;
const redirectUri = `${process.env.REACT_APP_SSO_REDIRECT_URI}`;
const api = `${process.env.REACT_APP_API}`;

axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer `+ localStorage.getItem('jwtToken');
  return config;
}, (error) => {
  
});

axios.interceptors.response.use((successRes) => {
    return successRes;
  },(error) => {
    return update_token(error);
  }
);

function has_redirect(str) {
  if (!isEmpty(localStorage.getItem('redirect')))
    return true;
  return false;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect_status: null,
      userStatus: false,
      isSso: true,
      authStatus: true,
    }

    this.redirect_sso = this.redirect_sso.bind(this);
    this.caught_sso = this.caught_sso.bind(this);
    this.logout_sso = this.logout_sso.bind(this);
    this.callback_sso = this.callback_sso.bind(this);
  }

  componentDidMount() {
    const data = this.props.auth.user;
    // console.log("ssss",data);
    if (!isEmpty(data)) {
      this.props.getUserDetail(data);
    }
    const match = window.location.pathname;
    if (match === "/") {
      console.log("match!")
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        window.location.reload();
        window.history.pushState(null, null, window.location.href);
      };
    } else {
      if (localStorage.getItem('isChecked') != null)
        localStorage.removeItem('isChecked');
    }
  }

  /*SSO Stuff*/
  login_sso() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramRedirect = urlParams.get('redirect');
    if (!isEmpty(paramRedirect))
      localStorage.setItem('redirect', paramRedirect);

    login_sso();
    return (
      <LoadingComponent />
    )
    // return preload();
  }

  redirect_sso() {
    var self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const paramCode = urlParams.get('code');

    if (paramCode == null) {
      return <Redirect to="/" />;
    }

    if (self.state.redirect_status == null) {
      redirect_sso().then((res) => {
        //set jwtToken
        localStorage.setItem('jwtToken', res.data.access_token);
        self.props.login(self.state);
        status_login().then((res) => {
          self.setState({ redirect_status: res.success, userStatus: true });
        }).catch((err) => {
          //console.log('woi', err);
        });
      });
    }

    if (self.state.redirect_status) {
      const data = this.props.auth.user;
      this.props.getUserDetail(data);

      let redirectTo = localStorage.getItem('redirect');
      return !has_redirect() ? <Redirect to="/timeline"/> : <Redirect to={`${redirectTo}`}/>;

    }

    return (
      <LoadingComponent />
    )
  }

  caught_sso() {
    var self = this;
    let obj = caught_sso();
    let isExecute = 0
    if (!obj.hasOwnProperty("message")) {//pass data user to backend
      //reencrypt
      let data = dontCry(JSON.stringify(obj, 'e'));
      console.log('cok', data);
      axios
        .post(
          api + "auth/sso/login",
          {
            type: "caught",
            code: data
          }
          // config
        )
        .then((response) => {
          var res = response.data;
          //set jwtToken
          if (res != undefined) {
            localStorage.setItem('jwtToken', res.data?.access_token);
          }
          
          self.props.login(self.state);
          if (isExecute == 0) {
            isExecute = 1
            if (self.state.redirect_status == null) {
              status_login().then((res) => {
                self.setState({ redirect_status: res.success });
              }).catch((error) => {
                  //handler jika error
                  if (error.response?.status == 401) {
                    let config = {};
                    let jwtToken = localStorage.getItem('jwtToken')
                    if (jwtToken != undefined) {
                      config = {
                          headers: { Authorization: "Bearer " + jwtToken },
                      };
                    }
                    axios.post(
                        api + "auth/sso/get-new-token",
                        {}, config
                        // config
                    )
                    .then((response) => {
                        var response = response.data;
                        self.setState({ redirect_status: res.success });
                    });
                  }
                }
              )
            }
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            let url = sso + "/auth?client_id=" + clientId + "&redirect_uri=" + redirectUri;
            window.location.replace(url);
          }

          if (error.response?.status == 503) {
            localStorage.setItem('isChecked', true);
            alert('Profile akun Anda bermasalah !, Silahkan hubungi CS Schoolmedia !')
            window.location.replace('/');
          }
        });

      if (self.state.redirect_status) {
        console.log('dnd')
        const data = this.props.auth.user;
        this.props.getUserDetail(data);

        let redirectTo = localStorage.getItem('redirect');
        return !has_redirect() ? <Redirect to="/timeline"/> : <Redirect to={`${redirectTo}`}/>;
      }
    } else {
      let url = sso + "/auth?client_id=" + clientId + "&redirect_uri=" + redirectUri;
      window.location.replace(url);
    }

    return (
      <LoadingComponent />
    )
  }

  logout_sso() {
    var self = this;
    let jwtToken = localStorage.getItem('jwtToken');
    logout_sso(jwtToken).then((res) => {

      window.location.replace(
        sso + "auth/logout?token=" + res.data.value + "&redirect_uri=" + redirectUri
      );
      localStorage.removeItem('jwtToken');
      if (!isEmpty(localStorage.getItem('redirect')))
        localStorage.removeItem('redirect');

    }).catch((err) => {
      // if (err.response.statusText === 'Unauthorized') {
        self.setState({ authStatus: false });
      // }
    });

    if (!self.state.authStatus)
      return <Redirect to="/" />;
    return (
      <LoadingComponent />
    )
  }

  callback_sso() {
    var self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('token');
    localStorage.setItem('tokenCallback', param);
    axios
      .post(
        api + "auth/sso/login",
        {
          type: "caught",
          code: localStorage.getItem('tokenCallback')
        }
        // config
      )
      .then((response) => {
        var res = response.data;
        //set jwtToken
        localStorage.setItem('jwtToken', res.data.access_token);
        self.props.login(self.state);
        if (self.state.redirect_status == null) {
          status_login().then((res) => {
            self.setState({ redirect_status: res.success });
          }).catch((err) => {
            // if (err.response.statusText === 'Unauthorized') {
              self.setState({ authStatus: false });
            // }
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });

    if (!self.state.authStatus)
      return <Redirect to="/" />;

    if (self.state.authStatus && self.state.redirect_status) {
      localStorage.removeItem('tokenCallback');
      const data = this.props.auth.user;
      this.props.getUserDetail(data);
      return <Redirect to="/profile/settings" />;
    }
    return (
      <LoadingComponent />
    )
  }
  render() {
    var currUrl = window.location.href;
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          {/* SSO */}
          <Route exact path="/auth" render={this.login_sso} />
          <Route exact path="/auth/sso/redirect" render={this.redirect_sso} />
          <Route exact path="/auth/sso/caught" render={this.caught_sso} />
          <Route exact path="/auth/sso/logout" render={this.logout_sso} />
          <Route exact path="/auth/sso/callback" render={this.callback_sso} />

        </Switch>
        <div id="wrapper">
          {!isEmpty(this.props.auth.user) ? <Navbar /> : ""}
          <div className="main_page_container">
            <UploadToast/>
            <SMRouter/>
          </div>
        </div>
      </Router>
    );
  }
}


const mapStateToProps = (state) => ({
  isReady: state.isReady,
  auth: state.auth,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  login: login,
  getUserDetail: getUserDetail,
  retriveDataError: retriveDataError,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App);
