import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';

const api = `${process.env.REACT_APP_API}`;

export default class CreateThread extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topicData: '',
      modalCreate: false,
      threadContent: '',
    }
    this.showModalCreate = this.showModalCreate.bind(this)
    this.hideModalCreate = this.hideModalCreate.bind(this)
    this.createThread = this.createThread.bind(this)
  }
  componentDidMount() {

  }
  static getDerivedStateFromProps(props, state){
    if(props.topicData != state.topicData){
      return({
        topicData: props.topicData
      })
    }
  }
  showModalCreate() {
    this.setState({ modalCreate: true })
  }
  hideModalCreate() {
    this.setState({ modalCreate: !this.state.modalCreate })
  }
  createThread(){
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    const formData = new FormData();

    // append content
    let requestData = {
      content: this.state.threadContent,
      topic_id: this.state.topicData?.id,
      status: 1,
      feeling: 1,
      lat: "-0.789275",
      long: 113.92132
    };
    formData.append('json', JSON.stringify(requestData));
    axios.post(api + "topic/post-topic", formData, config)
      .then(res => {
        this.hideModalCreate()
      }).catch(err => {
        alert('gagal membuat thread baru', err.data)
      })
  }
  render() {
    return (
      <div className="post-new">
        {/* modal create */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
          open={this.state.modalCreate}
          onClose={this.hideModalCreate}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalCreate}>
            <>
              <div className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid" style={{ maxWidth: '510px' }}>
                <div className=" uk-width-1-1">
                  <h2 className="uk-modal-title text-center">Buat Postingan Baru</h2>
                  <div className="uk-flex uk-flex-column">
                    <TextField
                      className="mt-4"
                      id="outlined-multiline-static"
                      label="Info Topic"
                      multiline
                      rows={4}
                      // defaultValue={this.state.topicInfo}
                      variant="outlined"
                      onChange={e => { this.setState({ threadContent: e.target.value }) }}
                    />
                    <button className="button btn-schoolmedia small mt-4" onClick={this.createThread}>Buat</button>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
        {/* Modal Photo */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
          open={this.state.modalCreate}
          onClose={this.hideModalCreate}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalCreate}>
            <>
              <div className="bg-white pb-3 pt-3 modal-xs uk-grid-collapse uk-padding pb-5 uk-grid" style={{ maxWidth: '510px' }}>
                <div className=" uk-width-1-1">
                  <h2 className="uk-modal-title text-center">Buat Postingan Baru</h2>
                  <div className="uk-flex uk-flex-column">
                    <TextField
                      className="mt-4"
                      id="outlined-multiline-static"
                      label="Info Topic"
                      multiline
                      rows={4}
                      // defaultValue={this.state.topicInfo}
                      variant="outlined"
                      onChange={e => { this.setState({ threadContent: e.target.value }) }}
                    />
                    <button className="button btn-schoolmedia small mt-4" onClick={this.createThread}>Buat</button>
                  </div>
                </div>
              </div>
            </>
          </Fade>
        </Modal>
        <div className="post-new-media">
          <div className="post-new-media-user">
            <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
          </div>
          <div className="post-new-media-input">
            <input type="text" className="uk-input" onClick={this.showModalCreate} placeholder="What's Your Mind ? Dennis!" />
          </div>
        </div>

        <hr />
        <div className="post-new-type">
          <a href="#">
            <img src="/assets/images/icons/photo.png" alt="" />
                                        Photo/Video
                                    </a>
          <a href="#" className="uk-visible@s">
            <img src="/assets/images/icons/tag-friend.png" alt="" />
                                        Tag Friend
                                    </a>
          <a href="#"><img src="/assets/images/icons/reactions_wow.png" alt="" />
                                        Fealing /Activity
                                    </a>
        </div>
      </div>
    )
  }
}