import React from 'react';
import { MaintenanceOverlay } from '../error_pages/Maintenance';
import SkeletonList from 'component/partials/skeleton/SkeletonList';
import axios from 'axios';
import PostChats from './components/PostChats';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import isEmpty from "lodash/isEmpty";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { Link } from 'react-router-dom';

const api = `${process.env.REACT_APP_API}`;
const urlStatic = `${process.env.REACT_APP_SM_STATIC_URI}`;

class Chats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myUserName: null,
      dataMyUser: null,
      dataUserChat: null,
      toUsernameChat: null,
      replyMessage: null,
      chatMessage: null,
      dataFriend: [],
      loadingGetFriend: true,
      loadingProfile: true,
      refreshChat:true,
      showEmotList:false,
    }
    this._getFriend = this._getFriend.bind(this);
    this.toUserChat = this.toUserChat.bind(this);
    this.chatMessage = this.chatMessage.bind(this);
    this._postChat = this._postChat.bind(this);
    this.refreshChat = this.refreshChat.bind(this);
    this.replyMessage = this.replyMessage.bind(this);
    this.cancelReplyMessage = this.cancelReplyMessage.bind(this);
    this.showEmotList = this.showEmotList.bind(this);
    this.onEmojiClick = this.onEmojiClick.bind(this);
  }

  componentDidMount() {
    this.addClass();
    this.openChat(); 
    this._getFriend();
  }

  componentWillUnmount() {
    var v = document.getElementById("wrapper");
    var screen = document.getElementById("root");
    v.className -= "collapse-sidebar mobile-visible";
    screen.className -= "fit-screen";
  }

  static getDerivedStateFromProps(prevProps, props) {
    if (props.userReducer !== prevProps.userReducer) {
      return ({
        myUserName: prevProps.userReducer.userDetail.username,
        dataMyUser: prevProps.userReducer.userDetail,
        loadingProfile: false,
      })
    }
  }

  addClass() {
    var v = document.getElementById("wrapper");
    var screen = document.getElementById("root");
    v.className += " collapse-sidebar mobile-visible";
    screen.className += "n fit-screen";
  }

  openChat() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  }

  // temporarily disabled
  _getFriend() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    let params = {
      type: "all",
      page: 1,
    }
    axios.post(api + 'friend/get-friend', params, config)
      .then(res => {
        this.setState({
          dataFriend: res.data.data.items,
          loadingGetFriend: false,
        })
      })
      .catch(err => {
        this.setState({
          loadingGetFriend: false,
        })
      })
  }

  toUserChat(data) {
    this.setState({
      dataUserChat: data,
      toUsernameChat: data.username,
      refreshChat: true,
      showEmotList: false,
      replyMessage: null
    })
  }

  chatMessage(e) {
    if (e) {
      this.setState({ chatMessage : e.target.value })
    } else {
      this.setState({ chatMessage : null })
    }
  }

  _postChat(e) {
    e.preventDefault();
    if (!isEmpty(this.state.chatMessage)) {
      const inputElm = document.getElementById("chatInput");
  
      let jwtToken = localStorage.getItem("jwtToken")
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      }

      let formData = new FormData();
      formData.append('content', this.state.chatMessage);
      formData.append('recipient', this.state.toUsernameChat);
      if (!isEmpty(this.state.replyMessage)) {
        formData.append('message_id', this.state.replyMessage);
      }

      axios.post(api + 'message/post-message', formData, config)
        .then(res => {
          this.setState({ 
            refreshChat: true,
            chatMessage: null,
            replyMessage: null
          });
          inputElm.value = "";
        })
        .catch(err => {
          this.setState({ 
            refreshChat: true,
            chatMessage: null,
            replyMessage: null
          });
          inputElm.value = "";
        })
    }
  }
  refreshChat(status) {
    this.setState({refreshChat: status });
  }
  replyMessage(data) {
    var objDiv = document.getElementById("replyMessageDisplay");
    this.setState({
      replyMessage: String(data.id)
    }, function(){
      ReactDOM.render(
        <div className="w-100 d-inline-flex justify-content-between align-items-center">
          <p>{data.content}</p>
          <i className="icon-line-awesome-close" onClick={this.cancelReplyMessage.bind(this)}></i>
        </div>
      , objDiv)
    })
  }
  cancelReplyMessage() {
    var objDiv = document.getElementById("replyMessageDisplay");
    this.setState({
      replyMessage: null
    }, function(){
      ReactDOM.render("", objDiv)
    })
  }
  showEmotList(){
    this.setState({showEmotList: true});
  }

  showKeyboardEmotList(){
    if(this.state.showEmotList){
      return(
        <div className="message-reply w-100" style={{ marginTop: "0px" }}>
          <div id="emotList" className="p-2  w-100">
            <div className="w-100 d-inline-flex justify-content-end align-items-center">
              <i className="icon-line-awesome-close" onClick={e => {this.setState({showEmotList: false})}}></i>
            </div>
            <Picker
              onEmojiClick={this.onEmojiClick}
              disableAutoFocus={true}
              pickerStyle={{ width: '100%' }}
              skinTone={SKIN_TONE_MEDIUM_DARK}
              groupNames={{ smileys_people: "PEOPLE" }}
              native
            />
          </div>
        </div>
      )
    }
  }

  onEmojiClick(event, emojiObject) {
    const inputElm = document.getElementById("chatInput");
    var oldValue = inputElm.value;
    this.setState({
      chatMessage : oldValue + emojiObject.emoji
    }, function(){
      inputElm.value = oldValue + emojiObject.emoji;
    })
  };

  render() {
    return (
      // <!-- contents -->
      <>
        <div className="main_content">
          <div className="main_content_inner pt-0 bg-white chats-content-inner" style={{ maxWidth: "1300px" }}>
            <div className="uk-grid-collapse uk-grid">
              <div className="uk-width-4-4@m">
                <div className="chats-container margin-top-0">
                  <div className="chats-container-inner">
                    {/* <!-- chats --> */}
                    <div className="chats-inbox">
                      <div className="chats-headline pb-8">
                        <div className="input-with-icon">
                          <input className="uk-input" type="text" placeholder="Search" />
                        </div>
                      </div>

                      <ul>
                        {this.state.loadingGetFriend ?
                          <>
                            <SkeletonList /> 
                            <SkeletonList /> 
                            <SkeletonList /> 
                            <SkeletonList /> 
                            <SkeletonList /> 
                            <SkeletonList />
                            <SkeletonList />
                            <SkeletonList />
                          </> :
                          this.state.dataFriend.length > 0 ? 
                            this.state.dataFriend.map((value, key) => (
                              <li className={value.username === this.state.toUsernameChat ? "active-message" : ""}>
                                <a onClick={this.toUserChat.bind(this, value)}>
                                  <div className="message-avatar">
                                    <i className="status-icon status-online"></i>
                                    {!isEmpty(value.photo_profile) ? <img src={`${urlStatic + value.group_code}/photo/${value.photo_profile}`} alt="" /> :
                                      <img src='/assets/icons/user.svg' />
                                    }
                                  </div>
                                  <div className="message-by">
                                    <div className="message-by-headline">
                                      <h5
                                        style={{
                                          width: "100px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis"
                                        }}
                                      >{value.name} </h5>
                                      <span>4 hours ago</span>
                                    </div>
                                    <p>You: Yes, in an organization stature, this is a must</p>
                                    <span className="message-readed uil-check"> </span>
                                  </div>
                                </a>
                              </li>
                            )
                          ) : <div className="mt-4 ml-4">Tidak ada teman</div>
                        }
                      </ul>
                    </div>

                    {/* <!-- Message Content --> */}
                    <div className="message-content">
                      {!isEmpty(this.state.dataUserChat) ? (
                        <div className="chats-headline pb-8">
                          <div className="uk-flex">
                            <Link to={"/" + this.state.toUsernameChat} data-caption={this.state.toUsernameChat}>
                              {!isEmpty(this.state.dataUserChat) && this.state.dataUserChat.photo_profile != undefined 
                                ? <img src={`${urlStatic + this.state.dataUserChat.group_code}/photo/${this.state.dataUserChat.photo_profile}`} alt="" width="40" className="uk-border-circle" /> 
                                : <img src='/assets/icons/user.svg' width="40" className="uk-border-circle"/>
                              }
                            </Link>
                            <Link to={"/" + this.state.toUsernameChat} data-caption={this.state.toUsernameChat}>
                              <h4 className="ml-2"> {this.state.dataUserChat.name} <span>Online</span> </h4>
                            </Link>
                          </div>

                          <div className="message-action">
                            <a href="#" uk-tooltip="filter">
                              <i className="uil-outgoing-call"></i>
                            </a>
                            <a href="#" uk-tooltip="filter">
                              <i className="uil-video"></i>
                            </a>
                            <a href="#" uk-tooltip="More">
                              <i className="uil-ellipsis-h"></i>
                            </a>
                            <div uk-dropdown="pos: left ; mode: click ;animation: uk-animation-slide-bottom-small">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#"> Refresh </a></li>
                                <li><a href="#"> Manage</a></li>
                                <li><a href="#"> Setting</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/* <!-- Message Content Inner --> */}
                      {!isEmpty(this.state.myUserName) && !isEmpty(this.state.dataUserChat) ? (
                        <PostChats  
                          receiptName={this.state.dataUserChat.username}
                          senderName={this.state.myUserName}
                          refresh={this.state.refreshChat}
                          refreshChat={this.refreshChat}
                          replyMessage={this.replyMessage}
                        />
                      ) : (
                        <div className="message-content-inner"> 
                          <p>Mulai chat dengan seseorang</p>
                        </div>
                      )}
                      {/* <!-- Message Content Inner / End --> */}

                      {/* <!-- Reply Area --> */}
                      {!isEmpty(this.state.dataUserChat) ? (
                        <>
                          {this.showKeyboardEmotList()}
                          <div className="message-reply w-100" style={
                            !isEmpty(this.state.replyMessage)
                              ? { marginTop: "0px" }
                              : { display: "none", marginTop: "0px" } 
                            }
                          >
                            <div id="replyMessageDisplay" className="p-2  w-100">

                            </div>
                          </div>
                          <div className="message-reply" style={{ marginTop: "0px" }}>
                            <form onSubmit={this._postChat} className="uk-flex-middle uk-width-1-1 uk-grid">
                              <div className="uk-flex uk-flex-middle mr-3 uk-width-auto">
                                <div className="button btn-schoolmedia mr-2" onClick={this.showEmotList.bind(this)} uk-tooltip="filter">
                                  <i className="uil-smile-wink"></i>
                                </div>
                                {/* <a href="#" className="button btn-schoolmedia" uk-tooltip="filter">
                                  <i className="uil-link-alt"></i>
                                </a> */}
                              </div>
                              <textarea id="chatInput" className="uk-textarea uk-width-expand" data-autoresize rows="5" type="text" onKeyDown={
                                e => {
                                  if (e.keyCode == 13 && e.shiftKey == false){
                                    this._postChat(e)
                                  }
                                }
                              } placeholder="Ketik & tekan enter" value={this.state.chatMessage} onChange={this.chatMessage} />

                              <button type="submit" className="button btn-schoolmedia uk-width-auto">
                                <i className="uil-plane"></i>
                              </button>
                            </form>
                          </div>
                        </>
                      ) : null}

                      {/* <!-- <div className="message-reply">
                                          <textarea cols="1" rows="1" placeholder="Your Message" data-autoresize></textarea>
                                          <button className="btn primary ripple-effect">Send</button>
                                      </div> --> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="uk-width-1-4@m uk-visible@m chats-right-bar">
                <div className="uk-padding pt-3" uk-sticky="offset:60:bottom:true ; media : @m">
                  <div className="uk-grid-small uk-grid">
                    {this.state.loadingProfile ?
                      <>
                        <SkeletonList /> 
                      </> : <>
                        <div className="uk-width-100">
                          {!isEmpty(this.state.dataMyUser.photo_profile) ? <img src={`${urlStatic + this.state.dataMyUser.group_code}/photo/${this.state.dataMyUser.photo_profile}`} alt="" /> :
                            <img src='/assets/icons/user.svg' width="40" className="uk-border-circle" />
                          }
                        </div>
                        <div className="uk-width-expand">
                          <h4 className="mb-1"> {this.state.dataMyUser.name } </h4>
                          <p className="uk-text-small mb-2"> 2 days ago </p>
                          <a href="#" className="uk-text-small"> Veiw profile </a>
                        </div>
                      </>
                    }
                  </div>

                  <hr className="mt-3 mb-2" />
                  <p className="mb-0">Option</p>
                  <ul className="dropdown-user-menu">
                    <li><a href="#"> <i className="uil-user"></i> My Channal </a> </li>
                    <li><a href="#"> <i className="uil-thumbs-up"></i> Liked Videos </a></li>
                    <li><a href="#"> <i className="uil-cog"></i> Account Settings</a></li>
                    <li><a href="#"> <i className="uil-sign-out-alt"></i>Sign Out</a> </li>
                  </ul>

                  <hr className="mt-3 mb-0" />
                  <div className="section-header">
                    <div className="section-header-left">
                      <h3> Photos </h3>
                    </div>
                    <div className="section-header-right">
                      <a href="#" className="see-all text-schoolmedia"> See all</a>
                    </div>
                  </div>

                  <div className="uk-child-width-1-3 uk-grid-collapse uk-overflow- uk-grid" style={{ borderRadius: "16px" }}>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/post/img-1.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/post/img-2.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/post/img-3.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/post/img-4.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/category/img6.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div className="photo-card small" data-src={"/assets/images/category/img3.jpg"} data-uk-img=""></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};
export default connect(mapStateToProps)(Chats);