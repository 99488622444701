function timeAgo(previous) {

  const current = new Date();
  const timestamp = current.getTime();
  const tesDate = new Date(previous);
  const t = tesDate.getTime();

  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;

  let elapsed = timestamp - t;
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' detik lalu';
  }

  else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' menit lalu';
  }

  else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' jam lalu';
  }

  else if (elapsed < msPerMonth) {
    return 'sekitar ' + Math.round(elapsed / msPerDay) + ' hari lalu';
  }

  else if (elapsed < msPerYear) {
    return 'sekitar ' + Math.round(elapsed / msPerMonth) + ' bulan lalu';
  }

  else {
    return 'sekitar ' + Math.round(elapsed / msPerYear) + ' tahun lalu';
  }
}




export default timeAgo;