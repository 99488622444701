import React from "react";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import ActivityLogItem from "./ActivityLogItem";

const api = `${process.env.REACT_APP_API}`;

export default class ActivityLeftBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log: [],
      start_date: this.dateNow(),
      end_date: this.dateNow()
    }
    this.updateInputStart = this.updateInputStart.bind(this)
    this.updateInputEnd = this.updateInputEnd.bind(this)
    this.filterLog = this.filterLog.bind(this)
  }

  componentDidMount() {
    this.getLog()
  }

  getLog() {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken
      }
    }

    axios.post(api + "log/get-log", config)
      .then(res => {
        this.setState({
          log: res.data.data.data
        })
      })
  }

  filterLog() {
    let jwtToken = localStorage.getItem('jetToken')
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken
      }
    }

    axios.post(api + "log/get-log", {
      start_date: this.state.start_date,
      end_date: this.state.end_date
    }, config).then(res => {
      this.setState({
        log: res.data.data.data
      })
    })
  }

  dateNow() {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }
    return year+"-"+month+"-"+day
  }

  updateInputStart(e) {
    this.setState({
      start_date: e.target.value
    })
  }

  updateInputEnd(e) {
    this.setState({
      end_date: e.target.value
    })
  }

  render() {
    return (
      // <!-- sidebar -->
      <div className="main_sidebar">
        <div className="side-overlay" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></div>

        {/* // <!-- sidebar header --> */}
        <div className="sidebar-header">
          <h4> Menu Tema Grup</h4>
          <span className="btn-close" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></span>
        </div>

        {/* <!-- sidebar Menu --> */}
        <div className="sidebar">
          <div className="sidebar_innr" data-simplebar>
            <div className="sections mx-2">
              <div className="p-2 bg-white" uk-sticky="offset: 64">
                <h4 className="mb-0 pt-2">Log Aktivitas</h4>
                <form className="uk-flex uk-flex-wrap" noValidate>
                  <TextField
                    id="date"
                    label="Mulai"
                    type="date"
                    defaultValue={this.dateNow()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="w-100 mt-3"
                    onChange={this.updateInputStart}
                  />
                  <TextField
                    id="date"
                    label="Sampai"
                    type="date"
                    defaultValue={this.dateNow()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="w-100 mt-3"
                  />
                  <button type="button" onClick={this.filterLog} className="btn btn-schoolmedia w-100 mt-2">Cari</button>
                </form>
              </div>
              <hr className="m-0" />

              <ul className="mt-2">
                {
                  this.state.log.length ?
                    this.state.log.map((log) => {
                      return (
                        <ActivityLogItem information={log.information} date={log.created_at} />
                      )
                    })
                    :
                    <li>
                      <a href="#" className="p-0" style={{ whiteSpace: 'unset' }}>
                        <div className="uk-flex uk-flex-top p-2 w-100">
                          <div className="page-card-innr" style={{ margin: "0 10px" }}>
                            <h3 className="small text-dark text-center"> Tidak ada log </h3>
                          </div>
                        </div>
                      </a>
                    </li>
                }
              </ul>

            </div>

            <div className="sections">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
