import ButtonAddFriend from 'component/partials/global/ButtonAddFriend';
import React from 'react';
import { Link } from 'react-router-dom';
import ProfileHeader from './components/ProfileHeader';
import SkeletonList from 'component/partials/skeleton/SkeletonList';
import { connect } from 'react-redux';
import axios from 'axios';

const api = `${process.env.REACT_APP_API}`;

class UserFriends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: null,
      userName: null,
      myUserName: null,
      dataFriend: [],
      countToScroll: 1,
      loadingGetFriend: false,
      loadingNextFriend: false,
    }
    this._getFriend = this._getFriend.bind(this);
  }

  componentDidMount() {
    const path = window.location.pathname;
    let username = path.split("/").slice(1)[0]
    this.setState({
      userName: username,
      loadingGetFriend: true
    }, function () {
      this._getFriend(username)
    })
  }

  static getDerivedStateFromProps(nextProps, state) {
    console.log(nextProps)
    if (nextProps.auth != state.auth) {
      return ({
        myUserName: nextProps.auth.user.username
      })
    }
  }

  // temporarily disabled
  _getFriend(e) {
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    let params = {
      username: e,
      type: "all",
      page: 1,
    }
    if (this.state.myUserName === this.state.userName) {
      params = '';
    }
    axios.post(api + 'friend/get-friend', params, config)
      .then(res => {
        this.setState({
          dataFriend: res.data.data.items,
          loadingGetFriend: false,
          loadingNextFriend: false,
        })
      })
      .catch(err => {
        this.setState({
          countToScroll: this.setState.countToScroll,
          loadingGetFriend: false,
          loadingNextFriend: false,
        })
      })
  }

  render() {
    if (this.state.myUserName === this.state.userName) {
      return (
        <div className="main_content">
          <div className="main_content_inner pt-0">
            <ProfileHeader
              userName={this.state.userName}
            />
            <div className="section-small">
              <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
                <div className="uk-flex uk-flex-between">
                  <h2>Teman Saya</h2>
                  <div className="uk-flex uk-flex-column">
                    <button className="button small btn-schoolmedia">Permintaan Pertemanan</button>
                  </div>
                </div>

                <div className="uk-flex uk-flex-between">
                  <ul className="uk-tab"
                    uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                    <li className="uk-active uk-flex uk-flex-row">
                      <a href="#" className="uk-flex uk-flex-row">
                        {/* <i className="icon-feather-home mr-2"></i> */}
                                Semua Teman
                                {/* <p style={{fontSize: '.7rem'}}>235 Orang</p> */}
                      </a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Baru Ditambahkan</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Ulang Tahun</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Pekerjaan</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Lainnya</a>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="uk-switcher uk-margin" id="component-tab-left">
                <li>
                  <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                    <ul className="uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid">
                      {this.state.loadingGetFriend ?
                        <>
                          <SkeletonList /> <SkeletonList /> <SkeletonList />
                        </> :
                        this.state.dataFriend.length > 0 ?
                          this.state.dataFriend.map((value, key) => (
                            <li>
                              <div>
                                <div className="list-items">
                                  <Link to={"/" + value.username}>
                                    <div className="list-item-media">
                                      {value.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${value.group_code}/photo/${value.photo_profile}`} alt="" /> :
                                        <img src='/assets/icons/user.svg' />
                                      }
                                    </div>
                                  </Link>
                                  <div className="list-item-content">
                                    <Link to={"/" + value.username}>
                                      <h5> {value.name} </h5>
                                    </Link>
                                    <div className="uk-flex uk-flex-column">
                                      <span className="font-xs">{value.friends_total} Teman</span>
                                      <button className="button small btn-schoolmedia">Pesan</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                          ) : <div>Tidak ada teman</div>
                      }
                      {this.state.loadingNextFriend ?
                        <>
                          <SkeletonList /> <SkeletonList /> <SkeletonList />
                        </> :
                        <>

                        </>
                      }
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main_content">
          <div className="main_content_inner pt-0">
            <ProfileHeader
              userName={this.state.userName}
            />
            <div className="section-small">
              <div className="bg-white uk-padding pt-4 pr-4 pl-4 pb-0 rounded shadow">
                <div className="uk-flex uk-flex-between">
                  <h2>Teman {this.state.userName}</h2>
                  <div className="uk-flex uk-flex-column">
                    {/* <button className="button small btn-schoolmedia">Permintaan Pertemanan</button> */}
                  </div>
                </div>

                <div className="uk-flex uk-flex-between">
                  <ul className="uk-tab"
                    uk-switcher="connect: #component-tab-left; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                    <li className="uk-active uk-flex uk-flex-row">
                      <a href="#" className="uk-flex uk-flex-row">
                        {/* <i className="icon-feather-home mr-2"></i> */}
                                Semua Teman
                                {/* <p style={{fontSize: '.7rem'}}>235 Orang</p> */}
                      </a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Baru Ditambahkan</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Ulang Tahun</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Pekerjaan</a>
                    </li>
                    <li><a href="#">
                      {/* <i className="icon-feather-message-square mr-2"></i> */}
                        Lainnya</a>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="uk-switcher uk-margin" id="component-tab-left">
                <li>
                  <div className="bg-white uk-padding pt-2 pl-2 pr-2 pb-2 rounded shadow">
                    <ul className="uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid">
                      {this.state.loadingGetFriend ?
                        <>
                          <SkeletonList /> <SkeletonList /> <SkeletonList />
                        </> :
                        this.state.dataFriend.map((value, key) => (
                          <li>
                            <div>
                              <div className="list-items">
                                <Link to={"/" + value.username}>
                                  <div className="list-item-media">
                                    {value.photo_profile != null ? <img src={`https://static.schoolmedia.id/public/assets/socmed/${value.group_code}/photo/${value.photo_profile}`} alt="" /> :
                                      <img src='/assets/icons/user.svg' />
                                    }
                                  </div>
                                </Link>
                                <div className="list-item-content">
                                  <Link to={"/" + value.username}>
                                    <h5> {value.name} </h5>
                                  </Link>
                                  <div className="uk-flex uk-flex-column">
                                    <span className="font-xs">{value.friends_total} Teman</span>
                                    <ButtonAddFriend 
                                      username={value.username} 
                                      isfriend={value.is_friend} 
                                      isrequest={value.is_requested ? true : false}
                                    />
                                    {/* <ButtonAddFriend username={this.state.userName} /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                        )
                      }
                      {this.state.loadingNextFriend ?
                        <>
                          <SkeletonList /> <SkeletonList /> <SkeletonList />
                        </> :
                        <>

                        </>
                      }
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, null)(UserFriends);