import React from 'react';
import ReactPlayer from 'react-player';

export default class NewsDetail extends React.Component {
  render() {
    return (
      <>
        <div className="main_content">
          <div className="main_content_inner">
            <div className="uk-grid">
              <div className="uk-width-3-4@m">
                <div className="embed-video rounded">
                  {/* <iframe className="uk-responsive-width" src="https://www.youtube.com/embed/ae3ju4rEGgQ" frameborder="0" uk-video="automute: true" allowfullscreen uk-responsive></iframe> */}
                  <ReactPlayer
                    url='http://clips.vorwaerts-gmbh.de/VfE_html5.mp4' 
                    controls={true}
                    width='100%'
                    height
                  />
                  {/* <video src="https://yootheme.com/site/images/media/yootheme-pro.mp4" controls playsinline hidden uk-video></video> */}
                </div>

                <div className="p-3">
                  <h2 className="mt-lg-5 "> GREAT RC JET MODEL SHOW WITH 2X SUKHOI SU-30 MK ELSTER JET TEAM FLIGHT TO MUSIC </h2>
                  <p>299 views • <span> Sep 18, 2019 </span> </p>

                  <div className="uk-card-default rounded px-3 pb-md-3" uk-toggle="cls: uk-flex uk-flex-between@m uk-flex-middle; mode: media; media: @m">
                    <div className="user-details-card">
                      <div className="user-details-card-avatar">
                        <img src="assets/images/avatars/avatar-2.jpg" alt="" />
                      </div>
                      <div className="user-details-card-name">
                        Stella Johnson <span> Developer <span> 1 year ago </span> </span>
                      </div>
                    </div>
                    <div>
                      <strong className="mx-3 uk-visible@s"> Share on </strong>
                      <a href="#" className="button facebook transition-3d-hover">
                        <i className="icon-brand-facebook-f"></i>
                      </a>
                      <a href="#" className="button  primary transition-3d-hover">
                        <i className="icon-brand-twitter"></i>
                      </a>
                      <a href="#" className="button btn-red transition-3d-hover">
                        <i className="icon-brand-google-plus-g"></i>
                      </a>
                    </div>
                  </div>

                  <h3 className="mt-lg-6 mt-3"> Description</h3>
                  <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod
                  tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.
                            </p>

                  <p> Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                  ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                  amet.
                            </p>

                  <hr />

                  <div className="comments mt-lg-6 mt-3">
                    <h3>
                      Comments (5201)
                                    <span className="comments-amount">5210</span>
                    </h3>

                    <ul>
                      <li>
                        <div className="comments-avatar">
                          <img src="assets/images//avatars/avatar-1.jpg" alt="" />
                        </div>
                        <div className="comment-content">
                          <div className="comment-by">
                            Jonathan Madano <span>Student</span>
                            <a href="#" className="reply">
                              <i className="icon-line-awesome-undo"></i> Reply
                                                </a>
                          </div>
                          <p> Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                          nonummy nibh
                          euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                          enim ad
                          minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
                          nisl ut
                          aliquip ex ea commodo consequat. Nam liber tempor cum soluta nobis
                          eleifend
                          option
                          congue
                                            </p>
                        </div>

                        <ul>
                          <li>
                            <div className="comments-avatar">
                              <img src="assets/images/avatars/avatar-2.jpg" alt="" />
                            </div>
                            <div className="comment-content">
                              <div className="comment-by">
                                Stella Johnson<span>Student</span>
                                <a href="#" className="reply">
                                  <i className="icon-line-awesome-undo"></i>
                                                            Reply
                                                        </a>
                              </div>
                              <p> Nam liber tempor cum soluta nobis eleifend option congue ut
                              laoreet
                              dolore
                              magna aliquam erat volutpat nihil imperdiet doming id quod mazim
                              placerat
                              facer possim assum. Lorem ipsum dolor sit amet
                                                    </p>
                            </div>
                          </li>
                          <li>
                            <div className="comments-avatar">
                              <img src="assets/images/avatars/avatar-3.jpg" alt="" />
                            </div>
                            <div className="comment-content">
                              <div className="comment-by">
                                Adrian Mohani <span>Student</span>
                                <a href="#" className="reply">
                                  <i className="icon-line-awesome-undo"></i>
                                                            Reply
                                                        </a>
                              </div>
                              <p>tempor cum soluta nobis eleifend option congue ut laoreet dolore
                              magna
                              aliquam erat volutpat.
                                                    </p>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="comments-avatar">
                          <img src="assets/images/avatars/avatar-4.jpg" alt="" />
                        </div>
                        <div className="comment-content">
                          <div className="comment-by">Alex Dolgove<span>Student</span>
                            <a href="#" className="reply">
                              <i className="icon-line-awesome-undo"></i>
                                                    Reply
                                                </a>
                          </div>
                          <p>Nam liber tempor cum soluta nobis eleifend option congue ut laoreet
                          dolore magna
                          aliquam erat volutpat nihil imperdiet doming id quod mazim placerat
                          facer possim
                          assum. Lorem ipsum dolor sit amet
                                            </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <hr />
                  <div className="comments">
                    <h3>Add Comment </h3>
                    <ul>
                      <li>
                        <div className="comment-content p-0">
                          <form className="uk-grid-small uk-grid" uk-grid="">
                            <div className="uk-width-1-2@s uk-first-column">
                              <label className="uk-form-label">Name</label>
                              <input className="uk-input" type="text" placeholder="Name" />
                            </div>
                            <div className="uk-width-1-2@s">
                              <label className="uk-form-label">Email</label>
                              <input className="uk-input" type="text" placeholder="Email" />
                            </div>
                            <div className="uk-width-1-1@s uk-grid-margin uk-first-column">
                              <label className="uk-form-label">Comment</label>
                              <textarea className="uk-textarea" placeholder="Enter Your Comments her..." style={{ height: "160px" }}></textarea>
                            </div>
                            <div className="uk-grid-margin uk-first-column">
                              <input type="submit" value="submit" className="button btn-schoolmedia" />
                            </div>
                          </form>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*<!-- sidebar -->*/}
              <div className="uk-width-expand@m">
                <div uk-sticky="media :@m ; bottom: true ; offset:86">
                  <h4> Related Videos </h4>

                  <a href="single-video.html" className="video-post video-post-small">
                    {/*<!-- Blog Post Thumbnail -->*/}
                    <div className="video-post-thumbnail">
                      <span className="video-post-time">23:00</span>
                      <span className="play-btn-trigger"></span>
                      <img src="/assets/images/video/img-5.png" alt="" />
                    </div>

                    {/*<!-- Blog Post Content -->*/}
                    <div className="video-post-content">
                      <h3> Amazing Little Girl's VOICE LESSON w/Vocal Coach </h3>
                    </div>
                  </a>

                  <a href="single-video.html" className="video-post video-post-small">
                    {/*<!-- Blog Post Thumbnail -->*/}
                    <div className="video-post-thumbnail">
                      <span className="video-post-time">23:00</span>
                      <span className="play-btn-trigger"></span>
                      <img src="/assets/images/video/img-2.png" alt="" />
                    </div>

                    {/*<!-- Blog Post Content -->*/}
                    <div className="video-post-content">
                      <h3> GREAT RC JET MODEL SHOW WITH 2X SUKHOI SU-30 </h3>
                    </div>
                  </a>

                  <a href="single-video.html" className="video-post video-post-small">
                    {/*<!-- Blog Post Thumbnail -->*/}
                    <div className="video-post-thumbnail">
                      <span className="video-post-time">23:00</span>
                      <span className="play-btn-trigger"></span>
                      <img src="/assets/images/video/img-3.png" alt="" />
                    </div>

                    {/*<!-- Blog Post Content -->*/}
                    <div className="video-post-content">
                      <h3> Exploring an Abandoned Water Park in China </h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}