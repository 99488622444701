import { SEARCH_SUCCESS, SEARCH_ERROR, SEARCH_MORE, SEARCHING } from "redux/actions/types";

// Global State
const globalState = {
  data: [],
  searchKey: null,
  loading: false,
  loadingMore: false,
}

// Reducer
const searchReducer = (state = globalState, action) => {
  if(action.type === SEARCH_SUCCESS){
    let temp = state.data;
    // action.data.forEach(item => {
    //   temp.push(item)
    // })
    return{
      ...state,
      data: action.data,
      loading: false,
      loadingMore: false,
    }
  } else if(action.type === SEARCH_ERROR){
    return{
      ...state,
      loading: false,
      loadingMore: false,
    }
  } else if (action.type === SEARCH_MORE){
    return{
      ...state,
      searchKey: action.searchKey,
      loadingMore: true,
    }
  } else if (action.type === SEARCHING){
    return{
      ...state,
      searchKey: action.searchKey,
      loading: true,
    }
  }
  return state;
}

export default searchReducer;