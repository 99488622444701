import React from "react";
import { Link } from "react-router-dom";
import JoinedGroupCard from "../components/JoinedGroupCard";
import SuggestedGroupCard from "../components/SuggestedGroupCard";
import * as ForumAction from "redux/actions/ForumAction";
import { connect } from 'react-redux';

class GroupLevel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listForum: [],
      levelForum: null
    }
  }
  componentDidMount() {
    if(this.state.listForum.length === 0){
      this.getForumList()
    }
  }
  getForumList() {
    let { dispatch } = this.props;
    let action = ForumAction.getForumList()

    dispatch(action)
  }
  static getDerivedStateFromProps(props, state) {
    if (props.forumReducer != state.forumReducer) {
      if (props.forumReducer.listForum.length > 0) {
        let list = props.forumReducer.listForum
        let group = list.reduce((r, a) => {
          r[a.forum_category_id] = [...r[a.forum_category_id] || [], a];
          return r;
        }, {});
        // let res = JSON.parse(group)
        const url = window.location.pathname;
        const level = url.charAt(url.length - 1)
        let listForum = group[level]
        if(listForum === undefined){
          listForum = []
        }
        return ({
          levelForum: level,
          listForum: listForum
        })
      }
      else {
        const url = window.location.pathname;
        const level = url.charAt(url.length - 1)
        return ({
          levelForum: level,
          listForum: props.forumReducer.listForum
        })
      }
    }
  }
  render() {
    return (
      <>
        <div className="main_sidebar">
          <div className="side-overlay" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></div>

          {/* // <!-- sidebar header --> */}
          <div className="sidebar-header">
            <h4> Menu Tema Grup</h4>
            <span className="btn-close" uk-toggle="target: #wrapper ; cls: collapse-sidebar mobile-visible"></span>
          </div>

          {/* <!-- sidebar Menu --> */}
          <div className="sidebar">
            <div className="sidebar_innr" data-simplebar>
              <div className="sections">
                <ul>
                  <li className="sidebar-profile pt-2 pb-2">
                    <Link to="/groups">
                      <img src="/assets/images/avatars/avatars-1.png" alt=""></img>
                      <span className="uk-flex uk-flex-column" style={{ lineHeight: "1.5", display: "flex" }}> Grup Level {this.state.levelForum}
                        {/* <span className="font-xs">SMKN 1 Jakarta</span> */}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <div className="p-0">
                      <input type="text" className="form-control" placeholder="Cari grup" />
                    </div>
                  </li>
                </ul>
                <h3 className="mt-4"> Grup </h3>
                <ul>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/groups/example-group">
                      <div className="uk-flex uk-flex-middle p-2 w-100">
                        <div className="page-card-media">
                          <img src="/assets/images/avatars/avatar-7.jpg" alt="" className="mr-0" style={{ width: "40px", height: "40px" }} />
                        </div>
                        <div className="page-card-innr" style={{ margin: "0 10px" }}>
                          <h5 className="mb-0"> Grup Lainnya </h5>
                          <p className="small">1 Postingan baru</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>

              </div>

              <div className="sections">
              </div>
            </div>
          </div>
        </div>
        <div className="main_content" style={{ background: "none" }}>
          <div className="main_content_inner my-3">

            <div className="uk-flex uk-flex-middle uk-flex-between mb-3">
              <h1 className="mb-0">Grup Tetap Saya</h1>
              <button className="button btn-schoolmedia"><i className="uil-plus"></i> Buat Grup</button>
            </div>
            <div className="uk-position-relative" uk-slider="finite: true">
              <div className="uk-slider-container pb-3">
                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s  pr-lg-1 uk-grid" uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-small; delay: 100">
                  {this.state.listForum != undefined ?
                    this.state.listForum.length != 0 ?
                    this.state.listForum.map(val => {
                      return (
                        <JoinedGroupCard data={val} />
                      )
                    }) : <div>Tidak ada forum</div>
                    : <div>Tidak ada forum di level ini</div>
                  }
                </ul>

                <a className="uk-position-center-left-out uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous"></a>
                <a className="uk-position-center-right-out uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next"></a>
              </div>
            </div>

            <hr />

            <div className="uk-flex uk-flex-middle uk-flex-between mb-3">
              <h1 className="mb-0">Rekomendasi Grup Tetap</h1>
            </div>
            <div className="uk-flex uk-flex-wrap uk-width-1-1 uk-child-width-1-4@m uk-child-width-1-2@s" uk-grid uk-height-match="target: > .topik-item > .course-card-category; row: false">
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
              <SuggestedGroupCard className="p-3" />
            </div>

          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    forumReducer: state.forumReducer
  }
}

export default connect(mapStateToProps)(GroupLevel)
