import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import ProfileNI from './form-profile/ProfileNI';
import ProfileGU from './form-profile/ProfileGU';
import ProfileSI from './form-profile/ProfileSI';
import ProfileOW from './form-profile/ProfileOW';
import ProfileLA from './form-profile/ProfileLA';
import ProfilePU from './form-profile/ProfilePU';


const api = `${process.env.REACT_APP_API}`;
let jwtToken = localStorage.getItem("jwtToken");
let config = {
  headers: {
    Authorization: "Bearer " + jwtToken,
    'Content-Type': 'multipart/form-data'
  },
};

export default class FormProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataNI: {},
      dataGU: {},
      dataSI: {},
      dataOW: {},
      dataLA: {},
      dataPU: {},
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data.group_code === 'NI') {
      this.setState({
        dataNI: nextProps.data
      })
    } else if (nextProps.data.group_code === 'GU'){
      this.setState({
        dataGU: nextProps.data
      })
    } else if (nextProps.data.group_code === 'SI'){
      this.setState({
        dataSI: nextProps.data
      })
    } else if (nextProps.data.group_code === 'OW'){
      this.setState({
        dataOW: nextProps.data
      })
    } else if (nextProps.data.group_code === 'LA'){
      this.setState({
        dataLA: nextProps.data
      })
    } else if (nextProps.data.group_code === 'PU'){
      this.setState({
        dataPU: nextProps.data
      })
    }

  }
  formRender() {
    switch (this.props.data.group_code) {
      case 'NI':
        return (
          <>
            <ProfileNI data={this.state.dataNI}/>
          </>
        )
        break;
      case 'GU':
        return (
          <>
            <ProfileGU data={this.state.dataGU}/>
          </>
        )
        break;
      case 'SI':
        return (
          <>
            <ProfileSI data={this.state.dataSI}/>
          </>
        )
        break;
      case 'OW':
        return (
          <>
           <ProfileOW data={this.state.dataOW}/>
          </>
        )
        break;
      case 'LA':
        return (
          <>
          <ProfileLA data={this.state.dataLA}/>
          </>
        )
        break;
      case 'PU':
        return (
          <>
          <ProfilePU data={this.state.dataPU}/>
          </>
        )
        break;
    }
  }
  render() {
    return (
      <>
        {this.formRender()}
      </>
    )
  }
}